import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const LeftArrow = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "24px"} height={props.height || "24px"}
        preserveAspectRatio="xMidYMid meet"
      >
        <path xmlns="http://www.w3.org/2000/svg" fill={props.fill || "white"} d="M6.4 18 5 16.6 9.575 12 5 7.4 6.4 6l6 6Zm6.6 0-1.4-1.4 4.575-4.6L11.6 7.4 13 6l6 6Z" />
      </svg>
    </span>
  );
};

export default LeftArrow;
