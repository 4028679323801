import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Plus = (props) => {
  return (
    <span onclick={(e) => props.action && props.action(e)} class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""}   version="1.1" id="Layer_1" x="0px" y="0px" width={props.width || "14px"} height={props.height || "14px"} style={`enable-background:new 0 0 330 330; ${props.style || ''}`} fill={props.fill || ''}
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">

        <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
    </span>
  );
};

export default Plus;
