/*
  Modification: Added BigRibbon SVG
  By: Komal
  Date: 30/03/2023
*/
import { h } from 'preact';

const BigRibbon = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg width={props.width || "24"} height={props.height || "24"} viewBox={props.viewBox || "0 0 24 24"} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 0H0V24H24L11.68 12L24 0Z" fill={props.fill || "#FF3737"}/>
      </svg>
    </span>
  );
};

export default BigRibbon;
