import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
/*
  Modification: add class
  By: Komal Wable
  Date: 19/04/2023
*/
const Edit2 = (props) => {
  return (
    <span title={props.title || ''} class={props.masterClasses || ''}>
      <svg id={props.id} class={props.classes || ""} xmlns="http://www.w3.org/2000/svg" width={props.width || "10"} height={props.width || "10"} fill={props.fill || "none"} viewBox="0 0 10 10" >
        <path d="M4.6 1.8H1.8C1.58783 1.8 1.38434 1.88429 1.23431 2.03432C1.08429 2.18435 1 2.38783 1 2.6V8.2C1 8.41218 1.08429 8.61566 1.23431 8.76569C1.38434 8.91572 1.58783 9 1.8 9H7.4C7.61217 9 7.81566 8.91572 7.96568 8.76569C8.11571 8.61566 8.2 8.41218 8.2 8.2V5.4" stroke={props.stroke || "black"} stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.56235 1.24639C7.72012 1.08863 7.93409 1 8.1572 1C8.38031 1 8.59428 1.08863 8.75204 1.24639C8.90981 1.40416 8.99844 1.61813 8.99844 1.84124C8.99844 2.06435 8.90981 2.27832 8.75204 2.43608L4.98469 6.20344L3.39844 6.6L3.795 5.01375L7.56235 1.24639Z" stroke={props.stroke || "black"} stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};

export default Edit2;
