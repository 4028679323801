import { h, Component } from 'preact';
import { useState, useEffect,useRef } from "preact/hooks";
import axios from "axios";
import { route } from "preact-router";
import CONSTANTS from '../../lib/constants';
import { setItem, getItem, removeAll } from '../../lib/myStore';
import { modelMapping, getInitials, deepCopy , getDay, getMonth, getFormattedAmount,validateAadharNumber, typeOfEnquiryMappingClassColor, downloadCSV, getCompressedImageFile, vinNumberValidation, preventNumberSpecialChar, getDifferenceInDays } from '../../lib/utils';
import moment from "moment";
import { Home, Search, Clipboard, Cases, AddCall, PriceList,Notification, AddCase, ReceivePayment, Folder, Hyundai, Honda, Kia, Finance, Car, Accessories, Insurance, Vas, OngoingCall, Download, Upload, VB, Calculator, RoundStar, ViewedRecently, Analytics, Facility, Request, InteractionSearchSuggestion, History } from '../../components/svgs';
import toastr from "toastr";
import PDFSTYLE from "../../lib/pdfGenerationConfig";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import SemiInfoDetailCard from '../../components/semiInfoDetailCard';
import CallingCard from "../../components/callingCard";
import ListSingleCard from '../../components/listSingleCard';
import FormWizard from '../../components/formWizard';
import { Modal, ModalBody } from '../../components/rightDrawer';
import CreateEditForm from '../../components/createEditForm';
import SearchInputSuggestion from '../../components/searchInputSuggestion';
import { NewPopupModal, NewPopupModalBody } from '../../components/newPopupModal';
import Viewer from 'viewerjs';
import pincodeDirectory from 'india-pincode-lookup';
import { State, City }  from 'country-state-city';
import 'viewerjs/dist/viewer.css';
import imageCompression from 'browser-image-compression';
import DraggableCalculator from '../../components/draggableCalculator';
import RecentlyViewed from '../../components/recentlyViewed';
import ShowNotification from '../../components/showNotification';
import OrgChartModal from "../../components/orgChartModal";
import { callAPI, reportException } from '../../lib/transceiver';
import CoreEntityDynamicForm from '../../components/coreEntityDynamicForm';
import NewListV2 from '../../components/newListV2';
import SuggestionCard from '../../components/suggestionCard';
import { generateCancelTokenSource } from '../../lib/transceiver';

// import io from 'socket.io-client';
/*
  Modification: Added socketio
  By: Komal Wable
  Date: 19/04/2023
*/

let cancelTokenSource;

const GeneratePurchaseOrder = (props) => {
  let userInfo = getItem('userinfo');
  const {
    isMobileView,
    isCatalogueModalOpen,
    poStatus,
    functionBundle,
    isEdit,
    vendorDetail,
    purchaseOrderItems,
    setLimitUseEffect,
    getPurchaseOrderItem,
    setIsCatalogueModalOpen
  } = props;

  let [termsArray, setTermsArray] = useState([]);
  const [vendors, setVendors] = useState(null);
  let [vendorSelected, setVendorSelected] = useState(true);
  const [poType, setPoType] = useState("");
  const [isCatalogueLoading, setIsCatalogueLoading] = useState(false);
  {/*
    Modification:- PO Generation new field added (T178)
    Modified by:- Rajkumar Pradhan
    Modified on:- 20/02/2024
*/}
  let [inputBillingAddress, setInputBillingAddress] = useState('');
  let [isBillingAndDeliveryAddressSame, setIsBillingAndDeliveryAddressSame] = useState(false);
  let [isFullyPaid, setIsFullyPaid] = useState(false);
  let [inputDeliveryAddress, setInputDeliveryAddress] = useState('');
  let [inputRemark, setInputRemark] = useState('');
  let [inputSubject, setInputSubject] = useState('');
  let [inputValue, setInputValue] = useState('');
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [vendorDetails, setVendorDetails] = useState({});
  let [catalogueItems, setCatalogueItems] = useState([]);

  {/*
    Modification:- Master list of addresses - when the user types - shows all addresses with the same text
    By:- Rajkumar Pradhan
    On:- 17-05-2024
  */}
  const [selectedAddress, setSelectedAddress] = useState('');
  /*
    Modification:- setting Date for the vendor search
    By:- Rajkumar Pradhan
    On:- 01-03-2024
  */
  let [vendorResults, setVendorResults] = useState([]);
  let [isVendorLoading, setIsVendorLoading] = useState(false);
  const [poText, setPoText] = useState('');
  let [checkedList, setCheckedList] = useState([]);
  let [cataloguePayload, setCataloguePayload] = useState([]);
  let [isConfirmAllocationModalVisible, setIsConfirmAllocationModalVisible] = useState(false);

  const cancelTokenSource = useRef(null);


  let addressList = {
    Baner: "101, Phase3B, Ashtagandha, Baner, opp. KFC, Near Flour Works, Restrnt, Pune, Maharashtra 411045",
    Balewadi: "202, Phase3B, Ashtagandha, Baner, opp. KFC, Near Flour Works, Restrnt, Pune, Maharashtra 411045"
  };
  const purchaseOrderType = ["Consumable","Stationary","Printing","Uniform"];

  useEffect(() => {
    if(isEdit) {
      editPrefilled();
    }
  }, []);

  /*
    Modification:- Removed Vendor name setting useEffect
    By:- Rajkumar Pradhan
    On:- 01-03-2024
  */

  useEffect(async () => {
    if(vendorDetails && vendorDetails.uuid) {
      await getVendorCatalogue();
    }
  }, [vendorDetails]);

  useEffect(async () => {
    if(vendorDetails && vendorDetails.uuid && poType) {
      await getCatalogueItem();
    }
  }, [vendorDetails, poType]);

  /*
    modified : Ashutosh G
    modified : 06/10/2023
    modification : debouncing to get getCatalogueItem
  */
  useEffect(() => {
    let searchTimeout = setTimeout(async () => {
      if(vendorDetails && vendorDetails.uuid && poType) {
        await getCatalogueItem();
      }
    }, 500);

    return () => clearInterval(searchTimeout);
  }, [poText])


    const handleRateChange = (event, index, name, uuid) => {
      const { value } = event.target;
      let tempObj = [...cataloguePayload];
      tempObj.map((item) => {
        if (item.catalogueItemID === uuid) {
          item[name] = +value;
          item['catalogueItemTotal'] = calculateTotal(item.catalogueItemRate, item.catalogueItemGst)
          if (isCatalogueModalOpen) item['catalogueItemTotalRate'] = calculateTotalRate(item.catalogueItemRate, item.catalogueItemGst, item.dynamicProperties_quantity)
        }
      })
      setCataloguePayload(tempObj);
    };

    async function selectSingleCheckbox(e, index) {
      e.stopPropagation();
      const itemID = e.target.id;
      let checkedDataTemp = [...cataloguePayload];
      let existingIndex = checkedDataTemp.findIndex(item => item.catalogueItemID === itemID);
      if (!checkedList.includes(itemID)) {
        if (existingIndex !== -1 && checkedDataTemp[existingIndex].action === 'Inactive') {
          checkedDataTemp[existingIndex].action = 'Active';
        } else {
          const newItem = {
            thirdPartyID: vendorDetails.uuid,
            thirdPartyDisplayName: vendorDetails.dynamicProperties_displayName,
            catalogueItemID: itemID,
            catalogueItemDisplayName: e.target.name,
            catalogueItemRate: 0,
            catalogueItemGst: 0,
            catalogueItemTotal: 0,
            action: 'Active',
          };
          // checkedDataTemp.push(newItem);
        }
        checkedList.push(itemID);
      } else {
        const index = checkedList.indexOf(itemID);
        if (index > -1) {
          checkedDataTemp[existingIndex].catalogueItemRate = 0;
          checkedDataTemp[existingIndex].catalogueItemGst = 0;
          checkedDataTemp[existingIndex].catalogueItemTotal = 0;
          checkedDataTemp[existingIndex].action = 'Inactive';
          checkedList.splice(index, 1);
          if(!checkedDataTemp[existingIndex].uuid)  checkedDataTemp.splice(existingIndex, 1)
        }
      }
      setCataloguePayload([...checkedDataTemp]);
      setCheckedList([...checkedList]);
    };

    async function selectAllCheckboxes(e) {
      e.stopPropagation();
      let checkedDataTemp = [...cataloguePayload];

      if (catalogueItems && catalogueItems.length) {
        let checkedListTemp = checkedList;
        if (checkedListTemp.length === catalogueItems.length) {
          await Promise.all(checkedDataTemp.map((item, index) => {
            if (item.uuid) {
              item.catalogueItemRate = 0;
              item.catalogueItemGst = 0;
              item.catalogueItemTotal = 0;
              item.action = 'Inactive';
            } else {
              checkedDataTemp = checkedDataTemp.filter((obj) => obj.uuid !== item.uuid);
            }
          }));
          checkedListTemp = [];

        } else {
          checkedListTemp = [];
          await Promise.all(catalogueItems.map((item, index) => {
            const newItem = {
              thirdPartyID: vendorDetails.uuid,
              thirdPartyDisplayName: vendorDetails.dynamicProperties_displayName,
              catalogueItemID: item.uuid,
              catalogueItemDisplayName: item.dynamicProperties_displayName,
              catalogueItemRate: 0,
              catalogueItemGst: 0,
              catalogueItemTotal: 0,
              action: 'Active',
            };
            if (!checkedDataTemp.some((checkedItem) => checkedItem.catalogueItemID === item.uuid)) {
              checkedDataTemp.push(newItem);
            }
            checkedListTemp.push(`${item['uuid']}`);
          }));
        }
        setCheckedList && setCheckedList(checkedListTemp);
        setCataloguePayload(checkedDataTemp);
      }
    }

  const getCatalogueItem = async () => { // getting catalogueItem
    try {
      setIsCatalogueLoading(true);
      let paramsObject = {};
      if(Object.keys(vendorDetails)) {
        paramsObject["thirdPartyID"] = vendorDetails.uuid;
      }
      if(poType) {
        paramsObject["type"] = poType;
      }
      if(poText) {
        paramsObject["q"] = poText;
      }

      let response = await callAPI("get", "/v1/catalogueItems", new URLSearchParams(paramsObject).toString());
      if (response.data) setCatalogueItems(response.data);
      setIsCatalogueLoading(false);
    } catch (HTTPException) {
      setIsCatalogueLoading(false);
      let payload = {
        apiURL:(HTTPException && HTTPException.config && HTTPException.config.url) ? HTTPException.config.url : "",
        errorObject:HTTPException,
        statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
        errorMessage:HTTPException.message,
        functionName:"getCatalogueItem",
        fileName:"generatePurchaseOrder",
        pageName: "generatePurchaseOrder",
        userID:userInfo.uuid,
        userName:userInfo.displayName
      };
      await callAPI("post", "/v1/exception", payload);
      return HTTPException;
    }
  };

  const getVendorCatalogue = async () => { // getting selected user catalogueItem
    try {
      let response = await callAPI("get", "/v1/thirdPartyCatalogues", `thirdPartyID=${vendorDetails.uuid}`);
      if (response.data) {
        // setCatalogueListData(response.data);

        let checkedBoxValue = [];
        let inputBoxValue=[];
        response.data.map((item) => {
          // if (item.action === 'Active') {
          //   checkedBoxValue.push(item.catalogueItemID);
          // }
          //removing default value
          const { deletedAt, _id, __v, createdBy, updatedBy, createdAt, updatedAt, status, remark, ...filteredItem } = item;
          inputBoxValue.push(filteredItem);
        });
        setCheckedList(checkedBoxValue);
        setCataloguePayload(inputBoxValue);
      }
    } catch (HTTPException) {
      let payload = {
        apiURL:(HTTPException && HTTPException.config && HTTPException.config.url) ? HTTPException.config.url : "",
        errorObject:HTTPException,
        statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
        errorMessage:HTTPException.message,
        functionName:"getVendorCatalogue",
        fileName:"generatePurchaseOrder",
        pageName: "generatePurchaseOrder",
        userID:userInfo.uuid,
        userName:userInfo.displayName
      };
      await callAPI("post", "/v1/exception", payload);
      return HTTPException;
    }
  };

  const handleSelectChange = (event) => {
    const selectedKey = event.target.value;
    setSelectedAddress(selectedKey);
    handleBillingAddress(addressList[selectedKey])
    // setInputBillingAddress(address[selectedKey]);
  };

  const getSearchSuggestions = async (value) => {
      setIsVendorLoading(true);
      try {
          const response = await callAPI(`get`, `/v1/thirdParty?type=Vendor&searchVendor=${value}`, null, { cancelTokenSource: cancelTokenSource.current });
          setVendorResults(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setIsVendorLoading(false);
      setVendorSelected(false);
  };

  /*
    Modification:- Function to handle vendor search function
    By:- Rajkumar Pradhan
    On:- 01-03-2024
  */
  const handleVendorCall = async (value) => {
    cancelTokenSource.current = generateCancelTokenSource(cancelTokenSource.current);
    setVendors(value)
    await getSearchSuggestions(value);
  }

  const handleVendorChange = (value) => {
    setVendors(value.dynamicProperties_displayName);
    setVendorSelected(true);
    setVendor(value.uuid);
  }

  const handleBillingAddress = (selectedAddress) => {
    setInputBillingAddress(selectedAddress);
  }

  /*
    Modification:- Terms and Condition array will be set as soon as we set vendor (T178)
    By:- Rajkumar Pradhan
    On:- 21-02-2024
  */
  function setVendor(resultUUID) {
    let vendor = vendorResults.find(item => item.uuid === resultUUID);
    if (Object.keys(vendor).length > 0) {
      setTermsArray(vendor.dynamicProperties_termsAndConditions||[])
      setVendorDetails(vendor);
    }
  }

  /*
    Modification:- Po Generation handling of new field is taken care of (T178)
    Modified by:- Rajkumar Pradhan
    Modified on:- 20/02/2024
  */

  const handleInputChange = (e) => {
    setIsBillingAndDeliveryAddressSame(e.target.value === 'no');
    if(e.target.value === 'yes') {
      setInputDeliveryAddress(inputBillingAddress)
    } else {
      setInputDeliveryAddress('')
    }
  };

  const handleDeliveryAddress = (e) => {
    setInputDeliveryAddress(e.target.value);
  }

  const handleSubject = (e) => {
    setInputSubject(e.target.value);
  }

  const handleRemark = (e) => {
    setInputRemark(e.target.value);
  }

  /*
    Modified: Masum Raja
    Modified: 04/11/2023
    Modification: Added funtion for handle term and condition
  */
  const handleTermCondition = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddClick = () => {
    if (inputValue.trim() !== '') {
      const itemNumber = termsArray.length + 1;
      const newItem = `${itemNumber}. ${inputValue}`;
      setTermsArray([...termsArray, newItem]);
      setInputValue('');
    }
  };

  const handlePaidInputChange = (e) => {
    if(e.target.value === "no") {
      setIsFullyPaid(false)
    } else {
      setIsFullyPaid(true)
    }
  };

  const removeTermArrayValueInput = (e, indexToRemove) => {
    setTermsArray(prevTermsArray =>
      prevTermsArray.filter((_, index) => index !== indexToRemove)
        .map((item, index) => `${index + 1}. ${item.split('.').slice(1).join('.')}`)
    );
  }

  /*
    modified : Ashutosh G
    modified : 06/10/2023
    modification : filtered cataloguePayload variable and calling v1/purchaseOrder api
  */
  const generatePurchaseOrder = async () => {
    try {
      await setIsButtonLoading(true);
      let filteredPayload = cataloguePayload.filter(item => item.dynamicProperties_quantity).map(item => {
        let itemDetails = catalogueItems.find(catelogueItem => catelogueItem.uuid === item.catalogueItemID);
        return {
          "dynamicProperties_catalogueItemID": item.catalogueItemID,
          "dynamicProperties_catalogueItemName": item.catalogueItemDisplayName,
          "dynamicProperties_quantity": item.dynamicProperties_quantity,
          /*
            Bug: CDMS_ADMIN_FIXES_124 Input Workspace PO Details - Purchase Order Tracker - Download Purchase Order - Title	Itemwise Amount and Total Amount should be displayed
            Modification: Saved item,amount and totalRate of each catalogue
            By: Devang
            Date: 01/02/2024
          */
          /*
            Modification:- Po Generation new fields added (T178)
            Modified by:- Rajkumar Pradhan
            Modified on:- 20/02/2024
          */
          "dynamicProperties_catalogueItemRate": item.catalogueItemRate,
          "dynamicProperties_catalogueItemTotal": item.catalogueItemTotal,
          "dynamicProperties_catalogueItemTotalRate": item.catalogueItemTotalRate,
          "dynamicProperties_receivedQuantity": 0,
          "dynamicProperties_goodQuantity": 0,
          "dynamicProperties_defectiveQuantity": 0,
          "dynamicProperties_vendorID": item.thirdPartyID,
          "dynamicProperties_catalogueItemRate": item.catalogueItemRate,
          "dynamicProperties_catalogueItemGst": item.catalogueItemGst,
          "dynamicProperties_catalogueItemTotal": item.catalogueItemTotal,
          "dynamicProperties_typeOfCatalogue": itemDetails.dynamicProperties_typeOfCatalogue
        }
      });

      /*
        Modification:- No need for total quantity now as format has been changed
        By:- Rajkumar Pradhan
        On:- 04-03-2024
      */
      /*let dynamicProperties_totalQuantity = filteredPayload.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.dynamicProperties_quantity;
      }, 0);*/
      /*
        Modification:- Po Generation new fields added (T178)
        Modified by:- Rajkumar Pradhan
        Modified on:- 20/02/2024
      */
      let payload = {
        dynamicProperties_termAndConditions: termsArray,
        dynamicProperties_selectedAddress: selectedAddress,
        dynamicProperties_billingAddress: inputBillingAddress,
        dynamicProperties_deliveryAddress: isBillingAndDeliveryAddressSame ? inputDeliveryAddress : inputBillingAddress,
        dynamicProperties_vendorAddress: vendorDetails.dynamicProperties_address,
        dynamicProperties_subject: inputSubject,
        dynamicProperties_remark: inputRemark,
        dynamicProperties_catalogueItems: filteredPayload,
        dynamicProperties_vendorID: vendorDetails.uuid,
        dynamicProperties_status: "Ordered",
        dynamicProperties_defectiveQuantity: 0,
        dynamicProperties_goodQuantity: 0,
        dynamicProperties_receivedQuantity: 0,
        dynamicProperties_totalQuantity: 0,
        dynamicProperties_typeOfCatalogue: poType,
        dynamicProperties_createdBy: userInfo.name,
        dynamicProperties_isFullyPaid: isFullyPaid,
        poStatus: "draft-purchase-order"
      };
      await callAPI("post", "/v1/purchaseOrder", payload);

      await setIsButtonLoading(false);
      await getVendorCatalogue();
      setIsCatalogueModalOpen(false);
      toastr.success('PO generated successfully');
    } catch (HTTPException) {
      let payload = {
        apiURL:HTTPException.config.url,
        errorObject:HTTPException,
        statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
        errorMessage:HTTPException.message,
        functionName:"addVendorCatalogue",
        fileName:"generatePurchaseOrder",
        pageName: "generatePurchaseOrder",
        userID:userInfo.uuid,
        userName:userInfo.displayName
      };
      await setIsButtonLoading(false);
      setIsCatalogueModalOpen(false);
      await callAPI("post", "/v1/exception", payload);
      return HTTPException;
    }
  };

  const editPurchaseOrder = async () => {
    if(vendors !== vendorDetails.dynamicProperties_displayName || poType !== purchaseOrderItems.dynamicProperties_typeOfCatalogue) {
      setIsConfirmAllocationModalVisible(true);
    } else {
      let payload = {
        dynamicProperties_selectedAddress: selectedAddress,
        dynamicProperties_termAndConditions: termsArray,
        dynamicProperties_billingAddress: inputBillingAddress,
        dynamicProperties_deliveryAddress: inputDeliveryAddress,
        dynamicProperties_subject: inputSubject,
        dynamicProperties_remark: inputRemark,
      };
      let response = await callAPI("put", `/v1/purchaseOrder/${purchaseOrderItems.uuid}`, payload);
      if (response) {
        setLimitUseEffect(prevValue => !prevValue);
        await getPurchaseOrderItem(purchaseOrderItems.uuid);
        setIsCatalogueModalOpen(prevValue => !prevValue);
      }
    }
  }

  const confirmChanges = async () => {
    let payload = {
      dynamicProperties_termAndConditions: termsArray,
      dynamicProperties_selectedAddress: selectedAddress,
      dynamicProperties_billingAddress: inputBillingAddress,
      dynamicProperties_deliveryAddress: inputDeliveryAddress,
      dynamicProperties_subject: inputSubject,
      dynamicProperties_remark: inputRemark,
      dynamicProperties_catalogueItems: [],
      dynamicProperties_typeOfCatalogue: poType,
      dynamicProperties_vendorID: vendorDetails.uuid
    };
    let response = await callAPI("put", `/v1/purchaseOrder/${purchaseOrderItems.uuid}`, payload);
    if (response) {
      setLimitUseEffect(prevValue => !prevValue);
      await getPurchaseOrderItem(purchaseOrderItems.uuid);
      setIsCatalogueModalOpen(prevValue => !prevValue);
      setIsConfirmAllocationModalVisible(prevValue => !prevValue);
    }
  }

  const closeConfirmAllocationModalAndUpdate = () => {
    setIsConfirmAllocationModalVisible(false);
    setIsCatalogueModalOpen(prevValue => !prevValue)
  }

  async function editPrefilled() {
      setVendors(vendorDetail.dynamicProperties_displayName);
      setPoType(purchaseOrderItems.dynamicProperties_typeOfCatalogue);
      setInputSubject(purchaseOrderItems.dynamicProperties_subject);
      setInputBillingAddress(purchaseOrderItems.dynamicProperties_billingAddress);
      setSelectedAddress(purchaseOrderItems.dynamicProperties_selectedAddress);
      if(purchaseOrderItems.dynamicProperties_deliveryAddress) {
        setIsBillingAndDeliveryAddressSame(true);
        setInputDeliveryAddress(purchaseOrderItems.dynamicProperties_deliveryAddress);
      } else {
        setIsBillingAndDeliveryAddressSame(false);
      }
      setInputRemark(purchaseOrderItems.dynamicProperties_remark);
      setTermsArray([...termsArray,...purchaseOrderItems.dynamicProperties_termAndConditions]);
  }

  const toggleModal = (e) => {
    // setCatalogueItems([]);
    // setVendorDetails({});
    // setPoType("");
    // setPoText("");
    functionBundle.togglePOModal(e, "close")
  }

  return (
    <>
    <OrgChartModal isMobileView={isMobileView} title={`Purchase Order`} masterClasses={``} orgChartClasses={`${isMobileView ? 'h-full' : ''} w-85vw of-hidden`} isOpen={isCatalogueModalOpen} onClose={(e) => toggleModal(e) }>
      <div class="row">
      <div class="col-xs-12 col-lg-4 h-500px overflow-auto">
        { (poStatus === "draft-purchase-order" || poStatus === "new-purchase-order")&&
          <>
            <div class={`${isMobileView ? 'shadow5 fs-12 bor-rad-all-5' : ''} w-full input-box m-t-5`}>
            <span>Search Vendor</span>
            <div>
            <input
              type="text"
              placeholder="Search..."
              value={vendors}
              style={{ position:"relative" }}
              onChange={(e) => handleVendorCall(e.target.value)}
              onFocus={(e) => handleVendorCall(e.target.value)}
              onBlur={() => {
                // Delay setting vendorSelected to handle click on suggestion card
                setTimeout(() => {
                  setVendorSelected(true);
                }, 1000);
              }}
            />
            {!vendorSelected &&
              <SuggestionCard
              isHighlight
              highlightText={vendors}
              data={vendorResults}
              textUnderlined
              displayKey={'dynamicProperties_displayName'}
              isMobileView={isMobileView}
              suggestionAction={handleVendorChange}
              masterStyle={{
                borderTopRightRadius: '0',
                borderTopLeftRadius: '0',
                borderBottomRightRadius: '5px',
                borderBottomLeftRadius: '5px',
                zIndex: "1000",
                position: "absolute",
                backgroundColor: "white",
                width: "42%",
                padding: "0px",
                border: "1px solid black"
              }}
              icon={
                <div style={{ width: "24px", height: "24px", borderRadius: '100%', backgroundColor: '#F8F8F8', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <History  height="15px" width="15px"/>
                </div>}
            />
            }
            </div>
            </div>
            <div class={`${isMobileView ? 'shadow5 fs-12 bor-rad-all-5' : ''} w-full input-box m-t-5`}>
            <span>Select Type</span>
            <select class={`${isMobileView ? 'fs-10' : ''}`} name='type' value={poType} onChange={(e) => setPoType(e.target.value)}>
              <option value='' selected disabled>Select Type</option>
                {
                  purchaseOrderType.map((type, index) => (
                    <option value={type}>{type}</option>
                  ))
                }
            </select>
            </div>
          </>
        }
        <div class="w-full m-t-5 input-box">
          <div class='m-t-5'>Subject</div>
          <div class="display-flex">
            <input autocomplete="off" placeholder='Add Subject' class="m-b-0" value={inputSubject} onChange={handleSubject} />
          </div>
        </div>
        {/*
          Modification:- Fixed Radio font weight
          By:- Rajkumar Pradhan
          On:- 04-07-2024
        */}
        {poStatus === "new-purchase-order" &&
          <div class="w-full m-t-5 input-box">
            <div class='m-t-5'>Is it fully paid ?</div>
              <div class="display-flex m-t-5">
              <input type="radio" name="isFullyPaidSame" value="yes" checked={isFullyPaid ? true : false} id="fullyPaid-yes" onChange={handlePaidInputChange} />
              <label class="m-r-50 f-w-300" for="fullyPaid-yes" >Yes</label>
              <input type="radio" name="isFullyPaidSame" value="no" checked={isFullyPaid ? false : true} id="fullyPaid-no" onChange={handlePaidInputChange} />
              <label class="f-w-300" for="fullyPaid-no" >No</label>
            </div>
          </div>
        }
        <div class="w-full m-t-5 input-box">
          <div class='m-t-5'>Billing Location</div>
            <select value={selectedAddress} onChange={handleSelectChange}>
              <option value="">Select Address</option>
                {Object.keys(addressList).map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
            </select>
        </div>
        <div class="w-full m-t-5 input-box">
          <div class='m-t-5'>Billing Address</div>
          <div class="display-flex">
            <input autocomplete="off" placeholder='Add Billing Address' class="m-b-0" disabled value={inputBillingAddress} onChange={handleBillingAddress} />
          </div>
        </div>
        {/*
          Modification:- Fixed Radio font weight
          By:- Rajkumar Pradhan
          On:- 04-07-2024
        */}
        <div class="w-full m-t-5 input-box">
          <div>
            <div class='m-t-5'>Delivery address is same as billling address ?</div>
              <div class="display-flex m-t-5">
              <input type="radio" name="isBillingAndDeliveryAddressSame" value="yes" checked={isBillingAndDeliveryAddressSame ? false : true} id="billing-yes" onChange={handleInputChange} />
              <label class="m-r-50 f-w-300" for="billing-yes" >Yes</label>
              <input type="radio" name="isBillingAndDeliveryAddressSame" value="no" checked={isBillingAndDeliveryAddressSame ? true : false} id="billing-no" onChange={handleInputChange} />
              <label class="f-w-300" for="billing-no" >No</label>
            </div>
          </div>
        </div>
        {isBillingAndDeliveryAddressSame && (
          <div class="w-full m-t-5 input-box">
            <div class="m-t-5">
              <div>Delivery Address</div>
              <label>
                <input autocomplete="off" placeholder='Add Delivery Address' class="m-b-0" value={inputDeliveryAddress} onChange={handleDeliveryAddress} />
              </label>
            </div>
          </div>
        )}
        <div class="w-full m-t-5 input-box">
          <div class="m-t-5">Remark</div>
          <div class="display-flex">
            <input autocomplete="off" placeholder='Add Remark' class="m-b-0" value={inputRemark} onChange={handleRemark} />
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-lg-8">
        <div class="w-full m-t-5 input-box">
          <div class="m-t-5">Terms & Condition</div>
          <div class="display-flex">
            <input autocomplete="off" placeholder='Add Terms & Condition' class="m-b-0" value={inputValue} onChange={handleTermCondition} />
            <button class="m-l-16" onClick={handleAddClick}>ADD</button>
          </div>
          <div class={`h-420px w-full overflow-auto`}>
            {
              termsArray.map((value, index) => (
                <div class='display-flex flex-sb'>
                  <div>
                    {value}
                  </div>
                  <div class='text-color-red cursor-pointer m-r-10' onClick={(e) => removeTermArrayValueInput(e, index)}>X</div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
        <div class={`${isMobileView ? 'm-t-10' : 'm-t-15'} w-full flex-r-m`}>
          { isEdit ?
            <button class={`align-center f-w-400 m-r-10 noCloseImp primary-border-button${isButtonLoading ? ' buttonLoader' : ''}`} onClick={() => editPurchaseOrder()}>Edit PO</button> :
            <button class={`align-center f-w-400 m-r-10 noCloseImp primary-border-button${isButtonLoading ? ' buttonLoader' : ''}`} onClick={() => generatePurchaseOrder()}>Proceed to next step/Add items</button>
          }
        </div>
      </div>
    </OrgChartModal>
    {
      isConfirmAllocationModalVisible && (
        <OrgChartModal title={"Confirm Allocation"} masterClasses={``} orgChartClasses={`w-40vw`} isOpen={isConfirmAllocationModalVisible} onClose={() => closeConfirmAllocationModalAndUpdate()}>
          <div class="row">
            <div class="flex-c-m flex-direction-column m-b-16">
              <p class="fs-16 m-b-12">Changing the vendor or type will delect the selected items ?</p>
            </div>
            <div class="flex-r-m w-full">
              <button class="primary-button flex-c-m m-r-8" onClick={() => setIsConfirmAllocationModalVisible(false)}>Cancel</button>
              <button class="primary-button flex-c-m" onClick={() => confirmChanges()}>Confirm</button>
            </div>
          </div>
        </OrgChartModal>
      )
    }
    </>
  );
};

export default GeneratePurchaseOrder;
