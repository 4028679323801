import { route } from 'preact-router';
import { unProtectedRoutes } from './routes';

export class AppStore {
  static get(key) {
    /*
      Bug: COMP_BG_738 All Roles Mobile View - Honda / Kia - New , Renewal Mobile Back Button - Login Page	If the user is logged in and clicked on the back button of the mobile , the login page for few seconds
      Modification: Added conditions redireciting to / page, to prevent multiple redirects from same page
      By: Devang
      Date: 27/12/2023
    */
    /*
      Bug: T216 - BG_FN - After clicking on forgot password link , the page is getting auto refreshed. (Showstopper)
      Modification: Added set-new-password and set-password page to restrict from routing to / page
      By: Devang
      Date: 19/04/2024
    */
    /*
      Modification: T330 - Taking Authentication changed from KDMS - Updated condition value for includes operation
      By: Devang
      Date: 30/05/2024
    */
    /*
      Modification: T330 - Reverted below conditions did for COMP_BG_738 and rerouting as causing issue in showing sidebars as it directly routes the app without path parameter updating itself in app file and this condition is not needed because of logged in check useEffect
      By: Devang
      Date: 30/05/2024
    */
    /*console.log(unProtectedRoutes.includes(window.location.pathname), window.location.pathname, 'unProtectedRoutes.includes(window.location.pathname)')
    if (!window.localStorage.length && !unProtectedRoutes.includes(window.location.pathname)) {
      route(`/`);
    }*/
    if (!window.localStorage.getItem(key)  || window.localStorage.getItem(key) !== "undefined") {
      return JSON.parse(window.localStorage.getItem(key) || '{}');
    }
    return JSON.parse('{}');

  }
  static set(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
  static remove(key) {
    window.localStorage && window.localStorage.removeItem(key);
  }
  static removeAll() {
    window.localStorage && window.localStorage.clear();
  }
}
