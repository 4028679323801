import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Save = (props) => {
  return (
    <span class={`${props.masterClasses || ''} ${props.identityClass || ''}`} title={props.title || ''} onclick={(e) => props.action && props.action(e)}>
      <span class={`${props.isMobileView ? 'border-none-imp bg-none' : ''} flex-c-m save-png-container`}> <img src="/assets/images/gifs/save-static.png" class={`${props.hideStatic ? 'op-0-0' : ''} pos-absolute`} width={props.staticWidth || "23"} height={props.staticHeight || "23"}/> </span>
      <img src="/assets/images/gifs/save.gif" class={`${props.hideStatic ? '' : 'op-0-0'} active-gif ${props.classes || ""} ${props.identityClass || ''} pos-absolute`} width={props.width || "30"} height={props.height || "30"}/>
    </span>
  );
};

export default Save;
