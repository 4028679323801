import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const  IncomingCall = (props) => {
  return (
    <svg onClick={()=> props.action && props.action()} xmlns="http://www.w3.org/2000/svg" class={props.classes || ''} width={props.width || "26"} height={props.width || "26"}  fill={props.fill || "none"} viewBox="0 0 26 26">
      <path d="M17.7591 1.00012V8.22606H24.9993" stroke={props.stroke || "black"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M24.9993 1.00012L17.7591 8.22606" stroke={props.stroke || "black"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M24.9993 18.9686V22.5816C25.0006 22.917 24.9318 23.249 24.7971 23.5563C24.6625 23.8636 24.465 24.1395 24.2174 24.3662C23.9698 24.593 23.6774 24.7656 23.359 24.873C23.0407 24.9805 22.7033 25.0204 22.3686 24.9902C18.6554 24.5875 15.0885 23.3212 11.9547 21.293C9.03906 19.4439 6.56712 16.9769 4.7144 14.067C2.67503 10.9252 1.40589 7.34809 1.00979 3.62554C0.979639 3.29251 1.0193 2.95685 1.12624 2.63995C1.23319 2.32305 1.40508 2.03185 1.63097 1.78488C1.85686 1.53791 2.1318 1.34059 2.43829 1.20548C2.74478 1.07037 3.0761 1.00043 3.41115 1.00012H7.03129C7.61692 0.994364 8.18466 1.20133 8.62869 1.58245C9.07273 1.96356 9.36276 2.49281 9.44472 3.07155C9.59752 4.22778 9.88089 5.36305 10.2894 6.4557C10.4518 6.88675 10.4869 7.35522 10.3907 7.8056C10.2944 8.25597 10.0708 8.66938 9.7464 8.99682L8.21387 10.5263C9.9317 13.5414 12.4331 16.0378 15.4542 17.7522L16.9867 16.2228C17.3148 15.899 17.729 15.6758 18.1803 15.5798C18.6315 15.4837 19.1009 15.5188 19.5328 15.6808C20.6277 16.0885 21.7652 16.3713 22.9237 16.5238C23.5099 16.6064 24.0452 16.901 24.4279 17.3518C24.8106 17.8026 25.0139 18.378 24.9993 18.9686Z" stroke={props.stroke || "black"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

export default IncomingCall;
