import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Send = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg onclick={() => props.action && props.action()} xmlns="http://www.w3.org/2000/svg" version="1.0" class={`${props.classes || ""}`} width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M2488 3857 c-1329 -694 -2426 -1271 -2438 -1282 -35 -31 -50 -70 -50 -126 0 -62 27 -112 73 -136 17 -9 332 -119 700 -245 560 -191 672 -226 685 -216 13 10 1539 1314 2607 2227 l170 146 -76 -90 c-324 -385 -2080 -2508 -2076 -2511 3 -3 1725 -594 2202 -756 105 -35 185 -14 229 62 15 25 83 465 312 2003 162 1085 294 1997 294 2027 0 100 -60 160 -160 160 -53 -1 -123 -36 -2472 -1263z"/>
          <path d="M1860 858 c0 -513 0 -514 23 -558 28 -56 63 -81 120 -87 56 -7 106 9 140 45 51 53 597 806 590 812 -5 4 -197 71 -428 150 -231 79 -426 145 -432 148 -10 3 -13 -103 -13 -510z"/>
        </g>
      </svg>
    </span>
  );
};

export default Send;
