import { useState, useEffect } from 'preact/hooks';
import {formatDateTime,getInitials,typeOfEnquiryMappingStyleColor, typeOfPaymentMappingClassColor, isValidDate, formatDateTimeRelative} from '../../lib/utils';
import moment from "moment";
import {setItem, getItem } from '../../lib/myStore';
import { Clipboard } from '../../components/svgs';

const ListCard = (props) => {
  let [newDataSet, setDataset] = useState([]);
  let [selectedList, setSelectedList] = useState('');
  let [checkedIDs, setCheckedIDs] = useState(props.checkedList);
  let [isSortLatest, setIsSortLatest] = useState(props.isSortLatest);

  useEffect(() => {
    setCheckedIDs(props.checkedList);
  },[props.checkedList]);

  useEffect(() => {
    if (props.selectedList && props.selectedList.uuid) {
      setSelectedList(props.selectedList.uuid);
    } else {
      setSelectedList('');
    }
  },[props.selectedList]);

  useEffect(() => {
    setDataset(props.taskData);
    let currentTask = props.taskType === "dayPlanTask" ? getItem("currentTaskID") : getItem("currentRowID");
    if (Object.keys(currentTask).length) {
      setSelectedList(currentTask.uuid);
    }
  },[props.taskData]);

  useEffect(()=> {
    if (newDataSet && (props.taskType === "dayPlanTask" || props.taskType === "case") && newDataSet.rows) {
      let currentTask = props.taskType === "dayPlanTask" ? getItem("currentTaskID") : getItem("currentRowID");
      if (Object.keys(currentTask).length) {
        setSelectedList(currentTask.uuid);
      }
      props.showData(currentTask);
    }
  },[newDataSet]);

  useEffect(() => {
    setDataset(props.taskData);
    let currentTask = props.taskType === "dayPlanTask" ? getItem("currentTaskID") : getItem("currentRowID");
    if (Object.keys(currentTask).length) {
      setSelectedList(currentTask.uuid);
    }
  },[props.updateNextTask]);

  useEffect(() => {
    setIsSortLatest(props.isSortLatest);
  },[props.isSortLatest]);

  async function handleData(row, index) {
    await setSelectedList(row.uuid);
    props.taskType === "dayPlanTask" ? await setItem("currentTaskID", row) : await setItem("currentRowID", row);
    await props.showData(row, newDataSet[index+1]);
    if (props.setShowSemiDetailView) {
      props.setShowSemiDetailView(true);
    }
  }

  function deadlineDays(row) {
    if (row['dueDate']) {
      let z = moment(moment(row['dueDate']).format("DD/MM/YYYY"), 'DD-MM-YYYY');
      let y = moment(moment(new Date()).format("DD/MM/YYYY"), 'DD-MM-YYYY');
      let diffDays = y.diff(z, 'days');
      return diffDays;
    }
  }

  function renewalDeadlineDays(row) {
    if (row['dynamicProperties_endDate']) {
      let z = moment(moment(row['dynamicProperties_endDate']).format("DD/MM/YYYY"), 'DD-MM-YYYY');
      let y = moment(moment(new Date()).format("DD/MM/YYYY"), 'DD-MM-YYYY');
      let diffDays = y.diff(z, 'days');
      return diffDays;
    }
  }

  async function selectAllCheckboxes(e) {
    if (e.target.checked) {
      let checkedList = [];
      newDataSet.rows.map((row, index) => {
        // checkedList.push(`checkbox-${index}`);
        checkedList.push(`${row.uuid}`);
      });
      await setCheckedIDs(checkedList);
      await props.selectedRows(checkedList);
    } else {
      await setCheckedIDs([]);
      await props.selectedRows([]);
    }
  }

  async function selectSingleCheckbox(e) {
    e.preventDefault();
    let checkedList = checkedIDs;
    if (e.target.checked) {
      if (!checkedIDs.includes(e.target.id)) {
        checkedList.push(e.target.id);
      }
    } else {
      const index = checkedList.indexOf(e.target.id);
      if (index > -1) {
        checkedList.splice(index, 1);
      }
    }
    await setCheckedIDs(checkedList);
    await props.selectedRows(checkedList);
  }

  function routeToCaseView(row,index) {
    window.open(`/processView/${row.uuid}`, '_blank');
  }

  return (
    <div class="h-inherit">
      {/*
        Modification: removed index from the function call argument as it was not defined and returning error in inboxTask template
        By: Devang
        Date: 26/05/2022
      */}
      {props.taskType === "inboxTask" && newDataSet && newDataSet.length > 0 && newDataSet.map((row) => (
        <div class={`listCard m-b-5 pos-relative ${selectedList === row.uuid ? "text-black" : "text-white"}`} onClick={(e) => handleData(row)}>
          <div class='msgContainer cursor-pointer animatedHover min-h-inherit'  style={`${selectedList === row.uuid ? 'background:#d0e7f7; border-radius: 5px;' : ''}`}>
            <div class='display-flex justify-between align-center m-l-10 min-h-inherit'>
              <div class="display-flex align-center">
                <div class="h-24px w-24px">
                  <em className={`icon icon-file-text p-r-5 fs-14`} />
                </div>
                <div class="display-flex flex-direction-column m-l-10 m-r-10">
                  <p class={`fs-10 f-w-600 ${selectedList === row.uuid ? "text-white" : "text-grey"}`}>{row.userName}</p>
                  <p class={`fs-12 first-letter-capital listCardDescription ${selectedList === row.uuid ? "text-black" : "text-white"}`}>{row.message}</p>
                </div>
              </div>
              <div class={`m-r-30 tasks-tags bg-sunset-orange ${selectedList === row.uuid ? "bg-tag-bfbfbf" : ""}`}>
                <div class="display-flex flex-direction-column fs-10">
                  <p class="f-w-600" >{formatDateTime(row.createdAt)}</p>
                </div>
              </div>
            </div>
          </div>
          <span class="h-full pos-absolute color-white fs-9 text-center text-uppercase list-card-ribbon" style={`background:${ row.group === "task" ? "#20639B": row.group === "Approval"? "#2F9395":""}`}>{ row.group === "task" ? "Task" :(row.group === "Approval") ? "Approval":""}</span>
        </div>
      ))}
      {
        props.taskType === "dayPlanTask" && newDataSet && newDataSet.columns && newDataSet.rows && newDataSet.rows.length > 0 && (
          <div class="m-l-10 display-flex justify-flex-end align-center">
            {/*<input type="checkbox" checked={checkedIDs && (checkedIDs.length === newDataSet.rows.length)} onChange={(e) => selectAllCheckboxes(e)} class="cursor-pointer m-8" id="checkbox-all-dayplan" /><label for="checkbox-all-dayplan"/>*/}
            {/*<button class="filter-button m-b-5" title={`${isSortLatest ? 'Sort' : 'Sort Latest'}`} onClick={() => props.sortLatest(isSortLatest)}>
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 490 490">
                <g>
                	<polygon points="85.877,154.014 85.877,428.309 131.706,428.309 131.706,154.014 180.497,221.213 217.584,194.27 108.792,44.46    0,194.27 37.087,221.213  "/>
                	<polygon points="404.13,335.988 404.13,61.691 358.301,61.691 358.301,335.99 309.503,268.787 272.416,295.73 381.216,445.54    490,295.715 452.913,268.802  "/>
                </g>
              </svg>
            </button>*/}
          </div>
        )}
      { props.taskType === "dayPlanTask" && newDataSet && newDataSet.columns && newDataSet.rows.length > 0 && newDataSet.rows.map ((row) => (
        <div class={`listCard m-b-5 pos-relative ${selectedList === row.uuid ? "text-black" : "text-white"}`} onClick={(e) => handleData(row)}>
          <div class='msgContainer cursor-pointer animatedHover min-h-inherit'  style={`${selectedList === row.uuid ? 'background:#d0e7f7; border-radius: 5px;' : ''}`}>
            <div class='display-flex justify-between align-center m-l-10 min-h-inherit'>
              <div class="display-flex align-center">
                <div class="h-24px w-24px">
                  {props.taskIcon}
                </div>
                <div class="display-flex flex-direction-column m-l-10 m-r-10">
                  <p class={`fs-10 f-w-600 ${selectedList === row.uuid ? "text-gray" : "text-gray"}`}>{(row.interactionID && row.customerName) ? row.customerName: row.uniqueID}</p>
                  <p class={`fs-12 first-letter-capital listCardDescription ${selectedList === row.uuid ? "text-black" : "text-white"}`}>{row.displayName}</p>
                </div>
              </div>
              {/*
                  <div class={`m-r-30 tasks-tags ${selectedList === row.uuid ? "bg-tag-bfbfbf" : deadlineDays(row) >= 0 ? 'bg-sunset-orange':'bg-yellow'}`}>
                    <div class="display-flex flex-direction-column fs-10">
                      <p class="f-w-600" >{deadlineDays(row) >= 0 ? 'Overdue':'Due'}</p>
                      <p class="f-w-600"> {deadlineDays(row) + " Days"}</p>
                    </div>
                  </div>
                */}
            </div>
          </div>
          {/*  <span class="h-full pos-absolute color-white fs-9 text-center text-uppercase list-card-ribbon" style={`background:${ row.containerType === "Checklist" || row.containerType === "Select Cars" ? "#20639B": row.containerType === "Form" ? "#F16A43" :row.containerType === "Form and Approval" || row.containerType === "Approval"? "#2F9395":""}`}>{ row.containerType === "Checklist" || row.containerType === "Select Cars" ? "CHK": row.containerType === "Form" ? "Form" :(row.containerType === "Form and Approval" || row.containerType === "Approval") ? "Approval":""}</span>*/}
        </div>
      ))}
      {
        props.taskType === "recentlyViewedCases" &&
        <div class="p-l-0 p-r-0  listcard-thead">
          <div class="pos-sticky row flex-nowrap listcard-tr">
            <span title="Task" class="  p-t-8   listcard-th" style="width: 40px;">
              <span class="pos-relative"> Sr.
              </span>
            </span>
            <span title="Task" class="  p-t-8   listcard-th" style="width: 250px;">
              {/*
                Bug: 24/05/2023	All Roles	Honda/Kia - Renewal Policy	Right Navigation - Recently viewed	Reword - Tasks @ All instead of Cases @ All	P3	COMP_BG_483
                Modification: Reworded CASES to TASKS in case of recently viewed
                By: Devang
                Date: 09/06/2023
              */}
              <span class="pos-relative"> {props.listType === 'recentlyViewed' ? 'TASKS' : 'CASES'} @ All
              </span>
            </span>
            <span title="Open Since" class="  p-t-8 listcard-th " style="width: 108px;">
              <span class="pos-relative"> Last Viewed At</span>
            </span>
          </div>
        </div>

      }
      { props.taskType === "recentlyViewedCases" && newDataSet && newDataSet.columns && newDataSet.rows.length > 0 && newDataSet.rows.map ((row, index) => (
        <div class={`listCard listCard-border-bottom pos-relative text-black mobile-m-r-4 `} onClick={(e)=>{
          routeToCaseView(row,index);
        }}>
          <div class='msgContainer  mobile-listcard-border cursor-pointer animatedHover min-h-inherit'>
            <div class='display-flex justify-between align-center min-h-inherit'>
              <div class="display-flex align-center">
                <span style="width: 40px;height: 50px;border-right: 1px solid rgba(0,0,0,0.051);" class=" p-l-10 fs-10 fadeMoveUpAnimationNotForwards flex-c-m  listcard-td  ">
                  <span class="w-fit-content cursor-default" >
                    {index + 1}.
                  </span>
                </span>
                <span style="width: 250px; text-align: left;" class={`fadeMoveUpAnimationNotForwards pos-relative p-0 listcard-td `} >
                  {/*
                  Bug: COMP_BG_672 Alignment for Last Viewed At dates are not correct
                  Modification: change width full to 250px
                  By: Masum Raja
                  Date: 19/09/2023
                */}
                  <div class="h-full w-250px p-l-10 display-flex flex-direction-row flex-l-m  cursor-pointer">
                    <div class="" >
                      <span class=" h-full w-full">
                        <p class="h-full justify-center flex-direction-column p-0">
                          <span title="Info" class="p-0 listcard1-case accordion-category-heading fs-10 first-letter-capital" style="color: tomato; ">{row['dynamicProperties_customerName']}
                          </span>
                          <span class="h-full flex-l-m fs-12" title={row['uniqueID']}>{row['uniqueID']}
                          </span>
                        </p>
                      </span>
                    </div>
                  </div>
                </span>
                <span style="width: 108px;" class=" fadeMoveUpAnimationNotForwards flex-c-m  listcard-td  ">
                  <span class="w-fit-content cursor-default fs-11" style="">
                    {isValidDate(row.recentlyViewedAt) ? formatDateTimeRelative(row.recentlyViewedAt) : row.createdAt}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/*
        props.taskType === "case" && newDataSet && newDataSet.columns && newDataSet.rows && newDataSet.rows.length > 0 && (
          <div class="m-l-10"> <input type="checkbox" checked={checkedIDs && (checkedIDs.length === newDataSet.rows.length)} onChange={(e) => selectAllCheckboxes(e)} class="cursor-pointer m-8" id="checkbox-all-dayplan" /><label for="checkbox-all-dayplan"/></div>
        )*/}
      { props.taskType === "case" && newDataSet && newDataSet.columns && newDataSet.rows.length > 0 && newDataSet.rows.map ((row) => (
        <div class={`listCard m-b-5 pos-relative ${selectedList === row.uuid ? "text-black" : "text-white"}`} onClick={(e) => handleData(row)}>
          <div class='msgContainer cursor-pointer animatedHover min-h-inherit'  style={`${selectedList === row.uuid ? 'background:#d0e7f7; border-radius: 5px;' : ''}`}>
            <div class='display-flex justify-between align-center m-l-5 min-h-inherit'>
              <div class="display-flex align-center">
                <div class="h-24px w-24px">
                  <Clipboard masterClasses="flex-c-m" classes="w-20px h-20px" title={row && row.dynamicProperties_typeOfEnquiry} fill={selectedList === row.uuid  ? '#000' : '#fff'} />
                </div>
                <div class="display-flex flex-direction-column m-l-10 m-r-10">
                  <p class={`fs-10 ${selectedList === row.uuid ? "text-gray" : "text-d0e7f7"}`}>{row.interactionID ? row.caseID: row.uniqueID}</p>
                  {
                    !row.isVisited && (
                      <span class='notify-span' />
                    )
                  }
                  <p class={`fs-12 first-letter-capital listCardDescription text-white ${selectedList === row.uuid ? "text-black" : ""}`}>{row.dynamicProperties_customerName}</p>
                </div>
              </div>
            </div>
          </div>
          {/*<div class={`enquiry-type-dot pos-absolute`} style={`background: ${typeOfEnquiryMappingStyleColor[row && row.dynamicProperties_typeOfEnquiry]}`} />*/}
          {/*
            row.currentStage &&
          */}
          <span class={`h-full pos-absolute fs-9 text-center text-uppercase list-card-ribbon ${typeOfPaymentMappingClassColor[row && (row.dynamicProperties_typeOfPolicy && row.dynamicProperties_typeOfPolicy === 'Self') ? row.dynamicProperties_typeOfPolicy : row.dynamicProperties_paymentModeSMC]}`} style="padding: 0 !important;">{row && (row.dynamicProperties_typeOfPolicy && row.dynamicProperties_typeOfPolicy === 'Self') ? row.dynamicProperties_typeOfPolicy : row.dynamicProperties_paymentModeSMC}</span>
          {/*<span class="h-full pos-absolute color-white fs-9 text-center text-uppercase list-card-ribbon" style={`background:  ${row && row.dynamicProperties_caseStatus ? typeOfEnquiryMappingStyleColor[row && row.dynamicProperties_caseStatus] : '#20639B'}`}>{row.dynamicProperties_caseStatus}</span>
          <span class="h-full pos-absolute color-white fs-9 text-center text-uppercase list-card-ribbon" style={`background:#20639B`}>{moment(row.createdAt).format("MMM - YY")}</span>*/}
        </div>
      ))}
      { props.taskType === "callTask" && newDataSet && newDataSet.length > 0 && newDataSet.map ((row) => (
        <div class={`listCard m-b-5 pos-relative ${selectedList === row.uuid ? "text-black" : "text-white"}`} onClick={(e) => handleData(row)}>
          <div class='msgContainer cursor-pointer animatedHover min-h-inherit'  style={`${selectedList === row.uuid ? 'background:#d0e7f7; border-radius: 5px;' : ''}`}>
            <div class='display-flex justify-between align-center m-l-10 min-h-inherit'>
              <div class="display-flex align-center">
                <div class="h-24px w-24px">
                  {props.taskIcon}
                </div>
                <div class="display-flex flex-direction-column m-l-10 m-r-10">
                  <p class={`fs-10 f-w-600 ${selectedList === row.uuid ? "text-white" : "text-grey"}`}>{!row.extension ? "Ext: Unknown" : "Ext:" + row.extension}</p>
                  <p class='fs-14 first-letter-capital'>{row.mobileNumber ? row.mobileNumber : "Unknown" }</p>
                </div>
              </div>
              <div class={`m-r-30 tasks-tags bg-sunset-orange ${selectedList === row.uuid ? "bg-tag-bfbfbf" : ""}`}>
                <div class="display-flex flex-direction-column fs-10">
                  <p class="f-w-600" >{row.starttime ? formatDateTime(row.starttime) : '--'}</p>
                </div>
              </div>
            </div>
          </div>
          <span class="h-full pos-absolute color-white fs-9 text-center text-uppercase list-card-ribbon" style={`background:${ row.typeOfCaller === "Employee" ? "#20639B": row.typeOfCaller === "Customer" ? "#F16A43" : row.typeOfCaller === "Other"? "#2F9395":""}`}>{ row.typeOfCaller === "Employee" ? "Employee": row.typeOfCaller === "Customer" ? "Customer" :(row.typeOfCaller === "Others") ? "Others":""}</span>
        </div>
      ))}
      { props.taskType === "Courier" && newDataSet && newDataSet.length > 0 && newDataSet.map ((row) => (
        <div class={`listCard m-b-5 pos-relative ${selectedList === row.uuid ? "text-black" : "text-white"}`} onClick={(e) => handleData(row)}>
          <div class='msgContainer cursor-pointer animatedHover min-h-inherit'  style={`${selectedList === row.uuid ? 'background:#d0e7f7; border-radius: 5px;' : ''}`}>
            <div class='display-flex justify-between align-center m-l-10 min-h-inherit'>
              <div class="display-flex align-center">
                <div class="h-24px w-24px">
                  {props.taskIcon}
                </div>
                <div class="display-flex flex-direction-column m-l-10 m-r-10">
                  <p class={`fs-10 f-w-600 ${selectedList === row.uuid ? "text-white" : "text-grey"}`}>{row.caseID}</p>
                  <p class={`fs-12 first-letter-capital listCardDescription ${selectedList === row.uuid ? "text-black" : "text-white"}`}>{row.courierDescription}</p>
                </div>
              </div>
              <div class={`m-r-30 tasks-tags bg-sunset-orange ${selectedList === row.uuid ? "bg-tag-bfbfbf" : ""}`}>
                <div class="display-flex flex-direction-column fs-10">
                  <p class="f-w-600" >{formatDateTime(row.createdAt)}</p>
                </div>
              </div>
            </div>
          </div>
          <span class="h-full pos-absolute color-white fs-9 text-center text-uppercase list-card-ribbon" style={`background:${ row.typeOfCourier === "Legal" ? "#F6BE00": row.typeOfCourier === "Director" ? "#F16A43" :row.typeOfCourier === "HMIL" ? "#2F9395":""}`}>{ row.typeOfCourier === "Legal" ? "Legal": row.typeOfCourier === "Director" ? "Director" :row.typeOfCourier === "HMIL" ? "HMIL":""}</span>
        </div>
      ))}
      { props.taskType === "visitTask" && newDataSet && newDataSet.length > 0 && newDataSet.map ((row) => (
        <div class={`listCard fadeAnimationText m-b-5 pos-relative ${selectedList === row.uuid ? "text-black" : "text-white"}`} onClick={(e) => handleData(row)}>
          <div class='msgContainer cursor-pointer animatedHover min-h-inherit'  style={`${selectedList === row.uuid ? 'background:#d0e7f7; border-radius: 5px;' : ''}`}>
            <div class='display-flex justify-between align-center m-l-10 min-h-inherit'>
              <div class="display-flex align-center">
                <div class="h-24px w-24px">
                  {props.taskIcon}
                </div>
                <div class="display-flex flex-direction-column m-l-10 m-r-10">
                  <p class={`fs-10 f-w-600 ${selectedList === row.uuid ? "text-white" : "text-grey"}`}>{!row.uniqueID ? "Unknown" : row.uniqueID}</p>
                  <p class={`fs-12 first-letter-capital listCardDescription ${selectedList === row.uuid ? "text-black" : "text-white"}`}>{row.displayName ? row.displayName : "Unknown" }</p>
                </div>
              </div>
              <div class={`m-r-30 tasks-tags bg-sunset-orange ${selectedList === row.uuid ? "bg-tag-bfbfbf" : ""}`}>
                <div class="display-flex flex-direction-column fs-10">
                  <p class="f-w-600" >{row.createdAt ? formatDateTime(row.createdAt) : '--'}</p>
                </div>
              </div>
            </div>
          </div>
          <span class="h-full pos-absolute color-white fs-9 text-center text-uppercase list-card-ribbon" style={`background:olor-white fs-9 text-center text-uppercase ${ row.type === "Commercial" ? "#20639B": row.type === "Non-Commercial" ? "#F16A43" :""}`}>{ row.type === "Commercial" ? "Commercial": row.type === "Non-Commercial" ? "Non-Commercial" : ""}</span>
        </div>
      ))}
      {newDataSet && (newDataSet.length === 0 || newDataSet.rows && newDataSet.rows.length === 0 ) && (<p class="noTasksList f-w-400 fs-13">You are all caught up</p>)}
    </div>
  );
};

export default ListCard;
