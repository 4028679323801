/*
  Modification: Added SingleRightArrow SVG
  By: Komal
  Date: 30/03/2023
*/
/*
  modified : Ashutosh G
  modified : 07/11/2023
  modification : id property added on svg element
*/
import { h } from 'preact';

const SingleRightArrow = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} id={props.id} onclick={(e) => props.action && props.action(e)} width={props.width || "6"} height={props.height || "10"} viewBox={props.viewBox || "0 0 6 10"} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 9L5 5L1 1" stroke={props.stroke || "black"} stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};

export default SingleRightArrow;
