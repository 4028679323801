import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const ThirdParty = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512.000000pt"} height={props.height || "512pt"}
        viewBox={props.viewBox || "0 0 512.000000 512.000000"} preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke="none">
          <path d="M2430 5009 c-166 -32 -303 -172 -345 -351 -19 -81 -19 -253 0 -343 22 -106 86 -226 157 -296 l60 -59 -44 0 c-90 0 -249 -58 -350 -128 -57 -39 -156 -144 -196 -208 -46 -73 -89 -198 -102 -298 -7 -51 -11 -170 -8 -289 3 -193 5 -205 30 -258 36 -79 89 -133 166 -171 l67 -33 680 0 680 0 67 33 c80 39 138 101 170 181 22 53 23 68 22 306 -1 221 -4 259 -22 328 -69 253 -270 454 -518 517 -43 11 -95 20 -118 20 l-40 0 61 63 c112 114 162 239 170 428 9 201 -36 337 -147 443 -65 62 -137 100 -220 116 -62 12 -157 11 -220 -1z m271 -141 c99 -46 166 -137 189 -258 16 -84 12 -199 -10 -284 -52 -206 -183 -316 -359 -304 -123 9 -212 70 -271 188 -47 92 -63 177 -58 305 8 205 75 311 231 367 74 27 204 20 278 -14z m211 -1042 c210 -58 379 -228 432 -432 23 -91 23 -531 0 -577 -35 -70 -93 -110 -177 -122 l-47 -7 0 300 c0 330 0 332 -60 332 -58 0 -60 -13 -60 -337 l0 -293 -455 0 -455 0 0 288 c0 318 -3 335 -56 340 -63 6 -64 3 -64 -324 0 -333 5 -314 -82 -294 -75 18 -141 86 -158 161 -18 79 -8 465 13 539 54 186 199 343 377 408 96 35 166 41 465 38 211 -3 283 -7 327 -20z"/>
          <path d="M996 3965 c-47 -17 -94 -59 -112 -102 -12 -27 -14 -186 -14 -904 l0 -872 -59 56 c-33 32 -69 57 -80 57 -27 0 -61 -34 -61 -62 0 -28 227 -258 255 -258 10 0 71 52 137 117 127 126 142 155 96 187 -36 25 -56 19 -114 -35 l-54 -52 0 861 0 861 26 20 c26 20 38 21 319 21 270 0 293 1 308 18 23 25 21 58 -3 82 -19 19 -33 20 -312 20 -223 -1 -302 -4 -332 -15z"/>
          <path d="M3480 3960 c-25 -25 -26 -62 -2 -83 16 -15 53 -17 309 -17 279 0 291 -1 317 -21 l26 -20 0 -861 0 -861 -54 52 c-59 55 -85 62 -116 31 -38 -38 -27 -59 98 -183 66 -65 127 -117 137 -117 28 0 255 230 255 258 0 28 -34 62 -61 62 -11 0 -47 -25 -80 -57 l-59 -56 0 872 c0 709 -3 878 -14 904 -17 43 -76 93 -123 106 -23 7 -156 11 -325 11 -275 0 -289 -1 -308 -20z"/>
          <path d="M2505 2425 c-24 -23 -25 -28 -25 -176 l0 -152 -54 52 c-58 54 -78 60 -114 35 -46 -32 -31 -61 96 -187 66 -65 127 -117 137 -117 28 0 255 230 255 258 0 28 -34 62 -61 62 -11 0 -47 -25 -80 -57 l-59 -56 0 166 c0 160 -1 166 -22 181 -31 22 -45 20 -73 -9z"/>
          <path d="M858 1769 c-151 -22 -282 -133 -338 -286 -19 -53 -22 -77 -18 -156 3 -85 8 -103 41 -170 26 -52 54 -91 91 -125 l55 -49 -42 -13 c-151 -44 -303 -174 -374 -320 -101 -206 -89 -360 37 -474 85 -76 86 -76 615 -76 507 0 522 2 602 60 56 41 101 115 115 187 15 81 -8 191 -63 303 -72 146 -224 276 -376 320 l-41 13 57 53 c175 161 182 429 16 603 -102 107 -231 152 -377 130z m197 -144 c153 -69 224 -260 151 -409 -33 -68 -110 -139 -173 -160 -60 -21 -157 -20 -219 1 -67 23 -154 110 -177 177 -21 62 -22 158 -1 219 19 56 81 129 139 163 83 48 187 52 280 9z m73 -760 c220 -52 391 -249 396 -456 1 -56 -3 -71 -26 -106 -16 -25 -44 -49 -70 -62 -43 -20 -56 -21 -503 -21 -438 0 -461 1 -500 20 -84 40 -114 118 -91 230 39 187 196 349 382 394 81 20 330 20 412 1z"/>
          <path d="M4120 1769 c-125 -20 -262 -125 -315 -241 -77 -170 -39 -368 96 -492 l57 -53 -41 -13 c-151 -44 -305 -176 -376 -320 -55 -112 -79 -230 -63 -307 14 -69 61 -143 115 -183 81 -60 96 -61 623 -58 475 3 479 3 525 26 88 43 148 122 170 222 15 71 -10 190 -64 300 -71 146 -223 276 -374 320 l-42 13 55 49 c37 34 65 73 91 125 33 67 38 86 41 167 3 74 0 103 -18 156 -69 203 -267 323 -480 289z m207 -145 c66 -32 136 -109 158 -175 24 -70 17 -179 -13 -239 -54 -105 -143 -162 -259 -168 -94 -5 -157 16 -222 74 -71 64 -104 136 -104 228 0 230 233 378 440 280z m72 -759 c147 -35 280 -141 344 -275 81 -171 64 -296 -48 -350 -39 -19 -62 -20 -500 -20 -447 0 -460 1 -503 21 -26 13 -54 37 -70 62 -23 35 -27 50 -26 106 5 205 175 403 391 455 81 20 329 20 412 1z"/>
          <path d="M2377 1730 c-21 -16 -26 -31 -32 -88 l-7 -67 -52 -21 -52 -21 -48 44 c-73 66 -87 62 -206 -53 -54 -52 -104 -109 -110 -125 -15 -35 -8 -54 44 -114 l34 -40 -20 -51 -20 -52 -65 -4 c-57 -4 -69 -8 -90 -32 -24 -28 -24 -32 -21 -178 3 -143 4 -151 27 -169 17 -14 39 -19 85 -19 l61 0 22 -57 22 -57 -45 -48 c-67 -71 -64 -81 63 -209 97 -99 110 -109 143 -109 29 0 44 8 75 40 21 22 44 40 51 40 6 0 32 -9 57 -20 l45 -20 8 -66 c10 -103 13 -104 199 -104 189 0 195 3 195 103 l0 63 55 23 56 23 49 -41 c76 -64 93 -60 207 52 139 136 145 153 71 233 l-40 44 23 55 23 55 61 0 c48 0 66 4 83 20 21 20 22 29 22 179 0 189 -2 193 -105 199 l-64 4 -22 55 -22 54 43 44 c70 71 65 86 -67 216 -130 129 -139 132 -212 64 l-48 -45 -57 26 -56 25 0 60 c0 50 -4 64 -25 84 -23 24 -29 25 -168 25 -129 0 -147 -2 -170 -20z m248 -164 c3 -36 12 -69 18 -74 17 -13 187 -83 217 -89 19 -3 36 5 71 36 l46 40 53 -54 53 -53 -37 -45 c-43 -52 -45 -80 -12 -145 13 -26 30 -66 37 -90 16 -56 41 -72 106 -72 l53 0 0 -80 0 -80 -45 0 c-75 0 -91 -13 -126 -107 -18 -46 -36 -86 -40 -89 -5 -3 -9 -21 -9 -40 0 -26 9 -45 40 -77 l39 -43 -52 -52 c-29 -29 -57 -52 -63 -52 -6 0 -23 14 -40 31 -49 52 -66 54 -152 16 -43 -19 -92 -40 -109 -47 -35 -13 -53 -53 -53 -117 l0 -33 -80 0 -80 0 0 58 c0 71 -9 80 -100 115 -36 14 -78 32 -95 41 -42 22 -47 21 -105 -25 l-52 -41 -54 53 -53 52 40 45 c46 52 48 75 15 140 -13 26 -30 66 -37 90 -18 60 -49 82 -116 82 l-54 0 3 78 3 77 62 5 c34 3 67 10 73 16 6 6 22 42 35 79 14 38 32 79 40 92 26 39 18 72 -26 121 l-42 44 56 56 56 56 45 -42 c53 -48 69 -51 134 -18 26 13 71 31 100 40 61 20 72 37 72 104 0 27 3 52 7 55 3 4 39 7 79 7 l72 0 7 -64z"/>
          <path d="M2462 1255 c-69 -19 -86 -29 -140 -79 -66 -60 -102 -142 -102 -232 0 -133 57 -231 170 -293 60 -33 71 -36 150 -36 102 0 161 23 228 88 65 63 94 127 100 217 3 60 0 86 -18 132 -58 154 -233 246 -388 203z m132 -116 c90 -22 155 -107 156 -202 0 -117 -110 -217 -226 -204 -128 14 -205 126 -180 260 10 52 78 124 134 141 59 18 64 18 116 5z"/>
        </g>
      </svg>
    </span>
  );
};

export default ThirdParty;
