/*
  modified : Ashutosh G
  modified : 24/10/2023
  modification : InventoryAvailableBox added
*/
/*
  modified : Ashutosh G
  modified : 27/10/2023
  modification : modified box to request total quantity and quantity per branch
*/
import { h } from 'preact';
import { useState, useEffect, useRef } from "preact/hooks";
import { callAPI, reportException } from '../../lib/transceiver';
import { setItem, getItem } from '../../lib/myStore';
import { deepCopy, preventNumberSpecialChar } from '../../lib/utils';

const InventoryAvailableBox = (props) => {
  const { fieldName, setCustomFiledValues, isEditable, entityObject, isDisabled } = props;
  const userInfo = getItem('userinfo');
  const FILENAME = "InventoryAvailableBox", PAGENAME = "InventoryAvailableBox";
  const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemIDs, setSelectedItemsIDs] = useState([]);
  const [inventorySearch, setInventorySearch] = useState("");
  const prevSelectedItemIDsRef = useRef(selectedItemIDs);
  const [savedItems, setSavedItems] = useState({});
  const [isPrefilled, setIsPrefilled] = useState(false);
  /*
    modified : Ashutosh G
    modified : 22/12/2023
    modification : listType, whichListType, isLoading, isUniqueIdExists variables added
  */
  let listType = [{ label: "Consumables", value: "catalogueitems", url: "/v1/inventoryStats" }, { label: "Assets", value: "assets", url: "/v1/assetStats"  }];
  const [whichListType, setWhichListType] = useState(listType[0].value);
  const [isLoading, setIsLoading] = useState(true);
  const [isUniqueIdExists, setIsUniqueIdExists] = useState(false);
  const quantityNotManipulationTask = ["Acknowledge Request"];

  useEffect(() => {
    /*
      modified : Ashutosh G
      modified : 20/12/2023
      modification : if not catelogue item data then don't validate
    */
    if (data.length > 0) {
      checkSavedItemValidity();
      let selectedItemKeys = Object.keys(savedItems);
      let allValid = selectedItemKeys.every(item =>  savedItems[item].askQuantityValid);
      let finalPayload;
      if(allValid) {
        finalPayload = selectedItemKeys.map(itemID => {
          let itemObject = data.find(item => item.uuid === itemID);
          return { ...itemObject, askQuantity: savedItems[itemID]["branchWiseAskQuantity"] }
        });
      } else {
        finalPayload = [];
      }
      setCustomFiledValues(prevData => { return { fieldName: fieldName, value: finalPayload } });
    }
  }, [savedItems]);

  /*
    modified : Ashutosh G
    modified : 18/12/2023
    modification : if selected task displayName is Receive Stock then set list type of selected items of that task
  */
  useEffect(() => {
    if (entityObject.displayName === "Receive Stock") {
      let selectedItems = JSON.parse(entityObject.dynamicProperties_customCatalogue);
      if (selectedItems[0] && selectedItems[0].dynamicProperties_type) {
        let listType = selectedItems[0].dynamicProperties_type.toLowerCase() === "asset" ? "assets" : "catalogueitems";
        setWhichListType(listType);
      }
    }
  }, [entityObject]);

  useEffect(() => {
    /*
      modified : Ashutosh G
      modified : 20/12/2023
      modification : setting prefill values in savedItems when isPrefilled is not true and there is catelogue data available
    */
    if (entityObject.dynamicProperties_customCatalogue && !isPrefilled && data.length) {
      const output = {};

      JSON.parse(entityObject.dynamicProperties_customCatalogue).forEach(item => {
          const uuid = item.uuid;
          const askQuantity = Object.values(item.askQuantity).reduce((sum, quantity) => sum + quantity, 0);
          const branchWiseAskQuantity = item.askQuantity;
          const askQuantityValid = true;

          output[uuid] = {
              askQuantity,
              branchWiseAskQuantity,
              askQuantityValid
          };
      });

      setSavedItems(output);
      setIsPrefilled(true);
    }
  }, [data]);

  function checkSavedItemValidity() {
    /*
      modified : Ashutosh G
      modified : 20/12/2023
      modification : adeed try catch block
    */
    try {
      let tempSavedItems = savedItems;
      let selectedItemKeys = Object.keys(savedItems);
      console.log(selectedItemKeys, 'selectedItemKeysselectedItemKeysselectedItemKeys');
      selectedItemKeys.map(item => {
        let totalSelectedBranchQuantity = 0;
        let branchWiseAskQuantityValid = true;
        console.log(branchWiseAskQuantityValid, 'branchWiseAskQuantityValidbranchWiseAskQuantityValid');
        let currentItem = data.find(itemObj => itemObj.uuid == item);
        console.log(currentItem, 'here1', savedItems[item].branchWiseAskQuantity);
        for (let key in savedItems[item].branchWiseAskQuantity) {
          let branchObject = currentItem.count.find(item => item.branchID === key);
          console.log(savedItems[item].branchWiseAskQuantity[key], "savedItems[item].branchWiseAskQuantity[key]", branchObject);
          console.log(branchObject.quantity, "branchObject.quantitybranchObject.quantity");
          if(savedItems[item].branchWiseAskQuantity[key] > branchObject.quantity) {
            branchWiseAskQuantityValid = false;
          }
          totalSelectedBranchQuantity = totalSelectedBranchQuantity + savedItems[item].branchWiseAskQuantity[key];
        }
        console.log(totalSelectedBranchQuantity, 'totalSelectedBranchQuantitytotalSelectedBranchQuantitytotalSelectedBranchQuantity');
        console.log(savedItems[item].askQuantity, 'savedItems[item].askQuantitysavedItems[item].askQuantitysavedItems[item].askQuantity');
        console.log(branchWiseAskQuantityValid, 'branchWiseAskQuantityValidbranchWiseAskQuantityValidbranchWiseAskQuantityValid');
        if (totalSelectedBranchQuantity !== savedItems[item].askQuantity || !branchWiseAskQuantityValid) {
          tempSavedItems[item].askQuantityValid = false;
        } else {
          tempSavedItems[item].askQuantityValid = true;
        }
      })
    } catch (error) {
      console.log("here1 error", error);
    }
  }

  useEffect(() => {
    let eliminatedIds = prevSelectedItemIDsRef.current.filter(item => !selectedItemIDs.includes(item));
    if (eliminatedIds.length) {
      let tempData = data;
      let index = tempData.findIndex(item => item.uuid === eliminatedIds[0]);
      tempData[index].count.map(item => delete item.askQuantity);
      setData(prevData => [...tempData]);
    }

    prevSelectedItemIDsRef.current = selectedItemIDs;
  }, [selectedItemIDs]);

  useEffect(() => {
    let searchTimeout = setTimeout(() => {
      getInventoryStats();
    }, 500);

    return () => clearTimeout(searchTimeout);
  }, [inventorySearch, whichListType]);

  /*
    modified : Ashutosh G
    modified : 22/12/2023
    modification : clearing savedItems and Loading true when changing whichListType
  */
  useEffect(() => {
    setIsLoading(true);
    setSavedItems({});
  }, [whichListType]);

  /*
    modified : Ashutosh G
    modified : 22/12/2023
    modification : getInventoryStats updated to get data from assest and inventory depending on whichListType
  */
  /*
    BY: Prasannadatta Kawadkar
    ON: 31 May 2024
    Modification: added logic for employee and department
  */
  async function getInventoryStats() {
    try {
      setIsLoading(true);
      let params = {};
      if (inventorySearch) {
        params["q"] = inventorySearch;
      };
      let res;
      if (entityObject.dynamicProperties_requestedFor === 'Employee' || entityObject.dynamicProperties_requestedFor === 'Department') {
        console.log(entityObject.dynamicProperties_requestedForSelection, '0000000000000000-----------------------');
        if (entityObject.dynamicProperties_requestedFor === 'Department') {
          res = await callAPI('get', '/v1/getDepartmentList', {uuids: [entityObject.dynamicProperties_requestedForSelection[0].uuid]});
        } else {
          res = await callAPI('get', '/v1/employee', {uuids: [entityObject.dynamicProperties_requestedForSelection[0].uuid]});
        }
        if (res.data) {
          params['locationID'] = res.data[0].locationID;
        }
        console.log(res.data, '222222222222222222222-------------------------------');
      }

      let selectedListType = listType.find(item => item.value === whichListType);
      let response = await callAPI("get", selectedListType.url , `${new URLSearchParams(params)}`);
      console.log(response.data, '1111111111111111111111-------------------------------');
      if (response.data) {
        /*
          modified : Ashutosh G
          modified : 18/12/2023
          modification : if task is Receive Stock then set only selected items to data to show on modal
        */
        if (entityObject.displayName === "Receive Stock") {
          let selectedItems = JSON.parse(entityObject.dynamicProperties_customCatalogue);
          let selectedItemIDs = selectedItems.map(item => item.uuid);
          let filteredSelectedItems = response.data.filter(item => selectedItemIDs.includes(item.uuid) );
          setData(filteredSelectedItems);
          setIsLoading(false);
          return;
        }

        setData(response.data);
      }
      setIsLoading(false);
    } catch (HTTPException) {
      setIsLoading(false);
      let payload = {
        apiURL:(HTTPException && HTTPException.config && HTTPException.config.url) ? HTTPException.config.url : "",
        errorObject:HTTPException,
        statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
        errorMessage:HTTPException.message,
        functionName:"getInventoryStats",
        fileName: FILENAME,
        pageName: PAGENAME,
        userID:userInfo.uuid,
        userName:userInfo.displayName
      };
      await callAPI("post", "/v1/exception", payload);
      return HTTPException;
    }
  }

  function selectItemFromList(e) {
    /*
      Bug: CDMS_ADMIN_FIXES_65 Functional	Admin tab - Request tile - Issue Inventory form - Catalogue - Branch - Total Req Qty , Request Qty Per Branch - Spinner (all Spinners)	It is allowing the negative value
      Modification: Commented special char checking as it was not working and set value to 0 if the target value is less than 0
      By: Devang
      Date: 31/01/2024
    */
    // if(["e", "E", "+", "-"].includes(event.target.value)) return;

    let target = e.target;
    let value = parseInt(target.value);
    if (value < 0) {
      value = 0;
      e.target.value = 0;
    }

    let tempSavedItems = savedItems;
    let selectedItem = data.find(item => item.uuid === target.id);
    if (value) {
      if(!tempSavedItems[target.id]) {
        tempSavedItems[target.id] = { askQuantity: value, branchWiseAskQuantity: {}, askQuantityValid: value <= selectedItem.totalQuantity ? true : false  }
      } else {
        tempSavedItems[target.id] = { ...tempSavedItems[target.id], askQuantity: value };
      }
    } else {
      delete tempSavedItems[target.id];
    }
    setSavedItems({...tempSavedItems});
  }

  function setBranchWiseQuantity(e) {
    /*
      Bug: CDMS_ADMIN_FIXES_65 Functional	Admin tab - Request tile - Issue Inventory form - Catalogue - Branch - Total Req Qty , Request Qty Per Branch - Spinner (all Spinners)	It is allowing the negative value
      Modification: Commented special char checking as it was not working and set value to 0 if the target value is less than 0
      By: Devang
      Date: 31/01/2024
    */
    // if(["e", "E", "+", "-"].includes(event.target.value)) return;

    let value = parseInt(e.target.value);
    if (value < 0) {
      value = 0;
      e.target.value = 0;
    }

    let target = e.target.id.split("_");
    let itemID = target[0];
    let branchID = target[1];
    let tempSavedItems = savedItems;

    let selectedItem = data.find(item => item.uuid === itemID);
    let selectedItemBranchID = selectedItem.count.find(item => item.branchID === branchID);

    if (!tempSavedItems[itemID]["branchWiseAskQuantity"]) {
      tempSavedItems[itemID]["branchWiseAskQuantity"] = {};
    }
    if (value) {
      tempSavedItems[itemID]["branchWiseAskQuantity"][branchID]= value;
      tempSavedItems[itemID]["askQuantityValid"] = value <= selectedItemBranchID.quantity ? true : false;
    } else {
      delete tempSavedItems[itemID]["branchWiseAskQuantity"][branchID];
    }
    setSavedItems(prevData => { return {...tempSavedItems} });
  }

  function changeInventorySearch(e) {
    setInventorySearch(e.target.value);
  }

  function toggleListType (e) {
    setWhichListType(prevValue => e.target.value);
  }

  /*
    modified : Ashutosh G
    modified : 22/12/2023
    modification : table structure changed, UniqueID column added and loading added
  */
  return (
    <div class="row m-b-5">
      <div className="w-100p dis-flex gap-10px">
        {/*
          Bug: CDMS_ADMIN_FIXES_60 Functional Admin tab - Request tile - Issue Inventory form	If not clicked on Edit form button , it is allowing to edit the data
          Modification: Added isDisabled in disabled attribute
          By: Devang
          Date: 31/01/2024
        */}
        <input type="text" placeholder="Search..." onChange={changeInventorySearch} disabled={isDisabled} />
        {
          (["Stock Transfer", "Acknowledge Request"].includes(entityObject.displayName) && entityObject.displayName !== "Receive Stock") &&
          <select name="listType" value={whichListType} onChange={toggleListType}>
            {
              listType.map(type => <option value={type.value}>{type.label}</option>)
            }
          </select>
        }
      </div>
      <div class='w-full h-55vh overflow-auto'>
        <table class={`bg-table-header h-full bor-all`}>
          <thead class='pos-sticky top-0 z-index-991'>
            {/*
              modified : Ashutosh G
              modified : 20/12/2023
              modification : column header will change depends on isEditable
            */}
            {/*{
              isEditable ?*/}
                <tr className="h-40px">
                  <th>Name</th>
                  { whichListType === "assets" && <th>Unique ID</th> }
                  { !quantityNotManipulationTask.includes(entityObject.displayName) && <th>Total Req. Qty</th> }
                  <th className="w-50p">
                    <table>
                      <tr>
                      {/*
                        BY: Prasannadatta Kawadkar
                        ON: 31 May 2024
                        Modification: branch will only be shown when requested for has value "branch"
                      */}
                        {entityObject.dynamicProperties_requestedFor === 'Branch' && <th className="text-center">Branch</th>}
                        <th className="text-center">In Stock</th>
                        { !quantityNotManipulationTask.includes(entityObject.displayName) && <th>Issue quantity from branch</th> }
                      </tr>
                    </table>
                  </th>
                </tr>
                {/*:
                <tr className="h-40px">
                  <th>Name</th>
                  { whichListType === "assets" && <th>Unique ID</th> }
                  <th className="w-50p">
                    <table>
                      <tr>
                        <th className="text-center">Branch</th>
                        <th className="text-center">In Stock</th>
                      </tr>
                    </table>
                  </th>
                </tr>
            }*/}
          </thead>
          <tbody className="pos-relative ">
            {
              isLoading
              ? <div class="ab-c-m"><p>Loading...</p></div>
              : data.length !== 0 ? data.map(item => {
                return (
                  <tr class={`${(savedItems[item.uuid] && !savedItems[item.uuid].askQuantityValid)  ? "error-div" : ""} bor-all`}>
                    <td>{item.dynamicProperties_displayName}</td>
                    { whichListType === "assets" && <td>{item.dynamicProperties_uniqueID}</td> }
                    {/*
                      modified : Ashutosh G
                      modified : 20/12/2023
                      modification : isEditable false then hiding input box
                    */}
                    {/*
                      Bug: CDMS_ADMIN_FIXES_60 Functional Admin tab - Request tile - Issue Inventory form	If not clicked on Edit form button , it is allowing to edit the data
                      Modification: Added isDisabled in disabled attribute and changed onChange to onInput
                      By: Devang
                      Date: 31/01/2024
                    */}
                    {!quantityNotManipulationTask.includes(entityObject.displayName) && <td>
                      <input type="number" name="askQuantity" id={item.uuid}
                      value={(savedItems[item.uuid] && savedItems[item.uuid].askQuantity) ? savedItems[item.uuid].askQuantity : ""}
                      onInput={selectItemFromList} onKeyDown={preventNumberSpecialChar} disabled={item.count.length === 0 || !isEditable || isDisabled}
                      />
                    </td>}
                    {
                      item.count.length !== 0 ?
                        <td className="p-0">
                          <table class="bor-all">
                            {
                              item.count.map(row => {
                                return (
                                  <tr>
                                  {/*
                                    BY: Prasannadatta Kawadkar
                                    ON: 31 May 2024
                                    Modification: branch will only be shown when requested for has value "branch"
                                  */}
                                    {entityObject.dynamicProperties_requestedFor === 'Branch' && <td className="text-center">{row.branchName}</td>}
                                    <td className="text-center">{row.quantity}</td>
                                    {/*
                                      modified : Ashutosh G
                                      modified : 20/12/2023
                                      modification : isEditable false then hiding input box
                                    */}
                                    {/*
                                      Bug: CDMS_ADMIN_FIXES_60 Functional Admin tab - Request tile - Issue Inventory form	If not clicked on Edit form button , it is allowing to edit the data
                                      Modification: Added isDisabled in disabled attribute and changed onChange to onInput
                                      By: Devang
                                      Date: 31/01/2024
                                    */}
                                    {/*
                                      BY: Prasannadatta Kawadkar
                                      ON: 18 May 2024
                                      Modification: if branch is same as one of the fields then it is set to be disabled when branch as a option is selected in Acknowledge request
                                    */}
                                    {!quantityNotManipulationTask.includes(entityObject.displayName) && (props.destinationLocation !== row.branchName) && <td>
                                      <input type="number" id={`${item.uuid}_${row.branchID}`} name="askQuantityUnderBranch"
                                      value={(savedItems[item.uuid] && savedItems[item.uuid]["branchWiseAskQuantity"] && savedItems[item.uuid]["branchWiseAskQuantity"][row.branchID]) ? savedItems[item.uuid]["branchWiseAskQuantity"][row.branchID] : ""}
                                      style={`color: ${savedItems[item.uuid] && savedItems[item.uuid]["branchWiseAskQuantity"] && savedItems[item.uuid]["branchWiseAskQuantity"][row.branchID] && row.quantity < savedItems[item.uuid]["branchWiseAskQuantity"][row.branchID] ? "red !important" : ""} `}
                                      disabled={ entityObject.displayName === 'Issue Inventory' && (savedItems[item.uuid] || !isEditable) ? (entityObject.dynamicProperties_requestedForSelection[0].displayName === row.branchName) : !savedItems[item.uuid] || !isEditable || isDisabled} onInput={setBranchWiseQuantity} onKeyDown={preventNumberSpecialChar}
                                      />
                                    </td>}
                                  </tr>
                                );
                              })
                            }
                            {
                              (savedItems[item.uuid] && !savedItems[item.uuid].askQuantityValid) &&
                              <tr>
                                <td colspan="3" class="text-red">Note: The total quantity entered should be less than available quantity in branch.</td>
                              </tr>
                            }
                          </table>
                        </td>
                        :
                        <td colspan="2" class="text-center">
                          No Inventory Found
                        </td>
                    }
                  </tr>
                );
              })
              :
              <p className="ab-c-m">No Data Found</p>
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InventoryAvailableBox;
