import { h } from 'preact';
/*
  Modification: Add Draft Svg
  By: Komal Wable
  Date: 19/04/2023
*/
const Draft = (props) => {
  return (
    <span title={props.title || ''} class={props.masterClasses || ''}>
      <svg class={props.classes || ''} onclick={(e) => props.action && props.action(e)} width={props.width || '28'} height={props.height || '28'} viewBox={props.viewBox || "0 0 17 17"} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 7V5.125L8.875 1H2.5C2.10218 1 1.72064 1.15804 1.43934 1.43934C1.15804 1.72064 1 2.10218 1 2.5V14.5C1 14.8978 1.15804 15.2794 1.43934 15.5607C1.72064 15.842 2.10218 16 2.5 16H11.5C11.8978 16 12.2794 15.842 12.5607 15.5607C12.842 15.2794 13 14.8978 13 14.5V13.75" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.5 1V5.5H13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.5723 7.24495C14.7292 7.08811 14.9419 7 15.1637 7C15.2735 7 15.3823 7.02163 15.4837 7.06366C15.5852 7.10569 15.6774 7.16729 15.755 7.24495C15.8327 7.32261 15.8943 7.41481 15.9363 7.51627C15.9784 7.61774 16 7.72649 16 7.83632C16 7.94615 15.9784 8.0549 15.9363 8.15637C15.8943 8.25783 15.8327 8.35003 15.755 8.42769L10.827 13.3558L9.25 13.75L9.64425 12.173L14.5723 7.24495Z" fill="#D9D9D9" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};
export default Draft;
