import { h, Component } from 'preact';
import style from './style';
import { setItem } from '../../../lib/myStore';

async function refreshPage() {
  setItem('isTimedout', false);
  window.location.reload();
}

export default class NetworkIssue extends Component {
  render() {
    return (
      <div class={`${style.home} page has-text-center`}>
        <div>
          <h4> Seems like your network has some issue.</h4>
        </div>
        {
          /*
            modification: change image width size
            By: Manohar Sule
            On: 28 May 2024
            Bug: T218 - BG_UI - Display Issue with 'Please contact your IT administrator OR Retry' Message
          */
        }
        <img src="/assets/images/No-connection.gif" style="width: 400px" />
        <div >
          <h4>
            Please contact your IT Administrator OR
            <button class="m-l-10 primary-button" onClick={() => refreshPage()}>Retry</button>
          </h4>
        </div>
      </div>
    );
  }
}
