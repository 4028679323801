/*
  Modification: add css
  By: Komal Wable
  Date: 19/04/2023
*/
import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Rupee = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg onclick={() => props.action && props.action()} xmlns="http://www.w3.org/2000/svg" version="1.0" class={`${props.classes || ""}`} width={props.width || "25"} height={props.height || "25"} viewBox={props.viewBox || "0 0 1627.000000 2400.000000"} preserveAspectRatio="xMidYMid meet" fill={props.fill || "#408b8b"}>
        <g transform="translate(0.000000,2400.000000) scale(0.100000,-0.100000)" fill={props.fill || "#408b8b"} stroke={props.stroke || "none"}>
          <path d="M7335 23949 c-2502 -5 -4827 -10 -5165 -11 l-614 -3 -777 -1150 c-427 -632 -772 -1152 -767 -1154 6 -2 184 2 397 9 845 28 2176 28 2746 1 1359 -65 2271 -206 3080 -476 1030 -344 1734 -891 2165 -1682 39 -70 70 -133 70 -138 0 -8 -39 -10 -117 -8 -65 2 -471 7 -903 13 -432 6 -1091 15 -1465 20 -1856 27 -3452 50 -3915 56 l-515 6 -702 -1179 c-393 -659 -697 -1179 -690 -1180 6 -1 1258 -9 2782 -18 4017 -22 5878 -35 5882 -38 11 -11 -91 -437 -158 -662 -296 -990 -805 -1757 -1524 -2297 -225 -169 -438 -297 -717 -433 -807 -391 -1790 -599 -3108 -655 -591 -25 -1626 -3 -2350 49 -58 4 -108 5 -111 2 -3 -3 -16 -454 -28 -1001 -12 -547 -22 -1031 -24 -1075 l-2 -80 4340 -5432 4340 -5432 1918 0 c1054 -1 1917 0 1917 2 0 2 -1964 2512 -4365 5578 -2401 3066 -4365 5577 -4365 5580 0 3 80 16 178 28 438 57 1002 162 1423 266 2251 558 3952 1638 4932 3130 192 292 368 625 497 940 167 404 313 938 371 1356 l12 87 181 6 c99 3 618 13 1151 22 534 9 1085 19 1225 22 l254 7 698 1080 c384 594 712 1102 729 1129 l31 49 -634 -7 c-348 -4 -1162 -13 -1808 -21 -646 -8 -1310 -15 -1475 -17 l-300 -2 -3 44 c-38 669 -473 1498 -1211 2305 l-83 90 2029 3 2028 2 720 1126 c396 620 724 1132 728 1140 9 16 -1126 16 -8928 3z" />
        </g>
      </svg>
    </span>
  );
};

export default Rupee;
