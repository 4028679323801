import { h, Component } from 'preact';
import { useState, useEffect } from "preact/hooks";
import { Cross, Waiting } from '../../components/svgs';

const SuggestionCard = (props) => {

  const {
    isLoading,
    isListLoading,
    header,
    text,
    data,
    textUnderlined,
    displayKey,
    suggestionAction,
    icon,
    masterStyle,
    headingStyle,
    isMobileView,
    listContainerStyle,
    isHighlight,
    highlightText,
    suggestionStyle,
    northWestIconRequired,
    endCrossIcon,
    removedSuggestionHistory,
    isOpenCaseReferencesDrawer
  } = props;

  const listBoxStyle = isMobileView ? {
    border: isOpenCaseReferencesDrawer ? '1px solid #E7E7E7' : '',
    borderRadius: isOpenCaseReferencesDrawer ? '5px' : '',
    padding: '8px',
    } : {
      height: '30px'
  };

const listStyle = {
  fontWeight: 400,
  fontSize: isMobileView ? '13px' : '14px',
  lineHeight: '18.78px',
  textDecoration: 'none',
  cursor: 'pointer',
  alignSelf: 'center',
  paddingLeft: isMobileView ? '8px' : '0',
  color: isMobileView ? '' : 'black',
};

const renderHighlighted = (dataObj, sentence, searchText) => {
    const highlightText = (sentence) => {
    if (!searchText) return sentence;
    const words = searchText.split(/\s+/).filter(Boolean);
    const regex = new RegExp(`(${words.join('|')})`, 'gi');
    return sentence.replace(regex, '<span style="background-color: yellow">$1</span>');
  };

  return (
    <div onClick={() => suggestionAction(dataObj)} style={{display: 'flex', width:'100%', alignItems:'center', gap:`${isMobileView ? '0' : '10px'}` }}>
      {(isMobileView && icon) && icon}
      {!isMobileView && <Waiting height="15px" width="15px"/>}
      <span dangerouslySetInnerHTML={{ __html: highlightText(sentence) }} style={listStyle} />
    </div>
  );
};

const openPdfPreviewDrawer = (dataObj) => {
  if (!isMobileView) {
    suggestionAction(dataObj);
  } else if (isOpenCaseReferencesDrawer) {
    suggestionAction(dataObj);
  }
}

  return (
    <div style={{...(isMobileView ? { boxShadow: 'none', marginTop:`${isOpenCaseReferencesDrawer ? '0px' : '10px'}` } : {boxShadow: 'none', padding:'10px', borderBottomRightRadius:'29px',borderBottomLeftRadius:'29px'}), ...masterStyle}}>
      <div style={{ padding: '0px', margin: '0px !important', height: '100%' }}>
        {!isMobileView && header && <div style={{ padding:`${isMobileView ? '0px' : '5px'}`, display: 'flex', alignItems: 'center'}}>
          {
            (!isMobileView && icon) && icon
          }
          {
            (!isMobileView && header) &&
            <span style={{fontWeight: 400, fontSize: '14px', color: '#000000', marginLeft: '10px', ...headingStyle}}>
              {header}
            </span>
          }
          {
            isLoading && <span>Loading...</span>
          }
        </div>}
       {
          <div className={'cardTextBoxStyle'} style={{width: '100%', height: isMobileView ? '100%' : 'calc(100% - 27px)', overflowY: 'auto', ...listContainerStyle}}>
            {
              !isListLoading ? (
                (data && data.length > 0) ?
                data.map((dataObj, index) =>
                  <div className='hover-bg-grey suggestion-box-row cursor-pointer' key={`${dataObj[displayKey]}-${index}`} style={{ display: 'flex', justifyContent: 'space-between', ...listBoxStyle}}>
                    {
                      isHighlight ?
                        renderHighlighted(dataObj, dataObj[displayKey], highlightText)
                      :
                      <div onClick={() => openPdfPreviewDrawer(dataObj)} style={{ display: 'flex', width:'100%' }}>
                        {
                          (isMobileView && icon) && icon
                        }
                        <span style={listStyle}>
                          {dataObj[displayKey]}
                        </span>
                      </div>
                    }
                    {endCrossIcon && isMobileView &&
                      <div onClick={() => removedSuggestionHistory(dataObj)}>
                        <Cross height="15px" width="15px"/>
                      </div>
                    }
                    {endCrossIcon && !isMobileView &&
                      <div className='removed-cross-icon' onClick={() => removedSuggestionHistory(dataObj)}>
                        <Cross height="15px" width="15px"/>
                      </div>
                    }
                  </div>
                ) :
                <span style={{fontWeight: 500, fontSize: '14px', color: '#919191', marginTop: '10px', lineHeight: '18.78px', textDecoration: textUnderlined ? 'underline' : 'none', padding: isMobileView ? '4px' : '0'}}>
                  No Data Found
                </span>
              ) :
              <span style={{fontWeight: 500, fontSize: '14px', color: '#919191', marginTop: '10px', lineHeight: '18.78px', padding: isMobileView ? '4px' : '0'}}>
                Please wait. Loading...
              </span>
            }
          </div>
       }
     </div>
   </div>
  );
};

export default SuggestionCard;
