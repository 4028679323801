import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Info = (props) => {
  return (
    <svg class={props.classes || ''} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" class={`${props.classes || ""}`} fill={props.fill || "none"} width={props.width || "19"} height={props.height || "19"}  viewBox="0 0 16 19" >
      <path d="M11.0625 4.13281L10.6562 6.04688H8.63281L9.0625 4.13281H11.0625ZM9.25781 12.9141C9.20052 13.1745 9.16406 13.3672 9.14844 13.4922C9.13281 13.6172 9.125 13.7214 9.125 13.8047C9.125 14.0286 9.18229 14.1901 9.29688 14.2891C9.41667 14.388 9.61979 14.4531 9.90625 14.4844L9.82031 15H6.57031L7.78906 9.54688C7.85677 9.23438 7.90365 8.99219 7.92969 8.82031C7.95573 8.64323 7.96875 8.49479 7.96875 8.375C7.96875 8.17188 7.91927 8.02865 7.82031 7.94531C7.72656 7.85677 7.52604 7.79688 7.21875 7.76562L7.33594 7.25L9.625 7.16406H10.5156L9.25781 12.9141Z" fill="#002C5F"/>
      <circle cx="8" cy="10" r="7.5" stroke="#3C3C3C"/>
    </svg>
  );
};

export default Info;
