import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const LineArrow = (props) => {
  return (
    <span title={props.title || ''} class={props.masterClasses || ''}>
      <svg class={props.classes || ""} xmlns="http://www.w3.org/2000/svg" onclick={(e) => props.action && props.action(e)} version="1.0" width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M2909 4677 c-64 -24 -102 -53 -211 -165 -126 -127 -151 -172 -151 -272 0 -124 -14 -106 601 -722 l557 -558 -1737 0 c-1722 0 -1738 0 -1792 -20 -74 -28 -139 -98 -161 -174 -13 -45 -16 -91 -13 -224 3 -145 6 -173 25 -213 25 -56 84 -118 138 -144 39 -20 75 -20 1793 -25 l1752 -5 -560 -560 c-617 -617 -604 -601 -603 -722 1 -96 31 -148 165 -279 140 -138 176 -159 278 -159 62 1 84 5 125 27 67 36 1953 1923 1981 1983 28 60 26 176 -5 236 -32 64 -1928 1958 -1985 1984 -52 23 -151 29 -197 12z"/>
        </g>
      </svg>
    </span>
  );
};

export default LineArrow;
