import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Warn = (props) => {
  return (
    <svg onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" class={`${props.classes || ""}`} fill={props.fill || "none"} width={props.width || "18"} height={props.height || "16"}  viewBox="0 0 18 16" >
      <path d="M16.7858 13.0018L10.3909 1.81049C10.2515 1.56444 10.0493 1.35979 9.80492 1.2174C9.56057 1.07502 9.28282 1 9.00002 1C8.71721 1 8.43947 1.07502 8.19512 1.2174C7.95077 1.35979 7.74857 1.56444 7.60913 1.81049L1.21423 13.0018C1.07328 13.2459 0.99938 13.5229 1 13.8048C1.00063 14.0866 1.07576 14.3633 1.21778 14.6068C1.3598 14.8503 1.56367 15.0519 1.80871 15.1912C2.05375 15.3304 2.33126 15.4025 2.61311 15.3999H15.4029C15.6834 15.3996 15.9589 15.3256 16.2017 15.1851C16.4445 15.0447 16.6461 14.8428 16.7862 14.5998C16.9263 14.3569 17.0001 14.0813 17 13.8008C16.9999 13.5203 16.9261 13.2447 16.7858 13.0018Z" stroke="#FF0000" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.99414 5.79639L8.99414 10.5927" stroke="#FF0000" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.00781 12.2023H9.01581" stroke="#FF0000" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

export default Warn;
