import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const UpArrow = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" width={props.width || "330pt"} height={props.height || "330pt"} viewBox={props.viewBox || "0 0 330 330"} style={`enable-background:new 0 0 330 330; ${props.style || ''}`} fill={props.fill || '#3b3b3b91'}>
        <path id="XMLID_225_" d="M4.393,250.607c5.857,5.857,15.355,5.858,21.213-0.001l139.39-139.393L304.393,250.607  c5.857,5.857,15.355,5.858,21.213-0.001c5.858-5.858,5.858-15.355,0-21.213L175.606,79.393c-2.813-2.813-6.628-4.393-10.606-4.393  s-7.794,1.581-10.606,4.394L14.393,229.393C8.535,235.251,8.535,244.749,4.393,250.607z"/>
        <g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g />
      </svg>
    </span>
  );
};

export default UpArrow;
