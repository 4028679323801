import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const FollowUp = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg onclick={() => props.action && props.action()} xmlns="http://www.w3.org/2000/svg" version="1.0" class={`${props.classes || ""}`} width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M3620 5025 c-241 -45 -455 -159 -630 -335 -185 -185 -295 -402 -337 -662 l-18 -108 -397 0 -398 0 0 70 c0 82 -17 130 -63 179 -47 49 -100 71 -177 71 -77 0 -130 -22 -177 -71 -46 -49 -63 -97 -63 -179 l0 -70 -160 0 -160 0 0 73 c-1 152 -93 247 -240 247 -147 0 -239 -95 -240 -247 l0 -73 -115 0 c-91 0 -125 -4 -170 -21 -74 -28 -146 -100 -174 -174 -21 -56 -21 -57 -21 -1725 0 -1668 0 -1669 21 -1725 28 -74 100 -146 174 -174 l56 -21 2069 0 2069 0 56 21 c74 28 146 100 174 174 21 56 21 62 21 1398 l0 1342 41 50 c137 166 230 364 265 570 24 137 15 379 -19 508 -57 215 -150 377 -307 538 -162 165 -337 268 -557 326 -84 22 -123 26 -273 29 -122 2 -198 -1 -250 -11z m421 -170 c62 -12 193 -57 252 -87 269 -134 451 -353 544 -654 25 -83 27 -101 27 -274 0 -173 -2 -191 -27 -275 -190 -623 -853 -928 -1433 -660 -269 124 -469 360 -561 660 -25 84 -27 102 -27 275 0 173 2 191 27 274 112 363 367 617 723 721 133 39 339 48 475 20z m-3201 -795 c34 -18 40 -66 40 -302 0 -253 -7 -292 -54 -304 -44 -11 -76 4 -92 41 -17 42 -20 476 -4 522 19 49 65 67 110 43z m800 0 c34 -18 40 -66 40 -302 0 -253 -7 -292 -54 -304 -44 -11 -76 4 -92 41 -17 42 -20 476 -4 522 19 49 65 67 110 43z m-1080 -452 c0 -172 10 -207 71 -265 47 -44 97 -63 169 -63 72 0 122 19 169 63 61 58 71 93 71 265 l0 152 160 0 160 0 0 -150 c0 -168 8 -201 63 -259 47 -49 100 -71 177 -71 77 0 130 22 177 71 55 58 63 91 63 259 l0 150 398 0 397 0 18 -108 c38 -238 144 -457 307 -635 l52 -57 -1387 0 -1386 0 3 338 c3 316 4 339 23 371 40 68 88 88 218 90 l77 1 0 -152z m3995 -3205 c-7 -62 -37 -109 -85 -137 -35 -21 -38 -21 -2025 -24 -1094 -2 -2013 0 -2042 3 -62 7 -109 37 -137 85 -21 35 -21 45 -24 1253 l-2 1217 1494 0 1493 0 104 -49 c57 -28 149 -62 204 -77 94 -26 112 -28 305 -28 193 0 211 2 305 28 109 30 270 102 356 160 l54 36 3 -1207 c2 -665 0 -1231 -3 -1260z"/>
          <path d="M3635 4701 c-316 -83 -539 -291 -641 -601 -26 -79 -28 -96 -29 -255 0 -138 4 -182 19 -235 90 -309 317 -536 626 -626 53 -15 97 -19 235 -19 159 1 176 3 255 29 316 104 535 343 606 662 21 95 15 326 -11 419 -24 88 -96 230 -158 310 -105 137 -295 265 -462 311 -94 25 -350 29 -440 5z m349 -162 c237 -46 448 -235 534 -478 24 -69 27 -89 27 -221 0 -132 -3 -152 -27 -221 -15 -42 -44 -105 -64 -140 -48 -82 -171 -205 -253 -253 -409 -238 -923 -25 -1051 435 -24 90 -27 244 -5 337 89 377 458 615 839 541z"/>
          <path d="M3760 4103 l0 -218 -112 -112 -113 -113 63 -63 62 -62 130 131 130 131 0 261 0 262 -80 0 -80 0 0 -217z"/>
          <path d="M592 2619 c-72 -28 -144 -101 -172 -176 -29 -78 -25 -206 9 -272 99 -190 369 -232 521 -81 159 160 102 452 -105 529 -74 28 -180 28 -253 0z m206 -169 c130 -79 74 -280 -78 -280 -152 0 -208 201 -78 280 43 26 113 26 156 0z"/>
          <path d="M1200 2480 l0 -80 1600 0 1600 0 0 80 0 80 -1600 0 -1600 0 0 -80z"/>
          <path d="M1200 2160 l0 -80 1440 0 1440 0 0 80 0 80 -1440 0 -1440 0 0 -80z"/>
          <path d="M4240 2160 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
          <path d="M592 1819 c-72 -28 -144 -101 -172 -176 -29 -78 -25 -206 9 -272 99 -190 369 -232 521 -81 159 160 102 452 -105 529 -74 28 -180 28 -253 0z m206 -169 c130 -79 74 -280 -78 -280 -152 0 -208 201 -78 280 43 26 113 26 156 0z"/>
          <path d="M1200 1680 l0 -80 1600 0 1600 0 0 80 0 80 -1600 0 -1600 0 0 -80z"/>
          <path d="M1200 1360 l0 -80 1440 0 1440 0 0 80 0 80 -1440 0 -1440 0 0 -80z"/>
          <path d="M4240 1360 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
          <path d="M592 1019 c-72 -28 -144 -101 -172 -176 -29 -78 -25 -206 9 -272 99 -190 369 -232 521 -81 159 160 102 452 -105 529 -74 28 -180 28 -253 0z m206 -169 c130 -79 74 -280 -78 -280 -152 0 -208 201 -78 280 20 12 51 20 78 20 27 0 58 -8 78 -20z"/>
          <path d="M1200 880 l0 -80 1600 0 1600 0 0 80 0 80 -1600 0 -1600 0 0 -80z"/>
          <path d="M1200 560 l0 -80 1440 0 1440 0 0 80 0 80 -1440 0 -1440 0 0 -80z"/>
          <path d="M4240 560 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
        </g>
      </svg>
    </span>
  );
};

export default FollowUp;
