import { h, Component } from 'preact';
import { useState, useEffect } from "preact/hooks";

const SingleCard = (props) => {
  const { masterClasses, classes, children, buttons, headerText } = props;

  let [anyButtonLabel, setButtonLabel] = useState(false);

  useEffect(() => {
    if (buttons && buttons.length) {
      buttons.map(button => {
        if (button.label)
          setButtonLabel(true);
      });
    }
  }, []);

  return (
    <div data-label={headerText} class={`p-16 bor-all bg-white single-card pos-relative ${masterClasses || ''}`}>
      <div class={`${classes || ''}`}>
        {children}
      </div>
      {
        anyButtonLabel && buttons && buttons.length > 0 &&
        <div>
          <hr class="m-t-16 m-b-0" />
          {/*
            Bug: COMP_BG_629 (The alignment of the "View" , "Authenticate" button is not correct)
            Modification: Added class justify-center for centering content
            By: Akanksha Malviya
            Date: 2nd Nov, 2023
          */}
          <div class="display-flex justify-center">
            {buttons.map(buttonObj => (
              buttonObj.label &&
                <button onClick={(e) => {buttonObj.action && buttonObj.action();}}>
                  {buttonObj.label}
                </button>
            ))}
          </div>
        </div>
      }
    </div>
  );
};

export default SingleCard;
