/*
  Modification: add Waiting svg
  By: Komal Wable
  Date: 19/04/2023
*/
import { h } from 'preact';
// from flaticon
const Waiting = (props) => {
  return (
    <span class={props.masterClasses || ''} title={props.title || ''}>
      <svg onclick={() => props.action && props.action()} class={`${props.classes || ""}`} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "256pt"} height={props.height || "256pt"}
        viewBox={props.viewBox || "0 0 14 18"} fill="none" preserveAspectRatio="xMidYMid meet"
      >
        <path d="M1 17H13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 1H13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.2857 17V13.6624C11.2856 13.2381 11.1049 12.8312 10.7834 12.5312L7.00001 9L3.21658 12.5312C2.89507 12.8312 2.71439 13.2381 2.71429 13.6624V17" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.71429 1V4.3376C2.71439 4.76191 2.89507 5.16881 3.21658 5.4688L7.00001 9L10.7834 5.4688C11.1049 5.16881 11.2856 4.76191 11.2857 4.3376V1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.3346 5.2L3.66536 5.2C3.43452 5.2 3.32701 5.48613 3.50074 5.63814L6.83537 8.55595C6.92963 8.63842 7.07037 8.63842 7.16463 8.55595L10.4993 5.63814C10.673 5.48613 10.5655 5.2 10.3346 5.2Z" fill="white" stroke="white"/>
      </svg>
    </span>
  );
};

export default Waiting;
