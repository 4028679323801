import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Edit = (props) => {
  return (
    <span title={props.title || ''} class={props.masterClasses || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" class={props.classes || ""} onclick={(e) => props.action && props.action(e)} version="1.1" width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512 512"}><g>
        <g xmlns="http://www.w3.org/2000/svg" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
        	<g>
        		<polygon points="51.2,353.28 0,512 158.72,460.8   " fill="#535c69" data-original="#000000" style="" class=""/>
        	</g>
        </g>
        <g xmlns="http://www.w3.org/2000/svg" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
        	<g>
        			<rect x="89.73" y="169.097" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -95.8575 260.3719)" width="353.277" height="153.599" fill="#535c69" data-original="#000000" style="" class=""/>
        	</g>
        </g>
        <g xmlns="http://www.w3.org/2000/svg" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
        	<g>
        		<path d="M504.32,79.36L432.64,7.68c-10.24-10.24-25.6-10.24-35.84,0l-23.04,23.04l107.52,107.52l23.04-23.04    C514.56,104.96,514.56,89.6,504.32,79.36z" fill="#535c69" data-original="#000000" style="" class=""/>
        	</g>
        </g>
        </g>
      </svg>
    </span>
  );
};

export default Edit;
