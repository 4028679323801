const PanVerify = (props) => {
  return (
    <span title={props.title || ''} class={props.masterClasses || ''}>
      <svg class={props.classes || ''} onclick={(e) => props.action && props.action(e)} width={props.width || '512.000000pt'} height={props.height || '512.000000pt'} xmlns="http://www.w3.org/2000/svg" version="1.1"
        id="Icons" x="0px" y="0px" viewBox={props.viewBox || "0 0 512.000000 512.000000"} style={`enable-background:new 0 0 32 32; ${props.style || ''}`} fill={props.fill || ''}
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || ''} stroke={props.stroke || 'none'}>
          <path d="M69 4537 c-19 -12 -42 -38 -51 -57 -17 -33 -18 -122 -18 -1430 0 -1308 1 -1397 18 -1430 9 -19 32 -45 51 -57 l34 -23 1422 0 c1126 0 1425 -3 1432 -12 5 -7 20 -38 32 -68 63 -152 182 -325 319 -463 116 -118 208 -186 431 -319 256 -153 236 -152 468 -17 98 57 212 128 253 158 328 234 551 581 632 984 22 106 23 135 23 524 0 400 -1 412 -21 439 -37 51 -67 65 -150 75 -138 15 -243 85 -316 212 -48 83 -63 87 -290 87 l-198 0 0 648 c0 609 -2 649 -19 688 -11 24 -33 50 -53 62 l-35 22 -1965 0 -1965 0 -34 -23z m3771 -422 l0 -145 -1770 0 -1770 0 0 145 0 145 1770 0 1770 0 0 -145z m-2 -712 l-3 -258 -205 -5 c-235 -6 -240 -7 -287 -87 -15 -27 -39 -63 -53 -81 -54 -70 -182 -132 -272 -132 -56 0 -101 -22 -134 -66 l-29 -37 0 -361 c0 -199 4 -400 8 -448 l8 -88 -1285 0 -1286 0 0 910 0 910 1770 0 1770 0 -2 -257z m610 -618 c23 -30 69 -75 102 -100 65 -50 185 -111 238 -121 l34 -7 -5 -316 c-4 -272 -7 -329 -25 -406 -55 -239 -162 -442 -324 -611 -111 -116 -213 -190 -428 -311 l-55 -32 -135 81 c-74 45 -158 98 -187 120 -249 184 -428 473 -489 793 -14 72 -17 149 -18 384 l-1 293 78 28 c98 34 201 104 272 183 30 33 55 64 55 69 0 4 190 8 423 8 l422 0 43 -55z" />
          <path d="M684 3021 c-53 -32 -74 -68 -74 -125 0 -66 27 -111 83 -136 40 -19 71 -20 576 -20 516 0 536 1 575 20 55 27 79 63 83 124 5 60 -18 105 -69 136 -32 19 -52 20 -588 20 -526 0 -557 -1 -586 -19z" />
          <path d="M685 2421 c-53 -32 -68 -58 -73 -119 -3 -47 0 -63 20 -91 12 -19 39 -42 58 -52 33 -18 74 -19 775 -19 l741 0 44 23 c103 53 108 196 8 257 -32 19 -52 20 -788 20 -718 0 -756 -1 -785 -19z" />
          <path d="M4305 2281 c-16 -10 -106 -96 -200 -190 -93 -94 -174 -171 -179 -171 -5 0 -42 31 -81 69 -77 75 -112 89 -182 76 -48 -9 -99 -60 -109 -109 -13 -71 8 -110 129 -234 159 -163 212 -192 294 -162 32 12 507 481 529 522 8 15 14 47 14 71 0 109 -126 184 -215 128z" />
        </g>
      </svg>
    </span>
  );
};
export default PanVerify;
