import { h, Component } from 'preact';
import { useState, useEffect } from "preact/hooks";

const Ribbon = (props) => {
  const { ribbonText, ribbonColor, type, variant } = props;

  return (
    <div>
      {
        type === 'rotated' && ribbonText &&
        <div class={`rotated-card-ribbon ${ribbonColor ? `${ribbonColor.toLowerCase()}-ribbon` : 'default-ribbon-color'}`}>
          {ribbonText}
        </div>
      }
      {
        type === 'folded' && ribbonText &&
        <div class={`folded-card-ribbon-container ${variant}-ribbon display-flex pos-absolute`}>
          <div class={`folded-card-ribbon ${ribbonColor ? `${ribbonColor.toLowerCase()}-ribbon` : 'default-ribbon-color'}`}>
            <span class="folded-card-ribbon-text">{ribbonText}</span>
          </div>
        </div>
      }
    </div>
  );
};

export default Ribbon;
