import { h } from 'preact';

const CarInsuranceLoader = (props) => {
  const { classes, imgClasses, textClasses } = props;

  return (
    <>
      {/*<div class="HeartRatingLoading">
      <svg width="16px" height="12px">
        <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
        <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
      </svg>
    </div> */}
      <div class={`${classes || ''} carLoader`} style={`top: ${props.top || ' '}; left: ${props.left || ''}`}>
        <img class={`${imgClasses || ''} img`} src="../../assets/images/carLoader.gif"/>
        <span class={`${textClasses || ''} text`}>Loading...</span>
      </div>
    </>
  );
};

export default CarInsuranceLoader;
