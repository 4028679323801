import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Insurance = (props) => {
  return (
    <span class={props.masterClasses || ''} title={props.title || ''}>
      <svg onclick={() => props.action && props.action()} class={`${props.classes || ""}`} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512pt"} height={props.height || "512pt"}
        viewBox={props.viewBox || "0 0 512.000000 512.000000"} preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || ''} stroke={props.stroke || 'none'} >
          <path d="M2365 5114 c-360 -37 -634 -113 -930 -258 -261 -129 -449 -262 -659 -465 -217 -212 -377 -432 -512 -706 -126 -258 -203 -511 -246 -810 -17 -118 -17 -512 0 -630 42 -295 120 -553 242 -800 137 -280 272 -468 494 -691 221 -220 412 -357 681 -489 188 -92 309 -137 500 -185 500 -126 1002 -102 1490 71 149 53 407 182 540 271 299 199 573 480 769 788 72 113 188 353 235 486 126 355 174 730 140 1094 -12 136 -27 169 -85 189 -34 12 -44 12 -69 0 -15 -8 -37 -26 -48 -41 -19 -25 -19 -33 -8 -201 13 -184 9 -297 -15 -487 -54 -416 -224 -814 -490 -1152 -74 -94 -278 -298 -372 -372 -345 -272 -752 -442 -1190 -497 -222 -28 -548 -11 -784 42 -235 52 -514 165 -717 292 -206 128 -467 359 -605 535 -273 347 -441 751 -497 1190 -16 125 -16 419 0 547 41 332 165 683 339 963 170 274 456 565 722 734 298 190 641 314 998 359 139 18 432 15 577 -5 538 -76 1037 -331 1399 -718 53 -56 96 -106 96 -110 0 -5 -99 -8 -220 -8 -140 0 -228 -4 -244 -11 -60 -28 -71 -122 -20 -170 l26 -24 351 -3 c312 -3 356 -1 383 13 18 9 37 28 43 41 7 17 11 140 11 380 l0 356 -29 29 c-48 47 -130 37 -164 -21 -14 -23 -17 -58 -17 -208 l0 -179 -87 90 c-396 406 -917 671 -1483 752 -109 16 -456 28 -545 19z" />
          <path d="M1945 3639 c-324 -109 -601 -205 -613 -211 -54 -28 -54 -24 -49 -621 4 -518 5 -557 25 -638 48 -202 133 -387 249 -550 79 -110 264 -304 378 -396 177 -143 562 -373 625 -373 32 0 269 125 399 210 172 112 287 208 427 352 226 235 361 476 427 763 19 84 21 129 24 637 5 592 5 588 -49 616 -42 22 -1209 412 -1231 411 -12 0 -287 -90 -612 -200z m1134 -193 c284 -96 524 -178 534 -181 16 -7 17 -36 17 -419 0 -447 -7 -552 -45 -695 -103 -387 -448 -763 -951 -1036 l-71 -39 -64 33 c-211 109 -427 267 -589 431 -217 219 -341 435 -397 694 -12 57 -16 168 -20 549 -3 262 -3 477 -1 477 1 0 242 81 533 180 292 98 532 179 534 179 2 1 236 -77 520 -173z" />
          <path d="M3155 2869 c-11 -6 -171 -162 -356 -346 l-337 -335 -182 227 c-199 249 -220 266 -287 244 -59 -19 -90 -93 -60 -146 32 -57 446 -564 473 -579 67 -37 66 -37 491 388 220 221 397 406 403 422 30 81 -69 166 -145 125z" />
        </g>
      </svg>
    </span>
  );
};

export default Insurance;
