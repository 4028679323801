/*
  modified : Ashutosh G
  modified : 30/05/2024
  modification : TaskErrorModal component added
*/
import OrgChartModal from "../../components/orgChartModal";

const TaskErrorModal = (props) => {
  const { isShowTaskCompletionError, taskCompletionErrorHeader, taskCompletionError, currentTask, toggleTaskCompletionError } = props;

  /*
    Modification: Increased the width of orgModal
    By: Devang
    Date: 04/06/2024
  */
  return (
    <OrgChartModal title={taskCompletionErrorHeader} masterClasses={``} orgChartClasses={`w-60vw`} isOpen={isShowTaskCompletionError} onClose={toggleTaskCompletionError}>
      <div class="row">
        {/*
          Rejection Comment - Alignment Issue of paragraphs
          Modification: Updated flex class
          By: Devang
          Date: 30/05/2024
        */}
        <div class="display-flex flex-direction-column m-b-16 w-100p">
          <p class="fs-16 m-b-12">{taskCompletionError}</p>
          {/*
            Rejection Comment - Showing 0 if there are no errorFields
            Modification: Updated conditions on error length and shifted errors message inside jsx condition
            By: Devang
            Date: 30/05/2024
          */}
          {
            currentTask.errorFields && currentTask.errorFields.length > 0 &&
            <>
              <p class="fs-16 m-b-12 w-100p">{"Please resolve the following errors to submit the form."}</p>
              <div class="formwizard-error-table">
                <table class="bor-all">
                  <tr class="bor-all"><th class="bor-all">Field Name</th><th class="text-center bor-all">Error Description</th></tr>
                  {
                    currentTask.errorFields.map(item =>
                      <tr class="bor-all">
                        <td class="w-20p text-red bor-all">{item.displayName}</td>
                        <td class="w-80p text-red bor-all">{item.errorMessage}</td>
                      </tr>
                    )
                  }
                </table>
              </div>
            </>
          }
        </div>
        <div class="flex-r-m w-full">
          <button class="primary-button flex-c-m" onClick={toggleTaskCompletionError}>Close</button>
        </div>
      </div>
    </OrgChartModal>
  );
};

export default TaskErrorModal;
