/**
* @param date - Date to be formatted, need not be JS date, ISO string would be fine as well
* @param isTimeRequired - If set to true, will also return the time of the day component
*/
// import http from 'fetch-bb';
import CONSTANTS from '../lib/constants';
import { AppStore } from '../lib/store';
import moment from "moment";
import imageCompression from 'browser-image-compression';
import { useContext } from "preact/hooks";
import ContextOne from '../components/context';

const userInfo = JSON.parse(localStorage.getItem('userInfo'));
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function monthName(month) {
  return months[month] ? months[month] : '';
}

function formatDateTime(date, isTimeRequired) {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone:'Asia/Kolkata'
  };
  let formattedDate = '';
  if (isTimeRequired) {
    options.hour = 'numeric';
    options.minute = 'numeric';
    options.second = 'numeric';
  }
  if (date) {
    formattedDate = new Intl.DateTimeFormat('en-IN', options).format(new Date(date));
  }
  return formattedDate;
}

{/*
  Modification:- Proper formating of the string is done
  Name:- Rajkumar Pradhan
  Date:- 31-08-2023
*/}
function properStringFormat (string) {
  return string.replace(/([a-z])([A-Z])/g, '$1 $2');
}

function formatDateTimeForDashboard(date, isTimeRequired) {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone:'Asia/Kolkata'
  };
  let formattedDate = '';
  if (isTimeRequired) {
    options.hour = 'numeric';
    options.minute = 'numeric';
    options.second = 'numeric';
  }
  if (date) {
    formattedDate = new Intl.DateTimeFormat('en-IN', options).format(new Date(date).setHours(new Date(date).getHours()-5,new Date(date).getMinutes()-30,0));
  }
  return formattedDate;
}

function getFormattedAmount(amount) {
  if (!isNaN(amount) || amount === 0) {
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(amount).split('.')[0];
  }
  return '-';
}

function applyAclForFeedAndChat(actorID,loggedInID) {
  if (actorID === loggedInID){
    return true;
  }
  return false;
}

function startLoader() {
  let element = document.getElementById('loader-bg');
  element.style.display = 'block';
}

function stopLoader() {
  let element = document.getElementById('loader-bg');
  element.style.display = 'none';
}

function getCommaSeparatedValues(list, value) {
  let names = [];
  if (!value) value = 'displayName';
  list.map((row) => {
    row ? names.push(row[value]) : '';
  });
  names = names.join(', ');
  return names;
}

function amountInWordsConversion(amount) {
  let mapping = {
    '0': '',
    '1': 'एक',
    '2': 'दोन',
    '3': 'तीन',
    '4': 'चार',
    '5': 'पाच',
    '6': 'सहा',
    '7': 'सात',
    '8': 'आठ',
    '9': 'नऊ',
    '10': 'दहा',
    '11': 'अकरा',
    '12': 'बारा',
    '13': 'तेरा',
    '14': 'चौदा',
    '15': 'पंधरा',
    '16': 'सोळा',
    '17': 'सतरा',
    '18': 'अठरा',
    '19': 'एकोणीस',
    '20': 'वीस',
    '21': 'एकवीस',
    '22': 'बावीस',
    '23': 'तेवीस',
    '24': 'चोवीस',
    '25': 'पंचवीस',
    '26': 'सव्वीस',
    '27': 'सत्तावीस',
    '28': 'अठ्ठावीस',
    '29': 'एकोणतीस',
    '30': 'तीस',
    '31': 'एकतीस',
    '32': 'बत्तीस',
    '33': 'तेहेतीस',
    '34': 'चौतीस',
    '35': 'पस्तीस',
    '36': 'छत्तीस',
    '37': 'सदतीस',
    '38': 'अडतीस',
    '39': 'एकोणचाळीस',
    '40': 'चाळीस',
    '41': 'एक्केचाळीस',
    '42': 'बेचाळीस',
    '43': 'त्रेचाळीस',
    '44': 'चव्वेचाळीस',
    '45': 'पंचेचाळीस',
    '46': 'सेहेचाळीस',
    '47': 'सत्तेचाळीस',
    '48': 'अठ्ठेचाळीस',
    '49': 'एकोणपन्नास',
    '50': 'पन्नास',
    '51': 'एक्कावन्न',
    '52': 'बावन्न',
    '53': 'त्रेपन्न',
    '54': 'चोपन्न',
    '55': 'पंचावन्न',
    '56': 'छप्पन्न',
    '57': 'सत्तावन्न',
    '58': 'अठ्ठावन्न',
    '59': 'एकोणसाठ',
    '60': 'साठ',
    '61': 'एकसष्ठ',
    '62': 'बासष्ठ',
    '63': 'त्रेसष्ठ',
    '64': 'चौसष्ठ',
    '65': 'पासष्ठ',
    '66': 'सहासष्ठ',
    '67': 'सदुसष्ठ',
    '68': 'अडुसष्ठ',
    '69': 'एकोणसत्तर',
    '70': 'सत्तर',
    '71': 'एक्काहत्तर',
    '72': 'बाहत्तर',
    '73': 'त्र्याहत्तर',
    '74': 'चौर्‍याहत्तर',
    '75': 'पंच्याहत्तर',
    '76': 'शहात्तर',
    '77': 'सत्याहत्तर',
    '78': 'अठ्ठ्याहत्तर',
    '79': 'एकोण ऐंशी',
    '80': 'ऐंशी',
    '81': 'एक्क्याऐंशी',
    '82': 'ब्याऐंशी',
    '83': 'त्र्याऐंशी',
    '84': 'चौऱ्याऐंशी',
    '85': 'पंच्याऐंशी',
    '86': 'शहाऐंशी',
    '87': 'सत्त्याऐंशी',
    '88': 'अठ्ठ्याऐंशी',
    '89': 'एकोणनव्वद',
    '90': 'नव्वद',
    '91': 'एक्क्याण्णव',
    '92': 'ब्याण्णव',
    '93': 'त्र्याण्णव',
    '94': 'चौऱ्याण्णव',
    '95': 'पंच्याण्णव',
    '96': 'शहाण्णव',
    '97': 'सत्त्याण्णव',
    '98': 'अठ्ठ्याण्णव',
    '99': 'नव्व्याण्णव'
  };
  let amountInWords = '';
  // let amountArray = amount.toString().split('.');
  let splitAmount = amount.toString().split('');
  let length = splitAmount.length;

  if (length === 1) {
    amountInWords = mapping[amount.toString()];
  }

  if (length === 2) {
    amountInWords = mapping[amount.toString()];
  }

  if (length === 3) {
    let array1 = splitAmount.splice(0, 1);

    if (splitAmount[0] === '0') {
      amountInWords = mapping[array1[0]] + 'शे ' + mapping[splitAmount[1]];
    } else {
      amountInWords = mapping[array1[0]] + 'शे ' + mapping[splitAmount.join('')];
    }
  }

  if (length === 4 || length === 5) {
    let array1 = (length === 4) ? splitAmount.splice(0, 1) : splitAmount.splice(0, 2);
    let array2 = splitAmount.splice(0, 1);

    amountInWords = (length === 4) ? (mapping[array1[0]] + ' हजार ') : (mapping[array1.join('')] + ' हजार ');

    if (array2[0] !== '0') {
      amountInWords += mapping[array2[0]] + 'शे ';
    }
    if (splitAmount[0] === '0') {
      amountInWords += mapping[splitAmount[1]];
    } else {
      amountInWords += mapping[splitAmount.join('')];
    }
  }

  if (length === 6 || length === 7) {
    let array1 = (length === 6) ? splitAmount.splice(0, 1) : splitAmount.splice(0, 2);
    let array2 = splitAmount.splice(0, 2);
    let array3 = splitAmount.splice(0, 1);

    amountInWords = (length === 6) ? (mapping[array1[0]] + ' लाख ') : (mapping[array1.join('')] + ' लाख ');

    if (array2[0] === '0' && array2[1] !== '0') {
      amountInWords += mapping[array2[1]] + ' हजार ';
    } else if (array2[0] !== '0'){
      amountInWords += mapping[array2.join('')] + ' हजार ';
    }
    if (array3[0] !== '0') {
      amountInWords += mapping[array3[0]] + 'शे ';
    }
    if (splitAmount[0] === '0') {
      amountInWords += mapping[splitAmount[1]];
    } else {
      amountInWords += mapping[splitAmount.join('')];
    }
  }

  if (length === 8 || length === 9) {
    let array1 = (length === 8) ? splitAmount.splice(0, 1) : splitAmount.splice(0, 2);
    let array2 = splitAmount.splice(0, 2);
    let array3 = splitAmount.splice(0, 2);
    let array4 = splitAmount.splice(0, 1);

    amountInWords = (length === 8) ? (mapping[array1[0]] + ' करोड ') : (mapping[array1.join('')] + ' करोड ');

    if (array2[0] === '0' && array2[1] !== '0') {
      amountInWords += mapping[array2[1]] + ' लाख ';
    } else if (array2[0] !== '0'){
      amountInWords += mapping[array2.join('')] + ' लाख ';
    }
    if (array3[0] === '0' && array3[1] !== '0') {
      amountInWords += mapping[array3[1]] + ' हजार ';
    } else if (array3[0] !== '0'){
      amountInWords += mapping[array3.join('')] + ' हजार ';
    }
    if (array4[0] !== '0') {
      amountInWords += mapping[array4[0]] + 'शे ';
    }
    if (splitAmount[0] === '0') {
      amountInWords += mapping[splitAmount[1]];
    } else {
      amountInWords += mapping[splitAmount.join('')];
    }
  }
  return amountInWords;
}

function convertArrayOfObjectsToCSV(args) {
  function isNumber (n) {
    let t;
    if (n) {t = parseFloat(n.toString().replace(/[^a-zA-Z ]/g, ""));}
    return isFinite(t) && +t === t;
  }
  let result, ctr, keys, columnDelimiter, lineDelimiter, data;
  data = args.data || null;
  if (data === null || !data.length) {
    return null;
  }
  columnDelimiter = args.columnDelimiter || ',';
  lineDelimiter = args.lineDelimiter || '\n';
  if (args.columns && args.columns.length) {
    keys = [];
    args.columns.map(column => {
      keys.push(column.field)
    });
  } else {
    const biggestObject = data.reduce((biggest, obj) => {
      if (Object.keys(biggest).length > Object.keys(obj).length) return biggest
      return obj
    });
    keys = Object.keys(biggestObject);
  }
  // keys = Object.keys(data[0]);
  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;
  data.forEach(item => {
    ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;
      if (item[key]) {
        if (isNumber(item[key]) === true) {
          result += parseFloat((item[key].toString()).replace(/[^a-zA-Z ]/g, ""));
        } else {
          result += "\"" + item[key] + "\"";
        }
      } else {
        result += "\"" + "-" + "\"";
      }
      ctr++;
    });
    result += lineDelimiter;
  });
  return result;
}

function downloadCSV(csvArray, fileName, columns) {
  let link;
  let csv = convertArrayOfObjectsToCSV({
    data: csvArray,
    columns
  });
  if (csv === null)
    return;
  if (!fileName.includes('.csv')) fileName = fileName + '.csv';
  const blob = new Blob([csv], {
    type: "text/csv;charset=utf-8;"
  });
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection, Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style = "visibility:hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

function stripEmpty(object) {
  Object.keys(object).forEach(key => {
    if (object[key] && typeof object[key] === 'object') stripEmpty(object[key]);
    else if (object[key] === undefined || object[key] === '' || object[key] === null) delete object[key];
  });
}

function removeEmptyObjects(object) {
  Object.keys(object).forEach(key => {
    if (object[key] && typeof object[key] === 'object' && !Object.keys(object[key]).length) {
      delete object[key];
    }
  });
}

//Modified By: Yashvi.
//Modification Date: 8 Dec 2020
//Modification: Changed passwordErrorMessageText function with all the validations.
/*
  Created By: Komal
  Created On: 7 Dec 2020
  modification : change the limit of regexLength
*/
function passwordErrorMessageText(data, action){
  const regexCaptial = /^(.*?[A-Z])+$/;
  const regexSmall = /^(.*?[a-z])+$/;
  const regexSpCharacter = /^(.*?[#?!@$%^&*-])+$/;
  const regexNumber = /^(.*?[0-9])+$/;
  const regexLength = /^.{8,15}$/;
  const errorMsgForUppercase = ' Uppercase character';
  const errorMsgForLowercase = ' Lowercase character';
  const errorMsgForNumber = ' Number';
  const errorMsgForSpCharacter = ' Symbol';
  const errorMsgForLength = ' and password length should be at least 8 characters long';
  let passwordErrorMessage = 'Password must contain atleast one';

  let mapCompleted = false;
  let capitalTested = false;
  let smallTested = false;
  let spCharacterTested = false;
  let numberTested = false;
  let lengthTested = false;
  let string = data.split('');
  passwordErrorMessage = 'Password must contain atleast one';
  if (data.length>0){
    string.map((letter,index) => {
      if (letter.match(regexCaptial)){
        capitalTested = true;
      }
      if (letter.match(regexSmall)){
        smallTested = true;
      }
      if (letter.match(regexSpCharacter)){
        spCharacterTested = true;
      }
      if (letter.match(regexNumber)){
        numberTested = true;
      }
      if (index === string.length -1){
        mapCompleted = true;
      }
      return '';
    });
    if (data.match(regexLength)){
      lengthTested = true;
    }
    if (mapCompleted){
      if (action === 'setPassword' || action === 'resetPassword') {
        return {capitalTested, smallTested, spCharacterTested, numberTested, lengthTested};
      }

      if (action === 'changePassword') {
        if (!capitalTested){
          passwordErrorMessage = (!smallTested || !spCharacterTested || !numberTested || !lengthTested) ? passwordErrorMessage + errorMsgForUppercase +',' : passwordErrorMessage + errorMsgForUppercase;
        }
        if (!smallTested){
          passwordErrorMessage = (!spCharacterTested || !numberTested || !lengthTested) ?  passwordErrorMessage + errorMsgForLowercase + "," : passwordErrorMessage + errorMsgForLowercase;
        }
        if (!spCharacterTested){
          passwordErrorMessage = (!numberTested || !lengthTested) ?  passwordErrorMessage + errorMsgForSpCharacter + "," : passwordErrorMessage + errorMsgForSpCharacter;
        }
        if (!numberTested){
          passwordErrorMessage = (!lengthTested) ? passwordErrorMessage + errorMsgForNumber + "," : passwordErrorMessage + errorMsgForNumber;
        }
        if (!lengthTested){
          passwordErrorMessage = passwordErrorMessage + errorMsgForLength;
        }
        return passwordErrorMessage;
      }
    }
  }
  else {
    passwordErrorMessage = '';
  }
}

function setTenantBrandColor() {
  let allElements = document.querySelectorAll('.tenant-brand-color');
  let allOpacityElements = document.querySelectorAll('.tenant-brand-color-opacity');

  if (allElements && allElements.length) {
    for (let i=0; i<=allElements.length-1; i++) {
      allElements[i].style.setProperty('--ttheme-default-colour', AppStore.get('brandColor'));
    }
  }
  if (allOpacityElements && allOpacityElements.length) {
    for (let i=0; i<=allOpacityElements.length-1; i++) {
      allOpacityElements[i].style.setProperty('--ttheme-default-colour-opacity', AppStore.get('brandColorOpacity'));
    }
  }
}

/*
Comment: check condition for titanDefAdmin role and return
Developer: Manohar
Date: 21/05/2020
*/

function aclWisePermissionAccess(coreEntity, action) {
  let userInfo1 = userInfo;
  if (!userInfo) userInfo1 = JSON.parse(localStorage.getItem('userInfo'));
  if(userInfo1 && (userInfo1.role === 'Silo Admin')) {
    return true;
  }
  else if (userInfo1 && userInfo1.userPermission && Object.keys(userInfo1.userPermission).length > 0 && userInfo1.userPermission[coreEntity]) {
    if (userInfo1.userPermission[coreEntity].length > 0 &&  this.hasPermission(userInfo1.userPermission[coreEntity], action)) {
      return true;
    } else {
      if ((userInfo.role === 'Silo Admin') && coreEntity === 'acl') {
        return true;
      }
      return false;
    }
  } else if (userInfo1 && userInfo1.rolePermission && Object.keys(userInfo1.rolePermission).length > 0 && userInfo1.rolePermission[coreEntity]) {
    if (this.hasPermission(userInfo1.rolePermission[coreEntity], action)) {
      return true;
    } else {
      return false;
    }
  } else if (userInfo1 && userInfo1.departmentPermission && Object.keys(userInfo1.departmentPermission).length > 0 && userInfo1.departmentPermission[coreEntity]) {
    if (this.hasPermission(userInfo1.departmentPermission[coreEntity], action)) {
      return true;
    } else {
      return false;
    }
  } else {
    if (userInfo1 && (userInfo.role === 'Silo Admin') && coreEntity === 'acl') {
      return true;
    }
    return false;
  }
}
/*
  Comment: Get Initials of DisplayName for Avatars
  Developer: Yashvi
  Date: 5 Feb 2022
*/
/*
  Modified By: Arun Singh
  Modification: The function has been updated to match the first letter of the first and last name and to remove the extra space in the displayname for avatars.
  Date: 29/09/2023
*/
/*
  Bug: Prod Bug - toUpperCase setting of undefined
  Modification: Added console to monitor error (if occured) on prod and ternary operation to make sure that splitDisplayName[0][0] exists
  By: Devang
  Date: 14/02/2024
*/
function getInitials(displayName) {
  if (typeof displayName === "string") {
    // Remove extra spaces between words
    let cleanedDisplayName = displayName.replace(/\s+/g, ' ').trim();
    let splitDisplayName = cleanedDisplayName.split(" ");
    if (splitDisplayName.length > 1) {
      return splitDisplayName[0] && splitDisplayName[0][0] ? (splitDisplayName[0][0].toUpperCase() + splitDisplayName[splitDisplayName.length - 1][0].toUpperCase()) : '';
    } else {
      return splitDisplayName[0] && splitDisplayName[0][0] ? splitDisplayName[0][0].toUpperCase() : '';
    }
  }
}
function getDay(date) {
  const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
  return days[ date.getDay() ];
}
function getMonth(date) {
  const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
  return months[ date.getMonth() ];
}

function getWeeks(days) {
  let weeks = (days/7);
  if (weeks % 1 !== 0) {
    weeks = weeks.toFixed(1);
  }
  return Math.round(weeks);
}
function validateAadharNumber(aadharNumber) {
  const d = [
	    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
	    [1, 2, 3, 4, 0, 6, 7, 8, 9, 5],
	    [2, 3, 4, 0, 1, 7, 8, 9, 5, 6],
	    [3, 4, 0, 1, 2, 8, 9, 5, 6, 7],
	    [4, 0, 1, 2, 3, 9, 5, 6, 7, 8],
	    [5, 9, 8, 7, 6, 0, 4, 3, 2, 1],
	    [6, 5, 9, 8, 7, 1, 0, 4, 3, 2],
	    [7, 6, 5, 9, 8, 2, 1, 0, 4, 3],
	    [8, 7, 6, 5, 9, 3, 2, 1, 0, 4],
	    [9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
	]

	// permutation table
	const p = [
	    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
	    [1, 5, 7, 6, 2, 8, 3, 0, 9, 4],
	    [5, 8, 0, 3, 7, 9, 6, 1, 4, 2],
	    [8, 9, 1, 6, 0, 4, 3, 5, 2, 7],
	    [9, 4, 5, 3, 1, 2, 6, 8, 7, 0],
	    [4, 2, 8, 6, 5, 7, 3, 9, 0, 1],
	    [2, 7, 9, 3, 8, 0, 6, 4, 1, 5],
	    [7, 0, 4, 6, 9, 1, 3, 2, 5, 8]
	]

	// validates Aadhar number received as string
    let c = 0
    let invertedArray = aadharNumber.split('').map(Number).reverse()

    invertedArray.forEach((val, i) => {
        c = d[c][p[(i % 8)][val]]
    })

    return (c === 0)
}
function addBusinessDaysToDate(date, days) {
  let day = date.getDay();

  date = new Date(date.getTime());
  date.setDate(date.getDate() + days + (day === 0 ? 1 : +!day) + (Math.floor((days - 1 + (day % 0 || 1)) / 5) * 1));
  return date;
}

const registrationTypeMappingClassColor = {
  Taxi: 'yellow',
  'Non Taxi': 'light-brown'
};

const registrationTypeTextMappingClassColor = {
  Taxi: 'text-black',
  'Non Taxi': 'text-white'
};
const registrationTypeMapping = {
  'Individual (Owned House)': 'Non Taxi',
  'Individual (Rented House)': 'Non Taxi',
  'Corporate (Owned)': 'Non Taxi',
  'Corporate (Rented)': 'Non Taxi',
  'CRTM': 'Taxi',
  'NRI': 'Non Taxi',
  'BH': 'Non Taxi'
};
const typeOfEnquiryMappingClassColor = {
  Hot: 'dark-red',
  New: 'blue',
  Cold: 'blue',
  Warm: 'orange',
  Cancelled: 'dark-red',
  Purchased: 'green',
  Self: 'orange'
};

const typeOfEnquiryMappingStyleColor = {
  Hold: '#c11d1d',
  Cancelled: '#ed553b',
  New: '#14adc6',
  Purchased: '#76b947',
  Hot: '#f50422',
  Cold: '#14adc6',
  Warm: '#e86224',
  '': '#20639B',
}

const typeOfEnquiryMappingColor = {
  Hold: 'dark-red',
  New: 'blue',
  Cancelled: 'orange',
  Purchased: 'green',
}

const typeOfPaymentMappingClassColor = {
  ACH: 'amber',
  Cheque: 'green',
  Self: 'dark-orange'
}

function inWords (num) {
  let a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
  let b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];
  if ((num = num.toString()).length > 9) return 'overflow';
  let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return; var str = '';
  str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
  str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
  str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
  str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
  str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
  return str;
}

const modelMapping = {
 venue: 'Venue',
 santro: 'Santro',
 grandi10nios: 'Grand i10 Nios',
 allnewi20: 'All New i20',
 i20nline: 'i20 N Line',
 alcazar: 'Alcazar',
 xcentprime: 'Xcent Prime',
 nextgenverna: "Next Gen Verna",
 aura: 'Aura',
 newcreta: 'New Creta',
 tucson: 'Tucson',
 konaev: 'Kona EV'
};

/*
  Bug:- COMP_BG_608
  Modification:- Added a new function to get difference in days without time
  Name:- Rajkumar Pradhan
  On:- 10-10-2023
*/
function getDifferenceInDaysWithoutTime(date) {
  if (!date) {
    return null;
  }
  // calculation of no. of days between two date

  // To set two dates to two variables
  let date1 = new Date(date);
  let date2 = new Date();

  // Set the time components to midnight (00:00:00)
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);

  // To calculate the no. of days between two dates
  let Difference_In_Days = (date2 - date1)/ (1000 * 60 * 60 * 24);

  return (Difference_In_Days).toFixed(0);
}

function getDifferenceInDays(date) {
  if (!date) {
    return null;
  }
  // calculation of no. of days between two date

  // To set two dates to two variables
  let date1 = new Date(date);
  let date2 = new Date();

  // To calculate the time difference of two dates
  let Difference_In_Time = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  return (Difference_In_Days).toFixed(0);
}

function isString(val) {
  return Object.prototype.toString.call(val) === '[object String]';
}

function isValidDate(val) {
  var timestamp = Date.parse(val);

  if (isNaN(timestamp) == false) {
    return true
  }
  return false
}

function getStageAcronym(stageName) {
  if (stageName) {
    stageName = stageName.toLowerCase();
    if (stageName === 'enquiry') return 'ENQ.';
    else if (stageName === 'booking') return 'BKG.';
    else if (stageName === 'retail') return 'RTL.';
    else if (stageName === 'delivery') return 'DLVRY.';
  }
}

/*
  modified : Ashutosh G
  modified : 15/09/2023
  modification : COMP_BG_671 solved (new getMomentCalendarTimeLabel added and comment code in formatDateTimeRelative() to ignore current year issue)
*/

/*function getMomentCalendarTimeLabel(date, isYearNeeded) {
	return moment(date).calendar(null, {
		sameDay: '[Today]',
		nextDay: '[Tomorrow]',
		nextWeek: 'dddd',
		nextWeek: 'dddd',
		lastDay: '[Yesterday]',
		lastWeek: '[Last] dddd',
		sameElse: isYearNeeded ? 'DD/MM/YYYY' : 'DD MMM',
	});
}*/

function getMomentCalendarTimeLabel(date, isYearNeeded) {
    const momentDate = moment(date);
    const today = moment().startOf('day');
    const tomorrow = moment().add(1, 'days').startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');

    if (momentDate.isSame(today, 'day')) {
        return 'Today';
    } else if (momentDate.isSame(tomorrow, 'day')) {
        return 'Tomorrow';
    } else if (momentDate.isSame(yesterday, 'day')) {
        return 'Yesterday';
    }

    return momentDate.format(isYearNeeded ? 'DD/MM/YYYY' : 'DD MMM');
}

function formatDateTimeRelative(date) {
	let d = getMomentCalendarTimeLabel(date, true);
	/*if (d.includes('/')) {
		let getDateYear = d.split('/') && d.split('/')[2];
		let currentYear = moment().format('YYYY');
		if (getDateYear === currentYear) d = getMomentCalendarTimeLabel(date, false);
	}*/
	return d;
}

function getDateColor (date) {
  if (date && date.toLowerCase() === 'today') return '#6bc950';
  return ''
}

function capitalizeFirstLetter(string) {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
}

function getBusinessDatesCount(startDate, endDate) {
  let count = 0;
  let curDate = +startDate;
  while (curDate <= +endDate) {
    const dayOfWeek = new Date(curDate).getDay();
    const isWeekend = (dayOfWeek === 0);
    if (!isWeekend) {
      count++;
    }
    curDate = curDate + 24 * 60 * 60 * 1000
  }
  return count;
}

function addDays(theDate, days) {
  return new Date(theDate.getTime() + days*24*60*60*1000);
}

const comparisonOperatorsHash = {
  '<': function(a, b) { return a < b; },
  '>': function(a, b) { return a > b; },
  '>=': function(a, b) { return a >= b; },
  '<=': function(a, b) { return a <= b; },
  '==': function(a, b) { return a == b; },
  '!=': function(a, b) { return a != b; },
  '===': function(a, b) { return a === b; },
  '!==': function(a, b) { return a !== b; }
};

function calcAge(dateString) {
  var birthday = +new Date(dateString);
  return ~~((Date.now() - birthday) / (31557600000));
}

function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

function camelCaseToNormalCase(str) {
  // console.log(str.replace(/([A-Z])/g, (match) => ` ${match}`).replace(/^./, (match) => match.toUpperCase()).trim(), "str.replace(/([A-Z])/g, (match) => ` ${match}`).replace(/^./, (match) => match.toUpperCase()).trim();");
  return str.replace(/([A-Z])/g, (match) => ` ${match}`).replace(/^./, (match) => match.toUpperCase()).trim();
}

function isEmptyObject(obj) {
  return JSON.stringify(obj) === '{}'
}

function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function getPreviousYearRange(start, stop, step) {
  /**
   * returns array of years from starting to end with gap of step_value
   * @param {number} start - starting Year. eg - 2023
   * @param {number} stop - ending Year. eg - 2002
   * @param {number} step - gap between years of array (should be negative value), eg - -1
   */
  return Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step)) ;
}

/*
  TO BE UPDATED
  Remove while loop, and replace with array.from.map
*/
function getZeroPrefixedNumber(num, size) {
  /**
   * returns string of number accepted as num parameter with prefix of '0' to fix length of size given
   * @param {number} num - number sent by user. eg - 2023
   * @param {number} size - length of num restricting min-length by giving '0' prefix. eg - 2002
   */
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

function getExpiryDateAfterYears(date = new Date(), numOfYear = 1) {
  date = new Date(date);
  return date.setFullYear(date.getFullYear() + numOfYear) - 24*60*60*1000;
}

function removeFalseKeyValuePairs(obj) {
  Object.keys(obj).filter((key) => {
    if (Array.isArray(obj[key]) && !obj[key].length) {
      delete obj[key];
    } else if (!(Boolean(obj[key]))) {
      delete obj[key];
    }
  });
  return obj;
}


function sortList(rows, col, isSortAscending) {
  let sortBy = col.sortableField || col.field; // first preference to sortableField
  let sortType = col.sortType && col.sortType.toLowerCase(); // string, array, date, number, null
  if (sortBy) {
    if (sortType === 'string' || !sortType) {
      if (isSortAscending) {
        rows = rows.sort((a, b) => {
          return (a[sortBy] || '').localeCompare(b[sortBy] || '');
        });
      } else {
        rows = rows.sort((a, b) => {
          return (b[sortBy] || '').localeCompare(a[sortBy] || '');
        });
      }
    } else if (sortType === 'array') {
      if (isSortAscending) {
        rows = rows.sort((a, b) => {
          return a[sortBy].length - b[sortBy].length;
        });
      } else {
        rows = rows.sort((a, b) => {
          return b[sortBy].length - a[sortBy].length;
        });
      }
    } else if (sortType === 'date') {
      if (isSortAscending) {
        rows = rows.sort((a,b) => {
          return new Date(a[sortBy] ||  new Date()) - new Date(b[sortBy] || new Date());
        });
      } else {
        rows = rows.sort((a,b) => {
          return new Date(b[sortBy] || new Date()) - new Date(a[sortBy] ||  new Date());
        });
      }
    } else if (sortType === 'number') {
      if (isSortAscending) {
        rows = rows.sort((a,b) => {
          return (a[sortBy] || 0) - (b[sortBy] || 0);
        });
      } else {
        rows = rows.sort((a,b) => {
          return (b[sortBy] || 0) - (a[sortBy] || 0);
        });
      }
    }
  }
  return rows;
}

function generateYearsBetween(startYear, endYear = new Date().getFullYear()) {
  let years = [];
  for (let i = startYear; i <= endYear; i++) {
    years.push(startYear);
    startYear++;
  }
  return years;
}

function getDashBoardDate(date = new Date()) {
  return moment(date).format('ddd, DD')
}
function getFullDate(date = new Date()) {
  return moment(date).format('DD MMM YYYY, hh:mm A')
}

function convertThousandsToK(count) {
  if (count >= 1000) {
    return `${parseInt(count/1000)}K`;
  }
  return count
}

function setDataInDOM(data, id, timeout) {

  let component = document.getElementById(id);
  if (component) {
    if (timeout !== null && !isNaN(timeout)) {
      setTimeout(() => {
        component.innerHTML = data;
      }, timeout);
    } else {
      component.innerHTML = data;
    }
  }
}

function titleCase(sentence) {
  if (sentence) {
    let wordsArr = sentence.toLowerCase().split(' ');
    wordsArr = wordsArr.map(word => word.charAt(0).toUpperCase() + word.substring(1))
    return wordsArr.join(' ');
  }
  return sentence;
}

function getTextWithHighlightMatching(text, searchText, highlightedStyle = {}) {
  const {
    textColor = '#000', // 000AFF - old blueish text color
    bgColor = '#fbee32'
  } = highlightedStyle

  // let searchTextRegex = new RegExp(searchText, "i");

  let searchTextInnerRegex = ``;
  searchText.split(' ').filter(text => text).map(text => {
    searchTextInnerRegex += `(?=.*${text})`;
  });
  let searchTextRegex = new RegExp(searchTextInnerRegex, "i");
  /*
    Modification: Added default text if searchText is not present to fix blank recentlyViewed sugestions
    By: Devang
    Date: 27/07/2023
  */
  let highlightedText = text;
  if (text && searchText) {
    highlightedText = (text.replace(searchTextRegex, match => `<span style="color: ${textColor}; background: ${bgColor}">${match}`)) + '</span>';
  }
  return {__html: highlightedText};
};


function minTwoDigits(n) {
  return (n < 10 ? '0' : '') + n;
}
function formatDateInput(date) {
  date = new Date(date);
  let month = date.getUTCMonth() + 1;
  let day = date.getUTCDate();
  let year = date.getUTCFullYear();
  let formattedDate = `${minTwoDigits(year)}-${minTwoDigits(month)}-${minTwoDigits(day)}`
  return formattedDate;
}

function normalCaseToCamelCase(text) {
  const words = text.trim().split(' ');

  const camelWords = words.map((word, index) => {
    if (index === 0) {
      return word;
    }
    return word ? (word.charAt(0).toUpperCase() + word.slice(1)) : word;
  });

  const camelCaseText = camelWords.join('');
  return camelCaseText;
}

function throwError(msg) {
  throw new Error(msg);
}

async function getCompressedImageFile(fileToBeUploaded) {
  let imageFileToBeCompressed = fileToBeUploaded.files[0];
  const options = {
    maxSizeMB: 0.2,
    maxWidthOrHeight: 900
  };
  let compressedFile = await imageCompression(imageFileToBeCompressed, options);
  let fileData = '';
  let fileObj = {};
  if (fileToBeUploaded.files[0].size > 30000) {
    fileData = compressedFile;
    fileObj.name= compressedFile.name;
    fileObj.size= compressedFile.size;
    fileObj.type= imageFileToBeCompressed.type;
    fileObj.value= fileToBeUploaded.value;
  } else {
    fileData = imageFileToBeCompressed;
    fileObj.name= imageFileToBeCompressed.name;
    fileObj.size= imageFileToBeCompressed.size;
    fileObj.type= imageFileToBeCompressed.type;
    fileObj.value= fileToBeUploaded.value;
  }
  return Promise.resolve(fileData);
}

/*
  Modification: Added function convert camelCase To Normal string
  By: Masum Raja
  Date: 20/09/2023
*/

function camelCaseToNormal(inputString) {
  return inputString
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    .replace(/_/g, ' ');
}

/*
  Modification:- Added vin number validation and capitalize All First Letter
  Name:- Masum Raja
  Date:- 21-09-2023
*/
function vinNumberValidation(value) {
  const regex = new RegExp("^[a-zA-Z0-9]{17}$");
  return regex.test(value)
}

function capitalizeAllFirstLetter(str) {
  return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

/*
  Modification:- Added age validation
  Name:- Masum Raja
  Date:- 09-10-2023
*/

function getAge(dateString) {
  var ageInMilliseconds = new Date() - new Date(dateString);
  return Math.floor(ageInMilliseconds/1000/60/60/24/365); // convert to years
}

function assignFiltersFromAnotherRoute(filterDispatchEntityKey, keyNeeded) {
  let [state, dispatch] = useContext(ContextOne);
  if (state.filters && state.filters[filterDispatchEntityKey]) {
    return state.filters[filterDispatchEntityKey][keyNeeded];
  }
  return
}

/*
  Bug: CDMS_ADMIN_FIXES_65 Functional	Admin tab - Request tile - Issue Inventory form - Catalogue - Branch - Total Req Qty , Request Qty Per Branch - Spinner (all Spinners)	It is allowing the negative value
  Modification: Added to prevent chars in number inputs
  By: Devang
  Date: 31/01/2024
*/
const preventNumberSpecialChar = (evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault();
/*
  Modification: T319 - Added Debounce function to limit the rate of function execution
  By: Devang
  Date: 20/05/2024
*/
const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

const showConfettiTasks = ["fill renewed policy details", "buy new policy"];

function roundToPrecision(number, precision) {
  return +(Math.round(number + `e+${precision}`)  + `e-${precision}`);
}
/*
  Modification: Exported all variables and functions at the end of the file
  By: Devang
  Date: 30/05/2024
*/
export {
  months,
  registrationTypeMappingClassColor,
  registrationTypeTextMappingClassColor,
  registrationTypeMapping,
  typeOfEnquiryMappingClassColor,
  typeOfEnquiryMappingStyleColor,
  typeOfEnquiryMappingColor,
  typeOfPaymentMappingClassColor,
  modelMapping,
  preventNumberSpecialChar,
  monthName,
  formatDateTime,
  properStringFormat,
  formatDateTimeForDashboard,
  getFormattedAmount,
  applyAclForFeedAndChat,
  startLoader,
  stopLoader,
  getCommaSeparatedValues,
  amountInWordsConversion,
  downloadCSV,
  stripEmpty,
  removeEmptyObjects,
  passwordErrorMessageText,
  setTenantBrandColor,
  aclWisePermissionAccess,
  getInitials,
  getDay,
  getMonth,
  getWeeks,
  validateAadharNumber,
  addBusinessDaysToDate,
  inWords,
  getDifferenceInDaysWithoutTime,
  getDifferenceInDays,
  isString,
  isValidDate,
  getStageAcronym,
  getMomentCalendarTimeLabel,
  formatDateTimeRelative,
  getDateColor,
  capitalizeFirstLetter,
  getBusinessDatesCount,
  addDays,
  getKeyByValue,
  camelCaseToNormalCase,
  isEmptyObject,
  deepCopy,
  getPreviousYearRange,
  getZeroPrefixedNumber,
  getExpiryDateAfterYears,
  removeFalseKeyValuePairs,
  sortList,
  generateYearsBetween,
  getDashBoardDate,
  getFullDate,
  convertThousandsToK,
  setDataInDOM,
  titleCase,
  getTextWithHighlightMatching,
  formatDateInput,
  normalCaseToCamelCase,
  throwError,
  camelCaseToNormal,
  vinNumberValidation,
  capitalizeAllFirstLetter,
  getAge,
  assignFiltersFromAnotherRoute,
  comparisonOperatorsHash,
  getCompressedImageFile,
  minTwoDigits,
  debounce,
  showConfettiTasks,
  roundToPrecision
}
