import { h, Component } from 'preact';
import { useState, useEffect } from "preact/hooks";
import CONSTANTS from '../../lib/constants';
import Axios from 'axios';
import { getItem, setItem } from '../../lib/myStore';
import ListCard from '../../components/listCard';
import CarInsuranceLoader from '../../components/carInsuranceLoader';


const RecentlyViewed = (props) => {
  let userInfo = getItem('userinfo');
  let [taskData, setTasksData] = useState([]);
  let [isLoading, setIsLoading] = useState(true);

  useEffect(async() => {
    if (props.isVisible) {
      setIsLoading(true);
      let mainInteraction = await Axios.get(`${CONSTANTS.API_URL}/api/v3/interaction`).then(async (res) => {
        console.log(res);
        // let masterInteractionID = res.data[0].uuid;
        if (props.listType === 'recentlyViewed') {
          // let QUERY = `${CONSTANTS.API_URL}/api/v1/caseListCount?masterInteractionID=${masterInteractionID}&dealershipID=${userInfo.userDealershipID}&recentlyViewedID=${userInfo.uuid}&isRecentlyViewed=true&noLimit=noLimit&sortBy=recentlyViewed`;
          let QUERY = `${CONSTANTS.API_URL}/api/v1/caseListCount?dealershipID=${userInfo.userDealershipID}&recentlyViewedID=${userInfo.uuid}&isRecentlyViewed=true&noLimit=noLimit&sortBy=recentlyViewed`;
          let taskResponseAll = await Axios.get(QUERY);
          console.log(taskResponseAll,'taskResponseAlltaskResponseAlltaskResponseAll');
          let list = await getRowsListForTask(taskResponseAll.data.list);
          await setTasksData(list);
          setIsLoading(false);
        }
        if (props.listType === 'followedCases') {
          let QUERY = `${CONSTANTS.API_URL}/api/v1/caseListCount?dealershipID=${userInfo.userDealershipID}&followedByID=${userInfo.uuid}&noLimit=noLimit&sortBy=dateCreated`;
          let taskResponseAll = await Axios.get(QUERY);
          console.log(taskResponseAll,'taskResponseAlltaskResponseAlltaskResponseAll');
          let list = await getRowsListForTask(taskResponseAll.data.list);
          await setTasksData(list);
          setIsLoading(false);
        }


      });

    }

  },[props]);

  async function getRowsListForTask(data) {
    const colList = [{
      label: 'Name',
      field: 'displayName',
      width: '200px',
      sort: 'asc'
    },
    {
      label: 'Active Since',
      field: 'createdOn',
      sort: 'asc'
    }, {
      label: 'Deadline',
      field: 'deadline',
      sort: 'asc'
    }, {
      label: 'Case ID',
      field: 'caseID',
      sort: 'desc'
    }];
    const listObj = {
      columns: colList, rows: data
    };
    return listObj;
  }

  return (
    <div style="width:100%; background:#fff; height: 100vh">
      <div class="modal-header" style='border-radius: 0 !important'>
        <span class="fs-12">
          <div class="display-flex">
            <ul class="breadrcrumb_arrows_inverted m-r-8">
              <li>{props.title}</li>
            </ul>
          </div>
        </span>
      </div>
      {
        isLoading ?
          <CarInsuranceLoader top='25%' left='2.5%' />
          :
          <div style='height: 90vh; overflow-y: auto; overflow-x: hidden;'>
            <ListCard taskType="recentlyViewedCases" taskData={taskData} listType={props.listType} />
          </div>
      }
    </div>
  );
};

export default RecentlyViewed;
