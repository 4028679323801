import { h, Component } from 'preact';
import { useState, useEffect } from "preact/hooks";
import axios from "axios";
import { route } from "preact-router";
import { getItem, removeAll } from '../../lib/myStore';
import CONSTANTS from '../../lib/constants';
import {
  Home,
  Clipboard,
  Cases,
  Notification,
  Eliminate,
  Insurance,
  Folder,
  InteractionSearchSuggestion,
  Hyundai,
  Honda,
  Kia,
  Report,
  Coupon,
  Analytics,
  CallOutlined,
  ThirdParty,
  Form,
  Ticket,
  Catalogue,
  Orgchart,
  Administration,
  AccessControl,
  Generate,
  FacilityRoom,
  Facility,
  Policy,
  Asset,
  Support,
  CaseStatusDashboard,
  History,
  Money
} from '../../components/svgs';

const LeftInfoPanel = (props) => {
  const { panelActive, showLeftPanel, setShowLeftPanel, setShowMobileFooter } = props;
  // const pathname = window.location.pathname;
  let [pathName, setPathName] = useState(window.location.pathname);
  let userInfo = getItem('userinfo');
  let [userDealershipID, setUserDealershipID] = useState(userInfo.userDealershipID ? userInfo.userDealershipID : '');

  useEffect(() => {
    setPathName(window.location.pathname);
  },[window.location.pathname]);

  useEffect(() => {
    userInfo = getItem('userinfo');
    setUserDealershipID(userInfo.userDealershipID ? userInfo.userDealershipID : '');
  },[props.isUserInfoUpdated]);

  /*
    Bug ID: COMP_BG_264
    Bug: If Kia user logs out, he is getting redirected to Honda Login Page
    modified : Komal Wable
    modified : 02/02/2023
    modification : add route to kia login page
  */
  const logout = async (e) => {
    try {
      e.preventDefault();
      let res = await axios.post(`${CONSTANTS.API_URL}/api/v1/logout`);
      let email = getItem('userinfo').email;
      await axios.put(`${CONSTANTS.API_URL}/api/v1/user/pageVisitUserInformation`, { action: 'logout', typeOfVisit: 'success', email });
      if (res) {
        if ((userInfo.userDealershipID && userInfo.userDealershipID === "f8ff4333-1d4d-4ebb-8669-f6ce67620b85")){
          removeAll();
          route('/kia');
        } else {
          removeAll();
          route(`/`);
        }
      }
    } catch (HTTPException) {
      if (HTTPException && HTTPException.config && HTTPException.config.url) {
        let payload = {
          apiURL: HTTPException.config.url,
          errorObject:HTTPException,
          statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
          errorMessage:HTTPException.message,
          functionName:"logout",
          fileName:"leftInfoPanel",
          pageName: "leftInfoPanel",
          userID:userInfo.uuid,
          userName:userInfo.displayName
        };
        await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      }
      return HTTPException;
    }
  };

  return (
    <Fragment>

      <div class="leftInfoPanel-tab f-w-500 isDesktop">
        <div
          title={`Crystal ${(userDealershipID && userDealershipID === "f8ff4333-1d4d-4ebb-8669-f6ce67620b85") ? 'Kia' : 'Honda'}`}
          class={'leftInfoPanel-menu-icon p-t-10'}
          onClick={() => {
            setPathName(`/workspace`);
            route(`/workspace`);
          }}
        >
          {/*
        Modification: Changed colors for svgs to white by default and 06141f (theme color) if selected route
        By: Devang
        Date: 22/02/2023
      */}
          <div>
            {(userDealershipID && userDealershipID === "f8ff4333-1d4d-4ebb-8669-f6ce67620b85") ?
              <Kia height="32px" width="32px" fill={pathName === "/workspace" ? '#fff' : '#fff'} />
              :
              <Honda height="32px" width="32px" fill={pathName === "/workspace" ? '#fff' : '#fff'} />
            }
          </div>
        </div>
        <div
          title='Home'
          class={pathName === "/workspace" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'}
          onClick={() => {
            setPathName(`/workspace`);
            route(`/workspace`);
          }}
        >
          <div>
            {/*
            Modification: Changed colors for svgs to white by default and 06141f (theme color) if selected route
            By: Devang
            Date: 22/02/2023
          */}
            <Home height="22px" width="22px" fill={pathName === "/workspace" ? '#06141f' : '#fff'} />
            {/*
            Bug: Feature - Dedicated Coupons Page
            Modification: Added flex-c-m to center the text
            By: Devang
            Date: 08/02/2023
          */}
            <div class='menu-name flex-c-m'>Home</div>
          </div>
        </div>
        {/*<div
        title='Task'
        class={pathName === "/workspacedetails/dayPlanWorkspace" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon '}
        onClick={() => {
          route(`/workspacedetails/dayPlanWorkspace`);
          setPathName('/workspacedetails/dayPlanWorkspace');
        }}
      >
        <div>
          <Clipboard height="22px" width="22px"fill={pathName === "/workspacedetails/dayPlanWorkspace" ? '#06141f' : '#fff'} />
        </div>
        <div class='menu-name flex-c-m'>Tasks</div>
      </div>
      {(userInfo.userDesignation && userInfo.userDesignation.toLowerCase() !== "administrator") && (
        <div
          title='Case'
          class={pathName === "/workspaceCaseDetails" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
          onClick={() => {
            setPathName('/workspaceCaseDetails');
            route(`/workspaceCaseDetails`);
          }}
        >
          <div style="margin-bottom: -5px;">
            <Cases height="22px" width="22px" viewBox="0 0 302 382" fill={pathName === "/workspaceCaseDetails" ? '#06141f' : '#fff'} />
          </div>
          <div class='menu-name flex-c-m'>Cases</div>
        </div>
        )
      }*/}

        {/*
        Modification: Added separate icon for reports
        By: Komal Wable
        Date: 22/12/2022
        {(userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "back-office executive" || userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "accounts executive" || userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "insurance head") && (
      */}
        {(userInfo.userDesignation && userInfo.userDesignation.toLowerCase() !== "insurance agent" && userInfo.userDesignation.toLowerCase() !== "administrator") && (
          <div
            title='Reports'
            class={pathName.includes("/reports") ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
            onClick={() => {
              setPathName('/reports');
              route(`/reports`);
            }}
          >
            <div style="margin-bottom: -5px;">
              {/*
              Modification: Changed colors for svgs to white by default and 06141f (theme color) if selected route
              By: Devang
              Date: 22/02/2023
            */}
              <Report height="22px" width="22px" fill={pathName.includes("/reports") ? '#06141f' : '#fff'} />
            </div>
            {/*
            Bug: Feature - Dedicated Coupons Page
            Modification: Added flex-c-m to center the text
            By: Devang
            Date: 08/02/2023
          */}
            <div class='menu-name flex-c-m'>Reports</div>
          </div>
        )}
        {/*
            Modified: My Approval details path for everyone
            By: Rajkumar Pradhan
            Date: 11-08-2023
        */}
        {(userInfo.userDesignation && (userInfo.userDesignation.toLowerCase() !== "administrator" && userInfo.userDesignation.toLowerCase() !== "business analyst")) && (
          <div
            title='My Approvals'
            class={pathName === "/workspaceMyApprovalDetails" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
            onClick={() => {
              setPathName('/workspaceMyApprovalDetails');
              route(`/workspaceMyApprovalDetails`);
            }}
          >
            <div style="margin-bottom: -5px;">

              <Clipboard height="22px" width="22px" fill={pathName === "/workspaceMyApprovalDetails" ? '#06141f' : '#fff'} />
            </div>

            <div class='menu-name flex-c-m'>My Approv.</div>
          </div>
        )}
        {/*
        Bug: Feature - Info Modal Value Change Approval
        Modification: Added Approval component to route to approval page
        By: Devang
        Date: 30/01/2023
      */}
        {(userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "insurance team lead") && (
          <div
            title='Approvals'
            class={pathName === "/workspaceApprovalDetails" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
            onClick={() => {
              setPathName('/workspaceApprovalDetails');
              route(`/workspaceApprovalDetails`);
            }}
          >
            <div style="margin-bottom: -5px;">
              {/*
              Modification: Changed colors for svgs to white by default and 06141f (theme color) if selected route
              By: Devang
              Date: 22/02/2023
            */}
              <Clipboard height="22px" width="22px" fill={pathName === "/workspaceApprovalDetails" ? '#06141f' : '#fff'} />
            </div>
            {/*
            Bug: Feature - Dedicated Coupons Page
            Modification: Added flex-c-m to center the text and changed approv. text to approval
            By: Devang
            Date: 08/02/2023
          */}
            <div class='menu-name flex-c-m'>Approv.</div>
          </div>
        )}
        {/*
        Modification: Removed access of coupons page from insurance team lead" and "insurance head" for analytics page
        By: Devang
        Date: 03/04/2023
      */}
        {userInfo.userDesignation && (userInfo.userDesignation.toLowerCase() === "service accounts executive" || userInfo.userDesignation.toLowerCase() === "service advisor" || userInfo.userDesignation.toLowerCase() === "bodyshop advisor") && (
          <div
            title='Coupons'
            class={pathName === "/workspaceCouponDetails" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
            onClick={() => {
              setPathName('/workspaceCouponDetails');
              route(`/workspaceCouponDetails`);
            }}
          >
            <div style="margin-bottom: -5px;">
              {/*
              Modification: Changed colors for svgs to white by default and 06141f (theme color) if selected route
              By: Devang
              Date: 22/02/2023
            */}
              <Coupon height="22px" width="22px" stroke={pathName === "/workspaceCouponDetails" ? '#06141f' : '#fff'} />
            </div>
            <div class='menu-name flex-c-m'>Coupons</div>
          </div>
        )}
        {userInfo.userDesignation && (userInfo.userDesignation.toLowerCase() === "service accounts executive" || userInfo.userDesignation.toLowerCase() === "service advisor" || userInfo.userDesignation.toLowerCase() === "bodyshop advisor") && (
          <div
            title='Policies'
            class={pathName === "/workspacePolicyCouponDetails" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
            onClick={() => {
              setPathName('/workspacePolicyCouponDetails');
              route(`/workspacePolicyCouponDetails`);
            }}
          >
            <div style="margin-bottom: -5px;">
              {/*
              Modification: Changed colors for svgs to white by default and 06141f (theme color) if selected route
              By: Devang
              Date: 22/02/2023
            */}
              <Policy height="22px" width="22px" stroke={pathName === "/workspacePolicyCouponDetails" ? '#06141f' : '#fff'} />
            </div>
            <div class='menu-name flex-c-m'>Policies</div>
          </div>
        )}
        {["insurance agent", "insurance team lead", "insurance head"].includes(userInfo.userDesignation && userInfo.userDesignation.toLowerCase()) && (
          <div
            title='Analytics'
            class={pathName === "/analytics" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
            onClick={() => {
              setPathName('/analytics');
              route(`/analytics`);
            }}
          >
            <div style="margin-bottom: -5px;">
              <Analytics height="22px" width="22px" stroke={pathName === "/analytics" ? '#06141f' : '#fff'} />
            </div>
            <div class='menu-name flex-c-m'>Analytics</div>
          </div>
        )}
        {/*
          Modification: Added new role for Allocation
          By: Masum Raja
          Date: 05/09/2023
      */}
        {(userInfo.userDesignation && (userInfo.userDesignation.toLowerCase() === "back-office executive" || userInfo.userDesignation.toLowerCase() === "insurance head" || userInfo.userDesignation.toLowerCase() === "insurance team lead")) && (
          <div
            title='Case Allocation'
            class={pathName === "/caseManagement" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
            onClick={() => {
              setPathName('/caseManagement');
              route(`/caseManagement`);
            }}
          >
            <div style="margin-bottom: -5px;">
              <InteractionSearchSuggestion height="22px" width="22px" viewBox="0 0 18 18" stroke={pathName === "/caseManagement" ? '#06141f' : '#fff'} />
            </div>
            <div class='menu-name flex-c-m'>Allocation</div>
          </div>
        )}
        {/*
          Bug: T329 - BG_FN - Login Page - Blank Page Displayed After Cache Clear and Page does not Reload on Login page view
          Modification: Handled condition - toLowerCase invoking on undefined
          By: Devang
          Date: 30/05/2024
        */}
        {/*(userInfo.userDesignation && (userInfo.userDesignation.toLowerCase() === "insurance head" || userInfo.userDesignation.toLowerCase() === "service accounts executive" || userInfo.userDesignation.toLowerCase() === "service advisor" || userInfo.userDesignation.toLowerCase() === "bodyshop advisor")) && (
          <div
            title='Silo Administration'
            class={pathName === "/siloAdministration" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
            onClick={() => {
              setPathName('/siloAdministration');
              route(`/siloAdministration`);
            }}
          >
            <div style="margin-bottom: -5px;">
              <Insurance height="22px" width="22px" fill={pathName === "/siloAdministration" ? '#06141f' : '#fff'} />
            </div>
            <div class='menu-name flex-c-m'>Silo Admin</div>
          </div>
        )*/}
        {/*
          Bug: T329 - BG_FN - Login Page - Blank Page Displayed After Cache Clear and Page does not Reload on Login page view
          Modification: Handled condition - toLowerCase invoking on undefined
          By: Devang
          Date: 30/05/2024
        */}
        {(userInfo.userDesignation && (userInfo.userDesignation.toLowerCase() === "service accounts executive" || userInfo.userDesignation.toLowerCase() === "service advisor" || userInfo.userDesignation.toLowerCase() === "bodyshop advisor")) && (
          <div
            title='Notification'
            class={pathName === "/notificationDashboard" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
            onClick={() => {
              setPathName('/notificationDashboard');
              route(`/notificationDashboard`);
            }}
          >
            <div style="margin-bottom: -5px;">
              <Notification height="24px" width="24px" stroke={pathName === "/notificationDashboard" ? '#06141f' : '#fff'} />
            </div>
            <div class='menu-name flex-c-m'>Notification</div>
          </div>
        )}
        {/*
        Modification: Changed colors for svgs to white by default
        By: Devang
        Date: 22/02/2023
      */}
        {/*
          Modification: Added Route to option for calling dashboard
          By: Devang
          Date: 15/02/2023

        <div title='Calling' class={pathName === "/callingDashboard" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
          setPathName('/callingDashboard');
          route(`/callingDashboard`);}}
        >
          <div style="margin-bottom: -5px;">
            <CallOutlined classes="p-2" height="24px" width="24px" stroke={pathName === "/callingDashboard" ? 'black' : 'white'} />
          </div>
          <div class='menu-name'>Calling</div>
        </div>*/}
        {/*
          Modification: Added new route Form, thirdParty, request and catalogue
          By: Masum Raja
          Date: 16/05/2023
      */}
        {/*
        Updated by: Rutuja
        Added access to entity mapping and catalogue to Silo admin
      */}
        {
          userInfo && userInfo.isSiloAdmin && (
            <div
              title='Form'
              class={pathName === "/entityFormMapping" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
              onClick={() => {
                setPathName('/entityFormMapping');
                route(`/entityFormMapping`);
              }}
            >
              <div style="margin-bottom: -5px;">
                <Form height="22px" width="22px" viewBox="0 0 302 382" fill={pathName === "/entityFormMapping" ? '#06141f' : '#fff'} />
              </div>
              <div class='menu-name flex-c-m'>Form</div>
            </div>
          )
        }

        {/*
          Modification:- Added this route to the Admin Route page whose userDesignation is only "administrator"
          By:- Rajkumar Pradhan
          Date:- 18-10-2023
        */}
        {/*(userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "administrator") && (
          <div
            title='Catalogue'
            class={pathName === "/catalogue" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
            onClick={() => {
              setPathName('/catalogue');
              route(`/catalogue`);
            }}
          >
            <div style="margin-bottom: -5px;">
              <Catalogue height="26px" width="26px" viewBox="0 0 600 500" fill={pathName === "/catalogue" ? '#06141f' : '#fff'} />
            </div>
            <div class='menu-name flex-c-m'>Catalogue</div>
          </div>
        )
        */}

        {
          userInfo && userInfo.isSiloAdmin && (
            <div title='Scheduler Dashboard' class={pathName === "/schedulerDashboard" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName('/schedulerDashboard');
              route(`/schedulerDashboard`);
            }}
            >
              <div style="margin-bottom: -5px;">
                <Generate height="22px" width="22px" fill={pathName === "/schedulerDashboard" ? '#06141f' : '#fff'} />
              </div>
              <div class='menu-name flex-c-m'>Scheduler</div>
            </div>
          )
        }
        {
          userInfo && userInfo.isSiloAdmin && (
            <div title='Administration' class={pathName === "/siloAdministration" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName('/siloAdministration');
              route(`/siloAdministration`);
            }}
            >
              <div style="margin-bottom: -5px;">
                <Administration height="22px" width="22px" fill={pathName === "/siloAdministration" ? '#06141f' : '#fff'} />
              </div>
              <div class='menu-name flex-c-m'>Admin</div>
            </div>
          )
        }
        {(userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "insurance head") && (
          <div title='Organization' class={pathName === "/organization" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
            setPathName('/organization');
            route(`/organization`);
          }}
          >
            <div style="margin-bottom: -5px;">
              <Orgchart height="22px" width="22px" fill={pathName === "/organization" ? '#06141f' : '#fff'} />
            </div>
            <div class='menu-name flex-c-m'>Org</div>
          </div>
        )
        }
        {userInfo && userInfo.isSiloAdmin && (
          <div title='Support' class={pathName === "/support" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
            setPathName('/support');
            route(`/support`);
          }}
          >
            <div style="margin-bottom: -5px;">
              <Support height="22px" width="22px" fill={pathName === "/support" ? '#06141f' : '#fff'} />
            </div>
            <div class='menu-name flex-c-m'>Support</div>
          </div>
        )
        }
        {
          userInfo && userInfo.isSiloAdmin && (
            <div title='>Config Manager' class={pathName === "/processMapping" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName('/processMapping');
              route(`/processMapping`);
            }}
            >
              <div style="margin-bottom: -5px;">
                <InteractionSearchSuggestion height="22px" width="22px" stroke={pathName === "/processMapping" ? '#06141f' : '#fff'} />
              </div>
              <div class='menu-name flex-c-m'>Config</div>
            </div>
          )
        }
        {
          userInfo && userInfo.isSiloAdmin && (
            <div title='Access Control' class={pathName === "/aclPermissions" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName('/aclPermissions');
              route(`/aclPermissions`);
            }}
            >
              <div style="margin-bottom: -5px;">
                <AccessControl height="22px" width="22px" fill={pathName === "/aclPermissions" ? '#06141f' : '#fff'} />
              </div>
              <div class='menu-name flex-c-m'>ACL</div>
            </div>
          )
        }
        {/*
          Modification:- Added these route to the Admin Route page whose userDesignation is only "administrator"
          By:- Rajkumar Pradhan
          Date:- 18-10-2023
        */}
        {/*(userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "administrator") && (
          <div
            title='ThirdPartyVendor'
            class={pathName === "/thirdParty" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
            onClick={() => {
              setPathName('/thirdParty');
              route(`/thirdParty`);
            }}
          >
            <div style="margin-bottom: -5px;">
              <ThirdParty height="22px" width="22px" viewBox="0 0 302 382" fill={pathName === "/thirdParty" ? '#06141f' : '#fff'} />
            </div>
            <div class='menu-name flex-c-m'>Vendors</div>
          </div>
        )}
        {/*(userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "administrator") && (
            <div
              title='Facility'
              class={pathName === "/facility" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
              onClick={() => {
                setPathName('/facility');
                route(`/facility`);
              }}
            >
              <div style="margin-bottom: -5px;">
                <Facility height="26px" width="26px" viewBox="0 0 600 500" fill={pathName === "/facility" ? '#06141f' : '#fff'} />
              </div>
              <div class='menu-name flex-c-m'>Facility</div>
            </div>
          )
          }
          {(userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "administrator") && (
            <div
              title='Facility Room'
              class={pathName === "/facilityRoom" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
              onClick={() => {
                setPathName('/facilityRoom');
                route(`/facilityRoom`);
              }}
            >
              <div style="margin-bottom: -5px;">
                <FacilityRoom height="26px" width="26px" viewBox="0 0 600 500" fill={pathName === "/facilityRoom" ? '#06141f' : '#fff'} />
              </div>
              <div class='menu-name flex-c-m'>Facility Room</div>
            </div>
          )
        }
        {(userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "administrator") && (
          <div
            title='Inventory'
            class={pathName === "/workspaceInventoryDetails" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
            onClick={() => {
              setPathName('/workspaceInventoryDetails');
              route(`/workspaceInventoryDetails`);
            }}
          >
            <div style="margin-bottom: -5px;">
              <Facility height="26px" width="26px" viewBox="0 0 600 500" fill={pathName === "/workspaceInventoryDetails" ? '#06141f' : '#fff'} />
            </div>
            <div class='menu-name flex-c-m'>Inventory</div>
          </div>
        )
        }
        {/*
          By: Prasannadatta Kawadkar
          on: 31st August 2023
          Modification: added shortcut for Inventory History route
        }
        {(userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "administrator") && (
          <div
            title='Inventory History'
            class={pathName === "/inventoryHistory" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
            onClick={() => {
              setPathName('/inventoryHistory');
              route(`/inventoryHistory`);
            }}
          >
            <div style="margin-bottom: -5px;">
              <Facility height="26px" width="26px" viewBox="0 0 600 500" fill={pathName === "/inventoryHistory" ? '#06141f' : '#fff'} />
            </div>
            <div class='menu-name flex-c-m'>History</div>
          </div>
        )}
        {(userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "administrator") && (
          <div
            title='Purchase Order'
            class={pathName === "/workspacePODetails" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
            onClick={() => {
              setPathName('/workspacePODetails');
              route(`/workspacePODetails`);
            }}
          >
            <div style="margin-bottom: -5px;">
              <FacilityRoom height="26px" width="26px" viewBox="0 0 600 500" fill={pathName === "/workspacePODetails" ? '#06141f' : '#fff'} />
            </div>
            <div class='menu-name flex-c-m'>PO</div>
          </div>
        )
        */}
        {/*(userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "administrator") && (
          <div
            title='Request'
            class={pathName === "/request" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
            onClick={() => {
              setPathName('/request');
              route(`/request`);
            }}
          >
            <div style="margin-bottom: -5px;">
              <Ticket height="22px" width="22px" viewBox="0 0 302 382" fill={pathName === "/request" ? '#06141f' : '#fff'} />
            </div>
            <div class='menu-name flex-c-m'>Request</div>
          </div>
        )*/}
        {/*
          Modification:- Added Admin Route page to access all those route whose userDesignation is only "administrator"
          By:- Rajkumar Pradhan
          Date:- 18-10-2023
        */}
        {
          (userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "administrator") && (
            <div title='Admin' class={pathName === "/adminRoute" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName('/adminRoute');
              route(`/adminRoute`);
            }}
            >
              <div style="margin-bottom: -5px;">
                <Facility height="22px" width="22px" fill={pathName === "/adminRoute" ? '#06141f' : '#fff'} stroke={pathName === "/adminRoute" ? '#06141f' : '#fff'} />
              </div>
              <div class='menu-name flex-c-m'>Admin Route</div>
            </div>
          )
        }
        {
          (userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "administrator") && (
            <div title='Admin' class={pathName === "/entityFormMapping" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName('/entityFormMapping');
              route(`/entityFormMapping`);
            }}
            >
              <div style="margin-bottom: -5px;">
                <FacilityRoom height="22px" width="22px" fill={pathName === "/entityFormMapping" ? '#06141f' : '#fff'} stroke={pathName === "/entityFormMapping" ? '#06141f' : '#fff'} />
              </div>
              <div class='menu-name flex-c-m'>Admin</div>
            </div>
          )
        }
        {/*
          Modification:- Added Silo Admin Route page to access all event, location, facility, facility room , employee
          By:- Rajkumar Pradhan
          Date:- 03-04-2024
        */}
        {
          (userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "administrator") && (
            <div title='Admin' class={pathName === "/siloAdmin" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName('/siloAdmin');
              route(`/siloAdmin`);
            }}
            >
              <div style="margin-bottom: -5px;">
                <Insurance height="22px" width="22px" fill={pathName === "/siloAdmin" ? '#06141f' : '#fff'} stroke={pathName === "/siloAdmin" ? '#06141f' : '#fff'} />
              </div>
              <div class='menu-name flex-c-m'>Silo Admin</div>
            </div>
          )
        }
        {
          (userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "administrator") && (
            <div title='Admin' class={pathName === "/inventory" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName('/inventory');
              route(`/inventory`);
            }}
            >
              <div style="margin-bottom: -5px;">
                <Catalogue height="22px" width="22px" fill={pathName === "/inventory" ? '#06141f' : '#fff'} stroke={pathName === "/inventory" ? '#06141f' : '#fff'} />
              </div>
              <div class='menu-name flex-c-m'>Inventory</div>
            </div>
          )
        }
        {
          (userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "administrator") && (
            <div title='Admin' class={pathName === "/vendor" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName('/vendor');
              route(`/vendor`);
            }}
            >
              <div style="margin-bottom: -5px;">
                <ThirdParty height="22px" width="22px" fill={pathName === "/vendor" ? '#06141f' : '#fff'} stroke={pathName === "/vendor" ? '#06141f' : '#fff'} />
              </div>
              <div class='menu-name flex-c-m'>Vendor</div>
            </div>
          )
        }
        {
          (userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "administrator") && (
            <div title='Admin' class={pathName === "/purchaseOrderDashboard" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName('/purchaseOrderDashboard');
              route(`/purchaseOrderDashboard`);
            }}
            >
              <div style="margin-bottom: -5px;">
                <Money height="22px" width="22px" fill={pathName === "/purchaseOrderDashboard" ? '#06141f' : '#fff'} stroke={pathName === "/purchaseOrderDashboard" ? '#06141f' : '#fff'} />
              </div>
              <div class='menu-name flex-c-m'>Purchase Order</div>
            </div>
          )
        }
        {
          (userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "administrator") && (
            <div title='Admin' class={pathName === "/requestManagement" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName('/requestManagement');
              route(`/requestManagement`);
            }}
            >
              <div style="margin-bottom: -5px;">
                <Ticket height="22px" width="22px" fill={pathName === "/requestManagement" ? '#06141f' : '#fff'} stroke={pathName === "/requestManagement" ? '#06141f' : '#fff'} />
              </div>
              <div class='menu-name flex-c-m'>Request</div>
            </div>
          )
        }
        {/*
            Modification: Added new route icon for Asset
            By: Masum Raja
            Date: 19/10/2023
        */}
        {/*(userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "administrator") && (
          <div
            title='Asset Register'
            class={pathName === "/asset" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
            onClick={() => {
              setPathName('/asset');
              route(`/asset`);
            }}
          >
            <div style="margin-bottom: -5px;">
              <Asset height="22px" width="22px" viewBox="0 0 302 382" fill={pathName === "/asset" ? '#06141f' : '#fff'} />
            </div>
            <div class='menu-name flex-c-m'>Asset</div>
          </div>
        )*/}

        {(userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "insurance head") && (
          <div
            title='Elimination'
            class={pathName === "/eliminationDashboard" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
            onClick={() => {
              setPathName('/eliminationDashboard');
              route(`/eliminationDashboard`);
            }}
          >
            <div style="margin-bottom: -5px;">
              <Eliminate height="24px" width="24px" fill={pathName === "/eliminationDashboard" ? '#06141f' : '#fff'} />
            </div>
            <div class='menu-name flex-c-m'>Elimination</div>
          </div>
        )}
        {/*
          Bug: T329 - BG_FN - Login Page - Blank Page Displayed After Cache Clear and Page does not Reload on Login page view
          Modification: Handled condition - toLowerCase invoking on undefined
          By: Devang
          Date: 04/06/2024
        */}
        {(userInfo.userDesignation && (userInfo.userDesignation.toLowerCase() === "insurance head" || userInfo.userDesignation.toLowerCase() === "insurance agent")) && (
          <div
            title='Case Completion Status'
            class={pathName === "/dataStatusDashboard" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
            onClick={() => {
              setPathName('/dataStatusDashboard');
              route(`/dataStatusDashboard`);
            }}
          >
            <div style="margin-bottom: -5px;">
              <CaseStatusDashboard height="24px" width="24px" fill={pathName === "/dataStatusDashboard" ? '#06141f' : '#fff'} />
            </div>
            <div class='menu-name flex-c-m'>Status</div>
          </div>
        )}
        <div title="Click to log out" class=" leftInfoPanel-logout cursor-pointer m-l-5 w-24px h-24px" onClick={(e) => logout(e)}>
          <em class="cursor-pointer icon icon-sign-out w-24px h-24px text-white fs-24" />
        </div>
      </div>
    </Fragment>
  );
};

export default LeftInfoPanel;
