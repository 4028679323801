import { h } from 'preact';

// from flaticon
const Eliminate = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''} onclick={(e) => props.action && props.action(e)}>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.0" class={`${props.classes || ""}`} width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke="none">
          <path d="M425 5015 l-25 -24 0 -2431 0 -2431 25 -24 24 -25 1709 0 c1667 0 1710 0 1733 19 22 17 24 29 29 123 l5 103 60 7 c310 39 602 266 738 575 54 123 70 204 71 368 0 162 -11 228 -60 354 -94 237 -295 442 -529 537 -72 30 -128 45 -262 70 l-23 4 0 1058 0 1057 -343 342 -342 343 -1393 0 -1393 0 -24 -25z m2695 -430 l0 -296 25 -24 24 -25 296 0 295 0 0 -1000 0 -1000 -22 -4 c-13 -3 -52 -10 -88 -16 -175 -32 -339 -120 -481 -260 -202 -200 -300 -447 -286 -725 13 -253 106 -458 291 -640 158 -155 291 -220 564 -271 18 -4 22 -11 22 -44 l0 -40 -1600 0 -1600 0 0 2320 0 2320 1280 0 1280 0 0 -295z m345 -5 l180 -180 -183 0 -182 0 0 180 c0 99 1 180 3 180 1 0 83 -81 182 -180z m565 -2523 c100 -28 168 -57 243 -107 427 -278 490 -875 131 -1234 -359 -359 -956 -296 -1234 131 -89 136 -125 259 -125 433 0 174 36 297 124 432 119 182 304 308 521 354 80 17 262 12 340 -9z"/>
          <path d="M825 4055 c-25 -24 -25 -26 -25 -215 0 -189 0 -191 25 -215 l24 -25 1311 0 1311 0 24 25 c25 24 25 26 25 215 0 189 0 191 -25 215 l-24 25 -1311 0 -1311 0 -24 -25z m2535 -215 l0 -80 -1200 0 -1200 0 0 80 0 80 1200 0 1200 0 0 -80z"/>
          <path d="M825 3415 c-25 -24 -25 -26 -25 -215 0 -189 0 -191 25 -215 l24 -25 1311 0 1311 0 24 25 c25 24 25 26 25 215 0 189 0 191 -25 215 l-24 25 -1311 0 -1311 0 -24 -25z m2535 -215 l0 -80 -1200 0 -1200 0 0 80 0 80 1200 0 1200 0 0 -80z"/>
          <path d="M825 2775 c-25 -24 -25 -26 -25 -215 0 -189 0 -191 25 -215 l24 -25 1311 0 1311 0 24 25 c25 24 25 26 25 215 0 189 0 191 -25 215 l-24 25 -1311 0 -1311 0 -24 -25z m2535 -215 l0 -80 -1200 0 -1200 0 0 80 0 80 1200 0 1200 0 0 -80z"/>
          <path d="M825 2135 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 791 0 791 0 24 25 c33 32 33 78 0 110 l-24 25 -791 0 -791 0 -24 -25z"/>
          <path d="M825 1895 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 551 0 551 0 24 25 c33 32 33 78 0 110 l-24 25 -551 0 -551 0 -24 -25z"/>
          <path d="M825 1655 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 23 -24 31 -25 135 -25 104 0 112 1 135 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -23 24 -31 25 -135 25 -104 0 -112 -1 -135 -25z"/>
          <path d="M1225 1655 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 21 -22 33 -25 95 -25 62 0 74 3 95 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -21 22 -33 25 -95 25 -62 0 -74 -3 -95 -25z"/>
          <path d="M665 455 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 231 0 231 0 24 25 c33 32 33 78 0 110 l-24 25 -231 0 -231 0 -24 -25z"/>
          <path d="M1305 455 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 21 -22 33 -25 95 -25 62 0 74 3 95 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -21 22 -33 25 -95 25 -62 0 -74 -3 -95 -25z"/>
          <path d="M3385 1735 c-16 -15 -25 -35 -25 -56 0 -29 21 -54 182 -216 l182 -183 -182 -183 c-161 -162 -182 -187 -182 -216 0 -43 38 -81 81 -81 29 0 54 21 216 182 l183 182 183 -182 c162 -161 187 -182 216 -182 43 0 81 38 81 81 0 29 -21 54 -182 216 l-182 183 182 183 c161 162 182 187 182 216 0 43 -38 81 -81 81 -29 0 -54 -21 -216 -182 l-183 -182 -183 182 c-162 161 -187 182 -216 182 -21 0 -41 -9 -56 -25z"/>
        </g>
      </svg>
    </span>
  );
};

export default Eliminate;
