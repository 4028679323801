import { h, Component, Fragment } from 'preact';
import { useState, useEffect, useRef } from "preact/hooks";
import { route } from "preact-router";
import CONSTANTS from '../../lib/constants';
import axios from "axios";
import { setItem, getItem, removeAll } from '../../lib/myStore';
import { Search, Cross, ContactSearchSuggestion, TaskSearchSuggestion, InteractionSearchSuggestion, CaseSearchSuggestion, User, Users } from '../../components/svgs';
import { isEmptyObject, getTextWithHighlightMatching } from '../../lib/utils';

let mainSource;

const SearchInputSuggestion = (props) => {
  const { setIsGlobalSearchVisible } = props;

  let searchInputRegex = /[^[\w\-\s]+$]/;
  let localStorageSearchText = getItem('searchText');
  let userInfo = getItem('userinfo');
  let [searchText, setSearchText] = useState(!isEmptyObject(localStorageSearchText) ? localStorageSearchText : '');
  let [searchSuggestionList, setSearchSuggestionList] = useState([]);
  let [isSuggestionListShown, setIsSuggestionListShown] = useState(false);
  let searchInputElement = useRef();
  let [isLoading, setIsLoading] = useState(true);

  let [recentlyViewedList, setRecentlyViewedList] = useState([]);
  let [masterInteractionID, setMasterInteractionID] = useState('');

  useEffect(() => {
    focusSearchInput();
  }, []);

  useEffect(async () => {
    console.log('innnnnnnnnnnnnnnnnn', searchText);
    let isCancelled = false;
    if (mainSource) {
      console.log(mainSource, 'mainSourcemainSource');
      mainSource.cancel();
    }

    const CancelToken = axios.CancelToken;
    mainSource = CancelToken.source();

    console.log(mainSource, 'MAINSOURCE-->');

    setIsLoading(true);
    if (searchText) {
      await getSearchSuggestions();
      await searchRecentlyViewedCases();
    } else {
      await getRecentlyViewedCases();
      setIsSuggestionListShown(false);
    }
    setIsLoading(false);
  }, [searchText]);

  const focusSearchInput = () => {
    searchInputElement.current.focus();
  };

  const getSearchSuggestions = async () => {
    try {
      /*
        Modification:- Search by Vendor in Global search. Create a new search functionality. (T178)
        Modified by:- Rajkumar Pradhan
        Modified on:- 20/02/2024
      */
      console.log(userInfo,"userInfouserInfouserInfouserInfo");
      let response;
      if (userInfo.userDesignation === "ADMINISTRATOR") {
        response = await axios.get(`${CONSTANTS.API_URL}/api/v1/globalAdminSearchSuggestions/${encodeURIComponent(searchText)}`, {
          cancelToken: mainSource.token
        });
      } else {
        response = await axios.get(`${CONSTANTS.API_URL}/api/v1/globalSearchSuggestions/${encodeURIComponent(searchText)}?dealershipID=${userInfo.userDealershipID}`, {
          cancelToken: mainSource.token
        });
      }
      if (response && response.data && response.data.length) {
        setSearchSuggestionList(response.data);
        setIsSuggestionListShown(true);
      } else {
        setSearchSuggestionList([]);
        setIsSuggestionListShown(false);
      }
    } catch (HTTPException) {
      setSearchSuggestionList([]);
      setIsSuggestionListShown(false);
      if (HTTPException && HTTPException.config && HTTPException.config.url) {
        let payload = {
          apiURL: HTTPException.config.url,
          errorObject:HTTPException,
          statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
          errorMessage:HTTPException.message,
          functionName:"getSearchSuggestions",
          fileName:"searchInputSuggestion",
          pageName: "searchInputSuggestion",
          userID:userInfo.uuid,
          userName:userInfo.displayName
        };
        await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      }
    }
  };

  /*
    Modification:- Search by Vendor in Global search. Create a new search functionality. (T178)
    Modified by:- Rajkumar Pradhan
    Modified on:- 20/02/2024
  */
  const getRowButtonIcon = (typeOfResult) => {
    if (['Contact', 'User'].includes(typeOfResult)) {
      return <ContactSearchSuggestion masterClasses="flex-c-m" title={typeOfResult} fill="#404040" width="20" height="20" />;
    } else if (typeOfResult === 'Task') {
      return <TaskSearchSuggestion masterClasses="flex-c-m" title={typeOfResult} fill="#404040" width="20" height="20" />;
    } else if (typeOfResult === 'Interaction') {
      return <InteractionSearchSuggestion masterClasses="flex-c-m" title={typeOfResult} fill="#404040" width="20" height="20" />;
    } else if (typeOfResult === 'Case') {
      return <CaseSearchSuggestion masterClasses="flex-c-m" title={typeOfResult} fill="#404040" width="20" height="20" />;
    } else if (typeOfResult === 'Courier') {
      return <div class="search-suggestion-letter-icon flex-c-m" title={typeOfResult}>C</div>;
    } else if (typeOfResult === 'Visit') {
      return <div class="search-suggestion-letter-icon flex-c-m" title={typeOfResult}>V</div>;
    } else if (typeOfResult === 'Name') {
      return <User masterClasses="flex-c-m" title={typeOfResult} fill="#404040" width="20" height="20" />;
    } else if (typeOfResult === 'DisplayName') {
      return <ContactSearchSuggestion masterClasses="flex-c-m" title={typeOfResult} fill="#404040" width="20" height="20" />;
    }
  };

  const setSearchTextValue = (e) => {
    if (!searchInputRegex.test(e.target.value)) {
      console.log('Ohh okay');
      setSearchText(e.target.value);
    }
    console.log(e.target.value, 'e.target.value', searchText);
  };

  const routeToSearchPage = (text) => {
    if (text) {
      setItem('searchText', text);
      setIsGlobalSearchVisible(e => !e);
      /*
        Modification:- Search by Vendor in Global search. Create a new search functionality. (T178)
        Modified by:- Rajkumar Pradhan
        Modified on:- 20/02/2024
      */
      if (userInfo.userDesignation === "ADMINISTRATOR") {
        route('/adminSearch');
      } else {
        route('/search');
      }
    }
  };

  const routeToSearchPageOnEnter = (e) => {
    if (e.keyCode == 13) {
      routeToSearchPage(searchText);
    }
  };

  const selectSearchSuggestion = (text) => {
    setSearchText(text);
    routeToSearchPage(text);
  };

  const getRecentlyViewedCases = async () => {
    let mainInteraction = await axios.get(`${CONSTANTS.API_URL}/api/v3/interaction`, {
      cancelToken: mainSource.token
    }).then(async (res) => {
      console.log(res);
      let masterInteractionID = res.data[0].uuid;
      setMasterInteractionID(masterInteractionID);
      // let QUERY = `${CONSTANTS.API_URL}/api/v1/caseListCount?masterInteractionID=${masterInteractionID}&dealershipID=${userInfo.userDealershipID}&recentlyViewedID=${userInfo.uuid}&isRecentlyViewed=true&noLimit=noLimit&sortBy=recentlyViewed`;
      let QUERY = `${CONSTANTS.API_URL}/api/v1/caseListCount?dealershipID=${userInfo.userDealershipID}&recentlyViewedID=${userInfo.uuid}&isRecentlyViewed=true&noLimit=noLimit&sortBy=recentlyViewed`;
      let response = await axios.get(QUERY);
      console.log(response,'setRecentlyViewedListResponse');
      setRecentlyViewedList(response.data.list);
    });
  };

  const searchRecentlyViewedCases = async () => {
    // let QUERY = `${CONSTANTS.API_URL}/api/v1/caseListCount?masterInteractionID=${masterInteractionID}&dealershipID=${userInfo.userDealershipID}&recentlyViewedID=${userInfo.uuid}&isRecentlyViewed=true&noLimit=noLimit&sortBy=recentlyViewed`;
    let QUERY = `${CONSTANTS.API_URL}/api/v1/caseListCount?dealershipID=${userInfo.userDealershipID}&recentlyViewedID=${userInfo.uuid}&isRecentlyViewed=true&noLimit=noLimit&sortBy=recentlyViewed`;
    if (searchText) {
      QUERY += `&q=${searchText}`;
    }
    let response = await axios.get(QUERY, {
      cancelToken: mainSource.token
    });
    console.log(response,'setRecentlyViewedListResponse');
    setRecentlyViewedList(response.data.list);
  };

  return (
    <Fragment>
      {
      /*
      Bug:COMP_BG_593 (Vertical Scrollbar is missing)
      Modification: Reworded the above mentioned
      By: Akanksha Malviya
      Date: 3rd Nov, 2023
      */
      }
      <div class={'search-input-suggestion-container'} style="overflow-y: auto">
        <div class="search-input-container flex-c-m">
          <input type="text" class="search-input" ref={searchInputElement} aria-label="search" placeholder="Search Text" value={searchText} onInput={setSearchTextValue} onkeydown={routeToSearchPageOnEnter} />
          <Search masterClasses={'flex-c-m cursor-pointer'} height="16px" width="16px" fill="#002C5F" title='Global Search' action={() => routeToSearchPage(searchText)} />
        </div>
        <div class="search-suggestion-list-container">
          {
            !isLoading ?
              <Fragment>
                {
                  (recentlyViewedList.length > 0) &&
                  <div>
                    <span>Recently Viewed Cases</span>
                    <div style="border-bottom: 1px dotted;max-height: 200px;overflow-y: auto;">
                      {recentlyViewedList.map((item) => (
                        <div class="search-suggestion cursor-pointer flex-l-m" onclick={() => selectSearchSuggestion(item.dynamicProperties_customerName)}>
                          {getRowButtonIcon('Case')}
                          <p class="search-suggestion-text" dangerouslySetInnerHTML={getTextWithHighlightMatching(item.dynamicProperties_customerName, searchText)} />
                        </div>
                      ))}
                    </div>
                  </div>
                }

                {
                  (isSuggestionListShown && searchText && searchSuggestionList.length > 0) &&
                <div>
                  {searchSuggestionList.map((item) => (
                    <div class="search-suggestion cursor-pointer flex-l-m" onclick={() => selectSearchSuggestion(item.text)}>
                      {getRowButtonIcon(item.typeOfResult)}
                      <p class="search-suggestion-text" dangerouslySetInnerHTML={getTextWithHighlightMatching(item.text, searchText)} />
                    </div>
                  ))}
                </div>
                }


                {
                  (recentlyViewedList.length === 0 && searchSuggestionList.length === 0) &&
                <span> No Records </span>
                }
              </Fragment> :
              <span> Loading... </span>
          }
        </div>
      </div>
    </Fragment>
  );
};

export default SearchInputSuggestion;
