import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Cancel = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M132 5109 c-68 -20 -132 -111 -132 -189 0 -87 -43 -41 1148 -1232 l1127 -1128 -1127 -1128 c-1037 -1037 -1127 -1131 -1138 -1172 -42 -159 91 -292 250 -250 41 11 135 101 1173 1138 l1127 1127 1128 -1127 c1037 -1037 1131 -1127 1172 -1138 159 -42 292 91 250 250 -11 41 -101 135 -1138 1172 l-1127 1128 1127 1128 c1037 1037 1127 1131 1138 1172 42 159 -91 292 -250 250 -41 -11 -135 -101 -1172 -1138 l-1128 -1127 -1127 1127 c-978 976 -1133 1128 -1168 1137 -47 13 -88 13 -133 0z"/>
        </g>
      </svg>
    </span>
  );
};

export default Cancel;
