import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Handshake = (props) => {
  return (
    <span class={`${props.masterClasses || ''} ${props.identityClass || ''}`} title={props.title || ''} onclick={(e) => props.action && props.action(e)}>
      <img src="/assets/images/gifs/handshake-static.png" class={`static-gif ${props.identityClass || ''}`} width={props.width || "30px"} height={props.height || "30px"}/>
      <img src="/assets/images/gifs/handshake.gif" class={`active-gif ${props.classes || ""} ${props.identityClass || ''}`} width={props.width || "30px"} height={props.height || "30px"}/>
    </span>
  );
};

export default Handshake;
