const Trash = (props) => {
  return (
    <span title={props.title || ''} class={props.masterClasses || ''}>
      <svg class={props.classes || ''} onclick={(e) => props.action && props.action(e)} width={props.width || '64px'} height={props.height || '64px'} xmlns="http://www.w3.org/2000/svg" version="1.1"
        id="Icons" x="0px" y="0px" viewBox={props.viewBox || "0 0 64 64"} style={`enable-background:new 0 0 32 32; ${props.style || ''}`} fill={props.fill || ''}>
        <path d="M39 0H25a2 2 0 0 0-2 2v6H10a2 2 0 0 0 0 4h2v50a2 2 0 0 0 2 2h36a2 2 0 0 0 2-2V12h2a2 2 0 0 0 0-4H41V2a2 2 0 0 0-2-2zm9 12v48H16V12h32zM27 8V4h10v4H27z"/>
        <path d="M26 56a2 2 0 0 0 2-2V20a2 2 0 0 0-4 0v34a2 2 0 0 0 2 2z"/>
        <path d="M38 56a2 2 0 0 0 2-2V20a2 2 0 0 0-4 0v34a2 2 0 0 0 2 2z"/>
      </svg>
    </span>
  );
};
export default Trash;
