import { h } from 'preact';
/*
  Modification: Add CreditCard Svg
  By: Komal Wable
  Date: 19/04/2023
*/
const CreditCard = (props) => {
  return (
    <span title={props.title || ''} class={props.masterClasses || ''}>
      <svg class={props.classes || ''} onclick={(e) => props.action && props.action(e)} width={props.width || '28'} height={props.height || '28'} viewBox={props.viewBox || "0 0 20 14"} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 4L19 4" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <rect x="0.75" y="0.75" width="18.5" height="12.5" rx="1.25" stroke="white" stroke-width="1.5"/>
        <rect x="13" y="8" width="5" height="3" rx="0.5" fill="white"/>
      </svg>
    </span>
  );
};
export default CreditCard;
