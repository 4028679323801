import { h } from 'preact';

const DownArrow2 = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" width={props.width || "24"} height={props.height || "24"} viewBox="0 0 24 24" fill={props.fill || 'none'} >
        <path d="M7 13L12 18L17 13" stroke={props.stroke ? props.stroke : "#002C5F"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7 6L12 11L17 6" stroke={props.stroke ? props.stroke : "#002C5F"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};

export default DownArrow2;
