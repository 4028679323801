import { h } from "preact";

const OrgChartModal = (props) => {
  const {title, titles, masterClasses, orgChartClasses, isOpen, onClose, children, isMobileView, handleScroll} = props;

  return (
    <div class='orgChartModal'>
      <div id="floatingModal" class={`org-chart-modal ${masterClasses || ''}`} style={`display: ${ isOpen ? "block" : "none"}`}>
        <div onScroll={handleScroll} class={`org-chart-modal-content org-chart-width ${orgChartClasses || ''}`} style='padding: 0px !important'>
          <div>
            <div class={`${isMobileView ? 'flex-sb' : ''} modal-header`}>
              <span class={`${isMobileView ? 'fs-13' : 'fs-12'}`}>
                <div class="display-flex">
                  <ul class={`${isMobileView ? 'm-0' : 'm-r-8'} breadrcrumb_arrows_inverted`}>
                    {
                      titles && titles.length &&
                        titles.map(title => (
                          <li>{title}</li>
                        ))
                    }
                    {
                      title &&
                      <li>{title}</li>
                    }
                  </ul>
                </div>
              </span>
              <span class={`${isMobileView ? 'flex-c-m m-0 fs-30' : ''} modal-close`} onClick={onClose}>&times;</span>
            </div>
            <div class="org-chart-modal-body background-transparent">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgChartModal;
