import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const AddCall = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512pt"} height={props.height || "512pt"}
        viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M1055 5091 c-128 -45 -362 -140 -470 -190 -203 -94 -246 -121 -330 -206 -302 -304 -335 -812 -93 -1435 448 -1152 1828 -2558 3003 -3058 669 -285 1207 -266 1531 53 76 74 87 91 157 232 41 84 117 257 168 385 92 229 93 234 94 318 0 77 -3 92 -32 151 -18 37 -52 84 -75 105 -37 34 -807 488 -968 571 -49 25 -65 28 -150 28 -81 0 -103 -4 -150 -26 -77 -36 -109 -66 -173 -161 -141 -210 -288 -299 -497 -301 -160 -1 -272 45 -442 181 -329 264 -816 766 -973 1002 -66 99 -98 204 -98 320 0 143 33 240 119 350 41 53 73 80 220 184 151 108 196 295 111 461 -78 153 -518 903 -552 943 -47 54 -96 86 -165 106 -78 23 -143 20 -235 -13z" />
          <path d="M3806 4868 c-57 -16 -129 -91 -144 -150 -8 -31 -12 -148 -12 -337 l0 -290 -312 -3 c-301 -3 -315 -4 -355 -25 -132 -71 -161 -230 -62 -338 66 -71 87 -75 431 -75 l298 0 0 -298 c0 -245 3 -307 16 -345 27 -80 120 -147 203 -147 73 0 156 52 194 123 21 40 22 54 25 355 l3 312 293 0 c342 0 370 5 433 73 99 108 71 270 -60 340 -40 21 -54 22 -354 25 l-312 3 -3 312 c-3 297 -4 314 -25 352 -51 95 -156 141 -257 113z" />
        </g>
      </svg>
    </span>
  );
};

export default AddCall;
