import { h } from 'preact';

const NoDocument = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg onclick={(e) => props.action && props.action(e)} width={props.width || "74"} height={props.height || "82"} viewBox={props.viewBox || "0 0 74 82"} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M60.5845 23.5V7.66667C60.5845 3.98477 57.5997 1 53.9178 1H7.66667C3.98477 1 1 3.98477 1 7.66667V74.3333C1 78.0152 3.98477 81 7.66667 81H53.9178C57.5997 81 60.5845 78.0152 60.5845 74.3333V69.8889" stroke={props.primaryColor || "#BFBFBF"} stroke-width="1.66667"/>
        <path d="M12.1428 14.75C11.9127 14.75 11.7262 14.9365 11.7262 15.1666C11.7262 15.3967 11.9127 15.5833 12.1428 15.5833V14.75ZM12.1428 15.5833H46.4283V14.75H12.1428V15.5833Z" fill={props.primaryColor || "#BFBFBF"}/>
        <path d="M12.1428 27.25C11.9127 27.25 11.7262 27.4365 11.7262 27.6666C11.7262 27.8967 11.9127 28.0833 12.1428 28.0833V27.25ZM12.1428 28.0833H36.9998V27.25H12.1428V28.0833Z" fill={props.primaryColor || "#BFBFBF"}/>
        <path d="M12.1428 39.75C11.9127 39.75 11.7262 39.9365 11.7262 40.1666C11.7262 40.3967 11.9127 40.5833 12.1428 40.5833V39.75ZM12.1428 40.5833H26.7142V39.75H12.1428V40.5833Z" fill={props.primaryColor || "#BFBFBF"}/>
        <path d="M12.1428 53.9167C11.9127 53.9167 11.7262 54.1033 11.7262 54.3334C11.7262 54.5635 11.9127 54.75 12.1428 54.75V53.9167ZM12.1428 54.75H30.9999V53.9167H12.1428V54.75Z" fill={props.primaryColor || "#BFBFBF"}/>
        <path d="M12.1428 68.0833C11.9127 68.0833 11.7262 68.2699 11.7262 68.5C11.7262 68.7301 11.9127 68.9167 12.1428 68.9167V68.0833ZM12.1428 68.9167H48.9998V68.0833H12.1428V68.9167Z" fill={props.primaryColor || "#BFBFBF"}/>
        <path d="M50.9299 64.056C60.6804 64.056 68.5848 56.0965 68.5848 46.278C68.5848 36.4595 60.6804 28.5 50.9299 28.5C41.1794 28.5 33.275 36.4595 33.275 46.278C33.275 56.0965 41.1794 64.056 50.9299 64.056Z" stroke={props.secondaryColor || "#808080"} stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M73 68.5L63.4001 58.8333" stroke={props.secondaryColor || "#808080"} stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M55.6094 41.5634L46.2465 50.9916" stroke={props.tertiaryColor || "#C43535"} stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M46.2485 41.5634L55.6114 50.9916" stroke={props.tertiaryColor || "#C43535"} stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};

export default NoDocument;
