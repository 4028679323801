import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Asset = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512.000000pt"} height={props.height || "512pt"}
        viewBox={props.viewBox || "0 0 512.000000 512.000000"} preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke="none">
        <path d="M1172 4703 c-111 -48 -137 -81 -137 -172 0 -63 3 -70 63 -167 34 -56 62 -106 62 -112 0 -5 -17 -12 -37 -15 -107 -18 -231 -119 -286 -234 l-32 -68 -6 -255 c-8 -293 -13 -320 -100 -498 -47 -95 -52 -114 -53 -172 0 -76 24 -131 76 -175 77 -65 -64 -60 1766 -60 l1664 0 24 28 24 28 0 930 0 930 -25 24 -24 25 -489 0 c-467 0 -491 -1 -513 -19 l-24 -19 -5 -462 -5 -462 -224 176 c-123 97 -235 179 -248 182 -35 9 -50 0 -240 -150 -94 -75 -174 -136 -176 -136 -2 0 -7 21 -11 48 -19 146 -155 296 -301 333 -27 7 -52 14 -54 15 -2 2 27 56 63 121 55 100 66 126 66 166 0 79 -33 124 -126 170 -116 59 -176 47 -273 -55 l-68 -70 -58 65 c-94 107 -156 120 -293 60z m188 -183 c75 -87 132 -111 212 -90 33 9 58 27 109 81 l66 70 36 -17 c20 -10 39 -24 42 -32 3 -9 -13 -47 -36 -86 -23 -39 -58 -99 -78 -133 l-36 -62 -160 -1 -160 0 -82 134 c-46 73 -83 138 -83 143 0 10 83 51 107 52 6 1 35 -26 63 -59z m2680 -765 l0 -825 -380 0 -380 0 0 825 0 825 380 0 380 0 0 -825z m-2125 308 c99 -53 144 -136 145 -263 l0 -84 -42 -37 -43 -37 -3 -357 -3 -357 -562 7 c-601 8 -587 6 -603 57 -4 12 15 64 50 141 87 191 97 240 105 527 5 210 9 250 25 280 35 67 96 119 166 141 14 4 183 7 375 6 338 -2 351 -3 390 -24z m980 -315 l220 -174 3 -322 2 -322 -114 0 -115 0 -3 223 c-3 202 -5 225 -23 257 -39 65 -70 75 -242 75 -166 0 -179 -4 -227 -69 -20 -25 -21 -43 -24 -257 l-3 -230 -117 3 -117 3 -3 316 -2 315 247 196 248 195 25 -18 c14 -10 124 -96 245 -191z m-170 -618 l0 -195 -95 0 -95 0 -3 185 c-1 101 0 190 3 198 3 9 28 12 97 10 l93 -3 0 -195z"/>
        <path d="M3470 4340 c-60 -14 -76 -131 -24 -177 31 -29 64 -29 98 -2 22 18 26 28 26 75 0 31 -4 64 -10 74 -12 23 -58 38 -90 30z"/>
        <path d="M3784 4340 c-35 -14 -54 -50 -54 -103 0 -59 31 -97 80 -97 49 0 80 38 80 99 0 74 -51 123 -106 101z"/>
        <path d="M3441 4011 c-40 -40 -34 -148 9 -171 31 -16 78 -12 100 10 16 16 20 33 20 80 0 47 -4 64 -20 80 -26 26 -83 27 -109 1z"/>
        <path d="M3751 4004 c-15 -19 -21 -41 -21 -74 0 -63 28 -100 76 -100 54 0 84 36 84 100 0 41 -5 56 -25 75 -34 35 -86 34 -114 -1z"/>
        <path d="M3459 3661 c-24 -25 -29 -37 -29 -81 0 -62 30 -100 80 -100 50 0 80 38 80 102 -1 56 -11 77 -47 96 -34 18 -52 14 -84 -17z"/>
        <path d="M3793 3680 c-31 -13 -43 -41 -43 -104 0 -47 4 -57 26 -75 35 -27 67 -26 101 3 24 21 27 31 27 80 0 43 -4 61 -19 75 -25 24 -63 33 -92 21z"/>
        <path d="M3451 3344 c-31 -39 -29 -117 4 -149 15 -16 36 -25 55 -25 49 0 80 38 80 98 0 67 -27 102 -80 102 -30 0 -43 -6 -59 -26z"/>
        <path d="M3770 3350 c-30 -30 -29 -129 3 -158 48 -45 120 -17 133 52 7 42 -11 103 -36 116 -31 16 -78 12 -100 -10z"/>
        <path d="M1459 4001 c-23 -23 -29 -38 -29 -73 0 -39 -3 -45 -38 -65 -48 -27 -88 -84 -101 -145 -18 -79 9 -160 71 -214 40 -35 76 -50 140 -59 51 -7 63 -16 73 -57 5 -21 1 -33 -19 -52 -33 -33 -61 -33 -91 2 -14 15 -34 37 -45 50 -27 31 -74 29 -107 -4 -21 -21 -24 -32 -20 -61 9 -50 50 -108 97 -135 35 -20 40 -27 40 -61 0 -27 7 -45 25 -62 52 -53 135 -15 135 62 1 33 5 41 40 63 51 31 86 82 100 144 12 55 7 93 -20 146 -32 63 -111 120 -165 120 -14 0 -41 6 -59 14 -54 22 -57 89 -6 116 29 16 44 12 85 -20 22 -18 50 -30 67 -30 35 0 78 40 78 72 0 36 -35 85 -79 110 -38 21 -41 26 -41 66 0 35 -6 50 -29 73 -16 16 -39 29 -51 29 -12 0 -35 -13 -51 -29z"/>
        <path d="M4295 2662 c-62 -22 -51 -14 -611 -423 -254 -186 -473 -344 -486 -353 -25 -16 -28 -14 -299 185 -151 111 -302 215 -336 231 -108 51 -312 75 -372 43 -48 -24 -57 -87 -18 -123 19 -18 35 -22 88 -22 90 0 172 -15 232 -43 50 -23 529 -369 535 -386 4 -11 -196 -157 -252 -184 -29 -14 -61 -21 -109 -21 -67 0 -72 2 -247 92 -105 54 -190 92 -207 92 -61 0 -96 -82 -55 -128 9 -11 97 -61 195 -110 203 -103 272 -122 378 -103 96 16 157 51 372 208 111 81 292 213 402 293 110 80 334 244 498 364 164 120 313 224 332 232 153 64 322 -75 302 -247 -4 -28 -18 -70 -32 -94 -37 -65 -1780 -1548 -1867 -1590 -56 -26 -74 -30 -153 -30 l-90 0 -525 225 -525 226 -5 700 -5 701 -30 48 c-22 36 -45 57 -84 77 l-55 29 -396 -3 -397 -3 -48 -30 c-36 -22 -57 -45 -77 -84 l-28 -53 0 -745 c0 -530 3 -757 11 -784 16 -52 64 -108 113 -130 38 -17 73 -19 435 -19 379 0 396 1 438 21 39 19 72 51 98 93 9 15 68 -8 500 -194 270 -116 517 -217 550 -225 124 -30 254 -13 366 46 58 31 107 71 474 384 105 89 456 388 780 664 387 329 605 521 633 557 149 198 83 488 -137 597 -56 27 -78 32 -151 35 -62 2 -99 -3 -135 -16z m-3031 -294 c14 -20 16 -107 16 -747 0 -660 -1 -726 -17 -743 -15 -17 -40 -18 -381 -18 -326 0 -367 2 -384 17 -17 15 -18 51 -18 742 0 641 2 729 16 749 l15 22 369 0 369 0 15 -22z"/>
        <path d="M1634 2360 c-30 -12 -54 -49 -54 -83 0 -40 49 -87 90 -87 42 0 90 47 90 89 0 59 -71 105 -126 81z"/>
        <path d="M1914 2360 c-30 -12 -54 -49 -54 -83 0 -40 49 -87 90 -87 42 0 90 47 90 89 0 59 -71 105 -126 81z"/>
        </g>
      </svg>
    </span>
  );
};

export default Asset;
