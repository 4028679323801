import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

{/*
  modified by Vipin
  modified on 24/03/2023
  modification: alarm2 svg was not changing size on providing height and width.
                modified Alarm2 Icon, added onClick function and viewBox value and modified the path as well.
*/}

const Alarm2 = (props) => {
  return (
    <svg onClick={()=> props.action && props.action()} xmlns="http://www.w3.org/2000/svg" class={props.classes || ''} width={props.width || "17"} height={props.width || "16"}  fill={props.fill || "none"} viewBox="0 0 17 16">
      <path d="M8.66893 15C12.0587 15 14.8066 12.2142 14.8066 8.77778C14.8066 5.34133 12.0587 2.55554 8.66893 2.55554C5.27918 2.55554 2.53125 5.34133 2.53125 8.77778C2.53125 12.2142 5.27918 15 8.66893 15Z" stroke={props.stroke || "#808080"} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.67188 5.66663V8.77774L10.2063 10.3333" stroke={props.stroke || "#808080"} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.30163 1L1 3.33334" stroke={props.stroke || "#808080"} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.3407 3.33334L14.0391 1" stroke={props.stroke || "#808080"} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4.06567 13.4443L2.53125 14.9999" stroke={props.stroke || "#808080"} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.2734 13.4443L14.8079 14.9999" stroke={props.stroke || "#808080"} stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

export default Alarm2;
