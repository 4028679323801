import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const AddContact = (props) => {
  return (
    <span class={`${props.masterClasses || ''} ${props.identityClass || ''}`} title={props.title || ''}>
      <svg onclick={() => props.action && props.action()} xmlns="http://www.w3.org/2000/svg" version="1.0" class={`${props.classes || ""} ${props.identityClass || ''}`} width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <g class={props.identityClass || ''} transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path class={props.identityClass || ''} d="M2345 4630 c-220 -25 -473 -96 -667 -188 -270 -127 -492 -295 -685 -517 -494 -569 -642 -1346 -392 -2061 42 -121 145 -330 216 -439 287 -438 727 -752 1232 -880 192 -48 282 -59 511 -59 229 0 319 11 511 59 739 188 1316 765 1504 1504 48 189 59 283 59 506 1 214 -11 332 -39 385 -26 50 -72 75 -140 75 -72 0 -113 -24 -140 -82 -17 -35 -17 -49 -7 -173 20 -226 13 -370 -28 -568 -54 -257 -184 -530 -353 -739 l-49 -62 -41 52 c-84 108 -170 198 -261 272 -104 84 -271 189 -360 224 l-56 24 83 86 c128 133 209 280 254 457 26 103 23 343 -5 445 -49 182 -140 337 -270 460 -384 368 -987 355 -1353 -29 -364 -381 -356 -976 20 -1346 l70 -69 -102 -51 c-236 -119 -428 -277 -579 -478 l-35 -47 -50 62 c-294 366 -433 836 -382 1295 61 553 381 1049 859 1328 325 191 673 267 1069 234 145 -12 160 -12 194 5 58 27 82 69 82 140 0 100 -44 144 -166 165 -86 15 -405 21 -504 10z m350 -1285 c287 -60 505 -329 505 -625 0 -297 -218 -565 -510 -626 -277 -58 -575 85 -699 337 -126 255 -80 541 118 740 156 155 370 219 586 174z m135 -1614 c243 -55 453 -170 627 -342 94 -93 178 -202 170 -222 -8 -22 -168 -123 -287 -183 -491 -244 -1072 -244 -1560 1 -121 61 -290 169 -290 186 0 22 88 134 164 210 199 199 461 330 738 369 98 14 340 3 438 -19z"/>
          <path class={props.identityClass || ''} d="M3935 4626 c-37 -17 -70 -52 -84 -89 -6 -16 -11 -103 -11 -202 l0 -174 -189 -3 c-207 -3 -216 -6 -265 -72 -29 -39 -29 -133 0 -172 49 -66 58 -69 264 -72 l189 -3 3 -189 c3 -206 6 -215 72 -264 39 -29 133 -29 172 0 66 49 69 58 72 264 l3 189 189 3 c206 3 215 6 264 72 29 39 29 133 0 172 -49 66 -58 69 -264 72 l-189 3 -3 189 c-3 206 -6 215 -70 263 -34 25 -113 32 -153 13z"/>
        </g>
      </svg>
    </span>
  );
};

export default AddContact;
