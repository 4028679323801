import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const List = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg fill={props.fill || '#000'} class={props.classes || ''} onclick={(e) => props.action && props.action(e)} width={props.width || '10px'} height={props.height || '10px'}  version="1.1" id="Layer_1" x="0px" y="0px"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M64 144c26.5 0 48-21.5 48-48s-21.5-48-48-48S16 69.5 16 96s21.5 48 48 48zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM64 464c26.5 0 48-21.5 48-48s-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48zm48-208c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48z"/>
      </svg>
    </span>
  );
};
export default List;
