import { h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { setItem, getItem } from '../../lib/myStore';
import { formatDateTime, getFormattedAmount, inWords } from '../../lib/utils';
import LinearProgress from 'preact-material-components/LinearProgress';
import { NewPopupModal, NewPopupModalBody } from '../../components/newPopupModal';
import ListSingleCard from '../../components/listSingleCard';
import FileViewer from '../../components/fileViewer';
import moment from 'moment';
import axios from 'axios';
import CONSTANTS from '../../lib/constants';
import { callAPI, reportException } from '../../lib/transceiver';
import { QuestionMark } from '../../components/svgs';

/**
 * Modification: PH_TN_T1439
 * A functional component to render and handle select tag those who are MapperSelect(s) and select formType.
 * The component is designed to get and set its enum values based on its dependentDynamicProp change.
 * @component
 */
const MapperSelect = (props) => {
  const {
    allDynamicProps /** Prop - Arr of all dynamicProps in the form */,
    dynamicProp /** Prop - Represents a single dynamic prop which is responsible for having the information about the input field */,
    entityObject /** Prop - Represents the object which has all the information of the current entity */,
    saveFormInput /** Prop - Represents the function handling the change of value in the select tag */,
    propIndex /** Prop - Index of the traversing through dynamicProps */,
    isDisabled /** Props - Represents if form is editable */
  } = props;

  const dependentProp = dynamicProp.dependentDynamicProp ? allDynamicProps.find(dp => dp.name === dynamicProp.dependentDynamicProp) : null;

  /**
   * Represents list of objects which are shown as enums in the select tag.
   * @type {Object[]}
   */
  const [enumArr, setEnumArr] = useState([]);

  /**
   * Represents if the component enum list retrieval is in process.
   * @type {boolean}
   */
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Executes function to get Enum Array value if
   * either dynamicProp's dependent dynamicProp is updated.
   */
  useEffect(() => {
    getEnumArr();
  }, [dynamicProp.dependentDynamicProp ? entityObject[dynamicProp.dependentDynamicProp] : null].filter(e => e));

  /**
   * Fetches Enum Array for the current dynamicProp
   */
  const getEnumArr = async () => {
    if (dynamicProp.dependentDynamicProp && entityObject[dynamicProp.dependentDynamicProp] || !dynamicProp.dependentDynamicProp) {
      try {
        setEnumArr([]);
        setIsLoading(true);
        let response = await callAPI(`put`, `/v1/entityDynamicEnum/${dynamicProp.uuid}`, entityObject);
        setEnumArr(response.data);
        setIsLoading(false);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
      }
    } else {
      setEnumArr([]);
    }
  };

  return (
    <div class="display-flex flex-direction-column">
      <select disabled={isDisabled} class='select' id={`field-${dynamicProp.name}`} onChange={(e) => saveFormInput(e, dynamicProp.name, "select", 0)} >
        <option value=''>Select {dynamicProp.displayName}</option>
        {
          enumArr.map((enumVal) => (
            <option selected={entityObject[dynamicProp.name] === enumVal.uuid} value={enumVal.uuid}>{enumVal[dynamicProp.enumDisplayKey] || enumVal[`dynamicProperties_${dynamicProp.enumDisplayKey}`]}</option>
          ))
        }
      </select>
      {isLoading && <span class="italic">Options Loading...</span>}
      {dynamicProp.dependentDynamicProp && !entityObject[dynamicProp.dependentDynamicProp] && <span class="italic">Please select {dependentProp.displayName || dynamicProp.dependentDynamicProp} to show values here</span>}
    </div>
  )
};

const CreateEditForm = (props) => {
  const userInfo = getItem('userinfo');
  let futureDateDisabled = new Date();
  let dd = futureDateDisabled.getDate();
  let mm = futureDateDisabled.getMonth()+1; //January is 0!
  let yyyy = futureDateDisabled.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }

  futureDateDisabled = yyyy+'-'+mm+'-'+dd;
  let [isFileViewerOpen, setIsFileViewerOpen] = useState(false);
  let [uploadedfile, setUploadedfile] = useState([]);
  let [fileViewerFor, setFileViewerFor] = useState({
    for: 'case'
  });
  let [progress, setProgress] = useState(0);
  /*
    Changed by Rutuja
    Changed on 24//2/23
    There should be an edit button on the endorsement form, on clicking which, the auto populated fields will become editable  [CODE CHANGE]
  */
  let [isFormEditable, setIsFormEditable] = useState(false);
  let [isFormSubmitted, setIsFormSubmitted] = useState(false);

  /*
    Modification: PH_TN_T1439 Commented dBFetchFields logic
    By: Devang
    Date: 24/01/2024
  */
  /*
    modified : Ashutosh G
    modified : 22/12/2023
    modification : dBFetchFields added and urlObject which helps to detemine get data form DB for specific fields
  */
  /*const dBFetchFields = ["locationDB"];
  const urlObject = {
    "department": { url: "/v1/getDepartmentList", parameter: `dealershipID=${userInfo.userDealershipID}`},
    "branch": { url: "/v1/getBranchList", parameter: `dealershipID=${userInfo.userDealershipID}` },
    "employee": { url: "/v1/users", parameter: `dealershipIDs=${userInfo.userDealershipID}` }
  };
  const [employeeList, setEmployeeList] = useState([]);*/

  // Main form props data to get from processMapping
  /*
    - formOperation
    - formEntityName
    - formEntityObject
    - setFormEntityObject
    - formDynamicProps
  */
  let {entityName, operation, toggleFormPopover, backgroundColor, dynamicProps, entityObject, submitForm,
    setFormValueInput, saveFormInputRadioDefault, saveFormInputRadio, saveFormInputCheckbox, saveAreaFromForm, saveFormInput,
    uploadFile, errorField, stateList,
    isActionsReceived, selectedActionIndex, setSelectedActionIndex, checkForActionPendingProps, isMobileView} = props;

    /*
      Modification: setIsFormSubmitted state change from onClick to based on toggleFormPopover
      By: Masum Raja
      Date: 23/05/2023
    */

    useEffect(async () => {
      if (!toggleFormPopover) {
        setIsFormSubmitted(true);
      }
    }, [toggleFormPopover]);

  /*
    modified : Ashutosh G
    modified : 22/12/2023
    modification : this useEffect filter field obj from dynamicProps and use getRequestedForOptions to get data from db
  */
  /*useEffect(async () => {
    let dBFetchFieldsExistsArray = dynamicProps.filter(item => dBFetchFields.includes(item.name)).map(item => item.name);
    if (dBFetchFieldsExistsArray.length > 0) {
      if (dBFetchFieldsExistsArray.includes("locationDB")){
        await getRequestedForOptions("branch");
      }
    }
  }, [entityObject]);

  async function getRequestedForOptions(entity) {
    try {
      if (!entity || !Object.keys(urlObject).includes(entity.toLowerCase()))
        return;

      entity = entity.toLowerCase();

      let response = await callAPI(`get`, urlObject[entity].url, urlObject[entity].parameter);
      setEmployeeList(response.data);
    } catch (HTTPException) {
      let payload = {
        apiURL:HTTPException.config.url,
        errorObject:HTTPException,
        statusCode:HTTPException.response.status,
        errorMessage:HTTPException.message,
        functionName:"getRequestedForOptions",
        fileName: FILENAME,
        pageName: PAGENAME,
        userID: userInfo.uuid,
        userName:userInfo.displayName
      };
      await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      return HTTPException;
    }
  }*/

  async function formModalContainerOnScroll(element, index) {
    if (index || index === 0) {
      element = await document.getElementById(`formModalContainer-${index}`);
    }
    let target = element.target ? element.target : element;
    if (target) {
      if ((target.offsetHeight + target.scrollTop) >= target.scrollHeight) {
        target.style.boxShadow = "none";
      } else {
        target.style.boxShadow = "inset 3px -14px 11px -10px rgb(0 0 0 / 20%)";
      }
      let height = target.scrollHeight - target.clientHeight;
      let scrolled = (target.scrollTop / height);
      setProgress(scrolled);
    }
  }
  {/*
    Modification: conditionally change the interactionID
    By: Masum Raja
    Date: 22/05/2023
  */}
  async function getReferenceFilesForFileViewer(e, type, openViewer, fieldName, entityID) {
    try {
      let fileViewerObj = [];
      let fileID = [entityObject[''+fieldName]];
      if (type === 'Uploaded Files') {
        fileViewerObj.push({name: 'Uploaded Files', fileIDs: fileID});
      }
      /*
        modified : Ashutosh G
        modified : 24/07/2023
        modification : codition changed below
      */
      let payload = {
        interactionID: (entityObject.type === 'Vendor' || entityObject.type === 'Catalogue')  ? 'entity' : entityObject.uuid ? entityObject.uuid : 'entity',
        s3KeyFor: 'case',
        fileViewerObj
      };
      fileViewerFor['interactionID'] = entityID;
      let response = await axios.put(`${CONSTANTS.API_URL}/api/fileViewer`, payload);
      fileViewerFor.type = type;
      setFileViewerFor(fileViewerFor);
      if (response && response.data) {
        await setUploadedfile(response.data);
        if (openViewer) await setIsFileViewerOpen(true);
      }
    } catch (HTTPException) {
      console.error(HTTPException);
      let parameter = {
        interactionID: entityObject.uuid,
        s3KeyFor: 'case',
        fileViewerObj
      };
      let payload = {
        apiURL:HTTPException.config.url,
        errorObject:HTTPException,
        statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
        errorMessage:HTTPException.message,
        functionName:"getReferenceFilesForFileViewer",
        fileName:"createEditForm",
        pageName: "createEditForm",
        payload:parameter,
        userID:userInfo.uuid,
        userName:userInfo.displayName
      };
      await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      return HTTPException;
    }
  }
  async function toggleFileViewer() {
    setIsFileViewerOpen(!isFileViewerOpen);
  }

  async function closeFileViewer() {
    setIsFileViewerOpen(false);
  }

  function getFilteredList(dependentEnum) {
    let filteredList = dependentEnum.filter(dependentEnumObj => {
      let filteredLength = dependentEnumObj.query.filter((d) => {
        return d.valueOfProp === entityObject[d.nameOfProp];
      }).length;
      if (filteredLength === dependentEnumObj.query.length) {
        return dependentEnumObj;
      }
    });
    return filteredList;
  }
  async function toggleEditForm() {
    setIsFormEditable(!isFormEditable)
  }

  /*
    Modification: PH_TN_T1439 Added function get boolean of radio button values
    By: Devang
    Date: 24/01/2024
  */
  const getRadioValBoolean = (value) => {
    if (value.toLowerCase() === 'yes') return true;
    return false
  }

  /*
    Modification: PH_TN_T1439 Added function to render dynamicProps based on given inputs
    By: Devang
    Date: 24/01/2024
  */
  const renderProps = (dynamicProps) => {
    return (
      <div class="step-content formModalContainer" id="formModalContainer" style="display: block; box-shadow: none;">
        <div class="row">
          {/*
            By: Yashvi
            On: 17th Aug 2022
            Added all the required conditions to hide empty divs
        */}
        {/*
          By: Komal
          On: 28th Feb 2023
          Added condition for multipleDependentProps and multipleDependentEnum
        */}

        {/*
          Modification: On more condition added when ever any required field is empty error-div class become true
          By: Masum Raja
          Date: 24/05/2023
        */}
        {/*
          Modification: PH_TN_T1439 Added condition for isMapperSelect
          By: Devang
          Date: 24/01/2024
        */}
        {/*
            BY: Prasannadatta Kawadkar
            ON: 20 May 2024
            Modification: added dependentPropObjectKey in the below logic and made necessary changes
        */}
          {
            dynamicProps.length > 0 && dynamicProps.map((dynamicProp, propIndex) => (
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 m-t-15" style={`${((dynamicProp.formType !== "select" && dynamicProp.formType !== "radio" && dynamicProp.formType !== "checkbox" && !dynamicProp.dependentProp) ||
                ((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && !dynamicProp.dependentPropObjectKey && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue)))) ||
                ((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && dynamicProp.dependentPropObjectKey && ((typeof entityObject[dynamicProp.dependentProp] === 'object') && entityObject[dynamicProp.dependentProp][dynamicProp.dependentPropObjectKey] === dynamicProp.dependentPropValue) || (Array.isArray(entityObject[dynamicProp.dependentProp]) && entityObject[dynamicProp.dependentProp].find((obj) => { return obj[dynamicProp.dependentPropObjectKey] === dynamicProp.dependentPropValue})) ) ||
                ((dynamicProp.formType === "radio" || dynamicProp.formType === "checkbox") && !dynamicProp.dependentProp) ||
                (((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && (dynamicProp.typeofField !== "Array" && !dynamicProp.dependentProp))) ||
                (((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && (dynamicProp.typeofField === "Array" && !dynamicProp.dependentProp))) ||
                ((dynamicProp.formType === "date" || dynamicProp.formType === "time") && !dynamicProp.dependentProp) ||
                ((dynamicProp.formType === "file") && (!dynamicProp.dependentProp && (!entityObject[dynamicProp.name] || (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] === "NA")))) ||
                (dynamicProp.formType === "file" && !dynamicProp.dependentProp && (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] !== "NA")) ||
                ((dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && !dynamicProp.dependentProp) ||
                ((dynamicProp.formType === "radio" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp) ||
                ((dynamicProp.formType === "checkbox" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp) ||
                ((dynamicProp.formType === "checkbox" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && dynamicProp.name === "negotiationMethod" && dynamicProp.dependentProp && dynamicProp.dependentPropValue && (entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue)) ||
                (((dynamicProp.name === "area" || dynamicProp.name === "companyArea") && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0))) ||
                // ((dynamicProp.name === "gstState") && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) ||
                ((dynamicProp.formType === "select" && (dynamicProp.dependentProp && dynamicProp.dependentPropValue) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) && !dynamicProp.isMapperSelect ||
                ((dynamicProp.formType === "select" && !dynamicProp.entityName && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp) && !dynamicProp.isMapperSelect ||
                (((dynamicProp.formType === "select" && dynamicProp.entityName && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && (!dynamicProp.dependentProp || (dynamicProp.dependentProp && (dynamicProp.dependentPropValue && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) || (dynamicProp.dependentPropValues && ((dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item)))))))) && !dynamicProp.isMapperSelect) ||
                ((dynamicProp.formType === "select" && (dynamicProp.dependentEnum && dynamicProp.dependentEnum.length !== 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (dynamicProp.dependentEnum.findIndex((d) => d.isSelected) > -1) && !dynamicProp.dependentProp) && !dynamicProp.isMapperSelect ||
                ((dynamicProp.formType === "select" && (dynamicProp.multipleDependentEnum && dynamicProp.multipleDependentEnum.length !== 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (getFilteredList(dynamicProp.multipleDependentEnum).length === 1) && !dynamicProp.dependentProp) && !dynamicProp.isMapperSelect ||
                (dynamicProp.formType === "select" && ((!dynamicProp.dependentProp && !dynamicProp.dependentPropValue) || ((dynamicProp.dependentProp && dynamicProp.dependentPropValue) && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))))) && dynamicProp.isMapperSelect) ||
                // (dynamicProp.formType === "select" && dynamicProp.isMapperSelect) ||
                (dynamicProp.formType !== "select" && dynamicProp.dependentProp && !dynamicProp.dependentPropObjectKey && dynamicProp.dependentPropValue && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) ||
                (dynamicProp.formType !== "select" && dynamicProp.dependentProp && !dynamicProp.dependentPropValue && ((dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item))))) ||
                (dynamicProp.formType === "file" && dynamicProp.dependentProp  && dynamicProp.dependentPropValue && (!entityObject[dynamicProp.name] || (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] === "NA")) && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) ||
                (dynamicProp.formType === "file" && dynamicProp.dependentProp  && dynamicProp.dependentPropValue && (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] !== "NA") && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) ||
                (dynamicProp.formType === "file" && dynamicProp.dependentProp  && !dynamicProp.dependentPropValue && ((entityObject[dynamicProp.name] && entityObject[dynamicProp.name] === "NA") || !entityObject[dynamicProp.name]) && ((dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item))))) ||
                (dynamicProp.formType === "file" && dynamicProp.dependentProp  && !dynamicProp.dependentPropValue && (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] !== "NA") && ((dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item))))) ||
                ((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && !dynamicProp.dependentPropObjectKey && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue)))) ||
                ((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp]))) ||
                ((dynamicProp.formType === "date" || dynamicProp.formType === "time") && dynamicProp.dependentProp && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue)) || (dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp]))))) ||
                ((dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && dynamicProp.dependentProp && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue)))) ||
                (((dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])))) ||
                ((dynamicProp.formType === "radio" && (dynamicProp.enum || dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValue && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue)))) ||
                ((dynamicProp.formType === "radio" && (dynamicProp.enum || dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])))) ? '' : 'display: none;'}`}>
                <div class={`input-box ${(entityObject.errorFields && entityObject.errorFields.length > 0 && (entityObject.errorFields.findIndex(item => item.fieldName === dynamicProp.name) > -1)) || (errorField && errorField.length > 0 && (errorField.findIndex(item => item.name === dynamicProp.name) > -1))  && 'error-div'}`} id={`${dynamicProp.name}-input-box`}>
                  {(dynamicProp.name !== 'multipleDependentProps') && (dynamicProp.name !== 'multipleDependentEnum') && (
                    <p class="formLabel fs-15 p-b-3">{dynamicProp.displayName}
                      <span style={entityObject[dynamicProp.name] ? "display: none": "display:inline"}  id={`required-`+dynamicProp.name} class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span>
                      <span style={entityObject[dynamicProp.name] ? "display: inline": "display:none"} id={`success-`+dynamicProp.name}> <svg fill="green" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="15" height="15"><path d="M22.319,4.431,8.5,18.249a1,1,0,0,1-1.417,0L1.739,12.9a1,1,0,0,0-1.417,0h0a1,1,0,0,0,0,1.417l5.346,5.345a3.008,3.008,0,0,0,4.25,0L23.736,5.847a1,1,0,0,0,0-1.416h0A1,1,0,0,0,22.319,4.431Z"/></svg></span>
                      {/*
                        Modification: PH_TN_T1439 Added question Mark UI to show helperText
                        By: Devang
                        Date: 23/01/2024
                      */}
                      <span style={dynamicProp.helperText ? "display: inline-flex": "display:none"} id={`helperText-`+dynamicProp.helperText}><QuestionMark masterClasses="flex-c-m m-l-4 cursor-help bo-cir bg-hov-gray" title={dynamicProp.helperText} width="16" height="16"/></span>
                    </p>
                  )}
                  {/*
                    (dynamicProp.formType !== "text" && dynamicProp.formType !== "number" && dynamicProp.formType !== "month") && dynamicProp.formType !== "select" && dynamicProp.formType !== "radio" && dynamicProp.formType !== "checkbox" && !dynamicProp.dependentProp && (
                      <p class="formLabel fs-15 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                    )
                  }
                  {
                    (dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue)) &&
                      <p class="formLabel fs-15 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                    )
                  }
                  {
                    (dynamicProp.formType === "radio" || dynamicProp.formType === "checkbox") && !dynamicProp.dependentProp && (
                      <p class="formLabel fs-15 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                    )
                  */}
                  {
                    ((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") &&  (dynamicProp.typeofField !== "Array" && !dynamicProp.dependentProp)) && (
                      <div>
                        {/*<p class="formLabel fs-15 p-b-3">{dynamicProp.displayName}<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                        <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable ) ? true: false} onClick={(e) => props.inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} checked={entityObject[dynamicProp.name] ? true : false} value={entityObject[dynamicProp.name] ? entityObject[dynamicProp.name] : ''} onInput={(e) => props.setFormValueInput(e, dynamicProp.name)} onFocusout={(e) => props.saveFormInput(e, dynamicProp.name)} />
                      </div>
                    )
                  }
                  {
                    (dynamicProp.name === 'multipleDependentProps'|| dynamicProp.name ==='multipleDependentEnum') && (dynamicProp.formName === 'DynamicProperty'|| !dynamicProp.dependentProp) && (
                      <div class='display-flex'>
                        <p class="formLabel fs-15 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                        <button class='m-l-auto m-r-1' onClick={(e) => props.addObject(e, dynamicProp.name)}>ADD</button>
                      </div>
                    )
                  }

                  {/*
                    Modification: Added Separate condition template for Array typeofField
                    By: Devang
                    Date: 11/08/2022
                  */}
                  {
                    // for dynamicProp.name === enum for now
                  }
                  {
                    ((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && (dynamicProp.name !== 'multipleDependentProps') && (dynamicProp.name !== 'multipleDependentEnum')&& (dynamicProp.typeofField === "Array" && !dynamicProp.dependentProp)) && (
                      <div class="display-flex flex-direction-column">
                        <div class="display-flex">
                          <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable ) ? true: false} onClick={(e) => props.inputClicked(e)} name={dynamicProp.name} id={`${dynamicProp.name}`} value={dynamicProp.stageKey} onInput={(e) => props.setFormStageValueInput(e, 'stageKey', propIndex)} type={dynamicProp.formType} />
                          <button class="m-l-16" onClick={(e) => props.setFormArrayValueInput(e, dynamicProp.name, dynamicProp.stageKey, 'stageKey', propIndex)}>ADD</button>
                        </div>
                        <div class="display-flex flex-direction-column m-t-6">
                          {entityObject[dynamicProp.name] && entityObject[dynamicProp.name].length && entityObject[dynamicProp.name].map((value, index) => (
                            <div class="display-flex flex-sb">
                              <div>{value}</div>
                              <div class="text-color-red cursor-pointer" onClick={(e) => props.removeFormArrayValueInput(e, dynamicProp.name, index)}>X</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                  }
                  {/*
                    By: Komal
                    On: 28th Feb 2023
                    Added condition for multipleDependentProps and multipleDependentEnum
                */}
                  {
                    ((dynamicProp.formType === "text") && (dynamicProp.name === 'multipleDependentProps') && (dynamicProp.formName === 'DynamicProperty') && (dynamicProp.typeofField === "Array" && !dynamicProp.dependentProp)) && (
                      <div class=" m-t-10 display-flex flex-direction-column m-t-6" id={`${dynamicProp.name}-input-box`}>
                        {entityObject[dynamicProp.name] && entityObject[dynamicProp.name].length && entityObject[dynamicProp.name].map((value, multipleDependentIndexndex) => (
                          <div class="input-box display-flex flex-direction-column m-t-6">
                            <div class="text-color-red cursor-pointer m-l-auto" onClick={(e) => props.removeFormArrayValueInput(e, dynamicProp.name, multipleDependentIndexndex)}>X</div>
                            <div class="">
                              <p class="formLabel fs-15 p-b-3">Dependent Prop <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                              <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} type="text" onClick={(e) => props.inputClicked(e)} name={`dependentProp-${multipleDependentIndexndex}`} id={`dependentProp-${multipleDependentIndexndex}`} value={value.dependentProp ? value.dependentProp:''} onInput={(e) => props.setFormArrayValueInputForProp(e, `dependentProp-${multipleDependentIndexndex}`, e.target.value,dynamicProp.name,multipleDependentIndexndex,propIndex)} />
                            </div>
                            <div class="">
                              <p class="formLabel fs-15 p-b-3">Dependent Prop Values<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                              <div class="display-flex">
                                <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} onClick={(e) => props.inputClicked(e)} name={`dependentPropValues-${multipleDependentIndexndex}`} id={`dependentPropValues-${multipleDependentIndexndex}`} value={dynamicProp[`multipleDependentKey-${multipleDependentIndexndex}`]} onInput={(e) => props.setFormStageValueInputForDependantProps(e, `multipleDependentKey-${multipleDependentIndexndex}`, propIndex,value.dependentPropValues)} type='text'/>
                                <button class="m-l-16" onClick={(e) => props.setArrayValue(e, `dependentPropValues-${multipleDependentIndexndex}`, dynamicProp.name, dynamicProp[`multipleDependentKey-${multipleDependentIndexndex}`], multipleDependentIndexndex,propIndex,`multipleDependentKey-${multipleDependentIndexndex}`)}>ADD</button>
                              </div>
                              <div class="display-flex flex-direction-column m-t-6">
                                {value.dependentPropValues && value.dependentPropValues.length && value.dependentPropValues.map((valueDependentProp, multipleDependentPropValuesIndex) => (
                                  <div class="display-flex flex-sb">
                                    <div>{valueDependentProp}</div>
                                    <div class="text-color-red cursor-pointer" onClick={(e) => props.removeFormArrayValueInputFromProp(e, dynamicProp.name, multipleDependentPropValuesIndex,multipleDependentIndexndex)}>X</div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div class="">
                              <p class="formLabel fs-15 p-b-3">Dependent Prop Value<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                              <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} type="text" onClick={(e) => props.inputClicked(e)} name={`dependentPropValue-${multipleDependentIndexndex}`} id={`dependentPropValue-${multipleDependentIndexndex}`} value={value.dependentPropValue ? value.dependentPropValue : ''} onInput={(e) => props.setFormArrayValueInputForProp(e, `dependentPropValue-${multipleDependentIndexndex}`, e.target.value,dynamicProp.name,multipleDependentIndexndex,propIndex)} />
                            </div>
                          </div>
                        ))}
                      </div>
                    )
                  }
                  {
                    ((dynamicProp.formType === "text") && (dynamicProp.name === 'multipleDependentEnum') && (dynamicProp.formName === 'DynamicProperty') && (dynamicProp.typeofField === "Array" && !dynamicProp.dependentProp)) && (
                      <div class="input-box m-t-10 display-flex flex-direction-column m-t-6" id={`${'query'}-input-box`}>
                        {entityObject[dynamicProp.name] && entityObject[dynamicProp.name].length && entityObject[dynamicProp.name].map((value, multipleDependentIndexndex) => (
                          <div class="input-box display-flex flex-direction-column m-t-6">
                            <div class="text-color-red cursor-pointer m-l-auto" onClick={(e) => props.removeFormArrayValueInput(e, dynamicProp.name, multipleDependentIndexndex)}>X</div>
                            <div class="m-t-10 display-flex flex-direction-column m-t-6" >
                              <div class='display-flex'>
                                <p class="formLabel fs-15 p-b-3">Query <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                <button class='m-l-auto m-r-1' onClick={(e) => props.addObjectForEnum(e, dynamicProp.name,multipleDependentIndexndex)}>ADD</button>
                              </div>
                              {value.query && value.query.length && value.query.map((queryValue, queryIndex) => (
                                <div class=" input-box display-flex flex-direction-column m-t-6">
                                  <div class="text-color-red cursor-pointer m-l-auto" onClick={(e) => props.removeFormQueryValueInput(e, dynamicProp.name, multipleDependentIndexndex,queryIndex)}>X</div>
                                  <div class="">
                                    <p class="formLabel fs-15 p-b-3">Name Of Prop<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                    <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} type="text" onClick={(e) => props.inputClicked(e)} name={`nameOfProp-${queryIndex}`} id={`nameOfProp-${multipleDependentIndexndex}-${queryIndex}`} value={queryValue.nameOfProp ? queryValue.nameOfProp:''} onInput={(e) => props.setFormArrayValueInputForEnum(e, `nameOfProp-${queryIndex}`, e.target.value,dynamicProp.name,queryIndex,multipleDependentIndexndex)} />
                                  </div>
                                  <div class="m-t-10">
                                    <p class="formLabel fs-15 p-b-3">Value Of Prop<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                    <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} type="text" onClick={(e) => props.inputClicked(e)} name={`valueOfProp-${queryIndex}`} id={`valueOfProp-${multipleDependentIndexndex}-${queryIndex}`} value={queryValue.valueOfProp ? queryValue.valueOfProp : ''} onInput={(e) => props.setFormArrayValueInputForEnum(e, `valueOfProp-${queryIndex}`, e.target.value,dynamicProp.name,queryIndex,multipleDependentIndexndex)} />
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div class=" m-t-25">
                              <p class="formLabel fs-15 p-b-3">Dependent Enums<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                              <div class="display-flex">
                                <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} onClick={(e) => props.inputClicked(e)} name={`dependentEnums-${multipleDependentIndexndex}`} id={`dependentEnums-${multipleDependentIndexndex}`} value={dynamicProp[`dependentEnums-${multipleDependentIndexndex}`]} onInput={(e) => props.setFormStageValueInputForDependantProps(e, `dependentEnums-${multipleDependentIndexndex}`, propIndex,value.dependentEnums)} type='text'/>
                                <button class="m-l-16" onClick={(e) => props.setArrayValueForEnum(e, `dependentEnums-${multipleDependentIndexndex}`, dynamicProp.name, dynamicProp[`dependentEnums-${multipleDependentIndexndex}`], multipleDependentIndexndex,propIndex,`dependentEnums-${multipleDependentIndexndex}`)}>ADD</button>
                              </div>
                              <div class="display-flex flex-direction-column m-t-6">
                                {value.dependentEnums && value.dependentEnums.length && value.dependentEnums.map((valueDependentEnums, multipleDependentPropValuesIndex) => (
                                  <div class="display-flex flex-sb">
                                    <div>{valueDependentEnums}</div>
                                    <div class="text-color-red cursor-pointer" onClick={(e) => props.removeFormArrayValueInputFromEnum(e, dynamicProp.name, multipleDependentPropValuesIndex,multipleDependentIndexndex)}>X</div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>

                        ))}
                      </div>
                    )
                  }
                  {
                    (dynamicProp.formType === "date" || dynamicProp.formType === "time") && !dynamicProp.dependentProp && (
                      <input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} maxlength={dynamicProp.max && dynamicProp.max} value={moment(new Date(entityObject[dynamicProp.name])).format('YYYY-MM-DD') || ''} onInput={(e) => props.setFormValueInput(e, dynamicProp.name)} onFocusout={(e) => props.saveFormInput(e, dynamicProp.name)} max={(dynamicProp.typeOfValidation && dynamicProp.typeOfValidation === 'futureDateDisabled') && futureDateDisabled}/>
                    )
                  }
                  {
                    /*
                      Bug ID: COMP_BG_250
                      Bug: The upload file icon in the endorsement is incorrect for the mentioned fields
                      modified : Komal Wable
                      modified : 02/02/2023
                      modification : change icon
                    */
                  }
                  {
                    (dynamicProp.formType === "file") && (!dynamicProp.dependentProp && (!entityObject[dynamicProp.name] || (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] === "NA"))) && (
                      <div>
                        <input class="uploadInputFile" autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}`} id={`${dynamicProp.name}`} type={dynamicProp.formType} value={entityObject[dynamicProp.name]} accept="image/*,.pdf" onChange={(e) => props.uploadFile(e, dynamicProp.name)}/>
                        <label class="p-t-b-5" for={`${dynamicProp.name}`}>
                          <figure>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="24" width="24"><path d="M11.007,2.578,11,18.016a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l.007-15.421,2.912,2.913a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L14.122.879a3,3,0,0,0-4.244,0L6.667,4.091a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0Z" /><path d="M22,17v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17a1,1,0,0,0-1-1h0A1,1,0,0,0,22,17Z" /></svg>
                          </figure>
                          <span>Choose a file</span>
                        </label>
                      </div>
                    )
                  }
                  {
                    dynamicProp.formType === "file" && !dynamicProp.dependentProp && (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] !== "NA") && (
                      <div class="uploaded-image">
                        <button type="button" id={`image-0-${propIndex}`} class="primary-button cursor-pointer object-fit-contain w-full" onClick={(e)=> getReferenceFilesForFileViewer(e, 'Uploaded Files', true, dynamicProp.name, entityObject.uuid)}>View Uploaded File</button>
                        <p class="delete-file-icon" title="Delete file" onClick={(e) => props.deleteUploadedFile(e, dynamicProp.name)}>{'x'}</p>
                      </div>
                    )
                  }
                  {
                    (dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && !dynamicProp.dependentProp && (
                      <div class="display-flex p-r-10 align-center">
                        {/*
                          Modification: PH_TN_T1439 Fixed chrome radio button selection issue
                          By: Devang
                          Date: 24/01/2024
                        */}
                        {/*<input
                          key={`${dynamicProp.name}-yes`}
                          autocomplete="off"
                          disabled={(dynamicProp.isDisabled || !isFormEditable) ? true : false}
                          required={dynamicProp.isRequired ? "true" : "false"}
                          name={`${dynamicProp.name}-yes`}
                          id={`${dynamicProp.name}-yes`}
                          type={dynamicProp.formType}
                          checked={(entityObject[dynamicProp.name] === "yes" || entityObject[dynamicProp.name] === true) ? true : false}
                          value="yes"
                          onChange={(e) => props.saveFormInputRadioDefault(e, dynamicProp.name, dynamicProp.typeofField)}
                        />
                        <label class="abc p-l-10 p-r-10" style="font-weight: 300;" for={`${dynamicProp.name}-yes`}>Yes</label>

                        <input
                          key={`${dynamicProp.name}-no`}
                          autocomplete="off"
                          disabled={(dynamicProp.isDisabled || !isFormEditable) ? true : false}
                          required={dynamicProp.isRequired ? "true" : "false"}
                          name={`${dynamicProp.name}-no`}
                          id={`${dynamicProp.name}-no`}
                          type={dynamicProp.formType}
                          checked={(entityObject[dynamicProp.name] === "no" || entityObject[dynamicProp.name] === false) ? true : false}
                          value="no"
                          onChange={(e) => props.saveFormInputRadioDefault(e, dynamicProp.name, dynamicProp.typeofField)}
                        />
                        <label class="abc p-l-10 p-r-10" style="font-weight: 300;" for={`${dynamicProp.name}-no`}>No</label>*/}

                        {["Yes", "No"].map((enumVal) => (
                          <Fragment>
                            <input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-${propIndex}`} id={`${dynamicProp.name}-${propIndex}-${enumVal.toLowerCase()}`} type={dynamicProp.formType} defaultChecked={(entityObject[dynamicProp.name] === enumVal.toLowerCase() || entityObject[dynamicProp.name] === getRadioValBoolean(enumVal)) ? true : false} value={enumVal.toLowerCase()} onChange={(e) => props.saveFormInputRadio(e, dynamicProp.name, enumVal.toLowerCase())} />
                            <label class='p-l-10 p-r-10' style='font-weight: 300;' for={`${dynamicProp.name}-${propIndex}-${enumVal.toLowerCase()}`}>{enumVal}</label>
                          </Fragment>
                        ))}

                      </div>
                    )
                  }
                  {
                    (dynamicProp.formType === "radio" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp && (
                      <div class="display-flex align-center">
                        {/*
                          Modification: PH_TN_T1439 Fixed chrome radio button selection issue
                          By: Devang
                          Date: 24/01/2024
                        */}
                        {
                          dynamicProp.enum.map((enumVal) => (
                            <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} name={`${dynamicProp.name}`} id={`${dynamicProp.name}-${enumVal}`} type={dynamicProp.formType} defaultChecked={entityObject[dynamicProp.name] === enumVal} value={enumVal} onChange={(e) => props.saveFormInputRadio(e, dynamicProp.name, enumVal)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${enumVal}`}>{enumVal}</label></div>
                          ))
                        }
                      </div>
                    )
                  }
                  {
                    (dynamicProp.formType === "checkbox" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp && (
                      <div>
                        {
                          dynamicProp.enum.map((enumVal) => (
                            <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} name={`${dynamicProp.name}-${enumVal}`} id={`${dynamicProp.name}-${enumVal}`} type={dynamicProp.formType} defaultChecked={entityObject[dynamicProp.name] ? entityObject[dynamicProp.name].split(",").includes(enumVal) : ""} onChange={(e) =>  props.saveFormInputCheckbox(e, dynamicProp.name, enumVal)}  /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${enumVal}`}>{enumVal}</label></div>
                          ))
                        }
                      </div>
                    )
                  }
                  {
                    (dynamicProp.formType === "checkbox" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && dynamicProp.name === "negotiationMethod" && dynamicProp.dependentProp && dynamicProp.dependentPropValue && (entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) && (
                      <div>
                        {/*<p class="formLabel fs-15 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                        {
                          dynamicProp.enum.map((enumVal) => (
                            <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} name={`${dynamicProp.name}-${enumVal}`} id={`${dynamicProp.name}-${enumVal}`} type={dynamicProp.formType} defaultChecked={entityObject[dynamicProp.name] ? entityObject[dynamicProp.name].split(",").includes(enumVal) : ""} onChange={(e) =>  props.saveFormInputCheckbox(e, dynamicProp.name, enumVal)}  /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${enumVal}`}>{enumVal}</label></div>
                          ))
                        }
                      </div>
                    )
                  }
                  {
                    ((dynamicProp.name === "area" || dynamicProp.name === "companyArea") && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && !dynamicProp.isMapperSelect && (
                      <div>
                        {/*<p class="formLabel fs-15 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                        <select disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) =>  props.saveAreaFromForm(e, dynamicProp.name)} >
                          {
                            areaList.map((enumVal) => (
                              <option selected={entityObject[dynamicProp.name] === enumVal.officeName} value={enumVal.officeName}>{enumVal.officeName}</option>
                            ))
                          }
                        </select>
                      </div>
                    )
                  }
                  {/*
                    Modification: new dynamicProp field added for stateList
                    By: Rutuja
                    Date: 30/05/2023
                  }
                  {
                    ((dynamicProp.name === "gstState") && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (
                      <div>
                        <select disabled={(dynamicProp.isDisabled) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) =>  props.saveFormInput(e, dynamicProp.name, "select", 0)} >
                          <option value=''>Select State</option>
                          {
                            stateList && stateList.length > 0 && stateList.map((enumVal) => (
                              <option selected={entityObject.hasOwnProperty(dynamicProp.name) ? (entityObject[dynamicProp.name] === enumVal.name) : (entityObject["dynamicProperties_" + dynamicProp.name] === enumVal.name)} value={enumVal.name}>{enumVal.name}</option>
                            ))
                          }
                        </select>
                      </div>
                    )
                    */
                  }
                  {
                    (dynamicProp.formType === "select" && (dynamicProp.dependentProp && dynamicProp.dependentPropValue) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))) && !dynamicProp.isMapperSelect && (
                      <div>
                        {/*<p class="formLabel fs-15 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                        <div class="display-flex">
                          <select disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) => props.saveFormInput(e, dynamicProp.name, "select", 0)} >
                            <option value=''>Select {dynamicProp.displayName}</option>
                            {
                              dynamicProp.enum.map((enumVal) => (
                                <option selected={entityObject[dynamicProp.name] === enumVal} value={enumVal}>{enumVal}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                    )
                  }
                  {
                    (dynamicProp.formType === "select" && !dynamicProp.entityName && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp && !dynamicProp.isMapperSelect && (
                      <div>
                        {/*<p class="formLabel fs-15 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                        <div class="display-flex">
                          <select disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) => props.saveFormInput(e, dynamicProp.name, "select", 0)} >
                            <option value=''>Select {dynamicProp.displayName}</option>
                            {
                              dynamicProp.enum.map((enumVal) => (
                                <option selected={entityObject[dynamicProp.name] === enumVal} value={enumVal}>{enumVal}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                    )
                  }
                  {
                    ((dynamicProp.formType === "select" && dynamicProp.entityName && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && (!dynamicProp.dependentProp || (dynamicProp.dependentProp && (dynamicProp.dependentPropValue && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) || (dynamicProp.dependentPropValues && ((dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item)))))))) && !dynamicProp.isMapperSelect && (
                      <div>
                        {/*<p class="formLabel fs-15 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                        <div class="display-flex">
                          <select disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) => props.saveFormInput(e, dynamicProp.name, "select", 0)} >
                            <option value=''>Select {dynamicProp.displayName}</option>
                            {
                              dynamicProp.enum.map((item) => (
                                <option selected={entityObject[dynamicProp.name] === (dynamicProp.enumIDKey ? item[dynamicProp.enumIDKey] : item.uuid)} value={(dynamicProp.enumIDKey ? item[dynamicProp.enumIDKey] : item.uuid)}>{item[dynamicProp.enumDisplayKey]}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                    )
                  }
                  {
                    (dynamicProp.formType === "select" && (dynamicProp.dependentEnum && dynamicProp.dependentEnum.length !== 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (dynamicProp.dependentEnum.findIndex((d) => d.isSelected) > -1) && !dynamicProp.dependentProp && !dynamicProp.isMapperSelect && (
                      <div>
                        {/*<p class="formLabel fs-15 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                        <div class="display-flex">
                          <select disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) => props.saveFormInput(e, dynamicProp.name)} >
                            <option value=''>Select {dynamicProp.displayName}</option>
                            {
                              dynamicProp.dependentEnum[dynamicProp.dependentEnum.findIndex((d) => d.isSelected)].dependentEnums.map((enumVal) => (
                                <option selected={entityObject[dynamicProp.name] === enumVal} value={enumVal}>{enumVal}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                    )
                  }
                  {
                    (((dynamicProp.formType === "select" && (dynamicProp.multipleDependentEnum && dynamicProp.multipleDependentEnum.length !== 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (getFilteredList(dynamicProp.multipleDependentEnum).length === 1) && !dynamicProp.dependentProp) && !dynamicProp.isMapperSelect &&
                      <div>
                        {/*<p class="formLabel fs-15 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                        <div class="display-flex">
                          <select disabled={(dynamicProp.isDisabled || (entityObject.status && entityObject.status.toLowerCase() === "completed")) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) => props.saveFormInput(e, dynamicProp.name)} >
                            <option value=''>Select {dynamicProp.displayName}</option>
                            {
                              getFilteredList(dynamicProp.multipleDependentEnum).length > 0 && getFilteredList(dynamicProp.multipleDependentEnum)[0].dependentEnums.map((enumVal) => (
                                <option selected={entityObject[dynamicProp.name] === enumVal} value={enumVal}>{enumVal}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                    )
                  }
                  {/*
                    (((dynamicProp.formType !== "text" && dynamicProp.formType !== "number" && dynamicProp.formType !== "month")) && dynamicProp.formType !== "select") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))) && (
                      <p class="formLabel fs-15 p-b-3">{dynamicProp.displayName}<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                    )
                  }
                  {
                    (((dynamicProp.formType !== "text" && dynamicProp.formType !== "number" && dynamicProp.formType !== "month")) && dynamicProp.formType !== "select" && dynamicProp.dependentProp && !dynamicProp.dependentPropValue && ((dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item))))) && (
                      <p class="formLabel fs-15 p-b-3">{dynamicProp.displayName}<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                    )
                  */}
                  {
                    dynamicProp.formType === "file" && dynamicProp.dependentProp  && dynamicProp.dependentPropValue && (!entityObject[dynamicProp.name] || (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] === "NA")) && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))) && (
                      <div>
                        <input class="uploadInputFile" autocomplete="off" required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}`} id={`${dynamicProp.name}`} type={dynamicProp.formType} accept="image/*,.pdf" onChange={(e) => props.uploadFile(e, dynamicProp.name)}/>
                        <label class="p-t-b-5" for={`${dynamicProp.name}`}>
                          <figure>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="24" width="24"><path d="M11.007,2.578,11,18.016a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l.007-15.421,2.912,2.913a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L14.122.879a3,3,0,0,0-4.244,0L6.667,4.091a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0Z" /><path d="M22,17v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17a1,1,0,0,0-1-1h0A1,1,0,0,0,22,17Z" /></svg>
                          </figure>
                          <span>Choose a file</span>
                        </label>
                      </div>
                    )
                  }
                  {
                    dynamicProp.formType === "file" && dynamicProp.dependentProp  && dynamicProp.dependentPropValue && (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] !== "NA") && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))) && (
                      <div class="uploaded-image">
                        <button type="button" id={`image-0-${propIndex}`} class="primary-button cursor-pointer object-fit-contain w-full" onClick={(e)=> getReferenceFilesForFileViewer(e, 'Uploaded Files', true, dynamicProp.name, entityObject.uuid)}>View Uploaded File</button>
                        <p class="delete-file-icon" title="Delete file" onClick={(e) => props.deleteUploadedFile(e, dynamicProp.name)}>{'x'}</p>
                      </div>
                    )
                  }
                  {
                    dynamicProp.formType === "file" && dynamicProp.dependentProp  && !dynamicProp.dependentPropValue && ((entityObject[dynamicProp.name] && entityObject[dynamicProp.name] === "NA") || !entityObject[dynamicProp.name]) && ((dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item)))) && (
                      <div>
                        <input class="uploadInputFile" autocomplete="off" required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} accept="image/*,.pdf" onChange={(e) => props.uploadFile(e, dynamicProp.name)}/>
                        <label class="p-t-b-5" for={`${dynamicProp.name}`}>
                          <figure>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="24" width="24"><path d="M11.007,2.578,11,18.016a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l.007-15.421,2.912,2.913a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L14.122.879a3,3,0,0,0-4.244,0L6.667,4.091a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0Z" /><path d="M22,17v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17a1,1,0,0,0-1-1h0A1,1,0,0,0,22,17Z" /></svg>
                          </figure>
                          <span>Choose a file</span>
                        </label>
                      </div>
                    )
                  }

                  {
                    dynamicProp.formType === "file" && dynamicProp.dependentProp  && !dynamicProp.dependentPropValue && (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] !== "NA") && ((dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item)))) && (
                      <div class="uploaded-image">
                        <button type="button" id={`image-0-${propIndex}`} class="primary-button cursor-pointer object-fit-contain w-full" onClick={(e)=> getReferenceFilesForFileViewer(e, 'Uploaded Files', true, dynamicProp.name, entityObject.uuid)}>View Uploaded File</button>
                        <p class="delete-file-icon" title="Delete file" onClick={(e) => props.deleteUploadedFile(e, dynamicProp.name)}>{'x'}</p>
                      </div>
                    )
                  }
                  {
                    (dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && !dynamicProp.dependentPropObjectKey && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue))) && (
                      <div>
                        {/*<p class="formLabel fs-15 p-b-3">{dynamicProp.displayName}<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                        <input autocomplete="off" class=""  disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} onClick={(e) => props.inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} checked={entityObject[dynamicProp.name] ? true : false} value={entityObject[dynamicProp.name] ? entityObject[dynamicProp.name] : ''} onInput={(e) => props.setFormValueInput(e, dynamicProp.name)}  onFocusout={(e) => props.saveFormInput(e, dynamicProp.name)} />
                      </div>
                    )
                  }
                  {
                    ((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && dynamicProp.dependentPropObjectKey && ((typeof entityObject[dynamicProp.dependentProp] === 'object') && entityObject[dynamicProp.dependentProp][dynamicProp.dependentPropObjectKey] === dynamicProp.dependentPropValue) || (Array.isArray(entityObject[dynamicProp.dependentProp]) && entityObject[dynamicProp.dependentProp].find((obj) => { return obj[dynamicProp.dependentPropObjectKey] === dynamicProp.dependentPropValue})) ) && (
                      <div>
                        <input autocomplete="off" class=""  disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} onClick={(e) => inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} checked={entityObject[dynamicProp.name] ? true : false} value={entityObject[dynamicProp.name] ? entityObject[dynamicProp.name] : ''} onInput={(e) => setFormValueInput(e, dynamicProp.name)}  onFocusout={(e) => saveFormInput(e, dynamicProp.name)} />
                      </div>
                    )
                  }
                  {
                    (dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) && (
                      <div>
                        {/*<p class="formLabel fs-15 p-b-3">{dynamicProp.displayName}<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                        <input autocomplete="off" class=""  disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} onClick={(e) => props.inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} checked={entityObject[dynamicProp.name] ? true : false} value={entityObject[dynamicProp.name] ? entityObject[dynamicProp.name] : ''} onInput={(e) => props.setFormValueInput(e, dynamicProp.name)}  onFocusout={(e) => props.saveFormInput(e, dynamicProp.name)} />
                      </div>
                    )
                  }
                  {
                    (dynamicProp.formType === "date" || dynamicProp.formType === "time") && dynamicProp.dependentProp && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue)) || (dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])))) && (
                      <div>
                        {/*<p class="formLabel fs-15 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                          By: Yashvi
                          On: 29th May 2024
                          T208: BG_FN: Correction - Old Date of Birth Owner and Old Nominee Date of Birth
                          Added disabled attribute
                        */}
                        <input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} maxlength={dynamicProp.max && dynamicProp.max} value={moment(new Date(entityObject[dynamicProp.name])).format('YYYY-MM-DD') || ''} onChange={(e) => props.saveFormInput(e, dynamicProp.name)} max={(dynamicProp.typeOfValidation && dynamicProp.typeOfValidation === 'futureDateDisabled') && futureDateDisabled}/>
                      </div>
                    )
                  }
                  {
                    (dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && dynamicProp.dependentProp && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue))) && (
                      <div class="display-flex p-r-10 align-center">
                        {/*
                          Modification: PH_TN_T1439 Fixed chrome radio button selection issue
                          By: Devang
                          Date: 24/01/2024
                        */}
                        {/*<input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-yes`} id={`${dynamicProp.name}-yes`} type={dynamicProp.formType} checked={(entityObject[dynamicProp.name] === "yes" || entityObject[dynamicProp.name] === true) ? true : false} value="yes" onChange={(e) => props.saveFormInputRadioDefault(e, dynamicProp.name, dynamicProp.typeofField)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-yes`}>Yes </label>
                        <input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-no`} id={`${dynamicProp.name}-no`} type={dynamicProp.formType} checked={(entityObject[dynamicProp.name] === "no" || entityObject[dynamicProp.name] === false) ? true : false} value="no" onChange={(e) => props.saveFormInputRadioDefault(e, dynamicProp.name, dynamicProp.typeofField)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-no`}>No </label>*/}
                        {["Yes", "No"].map((enumVal) => (
                          <Fragment>
                            <input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-${propIndex}`} id={`${dynamicProp.name}-${propIndex}-${enumVal.toLowerCase()}`} type={dynamicProp.formType} defaultChecked={(entityObject[dynamicProp.name] === enumVal.toLowerCase() || entityObject[dynamicProp.name] === getRadioValBoolean(enumVal)) ? true : false} value={enumVal.toLowerCase()} onChange={(e) => props.saveFormInputRadio(e, dynamicProp.name, enumVal.toLowerCase())} />
                            <label class='p-l-10 p-r-10' style='font-weight: 300;' for={`${dynamicProp.name}-${propIndex}-${enumVal.toLowerCase()}`}>{enumVal}</label>
                          </Fragment>
                        ))}
                      </div>
                    )
                  }
                  {
                    ((dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp]))) && (
                      <div class="display-flex p-r-10 align-center">
                        {/*
                          Modification: PH_TN_T1439 Fixed chrome radio button selection issue
                          By: Devang
                          Date: 24/01/2024
                        */}
                        {/*<input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-yes`} id={`${dynamicProp.name}-yes`} type={dynamicProp.formType} checked={(entityObject[dynamicProp.name] === "yes" || entityObject[dynamicProp.name] === true) ? true : false} value="yes" onChange={(e) => props.saveFormInputRadioDefault(e, dynamicProp.name, dynamicProp.typeofField)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-yes`}>Yes </label>
                        <input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-no`} id={`${dynamicProp.name}-no`} type={dynamicProp.formType} checked={(entityObject[dynamicProp.name] === "no" || entityObject[dynamicProp.name] === false) ? true : false} value="no" onChange={(e) => props.saveFormInputRadioDefault(e, dynamicProp.name, dynamicProp.typeofField)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-no`}>No </label>*/}
                        {["Yes", "No"].map((enumVal) => (
                          <Fragment>
                            <input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-${propIndex}`} id={`${dynamicProp.name}-${propIndex}-${enumVal.toLowerCase()}`} type={dynamicProp.formType} defaultChecked={(entityObject[dynamicProp.name] === enumVal.toLowerCase() || entityObject[dynamicProp.name] === getRadioValBoolean(enumVal)) ? true : false} value={enumVal.toLowerCase()} onChange={(e) => props.saveFormInputRadio(e, dynamicProp.name, enumVal.toLowerCase())} />
                            <label class='p-l-10 p-r-10' style='font-weight: 300;' for={`${dynamicProp.name}-${propIndex}-${enumVal.toLowerCase()}`}>{enumVal}</label>
                          </Fragment>
                        ))}
                      </div>
                    )
                  }
                  {
                    (dynamicProp.formType === "radio" && (dynamicProp.enum || dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValue && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue))) && (
                      <div class="display-flex formRadioButtons">
                        {/*
                          Modification: PH_TN_T1439 Fixed chrome radio button selection issue
                          By: Devang
                          Date: 24/01/2024
                        */}
                        {
                          dynamicProp.enum.map((enumVal) => (
                            <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} name={`${dynamicProp.name}-${enumVal}`} id={`${dynamicProp.name}-${enumVal}`} type={dynamicProp.formType} defaultChecked={entityObject[dynamicProp.name] === enumVal} value={enumVal} onChange={(e) => props.saveFormInputRadio(e, dynamicProp.name, enumVal)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${enumVal}`}>{enumVal}</label></div>
                          ))
                        }
                      </div>
                    )
                  }
                  {
                    (dynamicProp.name === 'dynamicProperties_noOfDoublePayments') && (entityObject[dynamicProp.name] && entityObject[dynamicProp.name].length > 0) && Array(Number(entityObject[dynamicProp.name])).fill(1).map((item, index) => ((
                      <div class="input-box m-t-10" id={`${dynamicProp.name}-input-box`}>
                        <div>
                          <p class="formLabel fs-15 p-b-3">Wrong Transaction ID {index + 1} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                          <div class="display-flex">
                            <input autocomplete="off" class="" onClick={(e) => props.inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={`dynamicProperties_wrongTransactionID${index + 1}`} id={`dynamicProperties_wrongTransactionID${index + 1}`} type="text" checked={entityObject[`dynamicProperties_wrongTransactionID${index + 1}`] ? true : false} value={entityObject[`dynamicProperties_wrongTransactionID${index + 1}`] ? entityObject[`dynamicProperties_wrongTransactionID${index + 1}`] : ''} onInput={(e) => props.setFormValueInput(e, `dynamicProperties_wrongTransactionID${index + 1}`)}  onFocusout={(e) => props.saveFormInput(e, `dynamicProperties_wrongTransactionID${index + 1}`)} />
                          </div>
                        </div>
                        <div>
                          <p class="formLabel fs-15 p-b-3">Wrong Transaction Amount {index + 1} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                          <div class="display-flex">
                            <input autocomplete="off" class="" onClick={(e) => props.inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={`dynamicProperties_wrongTransactionAmount${index + 1}`} id={`dynamicProperties_wrongTransactionAmount${index + 1}`} type="number" checked={entityObject[`dynamicProperties_wrongTransactionAmount${index + 1}`] ? true : false} value={entityObject[`dynamicProperties_wrongTransactionAmount${index + 1}`] ? entityObject[`dynamicProperties_wrongTransactionAmount${index + 1}`] : ''} onInput={(e) => props.setFormValueInput(e, `dynamicProperties_wrongTransactionAmount${index + 1}`)}  onFocusout={(e) => props.saveFormInput(e, `dynamicProperties_wrongTransactionAmount${index + 1}`)} />
                          </div>
                        </div>
                        <div>
                          <p class="formLabel fs-15 p-b-3">Wrong Transaction Date {index + 1} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                          <div class="display-flex">
                            <input autocomplete="off" class="" onClick={(e) => props.inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={`dynamicProperties_wrongTransactionDate${index + 1}`} id={`dynamicProperties_wrongTransactionDate${index + 1}`} type="date" checked={entityObject[`dynamicProperties_wrongTransactionDate${index + 1}`] ? true : false} value={entityObject[`dynamicProperties_wrongTransactionDate${index + 1}`] ? entityObject[`dynamicProperties_wrongTransactionDate${index + 1}`] : ''} onInput={(e) => props.setFormValueInput(e, `dynamicProperties_wrongTransactionDate${index + 1}`)}  onFocusout={(e) => props.saveFormInput(e, `dynamicProperties_wrongTransactionDate${index + 1}`)} />
                          </div>
                        </div>
                      </div>
                    )))
                  }
                  {/*
                    modified : Ashutosh G
                    modified : 22/12/2023
                    modification : if dBFetchFields having dynamicProp.name then rendering below field
                  */}
                  {/*
                    (dBFetchFields.includes(dynamicProp.name) && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (
                      <div>
                        <select disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) =>  saveFormInput(e, dynamicProp.name, "select", 0)} >
                          <option value=''>{`Select ${dynamicProp.displayName}`}</option>
                          {
                            employeeList && employeeList.length > 0 && employeeList.map((enumVal) => (
                              <option selected={entityObject.hasOwnProperty(dynamicProp.uuid) ? (entityObject[dynamicProp.uuid] === enumVal.uuid) : (entityObject["dynamicProperties_" + dynamicProp.uuid] === enumVal.uuid)} value={enumVal.uuid}>{enumVal.displayName}</option>
                            ))
                          }
                        </select>
                      </div>
                    )
                  */}
                  {/*
                    Modification: PH_TN_T1439 Added condition and render for dynamic DB select
                    By: Devang
                    Date: 26/01/2024
                  */}
                  {
                    /*dynamicProp.formType === "select" && dynamicProp.isMapperSelect &&*/
                    dynamicProp.formType === "select" && ((!dynamicProp.dependentProp && !dynamicProp.dependentPropValue) || ((dynamicProp.dependentProp && dynamicProp.dependentPropValue) && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))))) && dynamicProp.isMapperSelect && (
                      <MapperSelect
                        allDynamicProps={dynamicProps}
                        dynamicProp={dynamicProp}
                        entityObject={entityObject}
                        saveFormInput={props.saveFormInput}
                        isDisabled={(dynamicProp.isDisabled || !isFormEditable || (entityObject.status && entityObject.status.toLowerCase() === "completed")) ? true: false}
                        propIndex={propIndex}
                      />
                    )
                  }
                  {/*
                    Modification: Added for conditions in configuration rule
                    By: komal wable
                    Date: 9/12/2022
                  */}
                  {
                    (dynamicProp.name === 'noOfConditions') && (dynamicProp.formName === 'ConfigurationRule') && (entityObject[dynamicProp.name] && entityObject[dynamicProp.name].length > 0) && Array(Number(entityObject[dynamicProp.name])).fill(1).map((item, index) => ((
                      <div class="input-box m-t-10" id={`${dynamicProp.name}-input-box`}>
                        <div>
                          <p class="formLabel fs-15 p-b-3">Triggered By Field{index + 1} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                          <div class="display-flex">
                            <input autocomplete="off" class="" onClick={(e) => props.inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={`triggeredByField${index + 1}`} id={`triggeredByField${index + 1}`} type="text" checked={entityObject[`triggeredByField${index + 1}`] ? true : false} value={entityObject[`triggeredByField${index + 1}`] ? entityObject[`triggeredByField${index + 1}`] : ''} onInput={(e) => props.setFormValueInput(e, `triggeredByField${index + 1}`)}  onFocusout={(e) => props.saveFormInput(e, `triggeredByField${index + 1}`)} />
                          </div>
                        </div>
                        <div>
                          <p class="formLabel fs-15 p-b-3">Triggered By Field Value {index + 1} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                          <div class="display-flex">
                            <input autocomplete="off" class="" onClick={(e) => props.inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={`triggeredByFieldValue${index + 1}`} id={`triggeredByFieldValue${index + 1}`} type="text" checked={entityObject[`triggeredByFieldValue${index + 1}`] ? true : false} value={entityObject[`triggeredByFieldValue${index + 1}`] ? entityObject[`triggeredByFieldValue${index + 1}`] : ''} onInput={(e) => props.setFormValueInput(e, `triggeredByFieldValue${index + 1}`)}  onFocusout={(e) => props.saveFormInput(e, `triggeredByFieldValue${index + 1}`)} />
                          </div>
                        </div>
                        <div>
                          <p class="formLabel fs-15 p-b-3">Operator{index + 1} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                          <div class="display-flex">
                            <input autocomplete="off" class="" onClick={(e) => props.inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={`operator${index + 1}`} id={`operator${index + 1}`} type="text" checked={entityObject[`operator${index + 1}`] ? true : false} value={entityObject[`operator${index + 1}`] ? entityObject[`operator${index + 1}`] : ''} onInput={(e) => props.setFormValueInput(e, `operator${index + 1}`)}  onFocusout={(e) => props.saveFormInput(e, `operator${index + 1}`)} />
                          </div>
                        </div>
                      </div>
                    )))
                  }
                  {
                    (dynamicProp.formType === "radio" && (dynamicProp.enum || dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) && (
                      <div class="display-flex formRadioButtons">
                        {/*
                          Modification: PH_TN_T1439 Fixed chrome radio button selection issue
                          By: Devang
                          Date: 24/01/2024
                        */}
                        {
                          dynamicProp.enum.map((enumVal) => (
                            <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} name={`${dynamicProp.name}-${enumVal}`} id={`${dynamicProp.name}-${enumVal}`} type={dynamicProp.formType} defaultChecked={entityObject[dynamicProp.name] === enumVal} value={enumVal} onChange={(e) => props.saveFormInputRadio(e, dynamicProp.name, enumVal)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${enumVal}`}>{enumVal}</label></div>
                          ))
                        }
                      </div>
                    )
                  }

                  {
                    (!dynamicProp.dependentProp || (dynamicProp.dependentProp && !dynamicProp.dependentPropObjectKey && ((entityObject['dynamicProperties_'+dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[''+dynamicProp.dependentProp] && entityObject[''+dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))))) && (
                      <span id={`error-`+dynamicProp.name} class="fs-10" >{dynamicProp.description}</span>
                    )
                  }
                  {
                    (entityObject.errorFields && entityObject.errorFields.length > 0 && (entityObject.errorFields.findIndex(item => item.fieldName === dynamicProp.name) > -1)) ? (
                      <p class="fs-10 text-color-red" >{entityObject.errorFields.find(item => item.fieldName === dynamicProp.name).errorMessage}</p>
                    ) : (
                      <p class="fs-10 text-color-red" style="visibility: hidden;">Hidden Error</p>
                    )
                  }

                  {
                    dynamicProp.isAmount && entityObject[dynamicProp.name] &&
                    <span id={`amount-`+dynamicProp.name} class="fs-10 display-block" style="color:#4949ed;">{`INR ${inWords(entityObject[dynamicProp.name])}`}</span>
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>
    );
  }

  const stepClick = (actionIndex, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation && e.stopPropagation();
    }
    setSelectedActionIndex && setSelectedActionIndex(actionIndex);
    checkForActionPendingProps && checkForActionPendingProps();
  }

  return (
    <NewPopupModal classes="formModal" modalWidth={"65%"} modalDisplay={('show-formPopover overflow-hidden')} onClose={(e) => toggleFormPopover(e)} backgroundColor={backgroundColor ? backgroundColor: ""} childrenClasses="overflow-hidden">
      <div class="enquiryForm background-transparent h-full">
        <div class="row p-t-10 p-l-30 p-r-30 p-b-10" style="background:#f7f7f7;border-bottom:1px solid lightgrey">
          <div class="col-xs-9 col-sm-10">
            <span class="formHeader display-flex">
              <h4 class="m-t-5 m-b-0">{operation} {((operation !== 'CNG' && operation !== 'Cancellation' && operation !== 'Correction' && operation !== 'Accessories') ? ((operation.split(' ').length === 1) && entityName) : '')}</h4>
            </span>
          </div>
          <div class="col-xs-3 col-sm-2 display-flex justify-flex-end align-center p-r-2">
            {/*
                Changed by Rutuja
                Changed on 24//2/23
                There should be an edit button on the endorsement form, on clicking which, the auto populated fields will become editable  [CODE CHANGE]
            */}
            {
              !isFormEditable && !isFormSubmitted && (
                <button onClick={(e) => toggleEditForm(e)} class="primary-button m-l-10 min-h-45">Edit Form</button>
              )
            }
            {
              isFormEditable && (
                <button onClick={(e) => submitForm(e)} class="primary-button m-l-10 min-h-45" disabled={((entityObject.errorFields && entityObject.errorFields.length > 0) || isFormSubmitted)} title={(entityObject.errorFields && entityObject.errorFields.length > 0) ? 'Oops! Seems like you have missed one or more items.' : ''}>{operation === 'Create' ? 'Create' :  'Update'}</button>
              )
            }
            <button onClick={(e) => toggleFormPopover(e)} class="secondary-button m-l-10 min-h-45">Cancel</button>
          </div>
        </div>

        <div class="h-full">
          {/*
            <LinearProgress progress={progress} />
              Changed by Rutuja
              Changed on 24//2/23
              There should be an edit button on the endorsement form, on clicking which, the auto populated fields will become editable  [CODE CHANGE]
          */}
          {/*
            Modification: PH_TN_T1439 Added condition if actions are fetched
            By: Devang
            Date: 24/01/2024
          */}
          <form class="h-full pos-relative">
          {
            /*
              modified by Manohar Sule
              modified at 28/05/2024
              bug No T279 - BG_FN - Form View Alignment Issue in Mobile View
              modification : set height to 60vh;
            */
          }
            {
              dynamicProps && (
                isActionsReceived ?
                  <ul class="stepper parallel horizontal h-full pos-static overflow-x-y no-scrollbar" style='margin:0 !important; overflow-y: hidden; height: 60vh !important'>
                    {dynamicProps.map((action, actionIndex) => (
                      <li
                        className={`step ${actionIndex === selectedActionIndex ? "active" : ""} ${(((action?.actionStatus === 'Error') || (action?.actionStatus === 'Pending' && action.isVisited)) && actionIndex !== selectedActionIndex) ? 'wrong' : (action?.actionStatus === 'Completed') ? 'done' : ''}`}
                        // style={((actions.length === 1 && !actions[0].displayName && actions[0]['dynamicProps'].length === 0) || actions.length === 0) && 'height: 0px !important;'}
                        onClick={() => stepClick(actionIndex)}
                      >

                      {
                        /*
                        	modified by Manohar Sule
                        	modified at 28/05/2024
                          bug No T129 - BG_FN - Inconsistent Tab Sizes
                        	modification : Add new min max width for form tabs;
                        */
                      }
                        <div class={`${actionIndex === selectedActionIndex && "step-active "} min-w-180px max-w-180px`}>
                          <div class="step-title waves-effect waves-dark">
                            {action.displayName}
                          </div>
                        </div>
                        { actionIndex === selectedActionIndex &&
                          <Fragment>
                            {renderProps(action.dynamicProperties)}
                            <div class={`${isMobileView ? 'p-l-16 p-r-16' : 'p-l-30 p-r-30'} noteDiv display-flex pos-absolute justify-between bgwhite left-0`} style={'bottom: 64px;'}>
                              <button class="secondary-button" style={`${!actionIndex && 'visibility: hidden;'}`} onClick={(e) => actionIndex && stepClick(actionIndex-1, e)}>Back</button>
                              {
                                selectedActionIndex !== dynamicProps.length - 1 &&
                                <button class="primary-button m-l-10" onClick={(e) => stepClick(actionIndex+1, e)}>Next</button>
                              }
                            </div>
                          </Fragment>
                        }
                      </li>
                    ))}
                  </ul>
                :
                renderProps(dynamicProps)
              )
            }
          </form>
        </div>
      </div>

      {isFileViewerOpen &&
        <FileViewer
          uploadBrowsedReferenceFile={props.uploadFile}
          closeFileViewer={closeFileViewer}
          toggleFileViewer={toggleFileViewer}
          isFileViewerOpen={isFileViewerOpen}
          uploadedfile={uploadedfile}
          fileViewerFor={fileViewerFor}
          contentDetails={{}}
          isDeleteHidden={true}
          isUploadHidden={true}
        />
      }
    </NewPopupModal>
  );
};

export default CreateEditForm;
