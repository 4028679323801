/*
  Modification: add Stuck svg
  By: Komal Wable
  Date: 19/04/2023
*/
import { h } from 'preact';

const Stuck = (props) => {
  return (
    <span class={props.masterClasses || ''} title={props.title || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" class={`${props.classes || ""}`} width={props.width || ""} height={props.width || ""} viewBox="0 0 4 19" fill="none">
        <path d="M2 1V12.6364" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2 17H2.1" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};

export default Stuck;
