import { h, Component } from 'preact';
import { useState, useEffect } from "preact/hooks";

const SingleMainRow = (props) => {
  const { masterClasses, classes, children } = props;

  return (
    <div class={`row m-t-16 ${classes || ''}`}>
      {children}
    </div>
  );
};

export default SingleMainRow;
