import { useState, useEffect, useRef } from 'preact/hooks';
import { setItem, getItem } from '../../lib/myStore';
import { formatDateTime, getFormattedAmount } from '../../lib/utils';
import LinearProgress from 'preact-material-components/LinearProgress';
import 'preact-material-components/LinearProgress/style.css';
import { NewPopupModal, NewPopupModalBody } from '../../components/newPopupModal';
import ListSingleCard from '../../components/listSingleCard';
import FileViewer from '../../components/fileViewer';
import moment from 'moment';
import axios from 'axios';
import CONSTANTS from '../../lib/constants';
import { callAPI, reportException, generateCancelTokenSource } from '../../lib/transceiver';

const FormWizard = (props) => {
  console.log("welllllllllll",props);
  const {
    isBlur,
    updateFormAPICancelToken
  } = props;
  let userInfo = getItem('userinfo');
  let [fileViewerFor, setFileViewerFor] = useState({
    for: 'case'
  });
  var futureDateDisabled = new Date();
  var dd = futureDateDisabled.getDate();
  var mm = futureDateDisabled.getMonth()+1; //January is 0!
  var yyyy = futureDateDisabled.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }

  futureDateDisabled = yyyy+'-'+mm+'-'+dd;
  let [isScrolledToTheBottom, setIsScrolledToTheBottom] = useState(props.isScrolledToTheBottom);
  let [cartNumber, setCartNumber] = useState(props.cartNumber);
  let [progress, setProgress] = useState(0);
  let [actions, setActions] = useState(props.actions);
  let [dynamicProps, setDynamicProps] = useState(props.dynamicProps);
  let [dynamicPropsData, setDynamicPropsData] = useState(props.dynamicPropsData);
  let [interactionObj, setInteraction] = useState(props.interactionObj);
  let [areaList, setAreaList] = useState(props.areaList);
  let [cityList, setCityList] = useState(props.cityList);
	let [stateList, setStateList] = useState(props.stateList);
  let [exchangEvaluators, setExchangeEvaluators] = useState(props.exchangEvaluators);
  let [financeExecutives, setFinanceExecutives] = useState(props.financeExecutives);
  let [offers, setOffers] = useState(props.offers);
  let [schemes, setSchemes] = useState(props.schemes);
  let [coupons, setCoupons] = useState([]);
  let [lastQuotation, setLastQuotation] = useState(props.lastQuotation);
  let [selectedModel, setSelectedModel] = useState(props.selectedModel);
  let [modelMapping, setModelMapping] = useState(props.modelMapping);
  let [listofManufacturers, setListOfManufacturers] = useState(props.listofManufacturers);
  let [catalogoueItemList, setCatalogoueItemList] = useState(props.catalogoueItemList);
  let [variantList, setVariantList] = useState(props.variantList);
  let [testDriveCars, setTestDriveCars] = useState(props.testDriveCars);
  let [cartProducts, setCartProducts] = useState(props.cartProducts);
  let [assignUserList, setAssignUserList] = useState(props.assignUserList);
  let [currentAction, setCurrentAction] = useState(props.currentAction);
  let [currentRow, setCurrentRow] = useState(props.currentRow);
  let [courierData, setCourierData] = useState(props.courierData);
  let [quotations, setQuotations] = useState(props.quotations);
  let [testDriveQueue, setTestDriveQueue] = useState(props.testDriveQueue);
  let [selectedProducts, setSelectedProducts] = useState(props.selectedProducts);
  let [selectedColor, setSelectedColor] = useState(props.selectedColor);
  let [availableStock, setAvailableStock] = useState(props.availableStock);
  let [allUserList, setAllUserList] = useState(props.allUserList);
  let [isHandoverDocket, setIsHandoverDocket] = useState(props.isHandoverDocket);
  let [isEditDocket, setIsEditDocket] = useState(props.isEditDocket);
  let [courierImgDelete, setCourierImgDelete] = useState(props.courierImgDelete);
  let [selectedActionIndex, setSelectedActionIndex] = useState(props.selectedActionIndex);
  let [isBackModalDisable, setIsBackModalDisable] = useState(props.isBackModalDisable);
  let [isNextModalDisable, setIsNextModalDisable] = useState(props.isNextModalDisable);
  let [isSaveModalDisable, setIsSaveModalDisable] = useState(props.isSaveModalDisable);
  let [isFormEditable, setIsFormEditable] = useState(props.isFormEditable);
  let [isUserClickedEdit, setUserClickedEdit] = useState(false);
  let [insuranceCompanies, setInsuranceCompanies] = useState(props.insuranceCompanies);
  let [selectedArrayForDynamicProps, setSelectedArrayForDynamicProps] = useState(props.selectedArrayForDynamicProps);
  let [isFileViewerOpen, setIsFileViewerOpen] = useState(false);
  let [uploadedfile, setUploadedfile] = useState([]);
  /*
    Modification: T319 - Commented local saving state and used form parent component
    By: Devang
    Date: 20/05/2024
  */
  // let [isDataSaving, setIsDataSaving] = useState(props.isDataSaving);

  useEffect(async () => {
    try {
      let insuranceCompaniesList = await axios.get(`${CONSTANTS.API_URL}/api/v1/thirdParty?type=insuranceCompany&dmsName=${(userInfo && userInfo.userDealershipID === "f8ff4333-1d4d-4ebb-8669-f6ce67620b85") ? 'Kia' : 'Honda'}`);
      if (insuranceCompaniesList.data && insuranceCompaniesList.data.length) {
       setInsuranceCompanies(insuranceCompaniesList.data);
      }
    } catch (HTTPException) {
      console.error(HTTPException);
      if (HTTPException && HTTPException.config && HTTPException.config.url) {
        let payload = {
          apiURL:HTTPException.config.url,
          errorObject:HTTPException,
          statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
          errorMessage:HTTPException.message,
          functionName:"useEffect",
          fileName:"formWizard",
          pageName: "formWizard",
          userID:userInfo.uuid,
          userName:userInfo.displayName
        };
        await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      }
      return HTTPException;
    }
  }, []);
  useEffect(async() => {
    setIsScrolledToTheBottom(props.isScrolledToTheBottom);
    setCartNumber(props.cartNumber);
    setProgress(progress);
    setActions(props.actions);
    setDynamicProps(props.dynamicProps);
    setDynamicPropsData(props.dynamicPropsData);
    setInteraction(props.interactionObj);
    setAreaList(props.areaList);
    setCityList(props.cityList);
    setStateList(props.stateList);
    setExchangeEvaluators(props.exchangEvaluators);
    setFinanceExecutives(props.financeExecutives);
    setOffers(props.offers);
    setSchemes(props.schemes);
    // setCoupons(props.coupons);
    setLastQuotation(props.lastQuotation);
    setSelectedModel(props.selectedModel);
    setModelMapping(props.modelMapping);
    setListOfManufacturers(props.listofManufacturers);
    setCatalogoueItemList(props.catalogoueItemList);
    setVariantList(props.variantList);
    setTestDriveCars(props.testDriveCars);
    setCartProducts(props.cartProducts);
    setAssignUserList(props.assignUserList);
    setCurrentAction(props.currentAction);
    setCurrentRow(props.currentRow);
    setCourierData(props.courierData);
    setTestDriveQueue(props.testDriveQueue);
    setSelectedProducts(props.selectedProducts);
    setSelectedColor(props.selectedColor);
    setAvailableStock(props.availableStock);
    setAllUserList(props.allUserList);
    setIsHandoverDocket(props.isHandoverDocket);
    setIsEditDocket(props.isEditDocket);
    setCourierImgDelete(props.courierImgDelete);
    setSelectedActionIndex(props.selectedActionIndex);
    setIsBackModalDisable(props.isBackModalDisable);
    setIsNextModalDisable(props.isNextModalDisable);
    setIsSaveModalDisable(props.isSaveModalDisable);
    setSelectedArrayForDynamicProps(props.selectedArrayForDynamicProps);
    setQuotations(props.quotations);
    /*
      Modification: T319 - Commented local saving state and used form parent component
      By: Devang
      Date: 20/05/2024
    */
    // setIsDataSaving(props.isDataSaving);
    console.log('updateeeeeeeeeeee', props);
  }, [props]);

  // useEffect(async() => {
  //   let element = await document.getElementById(`formModalContainer-${selectedActionIndex}`);
  //   if (element && ((element.offsetHeight + element.scrollTop) < element.scrollHeight)) {
  //     element.style.boxShadow = "inset 3px -14px 11px -10px rgb(0 0 0 / 20%)";
  //     await props.setIsScrolledToTheBottom(false);
  //   } else {
  //     await props.setIsScrolledToTheBottom(false);
  //   }
  //   async function checkScrolledBottom () {
  //     if((element.offsetHeight + element.scrollTop) >= element.scrollHeight) {
  //       await props.setIsScrolledToTheBottom(true)
  //       await props.setIsSaveModalDisable(false)
  //       await props.setIsNextModalDisable(false)
  //       await props.setIsBackModalDisable(false)
  //       element.style.boxShadow = "none";
  //    } else {
  //      await props.setIsScrolledToTheBottom(false)
  //      element.style.boxShadow = "inset 3px -14px 11px -10px rgb(0 0 0 / 20%)";
  //    }
  //   }
  //   if (element) element.addEventListener("scroll",checkScrolledBottom);
  //
  //   return () => {
  //     if (element) element.removeEventListener("scroll", checkScrolledBottom);
  //   }
  // },[props.currentAction, props.isFormEditable])

  async function formModalContainerOnScroll(element, index) {
    if (index || index === 0) {
      element = await document.getElementById(`formModalContainer-${index}`);
    }
    /*
      Bug: T335 - BG_UI: Mobile View - Draft Case - the initial view under tab is not displayed, need to scroll up
      Modification: Added condition to check if element exists as done conditional rendering
      By: Devang
      Date: 04/06/2024
    */
    let target = element && element.target ? element.target : element;
    if (target) {
      if((target.offsetHeight + target.scrollTop) >= target.scrollHeight) {
        target.style.boxShadow = "none";
     } else {
       target.style.boxShadow = "inset 3px -14px 11px -10px rgb(0 0 0 / 20%)";
     }
     let height = target.scrollHeight - target.clientHeight
     let scrolled = (target.scrollTop / height);
     setProgress(scrolled);
   }
  }

  async function submitFormOnStep(e, type) {
    e.preventDefault();
    /*
      Modification: T319 - Handled states before saving on step change
      By: Devang
      Date: 20/05/2024
    */
    props.handleSaveTaskOnStep && props.handleSaveTaskOnStep();

    try {
      props.setIsDataSaving && props.setIsDataSaving(true);
      let payload = currentRow;
      if (actions.length) payload['actionName'] = currentAction ? currentAction.displayName : '';
      let Response = await callAPI(`put`, `/v2/task/${currentRow.uuid}/updateForm`, payload, { cancelTokenSource: updateFormAPICancelToken.current });
      if (actions.length) {
        let actionsArr = actions;
        let newActionArr = Response.data.actions;
        await Promise.all(actionsArr.map(action => {
          let actionObj = newActionArr.find(actionObj => actionObj.displayName === action.displayName);
          action['actionStatus'] = actionObj.status;
          action['isVisited'] = actionObj.isVisited;
        }));
        await setActions(actionsArr);
      }
      if (Response.data.errorFields && Response.data.errorFields.length) {
        payload['errorFields'] = Response.data.errorFields;
        /*
          modified : Ashutosh G
          modified : 20/05/2024
          modification : response isVisited added on payload isVisited
        */
        payload['isVisited'] = Response.data.isVisited;
        await setCurrentRow(payload);
      } else {
        payload['errorFields'] = [];
        /*
          Modification: Added isVisited in else block
          By: Devang
          Date: 24/05/2024
        */
        payload['isVisited'] = Response.data.isVisited;
        await setCurrentRow(payload);
      }
      props.setIsDataSaving && props.setIsDataSaving(false);
      let newIndex = selectedActionIndex;
      (type === 'next') ? newIndex = newIndex + 1 : newIndex = newIndex - 1;
      await props.setSelectedActionIndex(newIndex);
      await props.setCurrentAction(actions[newIndex]);
      formModalContainerOnScroll('', newIndex);
    } catch (HTTPException) {
      console.error(HTTPException);
      if (HTTPException && HTTPException.config && HTTPException.config.url) {
        let payload = {
          apiURL:HTTPException.config.url,
          errorObject:HTTPException,
          statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
          errorMessage:HTTPException.message,
          functionName:"submitFormOnStep",
          fileName:"formWizard",
          pageName: "formWizard",
          userID:userInfo.uuid,
          userName:userInfo.displayName
        };
        await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      }
      return HTTPException;
    }
  }

  /*
    Bug: COMP_BG_543_Renewal FollowUp tasks is created without entering data in the mandatory fields.
    Modification : added condition in stepclick to check error field
    By: Masum Raja
    Date: 05/10/2023
  */

  async function stepClick(index) {
    /*
      Modification: T319 - Handled states before saving on step change
      By: Devang
      Date: 20/05/2024
    */
    props.handleSaveTaskOnStep && props.handleSaveTaskOnStep();

    props.setIsDataSaving && props.setIsDataSaving(true);
    let payload = currentRow;
    if (actions.length) payload['actionName'] = currentAction ? currentAction.displayName : '';
    let Response = await callAPI(`put`, `/v2/task/${currentRow.uuid}/updateForm`, payload, { cancelTokenSource: updateFormAPICancelToken.current });
    if (actions.length) {
      let actionsArr = actions;
      let newActionArr = Response.data.actions;
      await Promise.all(actionsArr.map(action => {
        let actionObj = newActionArr.find(actionObj => actionObj.displayName === action.displayName);
        action['actionStatus'] = actionObj.status;
        action['isVisited'] = actionObj.isVisited;
      }));
      await setActions(actionsArr);
    }
    if (Response.data.errorFields && Response.data.errorFields.length) {
      payload['errorFields'] = Response.data.errorFields;
      /*
        modified : Ashutosh G
        modified : 20/05/2024
        modification : response isVisited added on payload isVisited
      */
      payload['isVisited'] = Response.data.isVisited;
      await setCurrentRow(payload);
    } else {
      payload['errorFields'] = [];
      /*
        Modification: Added isVisited in else block
        By: Devang
        Date: 24/05/2024
      */
      payload['isVisited'] = Response.data.isVisited;
      await setCurrentRow(payload);
    }
    props.setIsDataSaving && props.setIsDataSaving(false);

    props.setSelectedActionIndex(index);
    props.setCurrentAction(actions[index]);
    formModalContainerOnScroll('', index);

    /*
      Bug: T335 - BG_UI: Mobile View - Draft Case - the initial view under tab is not displayed, need to scroll up
      Modification: Added condition to handle in-div scrolling to desired step
      By: Devang
      Date: 05/06/2024
    */
    if (window.innerWidth <= 991) {
      const scrollToElement = document.getElementById(`wizard-step-${index}`);
      const mainScroller = document.getElementById(`wizard-stepper`);

      setTimeout(() => {
        if (mainScroller && scrollToElement) {
          mainScroller.scrollTop = scrollToElement.offsetTop;
        }
      }, 200);
    }
  }
  function inWords (num) {
    let a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
    let b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];
    if ((num = num.toString()).length > 9) return 'overflow';
    let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
    return str;
  }

  /*
    By: Yashvi
    On: 20th July 2022
    Toggle file viewer along with close file viewer and get selected file functions added. Also replaced all the viewAllImages function calls with getReferenceFilesForFileViewer function call
  */
  async function toggleFileViewer() {
    setIsFileViewerOpen(!isFileViewerOpen);
  }

  async function closeFileViewer() {
    setIsFileViewerOpen(false);
  }

  async function getReferenceFilesForFileViewer(e, type, openViewer, fieldName, interactionID) {
    try {
      let fileViewerObj = [];
      let fileID = [interactionObj['dynamicProperties_'+fieldName]];
      if (type === 'Uploaded Files') {
        fileViewerObj.push({name: 'Uploaded Files', fileIDs: fileID});
      }
      let payload = {
        interactionID: interactionObj.uuid,
        s3KeyFor: 'case',
        fileViewerObj
      };
      fileViewerFor['interactionID'] = interactionID;
      let response = await axios.put(`${CONSTANTS.API_URL}/api/fileViewer`, payload);
      fileViewerFor.type = type;
      setFileViewerFor(fileViewerFor)
      if (response && response.data) {
        await setUploadedfile(response.data);
        if (openViewer) await setIsFileViewerOpen(true);
      }
    } catch (HTTPException) {
      console.error(HTTPException);
      if (HTTPException && HTTPException.config && HTTPException.config.url) {
        let payload = {
          apiURL:HTTPException.config.url,
          errorObject:HTTPException,
          statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
          errorMessage:HTTPException.message,
          functionName:"getReferenceFilesForFileViewer",
          fileName:"formWizard",
          pageName: "formWizard",
          userID:userInfo.uuid,
          userName:userInfo.displayName
        };
        await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      }
      return HTTPException;
    }
  }

  function getFilteredList(dependentEnum) {
    let filteredList = dependentEnum.filter(dependentEnumObj => {
      let filteredLength = dependentEnumObj.query.filter((d) => {
        return d.valueOfProp === interactionObj['dynamicProperties_' + d.nameOfProp]
      }).length
      if (filteredLength === dependentEnumObj.query.length) {
        return dependentEnumObj;
      }
    });
    return filteredList
  }
  /*
    Modified by: Arun Singh
    Modified on: 07/12/2023
    COMP_BG_710 & 709: The alignment of the "Create Case" form is incorrect & Vertical Scrollbar is missing for the Actionable tabs (Customer Details , Vehicle Details , Policy Details)
  */
  /*
    modified : Ashutosh G
    modified : 31/01/2024
    modification : overflow-y class removed
  */
  /*
    modified : Ashutosh G
    modified : 20/05/2024
    modification : ${isBlur ? 'blur-5' : ''} added in classes of NewPopupModal
  */
  return (
    <NewPopupModal isMobileView={props.isMobileView} classes={`formModal ${isBlur ? 'blur-5' : ''}`} modalWidth={"65%"} modalDisplay={('show-formPopover overflow-hidden')} onClose={(e) => props.toggleFormPopover(e)} backgroundColor={props.backgroundColor ? props.backgroundColor: ""}>
      <div class="enquiryForm background-transparent h-full">
        <div class="row p-t-10 p-l-30 p-r-30 p-b-10" style="background:#f7f7f7;border-bottom:1px solid lightgrey">
          <div class="col-xs-8 col-sm-10">
            <span class="formHeader display-flex">
              <h4 class="m-t-5 m-b-0">{currentRow && currentRow.displayName} </h4>
            </span>
            {currentRow && currentRow.caseID && (
              <div class="display-flex align flex-direction-column">
                <p class="fs-12">{currentRow.caseID} </p>
              </div>
            )}
          </div>
          {props.isDataSaving ?
            <div class="col-xs-4 col-sm-2 display-flex justify-flex-end align-center p-r-2">
              <img src="/assets/images/inProgress.png" class="w-14px h-14px m-r-5" />
              <span class="text-error">Saving...</span>
            </div>
            :
            <div class="col-xs-4 col-sm-2 display-flex justify-flex-end align-center p-r-2">
              <img src="/assets/images/finish-flag.png" class="w-14px h-14px m-r-5" />
              <span class="text-success">Saved</span><span class="visible-false">...</span>
            </div>
          }
          {/*<div class="col-xs-5 col-sm-2 display-flex justify-flex-end align-center p-r-2">
            {
              !props.isFormEditable && (
                <button onClick={(e) => props.setFormEditable(e)} class="primary-button">Edit Info</button>
              )
            }
            {
              props.isFormEditable && (
                <button onClick={(e) => props.setFormEditable(e)} class="primary-button">Close Edit Info</button>
              )
            }
            <button onClick={(e) => props.submitFormNew(e)} class="primary-button m-l-10 min-h-45" disabled={(currentRow.errorFields && currentRow.errorFields.length > 0)}>Mark Complete</button>
            <LinearProgress progress={progress} />
          </div>*/}
        </div>
        <div class="h-full">
          <form class="h-full mobile-form pos-relative">
            {/*
              Bug: COMP_BG_690 Backoffice Executive , Accounts Executive , Caller (Not case owner)	Honda / Kia - Renewal Insurance "Scenario 1 - Backoffice Executive , Accounts Executive , Caller - Coupon Discount Approval Task - SELECTED COUPONS Checklist
                    Scenario 2 - Accounts Executive , Caller - Buy Renewal Policy - UPLOAD PAYMENT PROOF , UPLOAD PAN , UPLOAD AADHAR , UPLOAD Driver's License
                    Scenario 3 - Accounts Executive , Backoffice Executive - Fill Renewed Policy Details - THIRD PARTY START DATE , THIRD PARTY END DATE , ATTACH POLICY PDF "	Mentioned fields are editable (Expected Result: Mentioned fields should not be editable for mentioned scenarios and roles)
              Modification: Added conditions of disabled on every inputs available
              By: Devang
              Date: 29/12/2023
            */}
            {/*
              modified : Ashutosh G
              modified : 20/05/2024
              modification : stepClick onclick function moved on step name div because invoking function when we click on anywhere in form
            */}
            {/*
              Bug: T335 - BG_UI: Mobile View - Draft Case - the initial view under tab is not displayed, need to scroll up
              Modification: Added id to ul and li tags to handle scrollTop in mobile
              By: Devang
              Date: 05/06/2024
            */}
            <ul id={`wizard-stepper`} class="stepper parallel horizontal h-full pos-static overflow-x-y no-scrollbar" style='margin:0 !important'>
            {selectedArrayForDynamicProps && selectedArrayForDynamicProps.map((action, actionIndex) => (
              <li id={`wizard-step-${actionIndex}`} className={`step ${actionIndex === selectedActionIndex ? "active" : ""} ${(((action.actionStatus === 'Error') || (action.actionStatus === 'Pending' && action.isVisited)) && actionIndex !== selectedActionIndex) ? 'wrong' : (action.actionStatus === 'Completed') ? 'done' : ''} ${((actions.length === 1 && !actions[0].displayName && actions[0]['dynamicProps'].length === 0) || actions.length === 0) && 'noactions-before'}`} style={((actions.length === 1 && !actions[0].displayName && actions[0]['dynamicProps'].length === 0) || actions.length === 0) && 'height: 0px !important;'} title={action.displayName}>
                {
                  /*
                    modified by Manohar Sule
                    modified at 28/05/2024
                    bug No T129 - BG_FN - Inconsistent Tab Sizes
                    modification : Add new min max width for form tabs;
                  */
                }
                <div class={`${actionIndex === selectedActionIndex && "step-active"} min-w-180px max-w-180px`}>
                  {(actions.length > 1 && actions[0].displayName && actions[0]['dynamicProps'].length > 0) && (
                    <div class="step-title waves-effect waves-dark" onClick={(e) => {(actions.length > 1 && actions[0].displayName && actions[0]['dynamicProps'].length > 0) && stepClick(actionIndex)}}>
                      {/*action.actionStatus && (
                        <span><svg fill="green" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="15" height="15"><path d="M22.319,4.431,8.5,18.249a1,1,0,0,1-1.417,0L1.739,12.9a1,1,0,0,0-1.417,0h0a1,1,0,0,0,0,1.417l5.346,5.345a3.008,3.008,0,0,0,4.25,0L23.736,5.847a1,1,0,0,0,0-1.416h0A1,1,0,0,0,22.319,4.431Z"/></svg></span>
                      )*/}
                      {action.displayName}
                    </div>
                  )}
                </div>
                {/*
                  Bug: T335 - BG_UI: Mobile View - Draft Case - the initial view under tab is not displayed, need to scroll up
                  Modification: Added conditional rendering on formModalContainer
                  By: Devang
                  Date: 04/06/2024
                */}
                {
                  actionIndex === selectedActionIndex &&
                  <div id={`formModalContainer-${actionIndex}`} style={`display: ${actionIndex === selectedActionIndex ? "block;" : "none;"} ${((actions.length === 1 && !actions[0].displayName && actions[0]['dynamicProps'].length === 0) || actions.length === 0) && 'top: 0px;'}`} class={`step-content formModalContainer ${((actions.length === 1 && !actions[0].displayName && actions[0]['dynamicProps'].length === 0) || actions.length === 0) && 'noactions'}`} >
                  {
                    ((actions.length === 1 && !actions[0].displayName && actions[0]['dynamicProps'].length === 0) || actions.length === 0) && (
                      <div class="w-full">
                        {/*
                          !props.isFormEditable && dynamicPropsData && Object.keys(dynamicPropsData).map((key) => (
                            <div class="col-xs-12 col-sm-6">
                              <ListSingleCard typeOfCard={'formCard'} group={key} groupProps={dynamicPropsData[key]} interactionObj={interactionObj} coupons={coupons} insuranceCompanies={insuranceCompanies}/>
                            </div>
                          ))
                        */}
                        {
                          (dynamicProps && dynamicProps.length !== 0) && dynamicProps.map((dynamicProp, propIndex) => (
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 m-t-15" style={`${((dynamicProp.formType !== "select" && (dynamicProp.name !== "licenseNumber") && dynamicProp.formType !== "radio" && dynamicProp.formType !== "checkbox" && !dynamicProp.dependentProp) ||
                            ((dynamicProp.formType === "radio" || dynamicProp.formType === "checkbox") && !dynamicProp.dependentProp) ||
                            (((dynamicProp.name !== "licenseNumber") && (dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && !dynamicProp.dependentProp)) ||
                            (dynamicProp.name === "licenseNumber") ||
                            ((dynamicProp.formType === "date" || dynamicProp.formType === "time") && !dynamicProp.dependentProp) ||
                            ((dynamicProp.formType === "file") && (!dynamicProp.dependentProp && (!interactionObj['dynamicProperties_'+dynamicProp.name] || (interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name] === "NA")))) ||
                            (dynamicProp.formType === "file" && !dynamicProp.dependentProp && (interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name] !== "NA")) ||
                            ((dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && !dynamicProp.dependentProp) ||
                            ((dynamicProp.formType === "radio" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp) ||
                            ((dynamicProp.formType === "checkbox" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp) ||
                            ((dynamicProp.formType === "checkbox" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && (dynamicProp.name === "negotiationMethod" || dynamicProp.name === "addOnCoverage") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] === dynamicProp.dependentPropValue)) ||
                            (((dynamicProp.name === "area" || dynamicProp.name === "companyArea") && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0))) ||
                            (((dynamicProp.name === "state") && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0))) ||
                            (((dynamicProp.name === "city") && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0))) ||
                            (((dynamicProp.name !== "finalMobile" && dynamicProp.name !== "interestedModel" && dynamicProp.name !== "testDriveApprovalRole" && dynamicProp.name !== "testDriveAssignedUser")  && dynamicProp.formType === "select" && (dynamicProp.dependentProp && dynamicProp.dependentPropValue) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) &&(interactionObj["dynamicProperties_"+dynamicProp.dependentProp] === dynamicProp.dependentPropValue)) ||
                            (((dynamicProp.name !== "finalMobile" && dynamicProp.name !== "interestedModel" && dynamicProp.name !== "testDriveApprovalRole" && dynamicProp.name !== "testDriveAssignedUser")  && dynamicProp.formType === "select" && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp) ||
                            (((dynamicProp.name !== "finalMobile" && dynamicProp.name !== "interestedModel" && dynamicProp.name !== "testDriveApprovalRole" && dynamicProp.name !== "testDriveAssignedUser")  && dynamicProp.formType === "select" && (dynamicProp.dependentEnum && dynamicProp.dependentEnum.length !== 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (dynamicProp.dependentEnum.findIndex((d) => d.valueOfProp === interactionObj["dynamicProperties_" + d.nameOfProp]) > -1) && !dynamicProp.dependentProp) ||
                            ((dynamicProp.formType === "select" && (dynamicProp.multipleDependentEnum && dynamicProp.multipleDependentEnum.length !== 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (getFilteredList(dynamicProp.multipleDependentEnum).length === 1) && !dynamicProp.dependentProp) ||
                            (dynamicProp.formType !== "select" && (dynamicProp.name !== "licenseNumber") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue)) ||
                            (dynamicProp.formType !== "select" && (dynamicProp.name !== "licenseNumber") && dynamicProp.dependentProp && !dynamicProp.dependentPropValue && (dynamicProp.dependentPropValues.includes(interactionObj["dynamicProperties_" + dynamicProp.dependentProp]))) ||
                            (dynamicProp.formType === "file" && dynamicProp.dependentProp  && dynamicProp.dependentPropValue && (!interactionObj['dynamicProperties_'+dynamicProp.name] || (interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name] === "NA")) && ((interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (interactionObj["dynamicProperties_" + dynamicProp.dependentProp] && interactionObj["dynamicProperties_" + dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) ||
                            (dynamicProp.formType === "file" && dynamicProp.dependentProp  && dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name] !== "NA") && ((interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (interactionObj["dynamicProperties_" + dynamicProp.dependentProp] && interactionObj["dynamicProperties_" + dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) ||
                            (dynamicProp.formType === "file" && dynamicProp.dependentProp  && !dynamicProp.dependentPropValue && ((interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name] === "NA") || !interactionObj['dynamicProperties_'+dynamicProp.name]) && ((dynamicProp.dependentPropValues.includes(interactionObj["dynamicProperties_" + dynamicProp.dependentProp])))) ||
                            (dynamicProp.formType === "file" && dynamicProp.dependentProp  && !dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name] !== "NA") && ((dynamicProp.dependentPropValues.includes(interactionObj["dynamicProperties_" + dynamicProp.dependentProp])))) ||
                            ((dynamicProp.name !== "licenseNumber") && (dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && ((interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] && interactionObj['dynamicProperties_'+dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue)))) ||
                            ((dynamicProp.name !== "licenseNumber") && (dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(interactionObj["dynamicProperties_" + dynamicProp.dependentProp]))) ||
                            ((dynamicProp.formType === "date" || dynamicProp.formType === "time") && dynamicProp.dependentProp && (interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue)) ||
                            ((dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && dynamicProp.dependentProp && ((interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] && interactionObj['dynamicProperties_'+dynamicProp.dependentProp].length > 0 && interactionObj['dynamicProperties_'+dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue))))) ||
                            ((dynamicProp.formType === "radio" && (dynamicProp.enum || dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue)) ||
                            ((dynamicProp.formType === "radio" && (dynamicProp.enum || dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(interactionObj["dynamicProperties_" + dynamicProp.dependentProp]))) ||
                            ((dynamicProp.name === "interestedModel" && dynamicProp.formType === "select")) ||
                            ((dynamicProp.name === "finalMobile" && dynamicProp.formType === "select")) ||
                            ((dynamicProp.name === "exchangeEvaluator" && dynamicProp.formType === "select")) ||
                            ((dynamicProp.name === "selectedScheme" && dynamicProp.formType === "select")) ||
                            ((currentRow.displayName !== "Coupon Approval Task" && dynamicProp.name === "selectedCouponID" && (currentRow['dynamicProperties_negotiationMethod'] && currentRow['dynamicProperties_negotiationMethod'].split(",").includes("Coupon"))  && dynamicProp.formType === "checkbox")) ||
                            ((currentRow.displayName === "Coupon Approval Task" && dynamicProp.name === "selectedCouponID" && dynamicProp.formType === "checkbox")) ||
                            ((dynamicProp.name === "selectedOffer" && dynamicProp.formType === "select")) ||
                            ((dynamicProp.name === "manufacturer" && dynamicProp.formType === "select")) ||
                            ((dynamicProp.name === "otherCars" && dynamicProp.formType === "select")) ||
                            ((dynamicProp.name === "selectedAgent" && dynamicProp.formType === "select")) ||
                            ((dynamicProp.name === "selectedModel" && (!dynamicProp.dependentProp || (dynamicProp.dependentProp && (interactionObj["dynamicProperties_"+dynamicProp.dependentProp] === dynamicProp.dependentPropValue))) && dynamicProp.formType === "select")) ||
                            ((dynamicProp.name === "selectedVariant" && dynamicProp.formType === "select")) ||
                            ((dynamicProp.name === "selectedTestDriveModel"  && (interactionObj["dynamicProperties_"+dynamicProp.dependentProp] === dynamicProp.dependentPropValue) && dynamicProp.formType === "select")) ||
                            ((dynamicProp.name === "selectedTestDriveVariant" && (interactionObj["dynamicProperties_"+dynamicProp.dependentProp] === dynamicProp.dependentPropValue) && dynamicProp.formType === "select")) ||
                            ((dynamicProp.name === "selectedTestDriveCarID" && (interactionObj["dynamicProperties_typeOfTestDriveCar"] === "Test Drive Car") && dynamicProp.formType === "select")) ||
                            ((dynamicProp.name === "lostCaseApprovalRole" && (currentRow['dynamicProperties_outcome'] === "Lost") && dynamicProp.formType === "select")) ||
                            ((dynamicProp.name === "lostCaseAssignedUser" && (currentRow['dynamicProperties_outcome'] === "Lost") && dynamicProp.formType === "select")) ||
                            (((dynamicProp.name === "thirdPartyInsuranceCompanyID" || dynamicProp.name === "accessoriesInsuranceCompanyID" || dynamicProp.name === "cngInsuranceCompanyID" || dynamicProp.name === "insuranceCompanyID" || dynamicProp.name === "insuranceCompanyID2" || dynamicProp.name === "insuranceCompanyRenewalID" || dynamicProp.name === "totalLossInsuranceCompany" || dynamicProp.name === "renewalInsuranceCompanyID" || dynamicProp.name === "insuranceCompanyIDNew") && dynamicProp.formType === "select") && ((!dynamicProp.dependentProp) || (dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] === dynamicProp.dependentPropValue)) || (dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] && interactionObj['dynamicProperties_'+dynamicProp.dependentProp].length > 0 && interactionObj['dynamicProperties_'+dynamicProp.dependentProp].includes(dynamicProp.dependentPropValue))))) ||
                            (((dynamicProp.name === "latestQuotationID") && dynamicProp.formType === "select") && ((!dynamicProp.dependentProp) || (dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] === dynamicProp.dependentPropValue)))) ||
                            ((currentAction.displayName === "Need Assessment" && (propIndex === action.dynamicProps.length - 1))) ||
                            ((dynamicProp.formType === "select" && dynamicProp.entityName && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && (!dynamicProp.dependentProp || (dynamicProp.dependentProp && (dynamicProp.dependentPropValue && ((interactionObj[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (interactionObj[dynamicProp.dependentProp] && interactionObj[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) || (dynamicProp.dependentPropValues && ((dynamicProp.dependentPropValues.includes(interactionObj[dynamicProp.dependentProp])) || (interactionObj[dynamicProp.dependentProp] && interactionObj[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item))))))))) ?  '' : 'display: none;'}`}>
                              <div class={`input-box white-space-pre-wrap ${(currentRow.errorFields && currentRow.errorFields.length > 0 && (currentRow.errorFields.findIndex(item => item.fieldName === dynamicProp.name) > -1)) && 'error-div'}`} id={`${dynamicProp.name}-input-box`}>
                                <p class="formLabel fs-13 p-b-3 ">{dynamicProp.displayName}
                                {/*
                                  By: Yashvi
                                  On: 29th May 2024
                                  T237: BG_FN - Checkbox Deselection Does Not Remove Green Tick
                                  If value is undefined consider it as empty
                                */}
                                  <span style={(interactionObj["dynamicProperties_" + dynamicProp.name] && (interactionObj["dynamicProperties_" + dynamicProp.name] !== 'undefined')) ? "display: none": "display:inline"}  id={`required-${actionIndex}-`+dynamicProp.name} class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span>
                                  <span style={(interactionObj["dynamicProperties_" + dynamicProp.name] && (interactionObj["dynamicProperties_" + dynamicProp.name] !== 'undefined')) ? "display: inline": "display:none"} id={`success-${actionIndex}-`+dynamicProp.name}> <svg fill="green" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="15" height="15"><path d="M22.319,4.431,8.5,18.249a1,1,0,0,1-1.417,0L1.739,12.9a1,1,0,0,0-1.417,0h0a1,1,0,0,0,0,1.417l5.346,5.345a3.008,3.008,0,0,0,4.25,0L23.736,5.847a1,1,0,0,0,0-1.416h0A1,1,0,0,0,22.319,4.431Z"/></svg></span>
                                </p>
                                {/*
                                  dynamicProp.formType !== "select" && (dynamicProp.name !== "licenseNumber") && dynamicProp.formType !== "radio" && dynamicProp.formType !== "checkbox" && !dynamicProp.dependentProp && (
                                    <p class="formLabel fs-13 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                  )
                                }
                                {
                                  (dynamicProp.name !== "licenseNumber") && (dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month" || dynamicProp.formType === "radio" || dynamicProp.formType === "file" || dynamicProp.formType === "checkbox") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && ((interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] && interactionObj['dynamicProperties_'+dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue)) &&
                                    <p class="formLabel fs-13 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                  )
                                }
                                {
                                  (dynamicProp.formType === "radio" || dynamicProp.formType === "file" || dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "checkbox") && dynamicProp.dependentProp && (
                                    <p class="formLabel fs-13 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                  )
                                }
                                {
                                  (dynamicProp.formType === "radio" || dynamicProp.formType === "checkbox") && !dynamicProp.dependentProp && (
                                    <p class="formLabel fs-13 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                  )
                                */}
                                {/*
                                  Modification: T319 - Commented onFocusout function
                                  By: Ashutosh G
                                  Date: 23/05/2024
                                */}
                                {
                                  ((dynamicProp.name !== "licenseNumber") && (dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && !dynamicProp.dependentProp) && (
                                    <input autocomplete="off" maxlength={dynamicProp.max && dynamicProp.max} class="first-letter-capital" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} onClick={(e) => props.inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} checked={interactionObj["dynamicProperties_"+dynamicProp.name] ? true : false} value={interactionObj["dynamicProperties_" + dynamicProp.name] ? interactionObj["dynamicProperties_" + dynamicProp.name] : ''} onInput={(e) => props.setFormValueInput(e, dynamicProp.name, actionIndex)}
                                    // onFocusout={(e) => props.saveFormInput(e, dynamicProp.name)}
                                    />
                                  )
                                }
                                {
                                  ((dynamicProp.formType === "select" && dynamicProp.entityName && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && (!dynamicProp.dependentProp || (dynamicProp.dependentProp && (dynamicProp.dependentPropValue && ((interactionObj[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (interactionObj[dynamicProp.dependentProp] && interactionObj[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) || (dynamicProp.dependentPropValues && ((dynamicProp.dependentPropValues.includes(interactionObj[dynamicProp.dependentProp])) || (interactionObj[dynamicProp.dependentProp] && interactionObj[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item)))))))) && (
                                    <div>
                                      {/*<p class="formLabel fs-13 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                                      <div class="display-flex">
                                        <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) => props.saveFormInput(e, dynamicProp.name, "select", 0)} >
                                          <option value=''>Select {dynamicProp.displayName}</option>
                                          {
                                            dynamicProp.enum.map((item) => (
                                              <option selected={interactionObj[dynamicProp.name] === (dynamicProp.enumIDKey ? item[dynamicProp.enumIDKey] : item.uuid)} value={(dynamicProp.enumIDKey ? item[dynamicProp.enumIDKey] : item.uuid)}>{item[dynamicProp.enumDisplayKey]}</option>
                                            ))
                                          }
                                        </select>
                                      </div>
                                    </div>
                                  )
                                }
                                {
                                  dynamicProp.name === "licenseNumber" && (
                                    <div>
                                      {/*<p class="formLabel fs-13 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                                      <input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} onClick={(e) => props.inputClicked(e)} maxlength="15" required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} value={interactionObj['dynamicProperties_'+dynamicProp.name]} onInput={(e) => props.setFormValueInput(e, dynamicProp.name, actionIndex)} />
                                    </div>
                                  )
                                }
                                {/*
                                  Modification: T319 - Commented onFocusout function
                                  By: Ashutosh G
                                  Date: 23/05/2024
                                */}
                                {
                                  (dynamicProp.formType === "date" || dynamicProp.formType === "time") && !dynamicProp.dependentProp && (
                                    <input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} value={moment(new Date(interactionObj["dynamicProperties_" + dynamicProp.name])).format('YYYY-MM-DD') || moment(new Date()).format('YYYY-MM-DD')} onInput={(e) => props.setFormValueInput(e, dynamicProp.name, actionIndex)}
                                    max={(dynamicProp.typeOfValidation && dynamicProp.typeOfValidation === 'futureDateDisabled') && futureDateDisabled}
                                    // onFocusout={(e) => props.saveFormInput(e, dynamicProp.name)}
                                    />
                                  )
                                }
                                {/*
                                   modified by Vihang
                                   modified at 17 June 2022
                                   modification: styling changed for input type file for all the fields
                                */}
                                {
                                  (dynamicProp.formType === "file") && (!dynamicProp.dependentProp && (!interactionObj['dynamicProperties_'+dynamicProp.name] || (interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name] === "NA"))) && (
                                   <div>
                                      <input class="uploadInputFile" autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-${actionIndex}`} id={`${dynamicProp.name}-${actionIndex}`} type={dynamicProp.formType} value={interactionObj["dynamicProperties_" + dynamicProp.name]} accept="image/*,.pdf" onChange={(e) => props.uploadFile(e, dynamicProp.name)}/>
                                      <label class="p-t-b-5" for={`${dynamicProp.name}-${actionIndex}`}>
                                        <figure>
                                          <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="24" height="24"><path d="M11.007,2.578,11,18.016a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l.007-15.421,2.912,2.913a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L14.122.879a3,3,0,0,0-4.244,0L6.667,4.091a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0Z"/><path d="M22,17v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17a1,1,0,0,0-1-1h0A1,1,0,0,0,22,17Z"/></svg>
                                        </figure>
                                        <span>Choose a file</span>
                                      </label>
                                    </div>
                                  )
                                }
                                {
                                  dynamicProp.formType === "file" && !dynamicProp.dependentProp && (interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name] !== "NA") && (
                                    <div class="w-full">
                                      <div class="uploaded-image">
                                        <button type="button" id={`image-0-${propIndex}`} class="primary-button cursor-pointer object-fit-contain w-full" onClick={(e)=> getReferenceFilesForFileViewer(e, 'Uploaded Files', true, dynamicProp.name, interactionObj.uuid)}>View Uploaded File</button>
                                        {!(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) &&
                                          <p class="delete-file-icon" title="Delete file" onClick={(e) => props.deleteUploadedFile(e, dynamicProp.name)}>{'x'}</p>
                                        }
                                      </div>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && !dynamicProp.dependentProp && (
                                    <div class="display-flex p-r-10 align-center">
                                      <input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-${actionIndex}-yes`} id={`${dynamicProp.name}-${actionIndex}-yes`} type={dynamicProp.formType} checked={currentRow['dynamicProperties_' + dynamicProp.name] === "yes" ? true : false} value="yes" onChange={(e) => props.saveFormInputRadioDefault(e, dynamicProp.name)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${actionIndex}-yes`}>Yes </label>
                                      <input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-${actionIndex}-no`} id={`${dynamicProp.name}-${actionIndex}-no`} type={dynamicProp.formType} checked={currentRow['dynamicProperties_' + dynamicProp.name] === "no" ? true : false} value="no" onChange={(e) => props.saveFormInputRadioDefault(e, dynamicProp.name)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${actionIndex}-no`}>No </label>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.formType === "radio" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp && (
                                    <div class="display-flex align-center">
                                      {
                                        dynamicProp.enum.map((enumVal) => (
                                          <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} name={`${dynamicProp.name}-${actionIndex}`} id={`${dynamicProp.name}-${actionIndex}-${enumVal}`} type={dynamicProp.formType} checked={interactionObj["dynamicProperties_" + dynamicProp.name] === enumVal} value={interactionObj["dynamicProperties_" + dynamicProp.name]} onChange={(e) => props.saveFormInputRadio(e, dynamicProp.name, enumVal)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${actionIndex}-${enumVal}`}>{enumVal}</label></div>
                                        ))
                                      }
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.formType === "checkbox" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp && (
                                    <div>
                                      {
                                        dynamicProp.enum.map((enumVal) => (
                                          <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} name={`${dynamicProp.name}-${actionIndex}-${enumVal}`} id={`${dynamicProp.name}-${actionIndex}-${enumVal}`} type={dynamicProp.formType} defaultChecked={interactionObj["dynamicProperties_"+dynamicProp.name] ? interactionObj["dynamicProperties_"+dynamicProp.name].split(",").includes(enumVal) : ""} onChange={(e) =>  props.saveFormInputCheckbox(e, dynamicProp.name, enumVal)}  /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${actionIndex}-${enumVal}`}>{enumVal}</label></div>
                                        ))
                                      }
                                    </div>
                                  )
                                }
                                {
                                  ((dynamicProp.name === "area" || dynamicProp.name === "companyArea") && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (
                                    <div>
                                      {/*<p class="formLabel fs-13 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) =>  props.saveAreaFromForm(e, dynamicProp.name)} >
                                        {
                                          areaList.map((enumVal) => (
                                            <option selected={interactionObj["dynamicProperties_" + dynamicProp.name] === enumVal.officeName} value={enumVal.officeName}>{enumVal.officeName}</option>
                                          ))
                                        }
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  ((dynamicProp.name === "state") && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (
                                    <div>
                                      {/*<p class="formLabel fs-13 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) =>  props.setFormValueInput(e,dynamicProp.name, actionIndex)} >
                                        <option value=''>Select State</option>
                                        {
                                          stateList && stateList.length > 0 && stateList.map((enumVal) => (
                                            <option selected={interactionObj["dynamicProperties_" + dynamicProp.name] === enumVal.name} value={enumVal.name}>{enumVal.name}</option>
                                          ))
                                        }
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  ((dynamicProp.name === "city") && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (
                                    <div>
                                      {/*<p class="formLabel fs-13 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) =>  props.saveAreaFromForm(e,dynamicProp.name)} >
                                        <option value=''>Select City</option>
                                        {
                                          cityList && cityList.length > 0 && cityList.map((enumVal) => (
                                            <option selected={interactionObj["dynamicProperties_" + dynamicProp.name] === enumVal.name} value={enumVal.name}>{enumVal.name}</option>
                                          ))
                                        }
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  ((dynamicProp.name !== "finalMobile" && dynamicProp.name !== "interestedModel" && dynamicProp.name !== "testDriveApprovalRole" && dynamicProp.name !== "testDriveAssignedUser")  && dynamicProp.formType === "select" && (dynamicProp.dependentProp && dynamicProp.dependentPropValue) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) &&(interactionObj["dynamicProperties_"+dynamicProp.dependentProp] === dynamicProp.dependentPropValue) && (
                                    <div>
                                      {/*<p class="formLabel fs-13 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                                      <div class="display-flex">
                                        <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={ (e) => props.saveFormInput(e, dynamicProp.name, "select", 0)} >
                                          <option value=''>Select {dynamicProp.displayName}</option>
                                          {
                                            dynamicProp.enum.map((enumVal) => (
                                              <option selected={interactionObj["dynamicProperties_" + dynamicProp.name] === enumVal} value={enumVal}>{enumVal}</option>
                                            ))
                                          }
                                        </select>
                                      </div>
                                    </div>
                                  )
                                }
                                {
                                  ((dynamicProp.name !== "finalMobile" && dynamicProp.name !== "interestedModel" && dynamicProp.name !== "testDriveApprovalRole" && dynamicProp.name !== "testDriveAssignedUser")  && dynamicProp.formType === "select" && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp && (
                                    <div>
                                      {/*<p class="formLabel fs-13 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                                      <div class="display-flex">
                                        <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={ (e) => props.saveFormInput(e, dynamicProp.name, "select", 0)} >
                                          <option value=''>Select {dynamicProp.displayName}</option>
                                          {
                                            dynamicProp.enum.map((enumVal) => (
                                              <option selected={interactionObj["dynamicProperties_" + dynamicProp.name] === enumVal} value={enumVal}>{enumVal}</option>
                                            ))
                                          }
                                        </select>
                                      </div>
                                    </div>
                                  )
                                }
                                {
                                  ((dynamicProp.name !== "finalMobile" && dynamicProp.name !== "interestedModel" && dynamicProp.name !== "testDriveApprovalRole" && dynamicProp.name !== "testDriveAssignedUser")  && dynamicProp.formType === "select" && (dynamicProp.dependentEnum && dynamicProp.dependentEnum.length !== 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (dynamicProp.dependentEnum.findIndex((d) => d.valueOfProp === interactionObj["dynamicProperties_" + d.nameOfProp]) > -1) && !dynamicProp.dependentProp && (
                                    <div>
                                      {/*<p class="formLabel fs-13 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                                      <div class="display-flex">
                                        <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={ (e) => props.saveFormInput(e, dynamicProp.name)} >
                                          <option value=''>Select {dynamicProp.displayName}</option>
                                          {
                                            dynamicProp.dependentEnum[dynamicProp.dependentEnum.findIndex((d) => d.valueOfProp === interactionObj["dynamicProperties_" + d.nameOfProp])].dependentEnums.map((enumVal) => (
                                              <option selected={interactionObj["dynamicProperties_" + dynamicProp.name] === enumVal} value={enumVal}>{enumVal}</option>
                                            ))
                                          }
                                        </select>
                                      </div>
                                    </div>
                                  )
                                }
                                {
                                  (((dynamicProp.formType === "select" && (dynamicProp.multipleDependentEnum && dynamicProp.multipleDependentEnum.length !== 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (getFilteredList(dynamicProp.multipleDependentEnum).length === 1) && !dynamicProp.dependentProp) &&
                                    <div>
                                      {/*<p class="formLabel fs-13 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                                      <div class="display-flex">
                                        <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={ (e) => props.saveFormInput(e, dynamicProp.name)} >
                                          <option value=''>Select {dynamicProp.displayName}</option>
                                          {
                                            getFilteredList(dynamicProp.multipleDependentEnum).length > 0 && getFilteredList(dynamicProp.multipleDependentEnum)[0].dependentEnums.map((enumVal) => (
                                              <option selected={interactionObj["dynamicProperties_" + dynamicProp.name] === enumVal} value={enumVal}>{enumVal}</option>
                                            ))
                                          }
                                        </select>
                                      </div>
                                    </div>
                                  )
                                }
                                {/*
                                  dynamicProp.formType !== "select" && (dynamicProp.name !== "licenseNumber") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && ((interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] && interactionObj['dynamicProperties_'+dynamicProp.dependentProp].length > 0 && interactionObj['dynamicProperties_'+dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) && (
                                    <p class="formLabel fs-13 p-b-3">{dynamicProp.displayName}<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                  )
                                }
                                {
                                  dynamicProp.formType !== "select" && (dynamicProp.name !== "licenseNumber") && dynamicProp.dependentProp && !dynamicProp.dependentPropValue && (dynamicProp.dependentPropValues.includes(interactionObj["dynamicProperties_" + dynamicProp.dependentProp])) && (
                                    <p class="formLabel fs-13 p-b-3 dep">{dynamicProp.displayName}<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                  )
                                */}
                                {
                                  (dynamicProp.formType === "checkbox" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && (dynamicProp.name === "negotiationMethod" || dynamicProp.name === "addOnCoverage") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] === dynamicProp.dependentPropValue) && (
                                    <div>
                                      {
                                        dynamicProp.enum.map((enumVal) => (
                                          <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} name={`${dynamicProp.name}-${actionIndex}-${enumVal}`} id={`${dynamicProp.name}-${actionIndex}-${enumVal}`} type={dynamicProp.formType} defaultChecked={interactionObj["dynamicProperties_"+dynamicProp.name] ? interactionObj["dynamicProperties_"+dynamicProp.name].split(",").includes(enumVal) : ""} onChange={(e) =>  props.saveFormInputCheckbox(e, dynamicProp.name, enumVal)}  /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${actionIndex}-${enumVal}`}>{enumVal}</label></div>
                                        ))
                                      }
                                    </div>
                                  )
                                }
                                {
                                  dynamicProp.formType === "file" && dynamicProp.dependentProp  && dynamicProp.dependentPropValue && (!interactionObj['dynamicProperties_'+dynamicProp.name] || (interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name] === "NA")) && ((interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (interactionObj["dynamicProperties_" + dynamicProp.dependentProp] && interactionObj["dynamicProperties_" + dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))) && (
                                    <div>
                                       <input class="uploadInputFile" autocomplete="off" required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-${actionIndex}`} id={`${dynamicProp.name}-${actionIndex}`} type={dynamicProp.formType} accept="image/*,.pdf" onChange={(e) => props.uploadFile(e, dynamicProp.name)} disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false}/>
                                       <label class="p-t-b-5" for={`${dynamicProp.name}-${actionIndex}`}>
                                         <figure>
                                           <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="24" height="24"><path d="M11.007,2.578,11,18.016a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l.007-15.421,2.912,2.913a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L14.122.879a3,3,0,0,0-4.244,0L6.667,4.091a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0Z"/><path d="M22,17v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17a1,1,0,0,0-1-1h0A1,1,0,0,0,22,17Z"/></svg>
                                         </figure>
                                         <span>Choose a file</span>
                                       </label>
                                    </div>
                                  )
                                }

                                {
                                  dynamicProp.formType === "file" && dynamicProp.dependentProp  && dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name] !== "NA") && ((interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (interactionObj["dynamicProperties_" + dynamicProp.dependentProp] && interactionObj["dynamicProperties_" + dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))) && (
                                    <div class="w-full">
                                      <div class="uploaded-image">
                                        <button type="button" id={`image-0-${propIndex}`} class="primary-button cursor-pointer object-fit-contain w-full" onClick={(e)=> getReferenceFilesForFileViewer(e, 'Uploaded Files', true, dynamicProp.name, interactionObj.uuid)}>View Uploaded File</button>
                                        {!(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) &&
                                          <p class="delete-file-icon" title="Delete file" onClick={(e) => props.deleteUploadedFile(e, dynamicProp.name)}>{'x'}</p>
                                        }
                                      </div>
                                    </div>
                                  )
                                }
                                {
                                  dynamicProp.formType === "file" && dynamicProp.dependentProp  && !dynamicProp.dependentPropValue && ((interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name] === "NA") || !interactionObj['dynamicProperties_'+dynamicProp.name]) && ((dynamicProp.dependentPropValues.includes(interactionObj["dynamicProperties_" + dynamicProp.dependentProp]))) && (
                                    <div>
                                       <input class="uploadInputFile" autocomplete="off" required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}-${actionIndex}`} type={dynamicProp.formType} accept="image/*,.pdf" onChange={(e) => props.uploadFile(e, dynamicProp.name)} disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false}/>
                                       <label class="p-t-b-5" for={`${dynamicProp.name}-${actionIndex}`}>
                                         <figure>
                                           <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="24" height="24"><path d="M11.007,2.578,11,18.016a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l.007-15.421,2.912,2.913a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L14.122.879a3,3,0,0,0-4.244,0L6.667,4.091a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0Z"/><path d="M22,17v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17a1,1,0,0,0-1-1h0A1,1,0,0,0,22,17Z"/></svg>
                                         </figure>
                                         <span>Choose a file</span>
                                       </label>
                                    </div>
                                  )
                                }

                                {
                                  dynamicProp.formType === "file" && dynamicProp.dependentProp  && !dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name] !== "NA") && ((dynamicProp.dependentPropValues.includes(interactionObj["dynamicProperties_" + dynamicProp.dependentProp]))) && (
                                    <div class="w-full">
                                      <div class="uploaded-image">
                                        <button type="button" id={`image-0-${propIndex}`} class="primary-button cursor-pointer object-fit-contain w-full" onClick={(e)=> getReferenceFilesForFileViewer(e, 'Uploaded Files', true, dynamicProp.name, interactionObj.uuid)}>View Uploaded File</button>
                                        {!(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) &&
                                          <p class="delete-file-icon" title="Delete file" onClick={(e) => props.deleteUploadedFile(e, dynamicProp.name)}>{'x'}</p>
                                        }
                                      </div>
                                    </div>
                                  )
                                }
                                {/*
                                  Modification: T319 - Commented onFocusout function
                                  By: Ashutosh G
                                  Date: 23/05/2024
                                */}
                                {
                                  (dynamicProp.name !== "licenseNumber") && (dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && ((interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] && interactionObj['dynamicProperties_'+dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue))) && (
                                    <input autocomplete="off" maxlength={dynamicProp.max && dynamicProp.max} class="first-letter-capital"  disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} onClick={(e) => props.inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} checked={interactionObj["dynamicProperties_"+dynamicProp.name] ? true : false} value={interactionObj["dynamicProperties_" + dynamicProp.name] ? interactionObj["dynamicProperties_" + dynamicProp.name] : ''} onInput={(e) => props.setFormValueInput(e, dynamicProp.name, actionIndex)}
                                    // onFocusout={ (e) => props.saveFormInput(e, dynamicProp.name)}
                                    />
                                  )
                                }
                                {/*
                                  Modification: T319 - Commented onFocusout function
                                  By: Ashutosh G
                                  Date: 23/05/2024
                                */}
                                {
                                  (dynamicProp.name !== "licenseNumber") && (dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(interactionObj["dynamicProperties_" + dynamicProp.dependentProp])) && (
                                    <input autocomplete="off" maxlength={dynamicProp.max && dynamicProp.max} class="first-letter-capital"  disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} onClick={(e) => props.inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} checked={interactionObj["dynamicProperties_"+dynamicProp.name] ? true : false} value={interactionObj["dynamicProperties_" + dynamicProp.name] ? interactionObj["dynamicProperties_" + dynamicProp.name] : ''} onInput={(e) => props.setFormValueInput(e, dynamicProp.name, actionIndex)}
                                    // onFocusout={ (e) => props.saveFormInput(e, dynamicProp.name)}
                                    />
                                  )
                                }
                                {
                                  (dynamicProp.formType === "date" || dynamicProp.formType === "time") && dynamicProp.dependentProp && (interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) && (
                                    <div>
                                      {/*<p class="formLabel fs-13 p-b-3">{dynamicProp.displayName}
                                        <span style={interactionObj["dynamicProperties_" + dynamicProp.name] ? "display: none": "display:inline"}  id={`required-${actionIndex}-`+dynamicProp.name} class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span>
                                        <span style={interactionObj["dynamicProperties_" + dynamicProp.name] ? "display: inline": "display:none"} id={`success-${actionIndex}-`+dynamicProp.name}> <svg fill="green" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="15" height="15"><path d="M22.319,4.431,8.5,18.249a1,1,0,0,1-1.417,0L1.739,12.9a1,1,0,0,0-1.417,0h0a1,1,0,0,0,0,1.417l5.346,5.345a3.008,3.008,0,0,0,4.25,0L23.736,5.847a1,1,0,0,0,0-1.416h0A1,1,0,0,0,22.319,4.431Z"/></svg></span>
                                      </p>*/}
                                      <input autocomplete="off" required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} value={moment(new Date(interactionObj["dynamicProperties_" + dynamicProp.name])).format('YYYY-MM-DD') || moment(new Date()).format('YYYY-MM-DD')} onChange={ (e) => props.saveFormInput(e, dynamicProp.name)}  max={(dynamicProp.typeOfValidation && dynamicProp.typeOfValidation === 'futureDateDisabled') && futureDateDisabled} disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false}/>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.formType === "date" || dynamicProp.formType === "time") && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(interactionObj["dynamicProperties_" + dynamicProp.dependentProp])) && (
                                    <input autocomplete="off" required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} value={moment(new Date(interactionObj["dynamicProperties_" + dynamicProp.name])).format('YYYY-MM-DD') || moment(new Date()).format('YYYY-MM-DD')} onChange={ (e) => props.saveFormInput(e, dynamicProp.name)}  max={(dynamicProp.typeOfValidation && dynamicProp.typeOfValidation === 'futureDateDisabled') && futureDateDisabled} disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false}/>
                                  )
                                }
                                {
                                  (dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && ((dynamicProp.dependentProp && (interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] && interactionObj['dynamicProperties_'+dynamicProp.dependentProp].length > 0 && interactionObj['dynamicProperties_'+dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue))))) && (
                                    <div class="display-flex p-r-10 align-center">
                                      <input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-${actionIndex}-yes`} id={`${dynamicProp.name}-${actionIndex}-yes`} type={dynamicProp.formType} checked={currentRow['dynamicProperties_' + dynamicProp.name] === "yes" ? true : false} value="yes" onChange={(e) => props.saveFormInputRadioDefault(e, dynamicProp.name)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${actionIndex}-yes`}>Yes </label>
                                      <input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-${actionIndex}-no`} id={`${dynamicProp.name}-${actionIndex}-no`} type={dynamicProp.formType} checked={currentRow['dynamicProperties_' + dynamicProp.name] === "no" ? true : false} value="no" onChange={(e) => props.saveFormInputRadioDefault(e, dynamicProp.name)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${actionIndex}-no`}>No </label>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.formType === "radio" && (dynamicProp.enum || dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) && (
                                    <div class="display-flex formRadioButtons">
                                      {
                                        dynamicProp.enum.map((enumVal) => (
                                          <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} name={`${dynamicProp.name}-${actionIndex}-${enumVal}`} id={`${dynamicProp.name}-${actionIndex}-${enumVal}`} type={dynamicProp.formType} checked={interactionObj["dynamicProperties_" + dynamicProp.name] === enumVal} value={interactionObj["dynamicProperties_" + dynamicProp.name]} onChange={(e) => props.saveFormInputRadio(e, dynamicProp.name, enumVal)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${actionIndex}-${enumVal}`}>{enumVal}</label></div>
                                        ))
                                      }
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(interactionObj["dynamicProperties_" + dynamicProp.dependentProp])) && (
                                    <div class="display-flex p-r-10 align-center">
                                      <input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-${actionIndex}-yes`} id={`${dynamicProp.name}-${actionIndex}-yes`} type={dynamicProp.formType} checked={currentRow['dynamicProperties_' + dynamicProp.name] === "yes" ? true : false} value="yes" onChange={(e) => props.saveFormInputRadioDefault(e, dynamicProp.name)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${actionIndex}-yes`}>Yes </label>
                                      <input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-${actionIndex}-no`} id={`${dynamicProp.name}-${actionIndex}-no`} type={dynamicProp.formType} checked={currentRow['dynamicProperties_' + dynamicProp.name] === "no" ? true : false} value="no" onChange={(e) => props.saveFormInputRadioDefault(e, dynamicProp.name)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${actionIndex}-no`}>No </label>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.formType === "radio" && (dynamicProp.enum || dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(interactionObj["dynamicProperties_" + dynamicProp.dependentProp])) && (
                                    <div class="display-flex formRadioButtons">
                                      {
                                        dynamicProp.enum.map((enumVal) => (
                                          <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} name={`${dynamicProp.name}-${actionIndex}-${enumVal}`} id={`${dynamicProp.name}-${actionIndex}-${enumVal}`} type={dynamicProp.formType} checked={interactionObj["dynamicProperties_" + dynamicProp.name] === enumVal} value={interactionObj["dynamicProperties_" + dynamicProp.name]} onChange={(e) => props.saveFormInputRadio(e, dynamicProp.name, enumVal)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${actionIndex}-${enumVal}`}>{enumVal}</label></div>
                                        ))
                                      }
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.name === "interestedModel" && dynamicProp.formType === "select") && (
                                    <div class="display-flex">
                                      {/*<select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={model} onChange={e => {
                                              props.getVariantForSelectedModel(e.target.value)
                                              setModel(e.target.value)
                                              saveFormInput(e, dynamicProp.name)
                                              }
                                              }>
                                              <option style="text-transform:capitalize" value="" selected>Select Model</option>
                                              {catalogoueItemList.map((item)=>(
                                                <option style="text-transform:capitalize;" value={item.uuid}>{item.displayName}</option>
                                              ))}
                                              </select>
                                              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0 m-0" >
                                                <div class="row">
                                                    <div class="col-xs-12 p-l-0">
                                                      <p class="fs-15 p-b-3">{dynamicProp.displayName}</p>
                                                    </div>
                                                </div>
                                            </div>*/}
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.name === "finalMobile" && dynamicProp.formType === "select") && (
                                    <div class="display-block">
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={interactionObj[dynamicProp.name]} onChange={e => {
                                        props.saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" selected>Select Final Mobile</option>
                                        <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === interactionObj.dynamicProperties_mobile} value={interactionObj.dynamicProperties_mobile}>{interactionObj.dynamicProperties_mobile}</option>
                                        {
                                          interactionObj.dynamicProperties_alternateMobile && (
                                            <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === interactionObj.dynamicProperties_alternateMobile} value={interactionObj.dynamicProperties_alternateMobile}>{interactionObj.dynamicProperties_alternateMobile}</option>
                                          )
                                        }
                                        {
                                          interactionObj.dynamicProperties_alternateNumber && (
                                            <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === interactionObj.dynamicProperties_alternateNumber} value={interactionObj.dynamicProperties_alternateNumber}>{interactionObj.dynamicProperties_alternateNumber}</option>
                                          )
                                        }
                                        {
                                          interactionObj.dynamicProperties_homePhone && (
                                            <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === interactionObj.dynamicProperties_homePhone} value={interactionObj.dynamicProperties_homePhone}>{interactionObj.dynamicProperties_homePhone}</option>
                                          )
                                        }
                                        {
                                          interactionObj.dynamicProperties_otherPhone && (
                                            <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === interactionObj.dynamicProperties_otherPhone} value={interactionObj.dynamicProperties_otherPhone}>{interactionObj.dynamicProperties_otherPhone}</option>
                                          )
                                        }
                                        {
                                          interactionObj.dynamicProperties_whatsappNumber && (
                                            <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === interactionObj.dynamicProperties_whatsappNumber} value={interactionObj.dynamicProperties_whatsappNumber}>{interactionObj.dynamicProperties_whatsappNumber}</option>
                                          )
                                        }
                                        {
                                          interactionObj.dynamicProperties_contactPersonmobile && (
                                            <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === interactionObj.dynamicProperties_contactPersonmobile} value={interactionObj.dynamicProperties_contactPersonmobile}>{interactionObj.dynamicProperties_contactPersonmobile}</option>
                                          )
                                        }
                                        {
                                          interactionObj.dynamicProperties_contactPersonwhatsappNumber && (
                                            <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === interactionObj.dynamicProperties_contactPersonwhatsappNumber} value={interactionObj.dynamicProperties_contactPersonwhatsappNumber}>{interactionObj.dynamicProperties_contactPersonwhatsappNumber}</option>
                                          )
                                        }
                                        {
                                          interactionObj.dynamicProperties_alternateMobileNumber && (
                                            <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === interactionObj.dynamicProperties_alternateMobileNumber} value={interactionObj.dynamicProperties_alternateMobileNumber}>{interactionObj.dynamicProperties_alternateMobileNumber}</option>
                                          )
                                        }
                                        {
                                          interactionObj.dynamicProperties_whatsAppNumber && (
                                            <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === interactionObj.dynamicProperties_whatsAppNumber} value={interactionObj.dynamicProperties_whatsAppNumber}>{interactionObj.dynamicProperties_whatsAppNumber}</option>
                                          )
                                        }
                                      </select>
                                    </div>
                                  )
                                }
                                {/*
                                        (dynamicProp.name === "interestedModel" && dynamicProp.formType === "select") && model && variantList && (
                                          <div class="display-flex">
                                            <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id='variant' value={variant} onChange={e => {
                                              props.getVariantDetails(e.target.value)
                                              setVariant(e.target.value)
                                              saveFormInput(e, dynamicProp.name)
                                            }}>
                                              <option value="" selected>Select Variant</option>
                                              {
                                                variantList.map((variant)=>(
                                                  <option style="text-transform:capitalize" value={variant.uuid}>{variant.variant}</option>
                                                ))
                                              }
                                            </select>
                                          </div>
                                        )
                                      */}
                                {/*
                                  (dynamicProp.name === "exchangeEvaluator" && dynamicProp.formType === "select") && (
                                    <div class="display-block">
                                      <p class="fs-15 formLabel">Exchange Evaluator</p>
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={currentRow['dynamicProperties_exchangeEvaluator']} onChange={e => {
                                        props.saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" selected>Select Exchange Evaluator<span class="star-mandatory-entry p-l-2"></span></option>
                                        {exchangEvaluators.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === item.userID} value={item.userID}>{item.userName}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.name === "selectedScheme" && dynamicProp.formType === "select") && (
                                    <div class="display-block">
                                      <p class="formLabel fs-13 first-letter-capital">{dynamicProp.displayName + " "  }</p>
                                      {
                                        schemes.map((scheme, index) => (
                                          <div class="display-flex">
                                            <input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} type="checkbox" value={scheme.uuid} defaultChecked={interactionObj["dynamicProperties_"+dynamicProp.name] === scheme.uuid} default={interactionObj["dynamicProperties_" + dynamicProp.name]} disabled={(scheme.displayName.includes("Exchange") && interactionObj["dynamicProperties_typeOfBuyer"] !== "Exchange Buyer")} onChange={(e) => props.saveFormInputRadio(e, dynamicProp.name, scheme.uuid, index)} />
                                            <label class='p-l-10 p-r-10' style='font-weight: 300;'>{scheme.displayName + " - " + getFormattedAmount(scheme.discountAfterGST)  }</label>
                                          </div>
                                        ))
                                      }
                                    </div>
                                  )
                                */}
                                {
                                  (currentRow.displayName !== "Coupon Approval Task" && dynamicProp.name === "selectedCouponID" && (currentRow['dynamicProperties_negotiationMethod'] && currentRow['dynamicProperties_negotiationMethod'].split(",").includes("Coupon"))  && dynamicProp.formType === "checkbox") && (
                                    <div class="display-block">
                                      {
                                        coupons && coupons.length > 0 && coupons.map((coupon, index) => (
                                          <div class="display-flex">
                                            <input id={`${coupon.uuid}-${actionIndex}`} name={`${coupon.uuid}-${actionIndex}`} autocomplete="off" type="checkbox" value={coupon.uuid} default={interactionObj["dynamicProperties_" + dynamicProp.name]} defaultChecked={interactionObj["dynamicProperties_"+dynamicProp.name] ? interactionObj["dynamicProperties_"+dynamicProp.name].split(",").includes(coupon.uuid) : ""} onChange={(e) =>  props.saveFormInputCheckbox(e, dynamicProp.name, coupon.uuid)} disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} />
                                            <label for={`${coupon.uuid}-${actionIndex}`} class='h-auto p-l-10 p-r-10' style='font-weight: 300;'>{coupon.displayName} - {getFormattedAmount(coupon.amount)}</label>
                                          </div>
                                        ))
                                      }
                                    </div>
                                  )
                                }
                                {
                                  (currentRow.displayName === "Coupon Approval Task" && dynamicProp.name === "selectedCouponID" && dynamicProp.formType === "checkbox") && (
                                    <div class="display-block">
                                      <p class="formLabel fs-13">Total offer amount: {(coupons.length > 0 && interactionObj["dynamicProperties_selectedCouponID"]) && getFormattedAmount(interactionObj["dynamicProperties_selectedCouponID"].split(',').reduce((acc, obj) => {return acc + ((coupons.find(coupon => coupon.uuid === obj) && coupons.find(coupon => coupon.uuid === obj).amount) ? Number(coupons.find(coupon => coupon.uuid === obj).amount) : 0)}, 0))}</p>
                                      {
                                        coupons && coupons.length > 0 && coupons.map((coupon, index) => (
                                          <div class="display-flex">
                                            <input id={`${coupon.uuid}-${actionIndex}`} name={`${coupon.uuid}-${actionIndex}`} autocomplete="off" type="checkbox" value={coupon.uuid} default={interactionObj["dynamicProperties_" + dynamicProp.name]} defaultChecked={interactionObj["dynamicProperties_"+dynamicProp.name] ? interactionObj["dynamicProperties_"+dynamicProp.name].split(",").includes(coupon.uuid) : ""} onChange={(e) =>  props.saveFormInputCheckbox(e, dynamicProp.name, coupon.uuid)} disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false}/>
                                            <label for={`${coupon.uuid}-${actionIndex}`} class='h-auto p-l-10 p-r-10' style='font-weight: 300;'>{coupon.displayName} - {getFormattedAmount(coupon.amount)}</label>
                                          </div>
                                        ))
                                      }
                                    </div>
                                  )
                                }
                                {/*
                                  (dynamicProp.name === "selectedOffer" && dynamicProp.formType === "select") && (
                                    <div class="display-block">
                                      <p class="formLabel fs-13">Dealer Offer</p>
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={currentRow['dynamicProperties_selectedOffer']} onChange={e => {
                                        props.saveFormInput(e, dynamicProp.name);
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" selected>Select Offer</option>
                                        {offers.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === item.uuid} value={item.uuid}>{item.displayName}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.name === "manufacturer" && dynamicProp.formType === "select") && (
                                    <div>
                                      <p class="formLabel fs-13">Select Manufacturer</p>

                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={interactionObj['dynamicProperties_manufacturer']} onChange={e => {
                                        props.setSelectedMake(e)
                                        // saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" >Select Manufacturer</option>
                                        {listofManufacturers.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj['dynamicProperties_manufacturer'] === item} value={item}>{item}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.name === "otherCars" && dynamicProp.formType === "select") && (
                                    <div>
                                      <p class="formLabel fs-13">{dynamicProp.displayName}</p>
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={interactionObj['dynamicProperties_otherCars']} onChange={e => {
                                        // setSelectedMake(e)
                                        props.saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" >Select Manufacturer</option>
                                        {listofManufacturers.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj['dynamicProperties_otherCars'] === item} value={item}>{item}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                */}
                                {
                                  (dynamicProp.name === "selectedAgent" && dynamicProp.formType === "select") && (
                                    <div class="display-block">
                                      {/*<p class="fs-15 formLabel">{dynamicProp.displayName}</p>*/}
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" value={currentRow['dynamicProperties_selectedAgent']} onChange={e => {
                                        props.saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" disabled selected>{dynamicProp.displayName}<span class="star-mandatory-entry p-l-2"></span></option>
                                        {exchangEvaluators.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === item.userID} value={item.userID}>{item.userName}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {/*
                                  (dynamicProp.name === "exchangeCarModel" && dynamicProp.formType === "select") && (
                                    <div class="display-flex">
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={interactionObj['dynamicProperties_exchangeCarModel']} onChange={e => {
                                        props.setSelectedExchangeModel(e)
                                        // saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" >Select Model</option>
                                        {listOfModels && listOfModels.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj['dynamicProperties_exchangeCarModel'] === item.Model + " - " + item.Category + " - " + item.Year} value={item.Model + " - " + item.Category + " - " + item.Year}>{item.Model + " - " + item.Category + " - " + item.Year}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                */}

                                {
                                  (dynamicProp.name === "selectedModel" && (!dynamicProp.dependentProp || (dynamicProp.dependentProp && (interactionObj["dynamicProperties_"+dynamicProp.dependentProp] === dynamicProp.dependentPropValue))) && dynamicProp.formType === "select") && (
                                    <div class="display-flex">
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={selectedModel} onChange={e => {
                                        props.saveSelectedModel(e)
                                        // saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" >Select Model</option>
                                        {catalogoueItemList.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj['dynamicProperties_selectedModel'] === item.uuid} value={item.uuid}>{modelMapping[item.displayName.toLowerCase()]}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.name === "selectedVariant" && dynamicProp.formType === "select") && (
                                    <div class="display-flex">
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={selectedModel} onChange={e => {
                                        props.saveSelectedVariant(e)
                                        // saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" >Select Variant</option>
                                        {variantList.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj['dynamicProperties_selectedVariant'] === item.uuid} value={item.uuid}>{item.variant}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.name === "selectedTestDriveModel"  && (interactionObj["dynamicProperties_"+dynamicProp.dependentProp] === dynamicProp.dependentPropValue) && dynamicProp.formType === "select") && (
                                    <div class="display-flex">
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={selectedModel} onChange={e => {
                                        props.saveSelectedTestDriveModel(e)
                                        // saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" >Select Test Drive Model</option>
                                        {catalogoueItemList.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={(interactionObj['dynamicProperties_selectedModel'] === item.uuid) || (interactionObj['dynamicProperties_selectedTestDriveModel'] === item.uuid)} value={item.uuid}>{modelMapping[item.displayName.toLowerCase()]}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.name === "selectedTestDriveVariant" && (interactionObj["dynamicProperties_"+dynamicProp.dependentProp] === dynamicProp.dependentPropValue) && dynamicProp.formType === "select") && (
                                    <div class="display-flex">
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={selectedModel} onChange={e => {
                                        props.saveSelectedTestDriveVariant(e)
                                        // saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" >Select Test Drive Variant</option>
                                        {variantList.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj['dynamicProperties_selectedTestDriveVariant'] === item.uuid} value={item.uuid}>{item.variant}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.name === "selectedTestDriveCarID" && (interactionObj["dynamicProperties_typeOfTestDriveCar"] === "Test Drive Car") && dynamicProp.formType === "select") && (
                                    <div class="display-flex">
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={interactionObj["dynamicProperties_selectedTestDriveCarID"]} onChange={e => {
                                        props.saveSelectedTestDriveCar(e)
                                        // saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" >Select Test Drive Car</option>
                                        {testDriveCars.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" value={item.uuid}>{item.variant + " - " + item.registrationNumber}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.name === "lostCaseApprovalRole" && (currentRow['dynamicProperties_outcome'] === "Lost") && dynamicProp.formType === "select") && (
                                    <div>
                                      <select class="text-4b4d51 background-white border-none" type="text" id="employee2" value={interactionObj['dynamicProperties_lostCaseApprovalRole']} onChange={e => {
                                        props.getSelectedTypeUserList(e.target.value)
                                      }}
                                      style="text-shadow:none"
                                      disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false}
                                      >
                                        <option selected value=''>{'Select Responsible Role'}</option>
                                        <option selected={interactionObj['dynamicProperties_lostCaseApprovalRole'] === 'Insurance Team Lead'} value={'Insurance Team Lead'}>{'Insurance Team Lead'}</option>
                                        <option selected={interactionObj['dynamicProperties_lostCaseApprovalRole'] === 'Insurance Head'} value={'Insurance Head'}>{'Insurance Manager'}</option>
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.name === "lostCaseAssignedUser" && (currentRow['dynamicProperties_outcome'] === "Lost") && dynamicProp.formType === "select") && (
                                    <div>
                                      <select type="text" id="employee" class="m-t-10  first-letter-capital background-white border-none text-4b4d51" onChange={(e => props.saveFormInput(e, dynamicProp.name))} style="text-shadow:none" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false}>
                                        <option class="first-letter-capital" value='' selected>{`Select ${interactionObj['dynamicProperties_lostCaseApprovalRole'] ? interactionObj['dynamicProperties_lostCaseApprovalRole']: 'User'}`}</option>
                                        { assignUserList.map((user) => (
                                          <option selected={interactionObj['dynamicProperties_lostCaseAssignedUser'] === user.userID } value={user.userID}>{user.userName}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  ((dynamicProp.name === "thirdPartyInsuranceCompanyID" || dynamicProp.name === "accessoriesInsuranceCompanyID" || dynamicProp.name === "cngInsuranceCompanyID" || dynamicProp.name === "insuranceCompanyID" || dynamicProp.name === "insuranceCompanyID2" || dynamicProp.name === "insuranceCompanyRenewalID" || dynamicProp.name === "totalLossInsuranceCompany" || dynamicProp.name === "renewalInsuranceCompanyID" || dynamicProp.name === "insuranceCompanyIDNew") && dynamicProp.formType === "select") && ((!dynamicProp.dependentProp) || (dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] === dynamicProp.dependentPropValue)) || (dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] && interactionObj['dynamicProperties_'+dynamicProp.dependentProp].length > 0 && interactionObj['dynamicProperties_'+dynamicProp.dependentProp].includes(dynamicProp.dependentPropValue)))) && (
                                    <div class="display-block">
                                      {/*<p class="formLabel fs-13">{dynamicProp.displayName}{dynamicProp.dependentPropValues.includes(interactionObj[dynamicProp.dependentProp])}{interactionObj[dynamicProp.dependentProp]}</p>*/}
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={interactionObj[dynamicProp.name]} onChange={e => {
                                        props.saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" selected>Select Insurance Company</option>
                                        {insuranceCompanies && insuranceCompanies.length > 0 && insuranceCompanies.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === item.uuid} value={item.uuid}>{item.displayName}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  ((dynamicProp.name === "latestQuotationID") && dynamicProp.formType === "select") && ((!dynamicProp.dependentProp) || (dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] === dynamicProp.dependentPropValue))) && (
                                    <div class="display-block">
                                      {/*<p class="formLabel fs-13">{dynamicProp.displayName}{dynamicProp.dependentPropValues.includes(interactionObj[dynamicProp.dependentProp])}{interactionObj[dynamicProp.dependentProp]}</p>*/}
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={interactionObj[dynamicProp.name]} onChange={e => {
                                        props.saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" selected>Select Quotation</option>
                                        {quotations.map((item, index) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === item.uuid} value={item.uuid}>{`Quotation ${index+1}`}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  (currentAction.displayName === "Need Assessment" && (propIndex === action.dynamicProps.length - 1)) && (
                                    <div class="display-block">
                                      <p class="formLabel fs-13">Finance Manager<span class="star-mandatory-entry p-l-2"></span></p>
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={currentRow['dynamicProperties_financeExecutive']} onChange={e => {
                                        props.saveFormInput(e, "financeExecutive")
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" selected>Select Finance Manager</option>
                                        {financeExecutives.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_financeExecutive"] === item.userID} value={item.userID}>{item.userName}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  (!dynamicProp.dependentProp || (dynamicProp.dependentProp && ((interactionObj['dynamicProperties_'+dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] && interactionObj['dynamicProperties_'+dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))))) && (
                                    <span id={`error-`+dynamicProp.name} class="fs-10" >{dynamicProp.description}</span>
                                  )
                                }
                                {
                                  (currentRow.errorFields && currentRow.errorFields.length > 0 && (currentRow.errorFields.findIndex(item => item.fieldName === dynamicProp.name) > -1)) ? (
                                    <p class="fs-10 text-color-red" >{currentRow.errorFields.find(item => item.fieldName === dynamicProp.name).errorMessage}</p>
                                  ) : (
                                    <p class="fs-10 text-color-red" style="visibility: hidden;">Hidden Error</p>
                                  )
                                }
                                {/*
                                  T48: Auto next follow up date selection
                                */}
                                {
                                  dynamicProp.name === "nextFollowupDate" && interactionObj['dynamicProperties_numberOfMaxDaysToFollowUp'] &&
                                  <p id={`msg-`+dynamicProp.name} class="text-color-red fs-10" >Date selected should not be less than {interactionObj['dynamicProperties_numberOfMaxDaysToFollowUp']} days</p>
                                }
                                {/*
                                  dynamicProp.name === "engineNumber" && (
                                    <span id={`amount-`+dynamicProp.name} class="fs-10 first-letter-capital" style="display:none;" ></span>
                                  )
                                */}

                                {
                                  (dynamicProp.isAmount && interactionObj['dynamicProperties_'+dynamicProp.name] > 0) && (
                                    <span id={`amount-`+dynamicProp.name} class="fs-10 first-letter-capital display-block" style="color:#4949ed;">{`INR ${inWords(interactionObj['dynamicProperties_'+dynamicProp.name])}`}</span>
                                  )
                                }
                              </div>
                            </div>
                          ))
                        }
                        {
                          currentRow && currentRow.buttons && currentRow.buttons.length !== 0 && currentRow.buttons.map((button) => (
                            <button id={button} class="primary-button m-t-20 m-b-20 m-l-20 m-r-20" onClick={e => props.dynamicButtonClicked(e, button)}>{button}</button>
                          ))
                        }
                        {/*
                          (currentRow.displayName === 'Payment Confirmation (Renewal)' || currentRow.displayName === 'Renewal Followup') && interactionObj.dynamicProperties_paymentModeCustomerTransaction === "Credit" && (
                            <div class="taskDetailsSection" style="overflow-y:auto">
                              <button id="send-button" class="primary-button m-t-20 m-b-20 m-l-20 m-r-20" onClick={e => props.dynamicButtonClicked(e, "Send Code")}>Send Code</button>
                              <button id="autorize-button" class="primary-button m-t-20 m-b-20 m-l-20 m-r-20" onClick={e => props.dynamicButtonClicked(e, "Authorise")}>Authorise</button>
                            </div>
                          )
                        */}
                        {
                          currentRow.containerType === 'Form and Approval' && (currentRow.displayName !== "Handover Courier") && (currentRow.displayName !== "Close Visit") && (currentRow.quorum && currentRow.numberOfApprovals > 0) && (
                            <div class="taskDetailsSection" style="overflow-y:auto">
                              <button id="approve-button" class="primary-button m-t-20 m-b-20 m-l-20 m-r-20" onClick={e => props.dynamicButtonClicked(e, "Approve")}>Approve</button>
                              {/*!currentRow.quorum || currentRow.quorum === 1 && <button class="primary-button m-t-20 m-b-20 m-l-20 m-r-20" onClick={e => props.dynamicButtonClicked(e, "Submit")}>Submit for Approval {currentRow.quorum}</button>*/}
                              <button id="reject-button" class="secondary-button m-t-20 m-b-20 m-l-20 m-r-20" onClick={e => props.dynamicButtonClicked(e, "Reject")}>Reject</button>
                            </div>
                          )
                        }
                        {
                          currentRow.containerType === 'Form and Approval' && (currentRow.displayName !== "Handover Courier") && (currentRow.displayName !== "Close Visit") && (currentRow.quorum && !currentRow.numberOfApprovals) && (
                            <div class="taskDetailsSection" style="overflow-y:auto">
                              <button id="approve-button" class="primary-button m-t-20 m-b-20 m-l-20 m-r-20" onClick={e => props.dynamicButtonClicked(e, "Approve")}>Approve</button>
                            </div>
                          )
                        }
                        {
                          currentRow.containerType === 'Form and Approval' && (currentRow.displayName !== "Handover Courier") && (currentRow.displayName !== "Close Visit") && (!currentRow.quorum) && (
                            <div class="taskDetailsSection" style="overflow-y:auto">
                            <button id="approve-button" class="primary-button m-t-20 m-b-20 m-l-20 m-r-20" onClick={e => props.dynamicButtonClicked(e, "Approve")}>Approve</button>
                            {/*!currentRow.quorum || currentRow.quorum === 1 && <button class="primary-button m-t-20 m-b-20 m-l-20 m-r-20" onClick={e => props.dynamicButtonClicked(e, "Submit")}>Submit for Approval {currentRow.quorum}</button>*/}
                            <button id="reject-button" class="secondary-button m-t-20 m-b-20 m-l-20 m-r-20" onClick={e => props.dynamicButtonClicked(e, "Reject")}>Reject</button>
                            </div>
                          )
                        }
                      </div>
                    )
                  }
                  {
                    currentAction.name !== "summary" && (actions && actions.length > 0) && [actions[selectedActionIndex]].map((action, index) => (
                      <div class="row">
                        {/*
                          !props.isFormEditable && action.dynamicPropsData && Object.keys(action.dynamicPropsData).map((key) => (
                            <div class="col-xs-12 col-sm-6">
                              <ListSingleCard typeOfCard={'formCard'} group={key} groupProps={action.dynamicPropsData[key]} interactionObj={interactionObj} coupons={coupons} insuranceCompanies={insuranceCompanies}/>
                            </div>
                          ))
                        */}
                        { /*<p style="color:#7a7a7a; width: 100%;">{action.displayName}</p> */}
                        {
                          action.displayName === 'Discount Adjustment' && (
                            <div class="row" style="width:100%">
                            <div className={`col-xs-12 m-t-15 ${action.displayName === 'Discount Adjustment' ? 'col-sm-6 col-md-6 col-lg-6' : 'col-sm-12 col-md-12 col-lg-12'}`}>
                              <p>Discount Amount: <span style='margin-left: 10px; font-weight:600'> { interactionObj['dynamicProperties_discount'] ? getFormattedAmount(interactionObj['dynamicProperties_discount']) : '₹ 0' }</span></p>
                            </div>
                            <div className={`col-xs-12 m-t-15 ${action.displayName === 'Discount Adjustment' ? 'col-sm-6 col-md-6 col-lg-6' : 'col-sm-12 col-md-12 col-lg-12'}`}>
                              <p>Discount to Adjust Amount: <span style='margin-left: 10px; font-weight:600'> { interactionObj['dynamicProperties_discountToBeAdjusted'] ? getFormattedAmount(interactionObj['dynamicProperties_discountToBeAdjusted']) : interactionObj['dynamicProperties_discount'] ? getFormattedAmount(interactionObj['dynamicProperties_discount']) : '₹ 0' }</span></p>
                            </div>
                            </div>
                          )
                        }
                        {

                          action.dynamicProps && action.dynamicProps.length !== 0 && action.dynamicProps.map((dynamicProp, propIndex) => (
                            <div className={`col-xs-12 m-t-15 ${action.displayName === 'Discount Adjustment' ? 'col-sm-6 col-md-6 col-lg-6' : 'col-sm-12 col-md-12 col-lg-12'}`} style={`${((dynamicProp.formType !== "select" && (dynamicProp.name !== "licenseNumber") && dynamicProp.formType !== "radio" && dynamicProp.formType !== "checkbox" && !dynamicProp.dependentProp) ||
                             ((dynamicProp.formType === "radio" || dynamicProp.formType === "checkbox") && !dynamicProp.dependentProp) ||
                             (((dynamicProp.name !== "licenseNumber") && (dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && !dynamicProp.dependentProp)) ||
                             (dynamicProp.name === "licenseNumber") ||
                             ((dynamicProp.formType === "date" || dynamicProp.formType === "time") && !dynamicProp.dependentProp) ||
                             ((dynamicProp.formType === "file") && (!dynamicProp.dependentProp && (!interactionObj['dynamicProperties_'+dynamicProp.name] || (interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name] === "NA")))) ||
                             (dynamicProp.formType === "file" && !dynamicProp.dependentProp && (interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name] !== "NA")) ||
                             ((dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && !dynamicProp.dependentProp) ||
                             ((dynamicProp.formType === "radio" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp) ||
                             ((dynamicProp.formType === "checkbox" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp) ||
                             ((dynamicProp.formType === "checkbox" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && (dynamicProp.name === "negotiationMethod" || dynamicProp.name === "addOnCoverage") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] === dynamicProp.dependentPropValue)) ||
                             (((dynamicProp.name === "area" || dynamicProp.name === "companyArea") && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0))) ||
                             (((dynamicProp.name === "state") && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0))) ||
                             (((dynamicProp.name === "city") && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0))) ||
                             (((dynamicProp.name !== "finalMobile" && dynamicProp.name !== "interestedModel" && dynamicProp.name !== "testDriveApprovalRole" && dynamicProp.name !== "testDriveAssignedUser")  && dynamicProp.formType === "select" && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp) ||
                             (((dynamicProp.name !== "finalMobile" && dynamicProp.name !== "interestedModel" && dynamicProp.name !== "testDriveApprovalRole" && dynamicProp.name !== "testDriveAssignedUser")  && dynamicProp.formType === "select" && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValue !== "NA" && (interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue)) ||
                             (((dynamicProp.name !== "finalMobile" && dynamicProp.name !== "interestedModel" && dynamicProp.name !== "testDriveApprovalRole" && dynamicProp.name !== "testDriveAssignedUser") && dynamicProp.formType === "select" && (dynamicProp.dependentEnum && dynamicProp.dependentEnum.length !== 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (dynamicProp.dependentEnum.findIndex((d) => d.valueOfProp === interactionObj["dynamicProperties_" + d.nameOfProp]) > -1) && !dynamicProp.dependentProp) ||
                             ((dynamicProp.formType === "select" && (dynamicProp.multipleDependentEnum && dynamicProp.multipleDependentEnum.length !== 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (getFilteredList(dynamicProp.multipleDependentEnum).length === 1) && !dynamicProp.dependentProp) ||
                             (dynamicProp.formType !== "select" && (dynamicProp.name !== "licenseNumber") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && dynamicProp.dependentPropValue !== "NA" && (interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue)) ||
                             (dynamicProp.formType !== "select" && (dynamicProp.name !== "licenseNumber") && dynamicProp.dependentProp && !dynamicProp.dependentPropValue && (dynamicProp.dependentPropValues.includes(interactionObj["dynamicProperties_" + dynamicProp.dependentProp]))) ||
                             (dynamicProp.formType === "file" && dynamicProp.dependentProp  && dynamicProp.dependentPropValue && (!interactionObj['dynamicProperties_'+dynamicProp.name] || (interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name] === "NA")) && ((interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (interactionObj["dynamicProperties_" + dynamicProp.dependentProp] && interactionObj["dynamicProperties_" + dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) ||
                             (dynamicProp.formType === "file" && dynamicProp.dependentProp  && dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name] !== "NA") && ((interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (interactionObj["dynamicProperties_" + dynamicProp.dependentProp] && interactionObj["dynamicProperties_" + dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) ||
                             (dynamicProp.formType === "file" && dynamicProp.dependentProp  && !dynamicProp.dependentPropValue && (!interactionObj['dynamicProperties_'+dynamicProp.name] || (interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name] === "NA") || (interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name].length === 0)) && (interactionObj["dynamicProperties_" + dynamicProp.dependentProp])) ||
                             (dynamicProp.formType === "file" && dynamicProp.dependentProp  && !dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.name] && (interactionObj['dynamicProperties_'+dynamicProp.name] !== "NA" && interactionObj['dynamicProperties_'+dynamicProp.name].length !== 0))) ||
                             ((dynamicProp.name !== "licenseNumber") && (dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && interactionObj["dynamicProperties_" + dynamicProp.dependentProp] && ((interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (interactionObj["dynamicProperties_" + dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) ||
                             ((dynamicProp.name !== "licenseNumber") && (dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(interactionObj["dynamicProperties_" + dynamicProp.dependentProp]))) ||
                             ((dynamicProp.name !== "licenseNumber") && (dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && !dynamicProp.dependentPropValue && dynamicProp.dependentPropValues && interactionObj["dynamicProperties_" + dynamicProp.dependentProp] && ((interactionObj["dynamicProperties_" + dynamicProp.dependentProp].indexOf(dynamicProp.dependentPropValues.toString()) > -1) || (dynamicProp.dependentPropValues.includes(interactionObj["dynamicProperties_" + dynamicProp.dependentProp].substring(1))) || (dynamicProp.dependentPropValues.includes(interactionObj["dynamicProperties_" + dynamicProp.dependentProp].split(',')[1])))) ||
                             ((dynamicProp.formType === "date" || dynamicProp.formType === "time") && dynamicProp.dependentProp && (interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue)) ||
                             ((dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && dynamicProp.dependentProp && ((interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] && interactionObj['dynamicProperties_'+dynamicProp.dependentProp].length > 0 && interactionObj['dynamicProperties_'+dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue))))) ||
                             ((dynamicProp.formType === "radio" && (dynamicProp.enum || dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue)) ||
                             ((dynamicProp.formType === "radio" && (dynamicProp.enum || dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(interactionObj["dynamicProperties_" + dynamicProp.dependentProp]))) ||
                             ((dynamicProp.name === "interestedModel" && dynamicProp.formType === "select")) ||
                             ((dynamicProp.name === "finalMobile" && dynamicProp.formType === "select")) ||
                             ((dynamicProp.name === "exchangeEvaluator" && dynamicProp.formType === "select")) ||
                             ((dynamicProp.name === "selectedScheme" && dynamicProp.formType === "select")) ||
                             ((currentRow.displayName !== "Coupon Approval Task" && dynamicProp.name === "selectedCouponID" && (currentRow['dynamicProperties_negotiationMethod'] && currentRow['dynamicProperties_negotiationMethod'].split(",").includes("Coupon"))  && dynamicProp.formType === "checkbox")) ||
                             ((currentRow.displayName === "Coupon Approval Task" && dynamicProp.name === "selectedCouponID" && dynamicProp.formType === "checkbox")) ||
                             ((dynamicProp.name === "selectedOffer" && dynamicProp.formType === "select")) ||
                             ((dynamicProp.name === "manufacturer" && dynamicProp.formType === "select")) ||
                             ((dynamicProp.name === "otherCars" && dynamicProp.formType === "select")) ||
                             ((dynamicProp.name === "selectedAgent" && dynamicProp.formType === "select")) ||
                             ((dynamicProp.name === "selectedModel" && (!dynamicProp.dependentProp || (dynamicProp.dependentProp && (interactionObj["dynamicProperties_"+dynamicProp.dependentProp] === dynamicProp.dependentPropValue))) && dynamicProp.formType === "select")) ||
                             ((dynamicProp.name === "selectedVariant" && dynamicProp.formType === "select")) ||
                             ((dynamicProp.name === "selectedTestDriveModel"  && (interactionObj["dynamicProperties_"+dynamicProp.dependentProp] === dynamicProp.dependentPropValue) && dynamicProp.formType === "select")) ||
                             ((dynamicProp.name === "selectedTestDriveVariant" && (interactionObj["dynamicProperties_"+dynamicProp.dependentProp] === dynamicProp.dependentPropValue) && dynamicProp.formType === "select")) ||
                             ((dynamicProp.name === "selectedTestDriveCarID" && (interactionObj["dynamicProperties_typeOfTestDriveCar"] === "Test Drive Car") && dynamicProp.formType === "select")) ||
                             ((dynamicProp.name === "lostCaseApprovalRole" && (currentRow['dynamicProperties_outcome'] === "Lost") && dynamicProp.formType === "select")) ||
                             ((dynamicProp.name === "lostCaseAssignedUser" && (currentRow['dynamicProperties_outcome'] === "Lost") && dynamicProp.formType === "select")) ||
                             (((dynamicProp.name === "thirdPartyInsuranceCompanyID" || dynamicProp.name === "accessoriesInsuranceCompanyID" || dynamicProp.name === "cngInsuranceCompanyID" || dynamicProp.name === "insuranceCompanyID" || dynamicProp.name === "insuranceCompanyID2" || dynamicProp.name === "insuranceCompanyRenewalID" || dynamicProp.name === "totalLossInsuranceCompany" || dynamicProp.name === "renewalInsuranceCompanyID" || dynamicProp.name === "insuranceCompanyIDNew") && dynamicProp.formType === "select") && ((!dynamicProp.dependentProp) || (dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] === dynamicProp.dependentPropValue)) || (dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] && interactionObj['dynamicProperties_'+dynamicProp.dependentProp].length > 0 && interactionObj['dynamicProperties_'+dynamicProp.dependentProp].includes(dynamicProp.dependentPropValue))))) ||
                             ((currentAction.displayName === "Need Assessment" && (propIndex === action.dynamicProps.length - 1))) ||
                              ((dynamicProp.formType === "select" && dynamicProp.entityName && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && (!dynamicProp.dependentProp || (dynamicProp.dependentProp && (dynamicProp.dependentPropValue && ((interactionObj[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (interactionObj[dynamicProp.dependentProp] && interactionObj[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) || (dynamicProp.dependentPropValues && ((dynamicProp.dependentPropValues.includes(interactionObj[dynamicProp.dependentProp])) || (interactionObj[dynamicProp.dependentProp] && interactionObj[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item))))))))) ? '' : 'display: none;'}`}>
                              <div class={`input-box ${(currentRow.errorFields && currentRow.errorFields.length > 0 && (currentRow.errorFields.findIndex(item => item.fieldName === dynamicProp.name) > -1)) && 'error-div'}`} id={`${dynamicProp.name}-input-box`}>
                                <p class="formLabel fs-13 p-b-3">{dynamicProp.displayName}
                                  <span style={interactionObj["dynamicProperties_" + dynamicProp.name] ? "display: none": "display:inline"}  id={`required-${actionIndex}-`+dynamicProp.name} class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span>
                                  <span style={interactionObj["dynamicProperties_" + dynamicProp.name] ? "display: inline": "display:none"} id={`success-${actionIndex}-`+dynamicProp.name}> <svg fill="green" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="15" height="15"><path d="M22.319,4.431,8.5,18.249a1,1,0,0,1-1.417,0L1.739,12.9a1,1,0,0,0-1.417,0h0a1,1,0,0,0,0,1.417l5.346,5.345a3.008,3.008,0,0,0,4.25,0L23.736,5.847a1,1,0,0,0,0-1.416h0A1,1,0,0,0,22.319,4.431Z"/></svg></span>
                                </p>
                                {/*
                                  dynamicProp.formType !== "select" && (dynamicProp.name !== "licenseNumber") && dynamicProp.formType !== "radio" && dynamicProp.formType !== "checkbox" && !dynamicProp.dependentProp && (
                                    <p class="formLabel fs-13 p-b-3">{dynamicProp.displayName}
                                      <span style={interactionObj["dynamicProperties_" + dynamicProp.name] ? "display: none": "display:inline"}  id={`required-${actionIndex}-`+dynamicProp.name} class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span>
                                      <span style={interactionObj["dynamicProperties_" + dynamicProp.name] ? "display: inline": "display:none"} id={`success-${actionIndex}-`+dynamicProp.name}> <svg fill="green" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="15" height="15"><path d="M22.319,4.431,8.5,18.249a1,1,0,0,1-1.417,0L1.739,12.9a1,1,0,0,0-1.417,0h0a1,1,0,0,0,0,1.417l5.346,5.345a3.008,3.008,0,0,0,4.25,0L23.736,5.847a1,1,0,0,0,0-1.416h0A1,1,0,0,0,22.319,4.431Z"/></svg></span>
                                    </p>
                                  )
                                }
                                {
                                  (dynamicProp.formType === "radio" || dynamicProp.formType === "checkbox") && !dynamicProp.dependentProp && (
                                    <p class="formLabel fs-13 p-b-3">{dynamicProp.displayName}
                                      <span style={interactionObj["dynamicProperties_" + dynamicProp.name] ? "display: none": "display:inline"}  id={`required-${actionIndex}-`+dynamicProp.name} class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span>
                                      <span style={interactionObj["dynamicProperties_" + dynamicProp.name] ? "display: inline": "display:none"} id={`success-${actionIndex}-`+dynamicProp.name}> <svg fill="green" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="15" height="15"><path d="M22.319,4.431,8.5,18.249a1,1,0,0,1-1.417,0L1.739,12.9a1,1,0,0,0-1.417,0h0a1,1,0,0,0,0,1.417l5.346,5.345a3.008,3.008,0,0,0,4.25,0L23.736,5.847a1,1,0,0,0,0-1.416h0A1,1,0,0,0,22.319,4.431Z"/></svg></span>
                                    </p>
                                  )
                                */}
                                {/*
                                  Modification: T319 - Commented onFocusout function
                                  By: Ashutosh G
                                  Date: 23/05/2024
                                */}
                                {
                                  ((dynamicProp.name !== "licenseNumber") && (dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && !dynamicProp.dependentProp) && (
                                    <input autocomplete="off" maxlength={dynamicProp.max && dynamicProp.max} class="first-letter-capital"  disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} onClick={(e) => props.inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} checked={interactionObj["dynamicProperties_"+dynamicProp.name] ? true : false} value={interactionObj["dynamicProperties_" + dynamicProp.name] ? interactionObj["dynamicProperties_" + dynamicProp.name] : ''} onInput={(e) => props.setFormValueInput(e, dynamicProp.name, actionIndex)}
                                    // onFocusout={ (e) => props.saveFormInput(e, dynamicProp.name)}
                                    />
                                  )
                                }

                                {
                                  dynamicProp.name === "licenseNumber" && (
                                    <div>
                                      {/*<p class="formLabel fs-13 p-b-3">{dynamicProp.displayName}
                                        <span style={interactionObj["dynamicProperties_" + dynamicProp.name] ? "display: none": "display:inline"}  id={`required-${actionIndex}-`+dynamicProp.name} class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span>
                                        <span style={interactionObj["dynamicProperties_" + dynamicProp.name] ? "display: inline": "display:none"} id={`success-${actionIndex}-`+dynamicProp.name}> <svg fill="green" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="15" height="15"><path d="M22.319,4.431,8.5,18.249a1,1,0,0,1-1.417,0L1.739,12.9a1,1,0,0,0-1.417,0h0a1,1,0,0,0,0,1.417l5.346,5.345a3.008,3.008,0,0,0,4.25,0L23.736,5.847a1,1,0,0,0,0-1.416h0A1,1,0,0,0,22.319,4.431Z"/></svg></span>
                                      </p>*/}
                                      <input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} onClick={(e) => props.inputClicked(e)} maxlength="15" required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} value={interactionObj['dynamicProperties_'+dynamicProp.name]} onInput={(e) => props.setFormValueInput(e, dynamicProp.name, actionIndex)} />
                                    </div>
                                  )
                                }
                                {
                                  ((dynamicProp.formType === "select" && dynamicProp.entityName && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && (!dynamicProp.dependentProp || (dynamicProp.dependentProp && (dynamicProp.dependentPropValue && ((interactionObj[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (interactionObj[dynamicProp.dependentProp] && interactionObj[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) || (dynamicProp.dependentPropValues && ((dynamicProp.dependentPropValues.includes(interactionObj[dynamicProp.dependentProp])) || (interactionObj[dynamicProp.dependentProp] && interactionObj[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item)))))))) && (
                                    <div>
                                      {/*<p class="formLabel fs-13 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                                      <div class="display-flex">
                                        <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) => props.saveFormInput(e, dynamicProp.name, "select", 0)} >
                                          <option value=''>Select {dynamicProp.displayName}</option>
                                          {
                                            dynamicProp.enum.map((item) => (
                                              <option selected={interactionObj[dynamicProp.name] === (dynamicProp.enumIDKey ? item[dynamicProp.enumIDKey] : item.uuid)} value={(dynamicProp.enumIDKey ? item[dynamicProp.enumIDKey] : item.uuid)}>{item[dynamicProp.enumDisplayKey]}</option>
                                            ))
                                          }
                                        </select>
                                      </div>
                                    </div>
                                  )
                                }
                                {/*
                                  Modification: T319 - Commented onFocusout function
                                  By: Ashutosh G
                                  Date: 23/05/2024
                                */}
                                {
                                  (dynamicProp.formType === "date" || dynamicProp.formType === "time") && !dynamicProp.dependentProp && (
                                    <input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} value={moment(new Date(interactionObj["dynamicProperties_" + dynamicProp.name])).format('YYYY-MM-DD') || moment(new Date()).format('YYYY-MM-DD')} onInput={(e) => props.setFormValueInput(e, dynamicProp.name, actionIndex)}
                                    // onFocusout={ (e) => props.saveFormInput(e, dynamicProp.name)}
                                    max={(dynamicProp.typeOfValidation && dynamicProp.typeOfValidation === 'futureDateDisabled') && futureDateDisabled}
                                    />
                                  )
                                }
                                {
                                  (dynamicProp.formType === "file") && (!dynamicProp.dependentProp && (!interactionObj['dynamicProperties_'+dynamicProp.name] || (interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name] === "NA"))) && (
                                     <div>
                                        <input class="uploadInputFile" autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-${actionIndex}`} id={`${dynamicProp.name}-${actionIndex}`} type={dynamicProp.formType} value={interactionObj["dynamicProperties_" + dynamicProp.name]} accept="image/*,.pdf" onChange={(e) => props.uploadFile(e, dynamicProp.name)}/>
                                        <label class="p-t-b-5" for={`${dynamicProp.name}-${actionIndex}`}>
                                          <figure>
                                            <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="24" height="24"><path d="M11.007,2.578,11,18.016a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l.007-15.421,2.912,2.913a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L14.122.879a3,3,0,0,0-4.244,0L6.667,4.091a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0Z"/><path d="M22,17v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17a1,1,0,0,0-1-1h0A1,1,0,0,0,22,17Z"/></svg>
                                          </figure>
                                          <span>Choose a file</span>
                                        </label>
                                     </div>
                                  )
                                }
                                {
                                  dynamicProp.formType === "file" && !dynamicProp.dependentProp && (interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name] !== "NA") && (
                                    <div class="w-full">
                                      <div class="uploaded-image">
                                        <button type="button" id={`image-${index}-${propIndex}`} class="primary-button cursor-pointer object-fit-contain w-full" onClick={(e)=> getReferenceFilesForFileViewer(e, 'Uploaded Files', true, dynamicProp.name, interactionObj.uuid)}>View Uploaded File</button>
                                        {!(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) &&
                                          <p class="delete-file-icon" title="Delete file" onClick={(e) => props.deleteUploadedFile(e, dynamicProp.name)}>{'x'}</p>
                                        }
                                      </div>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && !dynamicProp.dependentProp && (
                                    <div class="display-flex formRadioButtons">
                                      <div class="display-flex p-r-10 align-center">
                                        <input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} required={dynamicProp.isRequired ? true : false} name={`${dynamicProp.name}-${actionIndex}-yes`} id={`${dynamicProp.name}-${actionIndex}-yes`} type={dynamicProp.formType} defaultChecked={currentRow['dynamicProperties_' + dynamicProp.name] === "yes" ? true : false} value="yes" onChange={(e) => props.saveFormInputRadioDefault(e, dynamicProp.name)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${actionIndex}-yes`}>Yes</label>
                                        <input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} required={dynamicProp.isRequired ? true : false} name={`${dynamicProp.name}-${actionIndex}-no`} id={`${dynamicProp.name}-${actionIndex}-no`} type={dynamicProp.formType} defaultChecked={currentRow['dynamicProperties_' + dynamicProp.name] === "no" ? true : false} value="no" onChange={(e) => props.saveFormInputRadioDefault(e, dynamicProp.name)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${actionIndex}-no`}>No </label>
                                      </div>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.formType === "radio" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp && (
                                    <div class="display-flex formRadioButtons">
                                      {
                                        dynamicProp.enum.map((enumVal) => (
                                          <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} name={`${dynamicProp.name}-${actionIndex}-${enumVal}`} id={`${dynamicProp.name}-${actionIndex}-${enumVal}`} type={dynamicProp.formType} checked={interactionObj["dynamicProperties_" + dynamicProp.name] === enumVal} value={interactionObj["dynamicProperties_" + dynamicProp.name]} onChange={(e) => props.saveFormInputRadio(e, dynamicProp.name, enumVal)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${actionIndex}-${enumVal}`}>{enumVal}</label></div>
                                        ))
                                      }
                                    </div>
                                  )
                                }
                                {/*
                                  Bug:- COMP_BG_589
                                  Modification:- Interaction object is now sent as a parameter
                                  Name:- Rajkumar Pradhan
                                  On:- 10-10-2023
                                */}
                                {
                                  (dynamicProp.formType === "checkbox" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp && (
                                    <div>
                                      {
                                        dynamicProp.enum.map((enumVal) => (
                                          <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} name={`${dynamicProp.name}-${actionIndex}-${enumVal}`} id={`${dynamicProp.name}-${actionIndex}-${enumVal}`} type={dynamicProp.formType} defaultChecked={interactionObj["dynamicProperties_"+dynamicProp.name] ? interactionObj["dynamicProperties_"+dynamicProp.name].split(",").includes(enumVal) : ""} onChange={(e) => props.saveFormInputCheckbox(e, dynamicProp.name, enumVal, interactionObj["dynamicProperties_" + dynamicProp.name])}/> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${actionIndex}-${enumVal}`}>{enumVal}</label></div>
                                        ))
                                      }
                                    </div>
                                  )
                                }
                                {
                                  ((dynamicProp.name === "area" || dynamicProp.name === "companyArea") && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (
                                    <div>
                                      {/*<p class="formLabel fs-13 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) =>  props.saveAreaFromForm(e,dynamicProp.name)} >
                                        {
                                          areaList.map((enumVal) => (
                                            <option selected={interactionObj["dynamicProperties_" + dynamicProp.name] === enumVal.officeName} value={enumVal.officeName}>{enumVal.officeName}</option>
                                          ))
                                        }
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  ((dynamicProp.name === "state") && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (
                                    <div>
                                      {/*<p class="formLabel fs-13 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) =>  props.setFormValueInput(e,dynamicProp.name, actionIndex)} >
                                        <option value=''>Select State</option>
                                        {
                                          stateList && stateList.length > 0 && stateList.map((enumVal) => (
                                            <option selected={interactionObj["dynamicProperties_" + dynamicProp.name] === enumVal.name} value={enumVal.name}>{enumVal.name}</option>
                                          ))
                                        }
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  ((dynamicProp.name === "city") && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (
                                    <div>
                                      {/*<p class="formLabel fs-13 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) =>  props.saveAreaFromForm(e,dynamicProp.name)} >
                                        <option value=''>Select City</option>
                                        {
                                          cityList && cityList.length > 0 && cityList.map((enumVal) => (
                                            <option selected={interactionObj["dynamicProperties_" + dynamicProp.name] === enumVal.name} value={enumVal.name}>{enumVal.name}</option>
                                          ))
                                        }
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  ((dynamicProp.name !== "finalMobile" && dynamicProp.name !== "interestedModel" && dynamicProp.name !== "testDriveApprovalRole" && dynamicProp.name !== "testDriveAssignedUser")  && dynamicProp.formType === "select" && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp && (
                                    <div>
                                      {/*<p class="formLabel fs-13 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                                      <div class="display-flex">
                                        <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={ (e) => props.saveFormInput(e, dynamicProp.name, "select", index)} >
                                          <option value=''>Select {dynamicProp.displayName}</option>
                                          {
                                            dynamicProp.enum.map((enumVal) => (
                                              <option selected={interactionObj["dynamicProperties_" + dynamicProp.name] === enumVal} value={enumVal}>{enumVal}</option>
                                            ))
                                          }
                                        </select>
                                      </div>
                                    </div>
                                  )
                                }
                                {
                                  ((dynamicProp.name !== "finalMobile" && dynamicProp.name !== "interestedModel" && dynamicProp.name !== "testDriveApprovalRole" && dynamicProp.name !== "testDriveAssignedUser")  && dynamicProp.formType === "select" && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValue !== "NA" && (interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) && (
                                    <div>
                                      {/*<p class="formLabel fs-13 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                                      <div class="display-flex">
                                        <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={ (e) => props.saveFormInput(e, dynamicProp.name, "select", index)} >
                                          <option value=''>Select {dynamicProp.displayName}</option>
                                          {
                                            dynamicProp.enum.map((enumVal) => (
                                              <option selected={interactionObj["dynamicProperties_" + dynamicProp.name] === enumVal} value={enumVal}>{enumVal}</option>
                                            ))
                                          }
                                        </select>
                                      </div>
                                    </div>
                                  )
                                }
                                {
                                  ((dynamicProp.name !== "finalMobile" && dynamicProp.name !== "interestedModel" && dynamicProp.name !== "testDriveApprovalRole" && dynamicProp.name !== "testDriveAssignedUser") && dynamicProp.formType === "select" && (dynamicProp.dependentEnum && dynamicProp.dependentEnum.length !== 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (dynamicProp.dependentEnum.findIndex((d) => d.valueOfProp === interactionObj["dynamicProperties_" + d.nameOfProp]) > -1) && !dynamicProp.dependentProp && (
                                    <div>
                                      {/*<p class="formLabel fs-13 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                                      <div class="display-flex">
                                        <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={ (e) => props.saveFormInput(e, dynamicProp.name)} >
                                          <option value=''>Select {dynamicProp.displayName}</option>
                                          {
                                            dynamicProp.dependentEnum[dynamicProp.dependentEnum.findIndex((d) => d.valueOfProp === interactionObj["dynamicProperties_" + d.nameOfProp])].dependentEnums.map((enumVal) => (
                                              <option selected={interactionObj["dynamicProperties_" + dynamicProp.name] === enumVal} value={enumVal}>{enumVal}</option>
                                            ))
                                          }
                                        </select>
                                      </div>
                                    </div>
                                  )
                                }
                                {
                                  (((dynamicProp.formType === "select" && (dynamicProp.multipleDependentEnum && dynamicProp.multipleDependentEnum.length !== 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (getFilteredList(dynamicProp.multipleDependentEnum).length === 1) && !dynamicProp.dependentProp) &&
                                    <div>
                                      {/*<p class="formLabel fs-13 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                                      <div class="display-flex">
                                        <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={ (e) => props.saveFormInput(e, dynamicProp.name)} >
                                          <option value=''>Select {dynamicProp.displayName}</option>
                                          {
                                            getFilteredList(dynamicProp.multipleDependentEnum).length > 0 && getFilteredList(dynamicProp.multipleDependentEnum)[0].dependentEnums.map((enumVal) => (
                                              <option selected={interactionObj["dynamicProperties_" + dynamicProp.name] === enumVal} value={enumVal}>{enumVal}</option>
                                            ))
                                          }
                                        </select>
                                      </div>
                                    </div>
                                  )
                                }
                                {/*
                                  dynamicProp.formType !== "select" && (dynamicProp.name !== "licenseNumber") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && dynamicProp.dependentPropValue !== "NA" && interactionObj["dynamicProperties_" + dynamicProp.dependentProp] && ((interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (interactionObj["dynamicProperties_" + dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))) && (
                                    <p class="formLabel fs-13 p-b-3">{dynamicProp.displayName}
                                        <span style={interactionObj["dynamicProperties_" + dynamicProp.name] ? "display: none": "display:inline"}  id={`required-${actionIndex}-`+dynamicProp.name} class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span>
                                        <span style={interactionObj["dynamicProperties_" + dynamicProp.name] ? "display: inline": "display:none"} id={`success-${actionIndex}-`+dynamicProp.name}> <svg fill="green" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="15" height="15"><path d="M22.319,4.431,8.5,18.249a1,1,0,0,1-1.417,0L1.739,12.9a1,1,0,0,0-1.417,0h0a1,1,0,0,0,0,1.417l5.346,5.345a3.008,3.008,0,0,0,4.25,0L23.736,5.847a1,1,0,0,0,0-1.416h0A1,1,0,0,0,22.319,4.431Z"/></svg></span>
                                      </p>
                                  )
                                }
                                {
                                  dynamicProp.formType !== "select" && (dynamicProp.name !== "licenseNumber") && dynamicProp.dependentProp && !dynamicProp.dependentPropValue && (dynamicProp.dependentPropValues.includes(interactionObj["dynamicProperties_" + dynamicProp.dependentProp])) && (
                                    <p class="formLabel fs-13 p-b-3">{dynamicProp.displayName}
                                        <span style={interactionObj["dynamicProperties_" + dynamicProp.name] ? "display: none": "display:inline"}  id={`required-${actionIndex}-`+dynamicProp.name} class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span>
                                        <span style={interactionObj["dynamicProperties_" + dynamicProp.name] ? "display: inline": "display:none"} id={`success-${actionIndex}-`+dynamicProp.name}> <svg fill="green" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="15" height="15"><path d="M22.319,4.431,8.5,18.249a1,1,0,0,1-1.417,0L1.739,12.9a1,1,0,0,0-1.417,0h0a1,1,0,0,0,0,1.417l5.346,5.345a3.008,3.008,0,0,0,4.25,0L23.736,5.847a1,1,0,0,0,0-1.416h0A1,1,0,0,0,22.319,4.431Z"/></svg></span>
                                      </p>
                                  )
                                */}

                                {
                                  (dynamicProp.formType === "checkbox" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && (dynamicProp.name === "negotiationMethod" || dynamicProp.name === "addOnCoverage") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] === dynamicProp.dependentPropValue) && (
                                    <div>
                                      {
                                        dynamicProp.enum.map((enumVal) => (
                                          <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} name={`${dynamicProp.name}-${actionIndex}-${enumVal}`} id={`${dynamicProp.name}-${actionIndex}-${enumVal}`} type={dynamicProp.formType} defaultChecked={interactionObj["dynamicProperties_"+dynamicProp.name] ? interactionObj["dynamicProperties_"+dynamicProp.name].split(",").includes(enumVal) : ""} onChange={(e) =>  props.saveFormInputCheckbox(e, dynamicProp.name, enumVal)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${actionIndex}-${enumVal}`}>{enumVal}</label></div>
                                        ))
                                      }
                                    </div>
                                  )
                                }
                                {
                                  dynamicProp.formType === "file" && dynamicProp.dependentProp  && dynamicProp.dependentPropValue && (!interactionObj['dynamicProperties_'+dynamicProp.name] || (interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name] === "NA")) && ((interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (interactionObj["dynamicProperties_" + dynamicProp.dependentProp] && interactionObj["dynamicProperties_" + dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))) && (
                                   <div>
                                      <input class="uploadInputFile" autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-${actionIndex}`} id={`${dynamicProp.name}-${actionIndex}`} type={dynamicProp.formType} accept="image/*,.pdf" onChange={(e) => props.uploadFile(e, dynamicProp.name)}/>
                                       <label class="p-t-b-5" for={`${dynamicProp.name}-${actionIndex}`}>
                                         <figure>
                                           <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="24" height="24"><path d="M11.007,2.578,11,18.016a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l.007-15.421,2.912,2.913a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L14.122.879a3,3,0,0,0-4.244,0L6.667,4.091a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0Z"/><path d="M22,17v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17a1,1,0,0,0-1-1h0A1,1,0,0,0,22,17Z"/></svg>
                                         </figure>
                                         <span>Choose a file</span>
                                       </label>
                                    </div>
                                  )
                                }


                                {
                                  dynamicProp.formType === "file" && dynamicProp.dependentProp  && dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name] !== "NA") && ((interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (interactionObj["dynamicProperties_" + dynamicProp.dependentProp] && interactionObj["dynamicProperties_" + dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))) && (
                                    <div class="w-full">
                                      <div class="uploaded-image">
                                        <button type="button" id={`image-${index}-${propIndex}`} class="primary-button cursor-pointer object-fit-contain w-full" onClick={(e)=> getReferenceFilesForFileViewer(e, 'Uploaded Files', true, dynamicProp.name, interactionObj.uuid)}>View Uploaded File</button>
                                        {!(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) &&
                                          <p class="delete-file-icon" title="Delete file" onClick={(e) => props.deleteUploadedFile(e, dynamicProp.name)}>{'x'}</p>
                                        }
                                      </div>
                                    </div>
                                  )
                                }
                                {
                                  dynamicProp.formType === "file" && dynamicProp.dependentProp  && !dynamicProp.dependentPropValue && (!interactionObj['dynamicProperties_'+dynamicProp.name] || (interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name] === "NA") || (interactionObj['dynamicProperties_'+dynamicProp.name] && interactionObj['dynamicProperties_'+dynamicProp.name].length === 0)) && (interactionObj["dynamicProperties_" + dynamicProp.dependentProp]) && (
                                    <div>
                                       <input class="uploadInputFile" autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-${actionIndex}`} id={`${dynamicProp.name}-${actionIndex}`} type={dynamicProp.formType} accept="image/*,.pdf" onChange={(e) => props.uploadFile(e, dynamicProp.name)}/>
                                        <label class="p-t-b-5" for={`${dynamicProp.name}-${actionIndex}`}>
                                          <figure>
                                            <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="24" height="24"><path d="M11.007,2.578,11,18.016a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l.007-15.421,2.912,2.913a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L14.122.879a3,3,0,0,0-4.244,0L6.667,4.091a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0Z"/><path d="M22,17v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17a1,1,0,0,0-1-1h0A1,1,0,0,0,22,17Z"/></svg>
                                          </figure>
                                          <span>Choose a file</span>
                                        </label>
                                     </div>
                                  )
                                }
                                {
                                  dynamicProp.formType === "file" && dynamicProp.dependentProp  && !dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.name] && (interactionObj['dynamicProperties_'+dynamicProp.name] !== "NA" && interactionObj['dynamicProperties_'+dynamicProp.name].length !== 0)) && (
                                    <div class="w-full">
                                      <div class="uploaded-image">
                                        <button type="button" id={`image-${index}-${propIndex}`} class="primary-button cursor-pointer object-fit-contain w-full" onClick={(e)=> getReferenceFilesForFileViewer(e, 'Uploaded Files', true, dynamicProp.name, interactionObj.uuid)}>View Uploaded File</button>
                                        {!(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) &&
                                          <p class="delete-file-icon" title="Delete file" onClick={(e) => props.deleteUploadedFile(e, dynamicProp.name)}>{'x'}</p>
                                        }
                                      </div>
                                    </div>
                                  )
                                }
                                {/*
                                  Modification: T319 - Commented onFocusout function
                                  By: Ashutosh G
                                  Date: 23/05/2024
                                */}
                                {
                                  (dynamicProp.name !== "licenseNumber") && (dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && interactionObj["dynamicProperties_" + dynamicProp.dependentProp] && ((interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (interactionObj["dynamicProperties_" + dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))) && (
                                    <input autocomplete="off" maxlength={dynamicProp.max && dynamicProp.max} class="first-letter-capital"  disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} onClick={(e) => props.inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} checked={interactionObj["dynamicProperties_"+dynamicProp.name] ? true : false} value={interactionObj["dynamicProperties_" + dynamicProp.name] ? interactionObj["dynamicProperties_" + dynamicProp.name] : ''} onInput={(e) => props.setFormValueInput(e, dynamicProp.name, actionIndex)}
                                    // onFocusout={ (e) => props.saveFormInput(e, dynamicProp.name)}
                                    />
                                  )
                                }
                                {/*
                                  Modification: T319 - Commented onFocusout function
                                  By: Ashutosh G
                                  Date: 23/05/2024
                                */}
                                {
                                  (dynamicProp.name !== "licenseNumber") && (dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(interactionObj["dynamicProperties_" + dynamicProp.dependentProp])) && (
                                    <input autocomplete="off" maxlength={dynamicProp.max && dynamicProp.max} class="first-letter-capital"  disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} onClick={(e) => props.inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} checked={interactionObj["dynamicProperties_"+dynamicProp.name] ? true : false} value={interactionObj["dynamicProperties_" + dynamicProp.name] ? interactionObj["dynamicProperties_" + dynamicProp.name] : ''} onInput={(e) => props.setFormValueInput(e, dynamicProp.name, actionIndex)}
                                    // onFocusout={ (e) => props.saveFormInput(e, dynamicProp.name)}
                                    />
                                  )
                                }
                                {/*
                                  Modification: T319 - Commented onFocusout function
                                  By: Ashutosh G
                                  Date: 23/05/2024
                                */}
                                {
                                  (dynamicProp.name !== "licenseNumber") && (dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && !dynamicProp.dependentPropValue && dynamicProp.dependentPropValues && interactionObj["dynamicProperties_" + dynamicProp.dependentProp] && (
                                    (interactionObj["dynamicProperties_" + dynamicProp.dependentProp].indexOf(dynamicProp.dependentPropValues.toString()) > -1)
                                    || (dynamicProp.dependentPropValues.includes(interactionObj["dynamicProperties_" + dynamicProp.dependentProp].substring(1)))
                                    || (dynamicProp.dependentPropValues.includes(interactionObj["dynamicProperties_" + dynamicProp.dependentProp].split(',')[1]))
                                    ) && (
                                    <div>
                                      {/*<p class="formLabel fs-13 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>*/}
                                      <input autocomplete="off" maxlength={dynamicProp.max && dynamicProp.max} class="first-letter-capital"  disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} onClick={(e) => props.inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} checked={interactionObj["dynamicProperties_"+dynamicProp.name] ? true : false} value={interactionObj["dynamicProperties_" + dynamicProp.name] ? interactionObj["dynamicProperties_" + dynamicProp.name] : ''} onInput={(e) => props.setFormValueInput(e, dynamicProp.name, actionIndex)}
                                      // onFocusout={ (e) => props.saveFormInput(e, dynamicProp.name)}
                                      />
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.formType === "date" || dynamicProp.formType === "time") && dynamicProp.dependentProp && (interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) && (
                                    <input autocomplete="off" maxlength={dynamicProp.max && dynamicProp.max} disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} value={moment(new Date(interactionObj["dynamicProperties_" + dynamicProp.name])).format('YYYY-MM-DD') || moment(new Date()).format('YYYY-MM-DD')} onChange={ (e) => props.saveFormInput(e, dynamicProp.name)}  max={(dynamicProp.typeOfValidation && dynamicProp.typeOfValidation === 'futureDateDisabled') && futureDateDisabled}/>
                                  )
                                }
                                {
                                  (dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && ((dynamicProp.dependentProp && (interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] && interactionObj['dynamicProperties_'+dynamicProp.dependentProp].length > 0 && interactionObj['dynamicProperties_'+dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue))))) && (
                                    <div class="display-flex p-r-10 align-center">
                                      <input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-${actionIndex}-yes`} id={`${dynamicProp.name}-${actionIndex}-yes`} type={dynamicProp.formType} checked={currentRow['dynamicProperties_' + dynamicProp.name] === "yes" ? true : false} value="yes" onChange={(e) => props.saveFormInputRadioDefault(e, dynamicProp.name)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${actionIndex}-yes`}>Yes </label>
                                      <input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-${actionIndex}-no`} id={`${dynamicProp.name}-${actionIndex}-no`} type={dynamicProp.formType} checked={currentRow['dynamicProperties_' + dynamicProp.name] === "no" ? true : false} value="no" onChange={(e) => props.saveFormInputRadioDefault(e, dynamicProp.name)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${actionIndex}-no`}>No </label>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.formType === "radio" && (dynamicProp.enum || dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj["dynamicProperties_" + dynamicProp.dependentProp] === dynamicProp.dependentPropValue) && (
                                    <div class="display-flex formRadioButtons">
                                      {
                                        dynamicProp.enum.map((enumVal) => (
                                          <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} name={`${dynamicProp.name}-${actionIndex}-${enumVal}`} id={`${dynamicProp.name}-${actionIndex}-${enumVal}`} type={dynamicProp.formType} checked={interactionObj["dynamicProperties_" + dynamicProp.name] === enumVal} value={interactionObj["dynamicProperties_" + dynamicProp.name]} onChange={(e) => props.saveFormInputRadio(e, dynamicProp.name, enumVal)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${actionIndex}-${enumVal}`}>{enumVal}</label></div>
                                        ))
                                      }
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.formType === "radio" && (dynamicProp.enum || dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(interactionObj["dynamicProperties_" + dynamicProp.dependentProp])) && (
                                    <div class="display-flex formRadioButtons">
                                      {
                                        dynamicProp.enum.map((enumVal) => (
                                          <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} name={`${dynamicProp.name}-${actionIndex}-${enumVal}`} id={`${dynamicProp.name}-${actionIndex}-${enumVal}`} type={dynamicProp.formType} checked={interactionObj["dynamicProperties_" + dynamicProp.name] === enumVal} value={interactionObj["dynamicProperties_" + dynamicProp.name]} onChange={(e) => props.saveFormInputRadio(e, dynamicProp.name, enumVal)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${actionIndex}-${enumVal}`}>{enumVal}</label></div>
                                        ))
                                      }
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.name === "interestedModel" && dynamicProp.formType === "select") && (
                                    <div class="display-flex">
                                      {/*<select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={model} onChange={e => {
                                              props.getVariantForSelectedModel(e.target.value)
                                              setModel(e.target.value)
                                              saveFormInput(e, dynamicProp.name)
                                              }
                                              }>
                                              <option style="text-transform:capitalize" value="" selected>Select Model</option>
                                              {catalogoueItemList.map((item)=>(
                                                <option style="text-transform:capitalize;" value={item.uuid}>{item.displayName}</option>
                                              ))}
                                              </select>
                                              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0 m-0" >
                                                <div class="row">
                                                    <div class="col-xs-12 p-l-0">
                                                      <p class=" fs-15 p-b-3">{dynamicProp.displayName}</p>
                                                    </div>
                                                </div>
                                            </div>*/}
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.name === "finalMobile" && dynamicProp.formType === "select") && (
                                    <div class="display-block">
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={interactionObj[dynamicProp.name]} onChange={e => {
                                        props.saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" selected>Select Final Mobile</option>
                                        <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === interactionObj.dynamicProperties_mobile} value={interactionObj.dynamicProperties_mobile}>{interactionObj.dynamicProperties_mobile}</option>
                                        {
                                          interactionObj.dynamicProperties_alternateMobile && (
                                            <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === interactionObj.dynamicProperties_alternateMobile} value={interactionObj.dynamicProperties_alternateMobile}>{interactionObj.dynamicProperties_alternateMobile}</option>
                                          )
                                        }
                                        {
                                          interactionObj.dynamicProperties_alternateNumber && (
                                            <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === interactionObj.dynamicProperties_alternateNumber} value={interactionObj.dynamicProperties_alternateNumber}>{interactionObj.dynamicProperties_alternateNumber}</option>
                                          )
                                        }
                                        {
                                          interactionObj.dynamicProperties_homePhone && (
                                            <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === interactionObj.dynamicProperties_homePhone} value={interactionObj.dynamicProperties_homePhone}>{interactionObj.dynamicProperties_homePhone}</option>
                                          )
                                        }
                                        {
                                          interactionObj.dynamicProperties_otherPhone && (
                                            <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === interactionObj.dynamicProperties_otherPhone} value={interactionObj.dynamicProperties_otherPhone}>{interactionObj.dynamicProperties_otherPhone}</option>
                                          )
                                        }
                                        {
                                          interactionObj.dynamicProperties_whatsappNumber && (
                                            <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === interactionObj.dynamicProperties_whatsappNumber} value={interactionObj.dynamicProperties_whatsappNumber}>{interactionObj.dynamicProperties_whatsappNumber}</option>
                                          )
                                        }
                                        {
                                          interactionObj.dynamicProperties_contactPersonmobile && (
                                            <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === interactionObj.dynamicProperties_contactPersonmobile} value={interactionObj.dynamicProperties_contactPersonmobile}>{interactionObj.dynamicProperties_contactPersonmobile}</option>
                                          )
                                        }
                                        {
                                          interactionObj.dynamicProperties_contactPersonwhatsappNumber && (
                                            <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === interactionObj.dynamicProperties_contactPersonwhatsappNumber} value={interactionObj.dynamicProperties_contactPersonwhatsappNumber}>{interactionObj.dynamicProperties_contactPersonwhatsappNumber}</option>
                                          )
                                        }
                                        {
                                          interactionObj.dynamicProperties_alternateMobileNumber && (
                                            <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === interactionObj.dynamicProperties_alternateMobileNumber} value={interactionObj.dynamicProperties_alternateMobileNumber}>{interactionObj.dynamicProperties_alternateMobileNumber}</option>
                                          )
                                        }
                                        {
                                          interactionObj.dynamicProperties_whatsAppNumber && (
                                            <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === interactionObj.dynamicProperties_whatsAppNumber} value={interactionObj.dynamicProperties_whatsAppNumber}>{interactionObj.dynamicProperties_whatsAppNumber}</option>
                                          )
                                        }
                                      </select>
                                    </div>
                                  )
                                }
                                {/*
                                        (dynamicProp.name === "interestedModel" && dynamicProp.formType === "select") && model && variantList && (
                                          <div class="display-flex">
                                            <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id='variant' value={variant} onChange={e => {
                                              props.getVariantDetails(e.target.value)
                                              setVariant(e.target.value)
                                              saveFormInput(e, dynamicProp.name)
                                            }}>
                                              <option value="" selected>Select Variant</option>
                                              {
                                                variantList.map((variant)=>(
                                                  <option style="text-transform:capitalize" value={variant.uuid}>{variant.variant}</option>
                                                ))
                                              }
                                            </select>
                                          </div>
                                        )
                                      */}
                                {
                                  (dynamicProp.name === "exchangeEvaluator" && dynamicProp.formType === "select") && (
                                    <div class="display-block">
                                      <p class="formLabel fs-13">Exchange Evaluator</p>
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={currentRow['dynamicProperties_exchangeEvaluator']} onChange={e => {
                                        props.saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" selected>Select Exchange Evaluator</option>
                                        {exchangEvaluators.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === item.userID} value={item.userID}>{item.userName}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.name === "selectedScheme" && dynamicProp.formType === "select") && (
                                    <div class="display-block">
                                      <p class="fs-15 first-letter-capital">{dynamicProp.displayName + " " }</p>
                                      {
                                        schemes.map((scheme, index) => (
                                          <div class="display-flex">
                                            <input autocomplete="off" type="checkbox" value={scheme.uuid} defaultChecked={interactionObj["dynamicProperties_"+dynamicProp.name] === scheme.uuid} default={interactionObj["dynamicProperties_" + dynamicProp.name]} disabled={((scheme.displayName.includes("Exchange") && interactionObj["dynamicProperties_typeOfBuyer"] !== "Exchange Buyer")) || (dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) } onChange={(e) => props.saveFormInputRadio(e, dynamicProp.name, scheme.uuid, index)} />
                                            <label class='p-l-10 p-r-10' style='font-weight: 300;'>{scheme.displayName + " - " + getFormattedAmount(scheme.discountAfterGST)  }</label>
                                          </div>
                                        ))
                                      }
                                    </div>
                                  )
                                }
                                {
                                  (currentRow.displayName !== "Coupon Approval Task" && dynamicProp.name === "selectedCouponID" && (currentRow['dynamicProperties_negotiationMethod'] && currentRow['dynamicProperties_negotiationMethod'].split(",").includes("Coupon"))  && dynamicProp.formType === "checkbox") && (
                                    <div class="display-block">
                                      {
                                        coupons && coupons.length > 0 && coupons.map((coupon, index) => (
                                          <div class="display-flex">
                                            <input name={`${coupon.uuid}-${actionIndex}`} id={`${coupon.uuid}-${actionIndex}`} autocomplete="off" type="checkbox" value={coupon.uuid} default={interactionObj["dynamicProperties_" + dynamicProp.name]} defaultChecked={interactionObj["dynamicProperties_"+dynamicProp.name] ? interactionObj["dynamicProperties_"+dynamicProp.name].split(",").includes(coupon.uuid) : ""} onChange={(e) =>  props.saveFormInputCheckbox(e, dynamicProp.name, coupon.uuid)} disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} />
                                            <label for={`${coupon.uuid}-${actionIndex}`} class='h-auto p-l-10 p-r-10' style='font-weight: 300;'>{coupon.displayName} - {getFormattedAmount(coupon.amount)}</label>
                                          </div>
                                        ))
                                      }
                                    </div>
                                  )
                                }
                                {
                                  (currentRow.displayName === "Coupon Approval Task" && dynamicProp.name === "selectedCouponID" && dynamicProp.formType === "checkbox") && (
                                    <div class="display-block">
                                      <p class="formLabel fs-13">Total offer amount: {(coupons.length > 0 && interactionObj["dynamicProperties_selectedCouponID"]) && getFormattedAmount(interactionObj["dynamicProperties_selectedCouponID"].split(',').reduce((acc, obj) => {return acc + ((coupons.find(coupon => coupon.uuid === obj) && coupons.find(coupon => coupon.uuid === obj).amount) ? Number(coupons.find(coupon => coupon.uuid === obj).amount) : 0)}, 0))}</p>
                                      {
                                        coupons && coupons.length > 0 && coupons.map((coupon, index) => (
                                          <div class="display-flex">
                                            <input name={`${coupon.uuid}-${actionIndex}`} id={`${coupon.uuid}-${actionIndex}`} autocomplete="off" type="checkbox" value={coupon.uuid} default={interactionObj["dynamicProperties_" + dynamicProp.name]} defaultChecked={interactionObj["dynamicProperties_"+dynamicProp.name] ? interactionObj["dynamicProperties_"+dynamicProp.name].split(",").includes(coupon.uuid) : ""} onChange={(e) =>  props.saveFormInputCheckbox(e, dynamicProp.name, coupon.uuid)} disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} />
                                            <label for={`${coupon.uuid}-${actionIndex}`} class='h-auto p-l-10 p-r-10' style='font-weight: 300;'>{coupon.displayName} - {getFormattedAmount(coupon.amount)}</label>
                                          </div>
                                        ))
                                      }
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.name === "selectedOffer" && dynamicProp.formType === "select") && (
                                    <div class="display-block">
                                      <p class="formLabel fs-13">Dealer Offer</p>
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={currentRow['dynamicProperties_selectedOffer']} onChange={e => {
                                        props.saveFormInput(e, dynamicProp.name);
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" selected>Select Offer</option>
                                        {offers.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === item.uuid} value={item.uuid}>{item.displayName}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.name === "manufacturer" && dynamicProp.formType === "select") && (
                                    <div>
                                    <p class="formLabel fs-13">Select Manufacturer</p>

                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={interactionObj['dynamicProperties_manufacturer']} onChange={e => {
                                        props.setSelectedMake(e)
                                        // saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" >Select Manufacturer</option>
                                        {listofManufacturers.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj['dynamicProperties_manufacturer'] === item} value={item}>{item}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }

                                {
                                  (dynamicProp.name === "otherCars" && dynamicProp.formType === "select") && (
                                    <div>
                                    <p class="formLabel fs-13">{dynamicProp.displayName}</p>

                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={interactionObj['dynamicProperties_otherCars']} onChange={e => {
                                        // setSelectedMake(e)
                                        props.saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" >Select Manufacturer</option>
                                        {listofManufacturers.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj['dynamicProperties_otherCars'] === item} value={item}>{item}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.name === "selectedAgent" && dynamicProp.formType === "select") && (
                                    <div class="display-block">
                                      {/*<p class="fs-15 formLabel">{dynamicProp.displayName}</p>*/}
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" value={currentRow['dynamicProperties_selectedAgent']} onChange={e => {
                                        props.saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" disabled selected>{dynamicProp.displayName}<span class="star-mandatory-entry p-l-2"></span></option>
                                        {exchangEvaluators.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === item.userID} value={item.userID}>{item.userName}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {/*
                                  (dynamicProp.name === "exchangeCarModel" && dynamicProp.formType === "select") && (
                                    <div class="display-flex">
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={interactionObj['dynamicProperties_exchangeCarModel']} onChange={e => {
                                        props.setSelectedExchangeModel(e)
                                        // saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" >Select Model</option>
                                        {listOfModels && listOfModels.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj['dynamicProperties_exchangeCarModel'] === item.Model + " - " + item.Category + " - " + item.Year} value={item.Model + " - " + item.Category + " - " + item.Year}>{item.Model + " - " + item.Category + " - " + item.Year}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                */}

                                {
                                  (dynamicProp.name === "selectedModel" && (!dynamicProp.dependentProp || (dynamicProp.dependentProp && (interactionObj["dynamicProperties_"+dynamicProp.dependentProp] === dynamicProp.dependentPropValue))) && dynamicProp.formType === "select") && (
                                    <div class="display-flex">
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={selectedModel} onChange={e => {
                                        props.saveSelectedModel(e)
                                        // saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" >Select Model</option>
                                        {catalogoueItemList.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj['dynamicProperties_selectedModel'] === item.uuid} value={item.uuid}>{modelMapping[item.displayName.toLowerCase()]}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.name === "selectedVariant" && dynamicProp.formType === "select") && (
                                    <div class="display-flex">
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={selectedModel} onChange={e => {
                                        props.saveSelectedVariant(e)
                                        // saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" >Select Variant</option>
                                        {variantList.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj['dynamicProperties_selectedVariant'] === item.uuid} value={item.uuid}>{item.variant}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.name === "selectedTestDriveModel"  && (interactionObj["dynamicProperties_"+dynamicProp.dependentProp] === dynamicProp.dependentPropValue) && dynamicProp.formType === "select") && (
                                    <div class="display-flex">
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={selectedModel} onChange={e => {
                                        props.saveSelectedTestDriveModel(e)
                                        // saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" >Select Test Drive Model</option>
                                        {catalogoueItemList.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={(interactionObj['dynamicProperties_selectedModel'] === item.uuid) || (interactionObj['dynamicProperties_selectedTestDriveModel'] === item.uuid)} value={item.uuid}>{modelMapping[item.displayName.toLowerCase()]}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.name === "selectedTestDriveVariant" && (interactionObj["dynamicProperties_"+dynamicProp.dependentProp] === dynamicProp.dependentPropValue) && dynamicProp.formType === "select") && (
                                    <div class="display-flex">
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={selectedModel} onChange={e => {
                                        props.saveSelectedTestDriveVariant(e)
                                        // saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" >Select Test Drive Variant</option>
                                        {variantList.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj['dynamicProperties_selectedTestDriveVariant'] === item.uuid} value={item.uuid}>{item.variant}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.name === "selectedTestDriveCarID" && (interactionObj["dynamicProperties_typeOfTestDriveCar"] === "Test Drive Car") && dynamicProp.formType === "select") && (
                                    <div class="display-flex">
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={interactionObj["dynamicProperties_selectedTestDriveCarID"]} onChange={e => {
                                        props.saveSelectedTestDriveCar(e)
                                        // saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" >Select Test Drive Car</option>
                                        {testDriveCars.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" value={item.uuid}>{item.variant + " - " + item.registrationNumber}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.name === "lostCaseApprovalRole" && (currentRow['dynamicProperties_outcome'] === "Lost") && dynamicProp.formType === "select") && (
                                    <div>
                                      <select class="text-4b4d51 background-white border-none" type="text" id="employee2" value={interactionObj['dynamicProperties_lostCaseApprovalRole']} onChange={e => {
                                        props.getSelectedTypeUserList(e.target.value)
                                      }}
                                      style="text-shadow:none"
                                      disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false}
                                      >
                                        <option selected={interactionObj['dynamicProperties_lostCaseApprovalRole'] == "NA"} value=''>{'Select Responsible Role'}</option>
                                        <option selected={interactionObj['dynamicProperties_lostCaseApprovalRole'] === 'Insurance Team Lead'} value={'Insurance Team Lead'}>{'Insurance Team Lead'}</option>
                                        <option selected={interactionObj['dynamicProperties_lostCaseApprovalRole'] === 'Insurance Head'} value={'Insurance Head'}>{'Insurance Manager'}</option>
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  (dynamicProp.name === "lostCaseAssignedUser" && (currentRow['dynamicProperties_outcome'] === "Lost") && dynamicProp.formType === "select") && (
                                    <div>
                                      <select type="text" id="employee" class="m-t-10  first-letter-capital background-white border-none text-4b4d51" onChange={(e => props.saveFormInput(e, dynamicProp.name))} style="text-shadow:none" disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false}>
                                        <option class="first-letter-capital" value='' selected>{`Select ${interactionObj['dynamicProperties_lostCaseApprovalRole'] ? interactionObj['dynamicProperties_lostCaseApprovalRole']: 'User'}`}</option>
                                        { assignUserList.map((user) => (
                                          <option selected={interactionObj['dynamicProperties_lostCaseAssignedUser'] === user.userID } value={user.userID}>{user.userName}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  ((dynamicProp.name === "thirdPartyInsuranceCompanyID" || dynamicProp.name === "accessoriesInsuranceCompanyID" || dynamicProp.name === "cngInsuranceCompanyID" || dynamicProp.name === "insuranceCompanyID" || dynamicProp.name === "insuranceCompanyID2" || dynamicProp.name === "insuranceCompanyRenewalID" || dynamicProp.name === "totalLossInsuranceCompany" || dynamicProp.name === "renewalInsuranceCompanyID" || dynamicProp.name === "insuranceCompanyIDNew") && dynamicProp.formType === "select") && ((!dynamicProp.dependentProp) || (dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] === dynamicProp.dependentPropValue)) || (dynamicProp.dependentProp && dynamicProp.dependentPropValue && (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] && interactionObj['dynamicProperties_'+dynamicProp.dependentProp].length > 0 && interactionObj['dynamicProperties_'+dynamicProp.dependentProp].includes(dynamicProp.dependentPropValue)))) && (
                                    <div class="display-block">
                                      {/*<p class="formLabel fs-13">{dynamicProp.displayName}{dynamicProp.dependentPropValues.includes(interactionObj[dynamicProp.dependentProp])}{interactionObj[dynamicProp.dependentProp]}</p>*/}
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={interactionObj[dynamicProp.name]} onChange={e => {
                                        props.saveFormInput(e, dynamicProp.name)
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" selected>Select Insurance Company</option>
                                        {insuranceCompanies && insuranceCompanies.length > 0 && insuranceCompanies.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_" + dynamicProp.name] === item.uuid} value={item.uuid}>{item.displayName}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  (currentAction.displayName === "Need Assessment" && (propIndex === action.dynamicProps.length - 1)) && (
                                    <div class="display-block">
                                      <p class="formLabel fs-13">Finance Manager</p>
                                      <select disabled={(dynamicProp.isDisabled || (currentRow.status && currentRow.status.toLowerCase() === "completed")) ? true: false} class='select' style="text-transform:capitalize" type="text" id="orderSource" value={currentRow['dynamicProperties_financeExecutive']} onChange={e => {
                                        props.saveFormInput(e, "financeExecutive")
                                      }
                                      }>
                                        <option style="text-transform:capitalize;font-size:16px" value="" selected>Select Finance Manager</option>
                                        {financeExecutives.map((item) => (
                                          <option style="text-transform:capitalize;font-size:16px;" selected={interactionObj["dynamicProperties_financeExecutive"] === item.userID} value={item.userID}>{item.userName}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )
                                }
                                {
                                  (!dynamicProp.dependentProp || (dynamicProp.dependentProp && (interactionObj['dynamicProperties_'+dynamicProp.dependentProp] === dynamicProp.dependentPropValue))) && (
                                    <span id={`error-`+dynamicProp.name} class="fs-10" >{dynamicProp.description}</span>
                                  )
                                }
                                {
                                  (currentRow.errorFields && currentRow.errorFields.length > 0 && (currentRow.errorFields.findIndex(item => item.fieldName === dynamicProp.name) > -1)) ? (
                                    <p class="fs-10 text-color-red" >{currentRow.errorFields.find(item => item.fieldName === dynamicProp.name).errorMessage}</p>
                                  ) : (
                                    <p class="fs-10 text-color-red" style="visibility: hidden;">Hidden Error</p>
                                  )
                                }
                                {/*
                                  T48: Auto next follow up date selection
                                */}
                                {
                                  dynamicProp.name === "nextFollowupDate" && interactionObj['dynamicProperties_numberOfMaxDaysToFollowUp'] &&
                                  <p id={`msg-`+dynamicProp.name} class="text-color-red fs-10" >Date selected should not be less than {interactionObj['dynamicProperties_numberOfMaxDaysToFollowUp']} days</p>
                                }
                                {/*
                                  dynamicProp.name === "engineNumber" && (
                                    <span id={`amount-`+dynamicProp.name} class="fs-10 first-letter-capital" style="display:none;" ></span>
                                  )
                                */}


                                {
                                  (dynamicProp.isAmount && interactionObj['dynamicProperties_'+dynamicProp.name] > 0) && (
                                    <span id={`amount-`+dynamicProp.name} class="fs-10 first-letter-capital display-block" style="color:#4949ed;">{`INR ${inWords(interactionObj['dynamicProperties_'+dynamicProp.name])}`}</span>
                                  )
                                }
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    ))
                  }

                  {
                    currentAction && currentAction.buttons && currentAction.buttons.length !== 0 && currentAction.buttons.map((button) => (
                      <button id={button} class="primary-button m-t-20 m-b-20 m-l-20 m-r-20" onClick={e => props.dynamicButtonClicked(e, button)}>{button}</button>
                    ))
                  }
                  {/*
                    currentRow.displayName === 'Finance Enquiry' && (actions && actions.length !== 0 && (selectedActionIndex === actions.length - 1)) && (
                      <div class="taskDetailsSection" style="overflow-y:auto">
                        <button id="approve-button" class="primary-button m-t-20 m-b-20 m-l-20 m-r-20" disabled={currentRow.status === 'Pending'} onClick={e => props.dynamicButtonClicked(e, "Finance Quotation")}>Generate Finance Quotation</button>
                      </div>
                    )
                  */}
                  {
                    testDriveQueue > 0 &&
                    <div>
                      <label>Test Drive Queue Number:</label><span>{testDriveQueue}</span>
                    </div>
                  }
                  {/*<div class="button-container display-flex p-l-30 m-t-10 p-b-10 justify-flex-end">
                    {
                      actions && actions.length !== 0 && selectedActionIndex !== 0 && (
                        <button class="primary-button" disabled={isBackModalDisable} onClick={(e) => {
                          submitFormOnStep(e, 'back')
                        }
                        }>Back</button>
                      )
                    }
                    {
                      actions && actions.length !== 0 && (selectedActionIndex !== actions.length - 1) && (
                        <button class="primary-button m-l-10" disabled={isNextModalDisable} type="submit" onClick={(e) => {
                          submitFormOnStep(e, 'next')
                        }
                        }>Next</button>
                      )
                    }
                  </div>*/}
                  </div>
                }
              </li>
              ))}
            </ul>
          </form>
        </div>
        <div class={`${props.isMobileView ? 'pos-relative' : 'pos-absolute'} noteDiv display-flex p-l-30 p-r-30 w-full justify-between bottom-20`}>
          <button class="secondary-button" type="submit" style={`${((actions && actions.length !== 0 && selectedActionIndex === 0) || (!actions || actions.length === 0)) && 'visibility: hidden;'}`} onClick={(e) => {
            submitFormOnStep(e, 'back')
          }
          }>Back</button>
          {
            actions && actions.length !== 0 && (selectedActionIndex !== actions.length - 1) && (
              <button class="primary-button m-l-10" type="submit" onClick={(e) => {
                submitFormOnStep(e, 'next')
              }
              }>Next</button>
            )
          }
          {/*
            modified : Ashutosh G
            modified : 20/05/2024
            modification : old disabled condition commented and new title and disabled condition added (Refer from caseSemiDetailViewV3)
          */}
          {((selectedActionIndex === actions.length - 1) || (!actions || actions.length === 0)) &&
            <button
              onClick={(e) => props.submitFormNew(e)}
              class="primary-button m-l-10"
              // disabled={(currentRow.errorFields && currentRow.errorFields.length > 0)}
              // title={(currentRow.errorFields && currentRow.errorFields.length > 0) ? 'Oops! Seems like you have missed one or more items.' : 'Complete Task'}
              disabled={props.isSaveDisabled}
              title={'Complete Task'}
            >
              Complete
            </button>
          }
        </div>
          {/*!isScrolledToTheBottom &&
            <svg class="scrollButton bounceAnimation cursor-pointer pos-absolute" onClick={(e)=> props.scrollToTheBottom(`formModalContainer-${selectedActionIndex}`)} fill="#808080" xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path d="M20 25.542 27.292 18.208 25.333 16.292 20 21.583 14.667 16.292 12.708 18.208ZM20 36.667Q16.5 36.667 13.458 35.375Q10.417 34.083 8.167 31.833Q5.917 29.583 4.625 26.542Q3.333 23.5 3.333 20Q3.333 16.5 4.625 13.458Q5.917 10.417 8.167 8.167Q10.417 5.917 13.458 4.625Q16.5 3.333 20 3.333Q23.5 3.333 26.542 4.625Q29.583 5.917 31.833 8.167Q34.083 10.417 35.375 13.458Q36.667 16.5 36.667 20Q36.667 23.5 35.375 26.542Q34.083 29.583 31.833 31.833Q29.583 34.083 26.542 35.375Q23.5 36.667 20 36.667Z"/></svg>
          {
            !props.isFormEditable && (
              <div class="noteDiv">
                <div class="row">
                  <div class="col-xs-12">
                    <span><span class="f-w-600 text-color-red">Note:</span> You are seeing this form in Non Editable Mode. Click on Close Edit Info button to enter Edit mode.</span>
                  </div>
                </div>
              </div>
            )
          }
          {
            props.isFormEditable && (
              <div class="noteDiv">
                <div class="row">
                  <div class="col-xs-12">
                    <span><span class="f-w-600 text-color-red">Note:</span> You are seeing this form in Edit Mode because some of the required fields are missing. Please fill the required fields and click on Close Edit Info to view the summary.</span>
                  </div>
                </div>
              </div>
            )
          */}
      </div>
      {isFileViewerOpen &&
        <FileViewer
          uploadBrowsedReferenceFile={props.uploadFile}
          closeFileViewer={closeFileViewer}
          toggleFileViewer={toggleFileViewer}
          isFileViewerOpen={isFileViewerOpen}
          uploadedfile={uploadedfile}
          fileViewerFor={fileViewerFor}
          contentDetails={{}}
          isDeleteHidden={true}
          isUploadHidden={true}
        />
      }
    </NewPopupModal>
  );
};

export default FormWizard;
