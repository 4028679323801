/*
  Modification: Add ApproveTask Svg
  By: Komal Wable
  Date: 19/04/2023
*/
import { h } from 'preact';

const ApproveTask = (props) => {
  return (
    <span title={props.title || ''} class={props.masterClasses || ''}>
      <svg class={props.classes || ''} onclick={(e) => props.action && props.action(e)} width={props.width || '28'} height={props.height || '28'} viewBox={props.viewBox || "0 0 14 17"} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.70581 16H11.5882C11.9626 16 12.3217 15.842 12.5864 15.5607C12.8512 15.2794 12.9999 14.8978 12.9999 14.5V5.125L9.11758 1H3.11758C2.74315 1 2.38406 1.15804 2.11931 1.43934C1.85455 1.72064 1.70581 2.10218 1.70581 2.5V5.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.76465 1V5.5H12.9999" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 10.75L2.41176 12.25L5.23529 9.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};
export default ApproveTask;
