import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Play = (props) => {
  return (
    <span id={props.identifier} class={props.masterClasses || ''} title={props.title || ''}>
      <svg onclick={() => props.action && props.action()} class={`${props.classes || ""}`} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "256pt"} height={props.height || "256pt"}
        viewBox={props.viewBox || "0 0 256.000000 256.000000"} preserveAspectRatio="xMidYMid meet"
      >
        <path xmlns="http://www.w3.org/2000/svg" d="M16.5 31.5h3v-15h-3Zm8-.45L35.1 24l-10.6-7.05ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm0-3q7.1 0 12.05-4.975Q41 31.05 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24 41Zm0-17Z" />
      </svg>
    </span>
  );
};

export default Play;
