/*
  Modification: add Neft svg
  By: Komal Wable
  Date: 19/04/2023
*/
import { h } from 'preact';

const Neft = (props) => {
  return (
    <span title={props.title || ''} class={props.masterClasses || ''}>
      <svg class={props.classes || ''} onclick={(e) => props.action && props.action(e)} width={props.width || '28'} height={props.height || '28'} viewBox={props.viewBox || "0 0 18 21"} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.69189 7.40381H11.3073" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.69189 9.32666H11.3073" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.69189 11.25L9.96113 14.3269" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.69189 11.25H7.84574" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.8457 11.25C10.4099 11.25 10.4099 7.40381 7.8457 7.40381" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.6155 2.80777L2.84623 2.80777C1.99656 2.80777 1.30777 3.49656 1.30777 4.34623L1.30777 10.5001M4.38469 18.1924L15.1539 18.1924C16.0036 18.1924 16.6924 17.5036 16.6924 16.6539L16.6924 10.5001" stroke="white" stroke-width="1.15385" stroke-linecap="round"/>
        <path d="M5.15381 19.7307L3.61535 18.1923L5.15381 16.6538" stroke="white" stroke-width="1.15385" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.8462 1.26927L14.3847 2.80773L12.8462 4.34619" stroke="white" stroke-width="1.15385" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};
export default Neft;
