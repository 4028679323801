/*
  Modification: Made V2 to fix update functionality and admin enhancements
  By: Devang
  Date: 23/01/2024
*/
import { h } from 'preact';
import { useState, useEffect, useRef } from "preact/hooks";
import { callAPI, reportException } from '../../lib/transceiver';
import { setItem, getItem } from '../../lib/myStore';
import { Information, BlockSign } from '../../components/svgs';


const CustomizeSelect = (props) => {
  const { dynamicProp, entityObject, handleChange, updateValue, removeValue, isDisabled, isShowDebitFields, displayName } = props;
  const fileName = "EmployeeDebitNoteV2", pageName = "EmployeeDebitNoteV2";

  const [searchedEmployeeList, setSearchedEmployeeList] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  useEffect(() => {
    if (isDropdownVisible) {
      let searchTimeout = setTimeout(() => {
        searchEmployee();
      }, 500);

      return () => clearTimeout(searchTimeout);
    }
  }, [searchKey, isDropdownVisible]);

  async function searchEmployee() {
    try {
      setIsLoading(true);
      let callType = dynamicProp.callType ? dynamicProp.callType : "get";
      let response;
      if (!dynamicProp.searchQuery) {
        let params = {
          q: searchKey
        };
        response = await callAPI(callType, dynamicProp.selectUrl, `${new URLSearchParams(params)}`);
      } else {
        response = await callAPI(callType, `${dynamicProp.selectUrl}?${dynamicProp.searchQuery}=${searchKey}`);
      }
      if (response.data) setSearchedEmployeeList(response.data);
    } catch (HTTPException) {
      setSearchedEmployeeList([]);
      reportException({
        HTTPException,
        fileName,
        pageName,
        functionName: 'searchEmployee'
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleSearchKeyChange(e) {
    setSearchKey(e.target.value ? e.target.value.trimStart() : '');
  }

  const useOutsideClick = (callback) => {
    const ref = useRef();
    useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };
      document.addEventListener('click', handleClick, true);
      return () => {
        document.removeEventListener('click', handleClick, true);
      };
    }, [ref]);
    return ref;
  };
  const empSearchRef = useOutsideClick(closeInputDropDown);
  function closeInputDropDown() {
    setIsDropdownVisible(false);
  }

  return (
    <div class="row m-b-5">
      {/*<input type="text" placeholder="Search Employee..."  />*/}
      <div class="flex-r-m w-full min-h-38px bor-rad-all-5 bor-all-gray pos-relative" ref={empSearchRef}>
        <input
          value={searchKey}
          onChange={handleSearchKeyChange}
          style="color:grey !important; margin-bottom:0px !important; border: none;"
          class={`${'w-full h-full'}`}
          type="text"
          placeholder='Search ...'
          onFocus={() => setIsDropdownVisible(true)}
          disabled={isDisabled}
        />
        {
          isDropdownVisible &&
          <div style={{ maxHeight: '150px', top: '36px', zIndex: '1000'}} class={`multi-select-container w-full bor-rad-all-5 bor-all-gray p-7 overflow-auto`}>
            {
              isLoading && searchKey ?
                <div class="w-full h-full flex-c-m flex-direction-column">
                  <p class="fs-16 lh-16-px">Loading Data...</p>
                </div>
                :
                searchedEmployeeList.length > 0 ? searchedEmployeeList.map((employee, index) => (
                  <div key={index} class={`${'flex-l-m'}`}>
                    <label class={`text-color-002c5f-imp cursor-pointer flex-c-m w-full`}>
                      <input
                        onChange={(e) => handleChange(employee, dynamicProp.name, e)}
                        checked={entityObject[dynamicProp.name] && entityObject[dynamicProp.name].findIndex(value => value.uuid === employee.uuid) > -1}
                        value={employee.uuid}
                        class={`multi-select-input cursor-pointer h-fit-content`}
                        type="checkbox"
                        disabled={isDisabled}
                      />
                      <div class="flex-c-m justify-between w-full p-l-8">
                        {
                          Object.keys(dynamicProp.selectDisplayObject).map(key => {
                            return (
                              <p>{employee[dynamicProp.selectDisplayObject[key]]}</p>
                            );})
                        }
                      </div>
                    </label>
                  </div>
                )): (
                  <div class="w-full h-full flex-c-m flex-direction-column">
                    <p class="fs-16 lh-16-px">{searchKey ? 'No data found for given input' : 'Please input search to show values' }</p>
                  </div>
                )
            }
          </div>
        }
      </div>
      <div class="m-t-8">
        {entityObject[dynamicProp.name] && entityObject[dynamicProp.name].length > 0 &&
          <div class="p-4 bor-all-black-25">
            <div class="display-flex w-full p-b-2 bor-b-DFDFDF f-w-500">
              <div class="w-5vw" />
              {
                dynamicProp.selectDisplayObject && Object.keys(dynamicProp.selectDisplayObject).map(key => {
                  return (
                    <div class="w-15vw flex-l-m p-2">
                      <p>{key}</p>
                    </div>
                  );})
              }
            </div>
            {
              entityObject[dynamicProp.name].map((employee, index) => (
                <div class="display-flex w-full p-b-2">
                  <div class="w-5vw flex-l-m">
                    <BlockSign fill={`red`} masterClasses="flex-c-m" classes={`w-24px h-24px ${isDisabled ? "cursor-not-allowed":"cursor-pointer"}`} action={(e) => removeValue(isDisabled, employee, dynamicProp.name, e)} />
                    {/*<input id={`${employee.uuid}`} class="flex-grow-1" name={`${employee.uuid}`} autocomplete="off" type="checkbox" value={employee.uuid} defaultChecked={setCustomFiledValues ? Object.keys(setCustomFiledValues).includes(employee.uuid) : ""} onChange={e => changeEmployeeDebitDetails(e, dynamicProp.name, employee.uuid)} />
                  <label for={`${employee.uuid}`} class='h-auto p-l-10 p-r-10 flex-grow-1' style='font-weight: 300;'>{employee.displayName}</label>*/}
                  </div>
                  {/* console.log(dynamicProp,"dynamicProp",employee)*/}
                  {
                    dynamicProp.selectDisplayObject && Object.keys(dynamicProp.selectDisplayObject).map(key => {
                      return (
                        <div class="w-15vw flex-l-m p-2">
                          <p>{employee[dynamicProp.selectDisplayObject[key]]}</p>
                        </div>
                      );})
                  }
                </div>
              ))}
          </div>
        }
      </div>
    </div>
  );
};

export default CustomizeSelect;
