import { h } from 'preact';

const Policy = (props) => {
  return (
    <span title={props.title || ''} class={props.masterClasses || ''}>
      <svg class={props.classes || ''} onclick={(e) => props.action && props.action(e)} width={props.width || '512.000000pt'} height={props.height || '512.000000pt'} viewBox={props.viewBox || "0 0 512.000000 512.000000"} xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "white"} stroke={props.stroke || "none"}>
        <path d="M442 5100 c-106 -28 -187 -75 -267 -155 -81 -81 -127 -161 -155 -271 -20 -75 -20 -117 -20 -1794 0 -1677 0 -1719 20 -1794 28 -110 74 -190 155 -271 81 -81 161 -127 271 -155 74 -19 114 -20 962 -20 982 0 925 -4 979 66 53 70 37 167 -35 222 l-35 27 -901 5 c-870 5 -902 6 -941 25 -50 25 -105 80 -130 130 -20 39 -20 70 -20 1765 0 1695 0 1726 20 1765 25 50 80 105 130 130 39 20 67 20 1340 20 l1300 0 40 -22 c52 -28 109 -88 132 -138 17 -37 18 -92 23 -680 5 -596 6 -643 23 -668 28 -43 75 -67 133 -67 65 0 111 29 142 90 l23 45 -4 640 c-3 704 0 673 -67 806 -66 129 -199 244 -338 291 l-67 23 -1320 2 c-1274 2 -1323 1 -1393 -17z"/>
        <path d="M747 4256 c-49 -18 -64 -32 -89 -80 -37 -73 -13 -153 62 -203 l33 -23 527 0 527 0 33 23 c48 32 72 69 77 119 7 58 -23 118 -74 149 l-38 24 -510 2 c-409 2 -517 0 -548 -11z"/>
        <path d="M720 3388 c-48 -33 -72 -70 -77 -120 -7 -58 23 -118 74 -149 l38 -24 1025 -3 c564 -2 1044 0 1068 3 57 8 118 62 132 116 19 69 -17 151 -80 184 -25 13 -173 15 -1088 15 l-1059 0 -33 -22z"/>
        <path d="M3665 2936 c-616 -207 -1017 -347 -1039 -361 -14 -9 -35 -36 -46 -60 -21 -43 -21 -51 -17 -577 4 -585 5 -597 72 -793 142 -415 531 -820 1040 -1081 163 -84 167 -84 338 4 509 262 899 672 1036 1092 64 194 64 196 68 778 4 526 4 534 -17 577 -11 24 -32 51 -46 60 -29 19 -1143 400 -1195 409 -25 5 -75 -8 -194 -48z m650 -440 l480 -163 0 -449 -1 -449 -26 -98 c-74 -271 -253 -522 -525 -739 -92 -73 -234 -169 -326 -218 l-76 -41 -58 29 c-87 45 -255 158 -370 250 -253 204 -429 457 -501 719 l-26 98 -1 449 0 449 450 153 c382 129 500 170 500 173 0 1 216 -73 480 -163z"/>
        <path d="M4353 2114 c-23 -11 -109 -119 -299 -373 -147 -196 -271 -360 -275 -366 -6 -5 -73 54 -172 152 -135 134 -169 163 -204 172 -76 21 -148 -11 -183 -79 -22 -44 -25 -85 -10 -127 14 -37 476 -501 517 -519 43 -19 85 -18 129 3 31 15 101 103 379 473 228 304 344 467 348 489 13 66 -24 146 -83 176 -38 19 -107 19 -147 -1z"/>
        <path d="M735 2546 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25 -8 240 -10 659 -8 613 3 622 3 650 24 98 73 98 195 0 268 -28 21 -37 21 -660 23 -510 2 -638 0 -662 -11z"/>
        </g>
      </svg>
    </span>
  );
};
export default Policy;
