import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Email = (props) => {
  return (
    <span class={props.masterClasses || ''} title={props.title || ''}>
      <svg onclick={() => props.action && props.action()} class={`${props.classes || ""}`} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M361 4499 c-172 -34 -318 -182 -351 -358 -14 -74 -14 -3088 0 -3162 34 -180 179 -325 359 -359 74 -14 4308 -14 4382 0 180 34 325 179 359 359 14 74 14 3088 0 3162 -34 180 -179 325 -359 359 -67 12 -4325 12 -4390 -1z m3227 -1306 l-1017 -1017 -143 137 c-79 76 -541 533 -1028 1017 l-885 879 2045 1 2045 0 -1017 -1017z m-2524 49 l678 -675 -721 -721 -721 -721 0 1434 0 1433 43 -38 c23 -20 347 -341 721 -712z m3756 -684 l0 -1433 -717 717 -718 718 715 715 c393 393 716 715 717 715 2 0 3 -645 3 -1432z m-2605 -459 c138 -138 267 -259 287 -270 45 -23 89 -24 131 -3 18 9 146 129 285 267 l252 252 718 -718 717 -717 -2045 0 -2045 0 720 720 c396 396 722 720 725 720 3 0 118 -113 255 -251z"/>
        </g>
      </svg>
    </span>
  );
};

export default Email;
