const SmileyRating = (props) => {
  return (
    <span title={props.title || ''} class={props.masterClasses || ''}>
      <svg class={props.classes || ''} onclick={(e) => props.action && props.action(e)} width={props.width || '512.000000pt'} height={props.height || '512.000000pt'} xmlns="http://www.w3.org/2000/svg" version="1.1"
        id="Icons" x="0px" y="0px" viewBox={props.viewBox || "0 0 512.000000 512.000000"} style={`enable-background:new 0 0 32 32; ${props.style || ''}`} fill={props.fill || ''}
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || ''} stroke={props.stroke || 'none'}>
          <path d="M2370 5113 c-379 -36 -661 -116 -980 -278 -378 -193 -717 -497 -965 -865 -104 -156 -232 -419 -294 -605 -49 -150 -89 -321 -113 -490 -17 -118 -17 -512 0 -630 42 -295 120 -553 242 -800 137 -280 272 -468 494 -691 221 -220 412 -357 681 -489 188 -92 309 -137 500 -185 500 -126 1002 -102 1490 71 149 53 407 182 540 271 365 243 667 578 866 963 181 348 271 694 286 1090 15 426 -73 832 -263 1214 -124 250 -263 447 -458 648 -216 224 -428 378 -711 518 -296 146 -572 225 -900 255 -102 9 -333 11 -415 3z m395 -303 c785 -72 1480 -553 1825 -1264 106 -217 166 -409 206 -661 25 -154 25 -496 0 -650 -40 -252 -100 -444 -206 -661 -223 -458 -585 -820 -1045 -1045 -216 -105 -408 -165 -660 -205 -154 -25 -496 -25 -650 0 -433 70 -808 240 -1135 514 -498 417 -800 1067 -800 1722 0 655 301 1302 800 1723 464 390 1069 582 1665 527z" />
          <path d="M1475 3564 c-187 -41 -360 -175 -444 -344 -38 -76 -42 -91 -39 -142 3 -44 10 -64 29 -84 33 -36 72 -54 114 -54 70 0 112 36 160 136 79 163 249 238 423 185 96 -29 169 -95 222 -201 43 -85 72 -110 135 -118 84 -9 154 50 162 137 4 39 -2 59 -41 136 -110 220 -311 350 -556 361 -63 2 -119 -2 -165 -12z" />
          <path d="M3375 3566 c-139 -32 -268 -106 -355 -204 -28 -32 -71 -98 -97 -150 -39 -79 -45 -100 -41 -137 10 -86 80 -142 166 -132 62 7 91 32 132 115 56 114 127 176 236 208 81 23 167 15 247 -24 79 -39 116 -78 169 -177 49 -91 90 -125 150 -125 98 0 167 87 146 184 -18 79 -79 178 -163 261 -85 86 -171 138 -275 169 -77 23 -240 29 -315 12z" />
          <path d="M1579 1927 c-41 -27 -69 -79 -69 -128 0 -66 126 -242 252 -350 402 -346 939 -403 1388 -148 169 96 331 249 423 401 56 93 49 161 -23 222 -25 20 -42 26 -81 26 -69 0 -102 -20 -150 -93 -132 -195 -338 -338 -565 -393 -92 -22 -284 -23 -382 0 -223 50 -425 187 -559 379 -31 43 -68 84 -84 92 -43 23 -110 19 -150 -8z" />
        </g>
      </svg>
    </span>
  );
};
export default SmileyRating;
