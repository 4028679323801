/*
  Modification: Added Notification SVG
  By: Komal
  Date: 30/03/2023
*/
import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Notification = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg onclick={(e) => props.action && props.action(e)} width={props.width || "24"} height={props.height || "24"} viewBox={props.viewBox || "0 0 24 24"} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z" stroke={props.stroke || "white"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.7295 21C13.5537 21.3031 13.3014 21.5547 12.9978 21.7295C12.6941 21.9044 12.3499 21.9965 11.9995 21.9965C11.6492 21.9965 11.3049 21.9044 11.0013 21.7295C10.6977 21.5547 10.4453 21.3031 10.2695 21" stroke={props.stroke || "white"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2 8C2 5.8 2.7 3.7 4 2" stroke={props.stroke || "white"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22 8C22 5.8363 21.2982 3.73096 20 2" stroke={props.stroke || "white"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};

export default Notification;
