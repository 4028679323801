/*
  T311: BG_FN: Dispute Case Status is not getting updated immediately
  T326: BG_PROD: Reopening a lost case doesn't change case status from LOST to DUE
  Modification: Update card details on prop change
  By: Yashvi
  On: 27th May 2024
*/
import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import Ribbon from '../../components/ribbon';
import SkeletonCard from '../../components/skeletonCard';

const SemiInfoDetailCard = (props) => {

  const [cardTextTop, setCardTextTop] = useState(props.cardTextTop);
  const [cardHeader, setCardHeader] = useState(props.cardHeader);
  const [cardSubHeader, setCardSubHeader] = useState(props.cardSubHeader);
  const [cardPreSubHeader, setCardPreSubHeader] = useState(props.cardPreSubHeader);
  const [cardPreSubHeaderBold, setCardPreSubHeaderBold] = useState(props.cardPreSubHeaderBold);
  const [cardSubHeaderBold, setCardSubHeaderBold] = useState(props.cardSubHeaderBold);
  const [cardSubHeaderTrimmed, setCardSubHeaderTrimmed] = useState(props.cardSubHeaderTrimmed);
  const [cardSubHeaderTrimmedBold, setCardSubHeaderTrimmedBold] = useState(props.cardSubHeaderTrimmedBold);
  const [cardNextSubHeader, setCardNextSubHeader] = useState(props.cardNextSubHeader);
  const [cardNextSubHeaderBold, setCardNextSubHeaderBold] = useState(props.cardNextSubHeaderBold)
  const [cardText1, setCardText1] = useState(props.cardText1);
  const [cardText2, setCardText2] = useState(props.cardText2);
  const [cardText3, setCardText3] = useState(props.cardText3);
  const [cardTextOuter, setCardTextOuter] = useState(props.cardTextOuter);
  const [ribbons, setRibbons] = useState(props.ribbons);
  const [tagName, setTagName] = useState(props.tagName);

  useEffect(() => {
    setCardTextTop(props.cardTextTop);
  }, [props.cardTextTop]);

  useEffect(() => {
    setCardHeader(props.cardHeader);
  }, [props.cardHeader]);

  useEffect(() => {
    setCardSubHeader(props.cardSubHeader);
  }, [props.cardSubHeader]);

  useEffect(() => {
    setCardPreSubHeader(props.cardPreSubHeader);
  }, [props.cardPreSubHeader]);

  useEffect(() => {
    setCardPreSubHeaderBold(props.cardPreSubHeaderBold);
  }, [props.cardPreSubHeaderBold]);

  useEffect(() => {
    setCardSubHeaderBold(props.cardSubHeaderBold);
  }, [props.cardSubHeaderBold]);

  useEffect(() => {
    setCardSubHeaderTrimmed(props.cardSubHeaderTrimmed);
  }, [props.cardSubHeaderTrimmed]);

  useEffect(() => {
    setCardSubHeaderTrimmedBold(props.cardSubHeaderTrimmedBold);
  }, [props.cardSubHeaderTrimmedBold]);

  useEffect(() => {
    setCardNextSubHeader(props.cardNextSubHeader);
  }, [props.cardNextSubHeader]);

  useEffect(() => {
    setCardNextSubHeaderBold(props.cardNextSubHeaderBold);
  }, [props.cardNextSubHeaderBold]);

  useEffect(() => {
    setCardText1(props.cardText1);
  }, [props.cardText1]);

  useEffect(() => {
    setCardText2(props.cardText2);
  }, [props.cardText2]);

  useEffect(() => {
    setCardText3(props.cardText3);
  }, [props.cardText3]);

  useEffect(() => {
    setCardTextOuter(props.cardTextOuter);
  }, [props.cardTextOuter]);

  useEffect(() => {
    setRibbons(props.ribbons);
  }, [props.ribbons]);

  useEffect(() => {
    setTagName(props.tagName);
  }, [props.tagName]);

  return (
    <div id={props.identifier ? props.identifier : `${props.title || ''}`} class={`newWorkSpaceCard pos-relative semi-info-detail-card overflow-hidden cursor-pointer ${props.masterCardClasses || ''}`}
      style={`background:${props.cardBgColor};height:${props.cardHeight}; padding: ${props.cardPadding || '16px'}; ${props.masterCardStyle || ''}`}
      onclick={(e) => props.action && props.action(e)} title={props.title || ''}
    >
      {
        (props.isLoading) ? (
          <div class='flex flex-col gap-10px'>
            {/*<SkeletonCard containerClassName={'w-50px'} height={'10px'} />*/}
            <SkeletonCard containerClassName={'w-full'} height={'10px'} />
            <SkeletonCard containerClassName={'w-full'} height={'10px'} />
            <SkeletonCard containerClassName={'w-full'} height={'10px'} />
          </div>
        ) : (
          <div class={props.masterClasses || "p-t-0 p-b-0 display-flex justify-between flex-direction-column h-full first-letter-capital m-l-8"}>
            {
              (cardPreSubHeader || cardPreSubHeaderBold) && (
                <div  class="display-flex" style="width: 100%;justify-content: space-between; align-self: self-end;">
                  <p class="fs-13 f-w-400"><span class={cardPreSubHeaderBold ? "font-bold p-r-5" : ""}>{cardPreSubHeaderBold}</span>{cardPreSubHeader && cardPreSubHeader}</p>
                </div>
              )
            }
            {
              cardHeader && (
                <div className={!cardTextTop ? "display-flex": ""} style="">
                  {
                    cardTextTop && (
                      <p class="fs-12 f-w-400 text-gray" style="align-self: baseline;"><span>{cardTextTop}</span></p>
                    )
                  }
                  <h1 title={cardHeader || ''} class={props.cardHeaderClasses || `fs-15 f-w-500 text-black`} style="margin:0; align-self: baseline;letter-spacing: 0.08px;">
                    {cardHeader !== '-' ? cardHeader : 'N/A'}
                  </h1>
                  {tagName &&
                    <div class="display-flex" style="width: 54%;justify-content:end;align-self: self-end;">
                      <span class="fs-10 f-w-500 m-l-10" style="border-radius:20px;color:#fff;padding:5px 10px;background:#154042;align-self: center;">{tagName}</span>
                    </div>
                  }
                </div>
              )
            }
            {
              ribbons && ribbons.length > 0 && ribbons.map(ribbon => (
                <Ribbon ribbonText={ribbon.text} ribbonColor={ribbon.color} type={ribbon.type} variant={ribbon.variant} isTextNotVisible={ribbon.isTextNotVisible} ribbonTextColor={ribbon.ribbonTextColor} />
              ))
            }
            {
              cardSubHeader && (
                <div  class="display-flex" style="width: 100%;justify-content: space-between; align-self: self-end;">
                  <p class="fs-13 f-w-400"><span class={cardSubHeaderBold ? "font-bold p-r-5" : ""}>{cardSubHeaderBold}</span>{cardSubHeader && cardSubHeader !== "" && cardHeader !== '-' ? cardSubHeader : 'N/A'}</p>
                </div>
              )
            }
            {
              cardSubHeaderTrimmed && (
                <div  class="display-flex" style="width: 100%;justify-content: space-between; align-self: self-end;">
                  <p class="fs-13 f-w-400 truncate-2"><span class={cardSubHeaderTrimmedBold ? "font-bold p-r-5" : ""}>{cardSubHeaderTrimmedBold}</span>{cardSubHeaderTrimmed && cardSubHeaderTrimmed !== "" && cardHeader !== '-' ? cardSubHeaderTrimmed : 'N/A'}</p>
                </div>
              )
            }
            {
              cardNextSubHeader && (
                <div  class="display-flex" style="width: 100%;justify-content: space-between; align-self: self-end;">
                  <p class="fs-13 f-w-400"><span class={cardNextSubHeaderBold ? "font-bold p-r-5" : ""}>{cardNextSubHeaderBold}</span>{cardNextSubHeader && cardNextSubHeader !== "" && cardHeader !== '-' ? cardNextSubHeader : 'N/A'}</p>
                </div>
              )
            }
            {
              cardText1 && (
                <p class="fs-13 f-w-400" style="align-self: baseline;"><span title={props.cardTextTitle1 ? props.cardTextTitle1 : ''}>{cardText1 !== "" && cardText1 !== '- | -' && cardText1 !== '-' ? cardText1 : 'N/A'}</span></p>
              )
            }
            {
              cardText2 && (
                <p class="fs-11 f-w-400" style="align-self: baseline;"><span title={props.cardTextTitle2 ? props.cardTextTitle2 : ''}>{cardText2 !== " " && cardText2 !== "" && cardText2 !== '- | -' ? cardText2 : 'N/A'}</span></p>
              )
            }
            {
              cardText3 && (
                <p class="fs-13 f-w-400" style="align-self: baseline;"><span title={props.cardTextTitle3 ? props.cardTextTitle3 : ''}>{cardText3 !== "" && cardText3 !== '- | -' ? cardText3 : 'N/A'}</span></p>
              )
            }
            <span class={props.iconMasterClasses ? props.iconMasterClasses + " pos-absolute op-0-6" : " pos-absolute right-0 bottom-0 op-0-6"}>
              {props.cardIcon}
            </span>
            {
              cardTextOuter && (
                <div class="">
                  <p class="fs-11 f-w-300" style="align-self: baseline;"><span>{cardTextOuter}</span></p>
                </div>
              )
            }
          </div>
        )
      }
    </div>
  );
};

export default SemiInfoDetailCard;
