import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon

const Phone = (props) => {
  return (
    <svg onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" class={`${props.classes || ""}`} width={props.width || "512.000000pt"} height={props.height || "512.000000pt"} viewBox={props.viewBox || "0 0 512.000000 512.000000"} preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
        <path d="M1398 5101 c-31 -10 -72 -28 -92 -40 -56 -35 -133 -126 -158 -189 l-23 -57 0 -2255 0 -2255 24 -60 c33 -83 133 -183 216 -216 l60 -24 1135 0 1135 0 57 23 c67 27 155 103 192 167 60 101 56 -44 54 2391 l-3 2229 -24 60 c-18 44 -42 78 -90 126 -48 48 -82 72 -126 90 l-60 24 -1120 2 c-1059 2 -1123 1 -1177 -16z m364 -238 c5 -109 17 -141 74 -197 69 -67 61 -66 724 -66 669 0 656 -1 729 71 51 51 71 112 71 212 l0 79 158 -4 c156 -3 159 -3 208 -33 30 -19 60 -49 79 -79 l30 -49 0 -2237 0 -2237 -30 -49 c-19 -30 -49 -60 -79 -79 l-49 -30 -1117 0 -1117 0 -49 30 c-30 19 -60 49 -79 79 l-30 49 0 2236 0 2236 28 47 c55 95 113 117 303 118 l142 0 4 -97z m1438 22 c0 -67 -3 -79 -25 -100 l-24 -25 -591 0 -591 0 -24 25 c-22 21 -25 33 -25 100 l0 75 640 0 640 0 0 -75z"/>
        <path d="M2226 3457 c-24 -25 -24 -21 -52 -269 l-17 -148 -214 0 c-214 0 -214 0 -238 -25 -33 -32 -33 -78 0 -110 24 -25 25 -25 229 -25 l206 0 -5 -27 c-3 -16 -18 -145 -35 -288 -17 -143 -33 -275 -36 -292 l-5 -33 -205 0 c-204 0 -205 0 -229 -25 -33 -32 -33 -78 0 -110 24 -25 25 -25 220 -25 149 0 195 -3 195 -12 0 -7 -9 -85 -20 -173 -25 -201 -25 -201 5 -230 31 -32 78 -33 109 -2 24 25 24 21 52 270 l17 147 319 0 c296 0 318 -1 318 -17 0 -10 -9 -94 -20 -187 -20 -175 -18 -194 19 -223 26 -20 70 -15 95 10 24 25 24 21 52 270 l17 147 194 0 c193 0 194 0 218 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -24 25 -26 25 -209 25 l-186 0 5 28 c3 15 18 144 35 287 17 143 33 275 36 293 l5 32 185 0 c183 0 185 0 209 25 33 32 33 78 0 110 -24 25 -27 25 -200 25 -110 0 -175 4 -175 10 0 6 9 89 20 186 16 137 18 180 8 197 -38 72 -127 59 -147 -21 -5 -20 -17 -112 -27 -204 l-17 -168 -319 0 c-296 0 -318 1 -318 18 0 9 9 87 20 172 25 195 25 196 -5 225 -31 32 -78 33 -109 2z m709 -604 c-3 -16 -18 -145 -35 -288 -17 -143 -33 -275 -36 -292 l-5 -33 -320 0 -320 0 5 33 c3 17 20 151 36 297 17 146 33 275 36 288 l4 22 320 0 320 0 -5 -27z"/>
      </g>
    </svg>
  );
};

export default Phone;
