import { h } from 'preact';

const Calendar = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} width={props.width || "14"} height={props.height || "16"} viewBox={props.viewBox || "0 0 14 16"} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6667 2.40002H2.33333C1.59695 2.40002 1 3.02683 1 3.80002V13.6C1 14.3732 1.59695 15 2.33333 15H11.6667C12.403 15 13 14.3732 13 13.6V3.80002C13 3.02683 12.403 2.40002 11.6667 2.40002Z" fill="white" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.66797 1V3.8" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.33203 1V3.8" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 6.59998H13" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.33203 9.40002H4.33991H4.33203Z" fill="white"/>
        <path d="M4.33203 9.40002H4.33991" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7 9.40002H7.00788H7Z" fill="white"/>
        <path d="M7 9.40002H7.00788" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.66797 9.40002H9.67584H9.66797Z" fill="white"/>
        <path d="M9.66797 9.40002H9.67584" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.33203 12.2H4.33991H4.33203Z" fill="white"/>
        <path d="M4.33203 12.2H4.33991" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7 12.2H7.00788H7Z" fill="white"/>
        <path d="M7 12.2H7.00788" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.66797 12.2H9.67584H9.66797Z" fill="white"/>
        <path d="M9.66797 12.2H9.67584" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};

export default Calendar;
