import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Form = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512.000000pt"} height={props.height || "512pt"}
        viewBox={props.viewBox || "0 0 512.000000 512.000000"} preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke="none">
          <path d="M320 4947 c-49 -16 -133 -102 -148 -153 -18 -60 -18 -4408 0 -4468 16 -55 99 -138 154 -154 32 -9 542 -12 2236 -12 2475 0 2252 -8 2331 80 24 26 49 66 55 88 17 57 17 4407 0 4464 -6 22 -31 62 -55 88 -79 88 145 80 -2337 79 -1574 -1 -2211 -4 -2236 -12z m4455 -172 c21 -20 25 -34 25 -80 l0 -55 -1680 0 -1680 0 0 -80 0 -80 1680 0 1680 0 0 -2055 0 -2056 -25 -24 -24 -25 -2191 0 -2191 0 -24 25 -25 24 0 2191 0 2191 25 24 24 25 2191 0 2191 0 24 -25z"/>
          <path d="M480 4560 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
          <path d="M800 4560 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
          <path d="M1120 4560 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
          <path d="M1680 4080 l0 -80 880 0 880 0 0 80 0 80 -880 0 -880 0 0 -80z"/>
          <path d="M640 3760 l0 -80 360 0 360 0 0 80 0 80 -360 0 -360 0 0 -80z"/>
          <path d="M665 3495 l-25 -24 0 -271 0 -271 25 -24 24 -25 1216 0 1215 0 0 80 0 80 -1160 0 -1160 0 0 160 0 160 1760 0 1760 0 0 -160 0 -160 -120 0 -120 0 0 -80 0 -80 175 0 c173 0 176 0 200 25 l25 24 0 271 0 271 -25 24 -24 25 -1871 0 -1871 0 -24 -25z"/>
          <path d="M3353 3170 c-12 -5 -28 -19 -36 -32 -15 -23 -217 -851 -217 -887 0 -10 9 -30 21 -45 35 -45 76 -37 185 39 52 36 97 65 101 65 5 0 47 -106 94 -235 l86 -235 -1393 0 -1394 0 0 160 0 160 1080 0 1080 0 0 80 0 80 -1135 0 -1136 0 -24 -25 -25 -24 0 -271 0 -271 25 -24 24 -25 1871 0 1871 0 24 25 25 24 0 271 0 271 -25 24 c-23 24 -29 25 -160 25 l-135 0 0 -80 0 -80 80 0 80 0 0 -160 0 -160 -202 1 -203 0 50 20 c58 23 85 51 85 86 0 14 -41 137 -91 274 -51 137 -89 252 -85 256 3 4 59 13 124 20 104 12 119 16 140 39 31 35 28 68 -10 108 -31 32 -669 500 -715 524 -25 14 -30 14 -60 2z m326 -390 l184 -134 -78 -11 c-80 -11 -99 -24 -111 -70 -3 -12 36 -131 91 -280 52 -143 96 -266 98 -274 1 -10 -22 -24 -70 -42 l-72 -27 -25 67 c-14 36 -59 158 -100 271 -49 132 -83 211 -97 223 -34 27 -70 21 -132 -24 -32 -22 -57 -36 -57 -30 0 9 105 455 116 494 5 17 8 16 37 -6 18 -13 115 -84 216 -157z"/>
          <path d="M640 2560 l0 -80 560 0 560 0 0 80 0 80 -560 0 -560 0 0 -80z"/>
          <path d="M640 1360 l0 -80 560 0 560 0 0 80 0 80 -560 0 -560 0 0 -80z"/>
          <path d="M665 1095 l-25 -24 0 -271 0 -271 25 -24 24 -25 1871 0 1871 0 24 25 25 24 0 271 0 271 -25 24 -24 25 -1871 0 -1871 0 -24 -25z m3655 -295 l0 -160 -1760 0 -1760 0 0 160 0 160 1760 0 1760 0 0 -160z"/>
        </g>
      </svg>
    </span>
  );
};

export default Form;
