const Docket = (props) => {
  return (
    <span title={props.title || ''} class={props.masterClasses || ''}>
      <svg class={props.classes || ''} onclick={(e) => props.action && props.action(e)} width={props.width || '512.000000pt'} height={props.height || '512.000000pt'} xmlns="http://www.w3.org/2000/svg" version="1.1"
        id="Icons" x="0px" y="0px" viewBox={props.viewBox || "0 0 512.000000 512.000000"} style={`enable-background:new 0 0 32 32; ${props.style || ''}`} fill={props.fill || ''}
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || ''} stroke={props.stroke || 'none'}>
          <path d="M1685 5106 c-16 -8 -254 -238 -527 -513 -385 -385 -500 -506 -508 -533 -6 -22 -10 -312 -10 -777 l0 -743 -43 0 c-57 0 -107 -29 -134 -76 l-23 -39 0 -537 0 -538 -161 0 c-179 0 -212 -8 -251 -62 -28 -40 -35 -106 -14 -146 16 -31 806 -1013 870 -1080 39 -42 76 -62 116 -62 62 0 97 28 208 166 l107 134 1409 0 1408 0 39 22 c25 14 46 37 59 63 19 40 20 62 20 702 l0 661 313 4 312 3 56 26 c68 32 120 82 157 152 l27 52 0 750 0 750 -27 52 c-34 63 -84 115 -141 145 -41 22 -52 23 -369 26 l-328 3 0 641 c0 621 -1 643 -20 683 -13 26 -34 49 -59 63 l-39 22 -1208 0 c-999 -1 -1214 -3 -1239 -14z m2263 -838 l-3 -553 -960 -5 -960 -5 -51 -27 c-66 -35 -128 -105 -151 -171 -17 -48 -18 -104 -18 -772 0 -834 -5 -787 94 -886 101 -101 17 -93 1104 -97 l947 -3 0 -574 0 -575 -1195 0 -1195 0 196 241 c108 132 207 257 220 276 45 67 25 165 -43 210 -22 15 -56 19 -198 23 l-170 5 -5 547 c-5 533 -6 547 -26 575 -43 59 -64 63 -342 63 l-252 0 2 663 3 662 430 5 c474 6 456 3 498 70 22 34 22 42 25 458 l3 422 1024 0 1025 0 -2 -552z m-2348 117 l0 -215 -222 0 -223 0 215 215 c118 118 218 215 222 215 5 0 8 -97 8 -215z m3218 -1652 l-3 -678 -1344 -3 c-985 -1 -1347 1 -1357 9 -12 9 -14 128 -14 674 0 364 3 665 7 668 3 4 615 7 1360 7 l1353 0 -2 -677z m-3556 -1030 c3 -517 4 -539 23 -572 36 -61 66 -76 165 -79 l88 -4 -260 -321 c-144 -177 -264 -326 -268 -330 -4 -5 -128 139 -274 320 l-266 328 82 5 c97 6 136 25 167 80 21 37 21 48 21 574 l0 536 260 0 259 0 3 -537z" />
          <path d="M2410 3223 c-60 -31 -61 -46 -58 -511 l3 -414 33 -29 c42 -38 89 -39 131 -3 l30 25 3 147 3 147 110 6 c92 4 118 10 160 31 62 32 129 103 158 166 32 70 30 179 -5 253 -33 72 -95 134 -167 167 -51 24 -69 26 -211 30 -135 3 -160 1 -190 -15z m337 -209 c15 -11 36 -37 47 -58 16 -32 17 -44 7 -76 -6 -21 -25 -50 -42 -64 -28 -23 -39 -26 -115 -26 l-84 0 0 126 0 126 79 -3 c61 -3 86 -9 108 -25z" />
          <path d="M3213 3225 c-17 -7 -36 -22 -42 -34 -7 -13 -11 -163 -11 -456 l0 -437 30 -30 30 -30 178 4 c221 5 264 18 358 112 102 102 144 217 144 389 0 163 -41 276 -132 374 -57 60 -126 96 -212 112 -86 16 -303 14 -343 -4z m322 -196 c106 -29 157 -115 163 -274 3 -84 0 -109 -18 -158 -44 -117 -104 -156 -237 -157 l-83 0 0 300 0 300 68 0 c37 0 85 -5 107 -11z" />
          <path d="M4110 3229 c-61 -24 -60 -12 -60 -492 l0 -439 29 -29 c49 -48 126 -35 156 26 11 26 15 69 15 189 l0 156 89 0 c114 0 145 7 176 38 36 35 35 99 -1 136 -26 25 -31 26 -145 26 l-119 0 0 100 0 100 129 0 c120 0 132 2 156 23 17 14 28 36 32 63 5 36 1 46 -25 75 l-30 34 -189 2 c-109 1 -198 -2 -213 -8z" />
        </g>
      </svg>
    </span>
  );
};
export default Docket;
