import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Money = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M282 4250 c-29 -10 -68 -28 -86 -39 -47 -29 -120 -110 -147 -162 -50 -98 -49 -76 -49 -1149 0 -1097 -1 -1070 57 -1169 60 -102 177 -181 299 -201 33 -6 107 -10 167 -10 l107 0 0 -117 c1 -65 5 -148 9 -185 19 -145 109 -270 239 -331 l67 -32 1935 0 1935 0 66 32 c113 56 202 169 228 293 9 41 11 318 9 1075 l-3 1020 -22 53 c-47 114 -143 207 -258 247 -36 13 -93 19 -197 23 l-148 4 0 117 c-1 64 -5 146 -9 183 -19 145 -109 270 -239 331 l-67 32 -1920 2 c-1866 2 -1921 2 -1973 -17z m3866 -205 c49 -21 81 -52 103 -100 18 -38 19 -89 19 -1050 l0 -1010 -23 -39 c-33 -58 -84 -94 -148 -106 -37 -7 -672 -9 -1895 -8 l-1839 3 -42 22 c-47 25 -84 71 -102 122 -15 46 -16 1979 0 2034 12 45 72 115 114 133 24 11 382 13 1903 14 1668 0 1877 -2 1910 -15z m647 -682 c50 -26 68 -46 94 -103 20 -44 21 -61 21 -1030 0 -664 -4 -997 -11 -1023 -13 -46 -67 -109 -113 -131 -31 -14 -209 -16 -1905 -16 -2035 0 -1908 -3 -1970 53 -50 46 -61 89 -61 255 l0 152 1613 0 c1006 0 1635 4 1672 10 86 14 173 60 234 125 65 68 98 140 111 236 5 41 10 392 10 788 l0 714 133 -5 c108 -4 139 -8 172 -25z"/>
          <path d="M2054 3975 c-384 -68 -708 -342 -840 -710 -49 -137 -67 -262 -61 -415 14 -333 173 -633 441 -832 541 -403 1325 -216 1622 387 123 251 152 509 84 768 -100 380 -405 684 -787 783 -123 32 -334 41 -459 19z m405 -225 c391 -100 661 -446 661 -847 0 -191 -42 -337 -140 -488 -235 -359 -691 -496 -1089 -326 -187 80 -356 243 -446 432 -60 128 -78 214 -78 379 0 129 3 155 26 233 103 337 358 567 700 633 97 18 260 11 366 -16z"/>
          <path d="M1829 3491 c-41 -41 -41 -98 0 -143 l29 -33 149 -5 c150 -6 190 -15 222 -54 11 -13 -10 -15 -179 -18 l-192 -3 -29 -33 c-41 -45 -41 -102 0 -143 l29 -29 191 0 c159 0 191 -2 191 -14 0 -18 -43 -49 -89 -64 -19 -7 -88 -12 -153 -12 -123 0 -150 -6 -180 -44 -23 -28 -23 -98 0 -124 9 -12 130 -127 267 -256 265 -250 283 -262 345 -236 39 16 60 51 60 101 0 39 -5 45 -122 158 -68 65 -140 133 -160 151 -21 17 -38 35 -38 39 0 4 21 12 46 19 58 16 144 72 185 121 16 20 42 62 57 93 27 58 27 58 72 58 73 0 119 17 140 53 24 38 25 68 5 107 -21 40 -46 51 -127 59 -67 6 -70 7 -83 39 l-14 32 74 0 c91 0 119 10 145 52 28 45 21 94 -20 131 l-30 27 -381 0 -381 0 -29 -29z"/>
          <path d="M585 3941 c-203 -53 -306 -277 -215 -466 25 -52 106 -130 157 -151 94 -40 207 -34 296 14 58 31 97 74 134 145 23 44 27 65 28 137 0 73 -4 93 -28 141 -32 67 -100 133 -165 161 -50 23 -157 32 -207 19z m156 -237 c77 -74 24 -194 -87 -194 -54 0 -104 53 -104 110 0 52 13 76 53 101 43 27 99 20 138 -17z"/>
          <path d="M3729 2495 c-181 -58 -274 -244 -214 -425 25 -76 118 -169 195 -196 164 -56 335 17 411 175 32 65 33 196 2 263 -30 66 -96 133 -160 163 -69 33 -168 41 -234 20z m148 -215 c69 -42 79 -125 23 -182 -22 -21 -38 -28 -69 -28 -79 0 -121 40 -121 117 0 47 22 80 64 99 43 18 66 17 103 -6z"/>
        </g>
      </svg>
    </span>
  );
};

export default Money;
