/*
  Modification: add PendingTask svg
  By: Komal Wable
  Date: 19/04/2023
*/
import { h } from 'preact';

const PendingTask = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" class={`${props.classes || ""}`} width={props.width || "10"} height={props.width || "10"} fill={props.fill || "none"} viewBox="0 0 14 18">
        <path d="M5.40918 9.05694C5.5285 8.79099 5.70747 8.52504 5.94611 8.39206C6.19318 8.23288 6.48161 8.17247 6.76367 8.22083C7.04574 8.26919 7.30454 8.42343 7.49725 8.65802C7.67623 8.92397 7.79554 9.18992 7.79554 9.52236C7.79554 10.3867 6.60236 10.8521 6.60236 10.8521V12.1819" stroke="white" stroke-width="0.795455" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.61475 13.7728H6.58991" stroke="white" stroke-width="0.795455" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.98875 1.04547H2.22738C1.80545 1.04547 1.40079 1.21308 1.10244 1.51144C0.804088 1.80979 0.636475 2.21444 0.636475 2.63638V15.3637C0.636475 15.7856 0.804088 16.1902 1.10244 16.4886C1.40079 16.7869 1.80545 16.9546 2.22738 16.9546H11.7728C12.1948 16.9546 12.5994 16.7869 12.8978 16.4886C13.1961 16.1902 13.3637 15.7856 13.3637 15.3637V5.42047L8.98875 1.04547Z" stroke="white" stroke-width="1.19318" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.59106 1.04547V5.8182H13.3638" stroke="white" stroke-width="1.19318" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};

export default PendingTask;
