import { h, Component } from 'preact';
import { useState, useEffect,useRef } from "preact/hooks";
import axios from "axios";
import { route } from "preact-router";
import CONSTANTS from '../../lib/constants';
import { setItem, getItem, removeAll } from '../../lib/myStore';
import { modelMapping, getInitials, deepCopy , getDay, getMonth, getFormattedAmount,validateAadharNumber, typeOfEnquiryMappingClassColor, downloadCSV, getCompressedImageFile, vinNumberValidation, preventNumberSpecialChar, getDifferenceInDays, debounce } from '../../lib/utils';
import moment from "moment";
import { Home, Search, Clipboard, Cases, AddCall, PriceList,Notification, AddCase, ReceivePayment, Folder, Hyundai, Honda, Kia, Finance, Car, Accessories, Insurance, Vas, OngoingCall, Download, Upload, VB, Calculator, RoundStar, ViewedRecently, Analytics, Facility, Request, InteractionSearchSuggestion, History, Money } from '../../components/svgs';
import toastr from "toastr";
import PDFSTYLE from "../../lib/pdfGenerationConfig";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import SemiInfoDetailCard from '../../components/semiInfoDetailCard';
import CallingCard from "../../components/callingCard";
import ListSingleCard from '../../components/listSingleCard';
import FormWizard from '../../components/formWizard';
import { Modal, ModalBody } from '../../components/rightDrawer';
import CreateEditForm from '../../components/createEditForm';
import SearchInputSuggestion from '../../components/searchInputSuggestion';
import { NewPopupModal, NewPopupModalBody } from '../../components/newPopupModal';
import Viewer from 'viewerjs';
import pincodeDirectory from 'india-pincode-lookup';
import { State, City }  from 'country-state-city';
import 'viewerjs/dist/viewer.css';
import imageCompression from 'browser-image-compression';
// import DraggableCalculator from '../../components/draggableCalculator';
import RecentlyViewed from '../../components/recentlyViewed';
import ShowNotification from '../../components/showNotification';
import OrgChartModal from "../../components/orgChartModal";
import { callAPI, reportException, generateCancelTokenSource } from '../../lib/transceiver';
import CoreEntityDynamicForm from '../../components/coreEntityDynamicForm';
import NewListV2 from '../../components/newListV2';
import SuggestionCard from '../../components/suggestionCard';
/*
 Modification:- Added GeneratePurchaseOrder component
 By:- Rajkumar Pradhan
 On:- 22-03-2024
*/
import GeneratePurchaseOrder from "../../components/generatePurchaseOrder";
import TaskErrorModal from "../../components/taskErrorModal";
// import io from 'socket.io-client';
/*
  Modification: Added socketio
  By: Komal Wable
  Date: 19/04/2023
*/

let cancelTokenSource;

const RightPanel = (props) => {
  let userInfo = getItem('userinfo');
  // const socket = io(`${CONSTANTS.API_URL}:9000`);
  /**********************************************************************/
  let videoTypeExtension = ['mov', 'mp4', 'mkv', 'm4v', 'mpeg','webm'];
  let audioTypeExtension = ['mp3', 'aac', 'm4a', 'f4a', 'ogg', 'oga'];
  let documentTypeExtension = ['ppt', 'pdf', 'docx'];
  let imageTypeExtension = ['png', 'jpg', 'jpeg', 'gif'];
  const filetypes = ['png', 'jpg', 'jpeg', 'pdf']
  let fileTypeExtension = ['csv'];
  let storedFileCsv;
  let errorCountFile;
  let failRecordCount;
  let failRecordDetails;
  let endorsementTypes = {
    // ncbCertificate: 'New NCB',
    cancellationApproval: 'Cancellation',
    ncbCertificate: 'NCB Refund',
    correction: 'Correction',
    accessories: 'Accessories',
    cng: 'CNG',
    newVehicleNo: 'New vehicle no',
    ncbRecoveryRequest: 'NCB Recovery',
    ownerTransfer: 'Owner Transfer'
  };
  const todaysDate = new Date();
  let [selectedArrayForDynamicProps, setSelectedArrayForDynamicProps] = useState([]);
  let [isFormEditable, setIsFormEditable] = useState(false);
  let [completedActions, setCompletedActions] = useState([]);
  let [isDataSaving, setIsDataSaving] = useState(false);
  let [userDisplayName, setUserDisplayName] = useState(userInfo.displayName);
  let [userBranchName, setUserBranchName] = useState(userInfo.userBranchName ? userInfo.userBranchName : '');
  let [userRoleName, setUserRoleName] = useState(userInfo.userRoleName ? userInfo.userRoleName : '');
  let [userDesignation, setUserDesignation] = useState(userInfo.userDesignation ? userInfo.userDesignation : '');
  let [newNotification, setNewNotification] = useState(false);
  let [isFollowUpModalOpen, setFollowUpModalOpen] = useState(false);
  let [isReceivePaymentModalOpen, setReceivePaymentModalOpen] = useState(false);
  let [isCreateCaseModalOpen, setCreateCaseModalOpen] = useState(false);
  let [cases, setCases] = useState([]);
  let [typeOfCase, setTypeOfCase] = useState('');
  let [typeOfPayments, setTypeOfPayments] = useState([]);
  let [typeOfPayment, setTypeOfPayment] = useState('');
  let [selectedCase, setSelectedCase] = useState('');
  let [selectedCaseObj, setSelectedCaseObj] = useState({});
  let [selectedRemarkCatagory, setSelectedRemarkCategory] = useState('');
  let [remark, setRemark] = useState('');
  let [typeofFollowup, setTypeofFollowup] = useState('');
  let [followupDate, setFollowupDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  let [isFollowupDone, setIsFollowupDone] = useState(false);
  let [amountPaid, setAmountPaid] = useState(0);
  let [otp, setOTP] = useState('');
  let [chequeNumber, setChequeNumber] = useState('');
  let [transactionReferenceNumber, setTransactionReferenceNumber] = useState('');
  let [payeeName, setPayeeName] = useState('');
  let [payeeBank, setPayeeBank] = useState('');
  let [modeOfPayment, setModeOfPayment] = useState('');
  const [isOpenAssignUsersTriggeredTask, setIsOpenAssignUsersTriggeredTask] = useState(false);
  let [triggeredUnassignedTaskList, setTriggeredUnassignedTaskList] = useState([])
  let [assignToUserList, setAssignToUserList] = useState([]);
  let [isOpenFormPopover, setIsOpenFormPopover] = useState(false);
  let [isOpenCaseFormPopover, setIsOpenCaseFormPopover] = useState(false);
  let [isEditCaseFormPopover, setIsEditCaseFormPopover] = useState(false);
  let [searchResults, setSearchResults] = useState([]);

  {/*
    Modification:-  When type of case is selected and create case is clicked, open the global modal with a loading state till the case is being created / show the case creation gif same as kdms, once created, open the modal
    Name:- Rajkumar Pradhan
    Date:- 26-08-2023
  */}
  let [createInsuranceCaseLoader,setCreateInsuranceCaseLoader]=useState(false);
  let [endorsementLoader,setEndorsementLoader] = useState(false);
  let [endorsementObj, setEndorsementObj] = useState({
    searchQuery: '',
    endorsementFileID: '',
    endorsementRemark: '',
    ncbCertificateID: '',
    isNoClaimBonus: '',
    typeOfEndorsement: '',
    typeOfCase: '',
    selectedManagerID: '',
    endorsementFileIDSignedURL: '',
    ncbCertificateIDSignedURL: '',
    reasonForNCBRecovery: '',
    recoveryAmount: 0,
    cancellationReason: '',
    otherCancellationReason: '',
    ncbPercentage: 0,
    endorsementRequestDate: null,
    chargesPercentage: '',
    ownerTransferDate: null,
    paymentMode: '',
    transactionID: '',
    paymentDate: null,
    invoiceDate: null,
    vehicleNumber: '',
    checkingDate: null,
    mailSentDate: null,
    callDate: null,
    insuranceCompany: '',
    approvalDate: null,
    cancelChequeID: '',
    cancelChequeIDSignedURL: '',
    panID: '',
    panIDSignedURL: '',
    electricityBillID: '',
    electricityBillIDSignedURL: '',
    nomineePanID: '',
    nomineePanIDSignedURL: '',
    sanctionLetterID: '',
    sanctionLetterIDSignedURL: '',
    accessoriesInvoiceID: '',
    accessoriesInvoiceIDSignedURL: '',
    inspectionPhotoID: '',
    inspectionPhotoIDSignedURL: '',
    inspectionReportID: '',
    inspectionReportIDSignedURL: '',
    rcCopyID: '',
    rcCopyIDSignedURL: '',
    paymentProofID: '',
    paymentProofIDSignedURL: '',
    bextractCopyID: '',
    bextractCopyIDSignedURL: '',
    cngInvoiceID: '',
    cngInvoiceIDSignedURL: '',
    phoneBillID: '',
    phoneBillIDSignedURL: '',
    nameCorrection: false,
    addressCorrection: false,
    nomineeNameCorrection: false,
    relationCorrection: false,
    hypoticationCorrection: false,
    emailCorrection: false,
    accessoriesCost: 0
  });
  let [isScrolledToTheBottom, setIsScrolledToTheBottom] = useState(false);
  let [isBackModalDisable, setIsBackModalDisable] = useState(false)
  let [isNextModalDisable, setIsNextModalDisable] = useState(false)
  let [isSaveModalDisable, setIsSaveModalDisable] = useState(false);
  let [cartNumber, setCartNumber] = useState(0);
  let [progress, setProgress] = useState(0);
  let [actions, setActions] = useState([{
    dynamicProps: []
  }]);
  let [dynamicProps, setDynamicProps] = useState([]);
  let [dynamicPropsData, setDynamicPropsData] = useState([]);
  let [interactionObj, setInteraction] = useState({});
	let [areaList, setAreaList] = useState([]);
  let [exchangEvaluators, setExchangeEvaluators] = useState([]);
  let [financeExecutives, setFinanceExecutives] = useState([]);
  let [insuranceCompanies, setInsuranceCompanies] = useState([]);
  let [offers, setOffers] = useState([]);
  let [schemes, setSchemes] = useState([]);
  let [lastQuotation, setLastQuotation] = useState({});
  let [selectedModel, setSelectedModel] = useState('')
  let [selectedVariantName, setSelectedVariantName] = useState('')
  let [variantList, setVariantList] = useState([])
  let [testDriveCars, setTestDriveCars] = useState([]);
  let [listofManufacturers, setListOfManufacturers] = useState([])
  let [catalogoueItemList, setCatalogoueItemList] = useState([])
  let [cartProducts, setCartProducts] = useState([]);
  let [assignUserList, setAssignUserList] = useState([])
  let [currentAction, setCurrentAction] = useState({});
  let [isFormUpdated, setIsFormUpdated] = useState(false);
  let [currentRow, setCurrentRow] = useState({});
  let [courierData, setCourierData] = useState({});
  let [testDriveQueue, setTestDriveQueue] = useState(0);
  let [selectedProducts, setSelectedProducts] = useState([]);
  let [selectedColor, setSelectedColor] = useState('');
  let [availableStock, setAvailableStock] = useState(0);
  let [allUserList, setAllUserList] = useState([])
  let [isHandoverDocket, setIsHandoverDocket] = useState(false);
  let [isEditDocket, setIsEditDocket] = useState(false);
  let [courierImgDelete, setCourierImgDelete] = useState(false)
  let [selectedActionIndex, setSelectedActionIndex] = useState(0);
  let [dynamicPropsWithValues, setDynamicPropsWithValues] = useState([]);
  let [stateList, setStateList] = useState(State.getStatesOfCountry('IN'));
  let [cityList, setCityList] = useState([]);
  let [isCreateEditFormPopover, setIsCreateEditFormPopover] = useState(false); // state to check if any process is selected to edit or to create new process and show CreateEditForm
  let [formOperation, setFormOperation] = useState();
  let [formEntityName, setFormEntityName] = useState();
  let [formEntityObject, setFormEntityObject] = useState(); // state to store data of selected process object
  let [formDynamicProps, setFormDynamicProps] = useState();
  let [isComponentFormUpdated, setIsComponentFormUpdated] = useState(false);
  let [isEntityFormObjectUpdated, setIsEntityFormObjectUpdated] = useState(false);
  let [currentUpdatedPropName, setCurrentUpdatedPropName] = useState('');
  let [isGlobalSearchVisible, setIsGlobalSearchVisible] = useState(false);


  let [isOngoingCallWindowOpen,setOngoingCallWindowOpen] = useState(false);
  let [isOngoingCallWindowClose,setOngoingCallWindowClose] = useState(false);

  let [isCallOriginated, setIsCallOriginated] = useState(false);
  let [isCallOriginatedByUser, setIsCallOriginatedByUser] = useState(false);

  let [callResponse, setCallResponse] = useState('');
  let [callResponseText, setCallResponseText] = useState('');
  let [callResponseObj, setCallResponseObj] = useState({});
  let [callResponseData, setCallResponseData] = useState('');
  let [isCallModalOpen,setCallModalOpen] = useState(false);
  let [reasonForCalling, setReasonForCalling] = useState('');
  let [followUpFor, setFollowUpFor] = useState('');
  let [mobileNumberEntered,setMobileNumberEntered] = useState('');
  /*
    Bug: T277 | BG_FN - Calculator Not Resetting Displayed Value on Reopen
    Modification: Commented calculator
    By: Devang
    Date: 24/05/2024
  */
  // let [isCalculatorModalOpen,setCalculatorModalOpen] = useState(false);
  let [isRecentlyViewedModalVisible, setRecentlyViewedModalVisibility] = useState(false);
  let [isFollowedCasesModalVisible, setFollowedCasesModalVisibility] = useState(false);
  let [isLoading, setIsLoading] = useState(true);
  let [showSlideNotification, setShowSlideNotification] = useState(false);
  let [message,setMessage] = useState('');
  let [vinNumber, setVinNumber] = useState('');
  let [isCaseErrorMsg, setIsCaseErrorMsg] = useState(false);
  let formValidation = false;
  let requestTypes = {
    // policy: "Policy Endorsement",
    // ncbCertificate: "New NCB",
    ncbRecoveryRequest: "NCB Recovery",
    cancellationApproval: "Cancellation",
    ncbCertificate: "NCB Refund",
    correction: "Correction",
    ownerTransfer: "Owner Transfer",
    accessories: "Accessories",
    cng: "CNG",
    newVehicleNo: "New vehicle no.",
  };
  let [pathName, setPathName] = useState(window.location.pathname);
  {/*
    Modification:- The type of case detail is getting stored for loader
    Name:- Rajkumar Pradhan
    Date:- 29-08-2023
  */}
  let [caseDetail,setCaseDetail]=useState("New");
  const [isCatalogueModalOpen, setIsCatalogueModalOpen] = useState(false);
  let [catalogueItems, setCatalogueItems] = useState([]);
  let [requestDynamicProps, setRequestDynamicProps] = useState();
  let [errorField, setErrorField] = useState([]);
  let [isCoreEntityFormPopover, setIsCoreEntityFormPopover] = useState(false); // state to check if any process is selected to edit or to create new process and show CreateEditForm

  /*
    modification : T319 (Change Taken From caseSemiDetailViewV3) states added
    modified : Ashutosh G
    modified : 23/05/2024
  */
  const [isShowTaskCompletionError, setIsShowTaskCompletionError] = useState(false);
  const [taskCompletionErrorHeader, setTaskCompletionErrorHeader] = useState('');
  const [taskCompletionError, setTaskCompletionError] = useState('');
  let [isSaveDisabled, setIsSaveDisabled] = useState(false)
  /*
    Modification: T319 (Change Taken From caseSemiDetailViewV3) - Added isBatchExists ref and debounceSave function to handle task update
    By: Ashutosh G
    Date: 22/05/2024
  */
  const isBatchExists = useRef(false);
  const [isFormToBeSaved, setIsFormToBeSaved] = useState(false);
  const debouncedSave = useRef(debounce(() => {
    handleTaskUpdate();
  }, 3000));

  useEffect(() => {
    setPathName(window.location.pathname);
  },[window.location.pathname]);


  // useEffect(async() => {
  //   socket.on('nudges', async(response) => {
  //     if (response) {
  //       if (response.actionableTo && response.actionableTo.includes(userInfo.uuid)){
  //         setShowSlideNotification(true)
  //         setMessage(response.message)
  //       }
  //     }
  //   })
  //   socket.on('task completed', async(response) => {
  //     if (response) {
  //       if (response.assignedUserID === userInfo.uuid) {
  //         setShowSlideNotification(true)
  //         setMessage(response.message)
  //       }
  //     }
  //   })
  //   socket.on('case', async(response) => {
  //     if (response) {
  //       if (response.responsibleUserIDs && response.responsibleUserIDs.includes(userInfo.uuid)) {
  //         setShowSlideNotification(true)
  //         setMessage(response.message)
  //       }
  //     }
  //   })
  //   socket.on('task assigned', async(response) => {
  //     if (response) {
  //       if (response.assignedUserID === userInfo.uuid) {
  //         setShowSlideNotification(true)
  //         setMessage(response.message)
  //       }
  //     }
  //   })
  // }, [socket]);

  useEffect(()=>{
    toastr.options = {
      "closeButton": true,
      "debug": false,
      "newestOnTop": true,
      "progressBar": true,
      "positionClass": "toast-bottom-left",
      "preventDuplicates": false,
      "onclick": null,
      "showDuration": "600",
      "hideDuration": "1000",
      "timeOut": "5000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "slideDown",
      "hideMethod": "slideUp"
    };
  },[]);
  {  /*
      Modified by: Arun Singh
      Modified on: 11/12/2023
      COMP_BG_206: Endorsement , Payment Cofirmation and Payment Reconciliation tasks are created without entering data in the mandatory fields.
    */
  }
  useEffect(async () => {
    try {
      let formName = endorsementObj.typeOfEndorsement ? endorsementObj.typeOfEndorsement : '';
      if (formEntityObject && formEntityObject.uuid && !formEntityObject.interactionID && formName) {
        let Response = await axios.put(`${CONSTANTS.API_URL}/api/v2/interaction/${formEntityObject.uuid}/${formName}/updateCreateEditForm`, formEntityObject);
        setFormEntityObject(Response.data);
      }
    } catch (HTTPException) {
      if (HTTPException && HTTPException.config && HTTPException.config.url) {
        let payload = {
          apiURL:HTTPException.config.url,
          errorObject:HTTPException,
          statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
          errorMessage:HTTPException.message,
          functionName:"useEffect-[isEntityFormObjectUpdated]",
          fileName:"rightPanel",
          pageName: "rightPanel",
          userID:userInfo.uuid,
          userName:userInfo.displayName
        };
        await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      }
      return HTTPException;
    }
  },[isEntityFormObjectUpdated]);

  /*
    Modification: T319 (Change Taken From caseSemiDetailViewV3) - Added handleTaskUpdate to handle task update based on if isBatchExists
    By: Ashutosh G
    Date: 22/05/2024
  */
  const handleTaskUpdate = () => {
    if (isBatchExists.current) {
      setIsFormToBeSaved(e => !e);
    }
  };

  /*
    Modification: T319 (Change Taken From caseSemiDetailViewV3) - Added refs for saveTaskForm and cancelToken
    By: Ashutosh G
    Date: 22/05/2024
  */
  const saveTaskForm = useRef();
  const cancelTokenSource = useRef(null);

  /*
    Modification: T319 (Change Taken From caseSemiDetailViewV3) - Added useEffect to handle taskUpdate on page close or url change
    By: Ashutosh G
    Date: 22/05/2024
  */
  useEffect(() => {
    window.addEventListener('beforeunload', handleTaskUpdate);

    return () => {
      cancelTokenSource.current = generateCancelTokenSource(cancelTokenSource.current);
      saveTaskForm.current();

      window.removeEventListener('beforeunload', handleTaskUpdate);
    };
  }, []);

  /*
    Modification: T319 (Change Taken From caseSemiDetailViewV3) - Added a useEffect to monitor signal to save form and execute saveTaskForm with new cancelToken
    By: Ashutosh G
    Date: 22/05/2024
  */
  useEffect(() => {
    cancelTokenSource.current = generateCancelTokenSource(cancelTokenSource.current);
    saveTaskForm.current();
  }, [isFormToBeSaved]);

  /*
    Modification: T319 (Change Taken From caseSemiDetailViewV3) - Commented code for updating the task and make a new function to update the task
    By: Ashutosh G
    Date: 22/05/2024
  */

  useEffect(async () => {
    try {
      // setIsDataSaving(true);
      if (actions.length) {
        setActions(actions);
        setSelectedArrayForDynamicProps(actions);
      }
      setSelectedActionIndex(selectedActionIndex);
      setCurrentRow(currentRow);
      setInteraction(interactionObj);

      isBatchExists.current = true;
      cancelTokenSource.current = generateCancelTokenSource(cancelTokenSource.current);
      debouncedSave.current();

      /*let payload = currentRow;
      if (actions.length) payload['actionName'] = currentAction ? currentAction.displayName : '';
      // if (isFormUpdated) {
      if (currentRow.uuid) {
        let Response = await axios.put(`${CONSTANTS.API_URL}/api/v2/task/${currentRow.uuid}/updateForm`, payload);
        if (actions.length) {
          let actionsArr = actions;
          let newActionArr = Response.data.actions;
          await Promise.all(actionsArr.map(action => {
            let actionObj = newActionArr.find(actionObj => actionObj.displayName === action.displayName);
            action['actionStatus'] = actionObj.status;
            action['isVisited'] = actionObj.isVisited;
          }));
          await setActions(actionsArr);
        }
        if (Response.data.errorFields && Response.data.errorFields.length) {
          payload['errorFields'] = Response.data.errorFields;
          await setCurrentRow(payload);
        } else {
          payload['errorFields'] = [];
          await setCurrentRow(payload);
        }
      }*/
      // }
      // setIsFormUpdated(false);
      // setIsDataSaving(false);
    } catch (HTTPException) {
      console.log(HTTPException, 'HTTPExceptionHTTPExceptionHTTPExceptionHTTPException');
      if (HTTPException && HTTPException.config && HTTPException.config.url) {
        let payload = {
          apiURL:HTTPException.config.url,
          errorObject:HTTPException,
          statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
          errorMessage:HTTPException.message,
          functionName:"useEffect-[isFormUpdated]",
          fileName:"rightPanel",
          pageName: "rightPanel",
          userID:userInfo.uuid,
          userName:userInfo.displayName
        };
        await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      }
      return HTTPException;
    }
  }, [isFormUpdated])

  /*
    Modification: T319 (Change Taken From caseSemiDetailViewV3) - Added function to update the task
    By: Ashutosh G
    Date: 22/05/2024
  */
  saveTaskForm.current = async () => {
    try {
      isBatchExists.current = false;
      setIsDataSaving(true);
      let payload = currentRow;
      if (actions.length) payload['actionName'] = currentAction ? currentAction.displayName : '';
      if (currentRow.uuid) {
        let Response = await callAPI(`put`, `/v2/task/${currentRow.uuid}/updateForm`, payload, { cancelTokenSource: cancelTokenSource.current });
        if (actions.length) {
          let actionsArr = actions;
          let newActionArr = Response.data.actions;
          await Promise.all(actionsArr.map(action => {
            let actionObj = newActionArr.find(actionObj => actionObj.displayName === action.displayName);
            action['actionStatus'] = actionObj.status;
            action['isVisited'] = actionObj.isVisited;
          }));
          await setActions(actionsArr);
        }
        if (Response.data.errorFields && Response.data.errorFields.length) {
          payload['errorFields'] = Response.data.errorFields;
          /*
            modified : Ashutosh G
            modified : 20/05/2024
            modification : response isVisited added on payload isVisited
          */
          payload['isVisited'] = Response.data.isVisited;
          await setCurrentRow(payload);
        } else {
          payload['errorFields'] = [];
          /*
            Modification: Added isVisited in else block
            By: Devang
            Date: 24/05/2024
          */
          /*
            Bug: T333 - BG_FN: Add New Case, Add Renewal Case - Not able to complete the case and Savings... is displayed for long time, Console is having error
            Modification: Fixed wrong keyword used in below line
            By: Devang
            Date: 04/06/2024
          */
          payload['isVisited'] = Response.data.isVisited;
          await setCurrentRow(payload);
        }
      }
      setIsDataSaving(false);
    } catch (HTTPException) {
      reportException({
        HTTPException,
        fileName:"rightPanel",
        functionName: 'saveTaskForm'
      })
   }
  }

  /*
    Modification: T319 - Added function to handle states and refs before saving in local component
    By: Devang
    Date: 20/05/2024
  */
  const handleSaveTaskOnStep = () => {
    console.log('HANDLEDD')
    isBatchExists.current = false;
    cancelTokenSource.current = generateCancelTokenSource(cancelTokenSource.current);
  }

  useEffect(async () => {
    if (isComponentFormUpdated) {
      if (isCreateEditFormPopover) {
        try {

          let payload = {};
          let uuid = formEntityObject.uuid;

          // setting payload
          formDynamicProps.length && formDynamicProps.map((dynamicProp) => {
            // payload[`dynamicProperties_${dynamicProp.name}`] = formEntityObject[dynamicProp.name];
            payload[`${dynamicProp.name}`] = formEntityObject[dynamicProp.name];
          });

          // await updateSelectedInteraction(uuid, payload);
          let currentEntityName = endorsementObj.typeOfEndorsement ? endorsementObj.typeOfEndorsement : endorsementObj.typeOfCase ? endorsementObj.typeOfCase : '';
          let parentEntityID = interactionObj.uuid;
          let parentEntityName = 'Interaction';
          await getEntityDynamicProps(currentEntityName, parentEntityID, 'parent', parentEntityName);

        } catch (error) {
          console.error(error);
        }
      }
    }
    setIsComponentFormUpdated(false);
  }, [isComponentFormUpdated])

  const useOutsideClick = (callback) => {
    const ref = useRef();
    useEffect(() => {
      const handleClick = (event) => {

        if (ref.current && !ref.current.contains(event.target)) {

          callback();
        }
      };
      document.addEventListener('click', handleClick, true);
      return () => {
        document.removeEventListener('click', handleClick, true);
      };
    }, [ref]);
    return ref;
  };

  const slideNotificationRef = useOutsideClick(handleSlideNotification);
  function handleSlideNotification(){
    setShowSlideNotification(false);
  }

  /*
    Modification: Added event listeners for key combos and escape to open and close search
    By: Devang
    Date: 22/02/2023
  */
  useEffect(() => {
    window.onkeydown = function(event) {
      checkIfSearchKeyComboPressed(event);
      checkIfSearchCloseKeyPressed(event);
    };
  }, []);

  useEffect(() => {
    setOngoingCallWindowOpen(props.isOngoingCallWindowOpen)
  },[props.isOngoingCallWindowOpen])

  const checkIfSearchKeyComboPressed = (e) => {
    let evtobj = window.event ? event : e
    // combination of ctrl + K
    if (evtobj.keyCode == 75 && evtobj.ctrlKey) {
      e.preventDefault();
      setIsGlobalSearchVisible(true);
    }
  }
  const checkIfSearchCloseKeyPressed = (e) => {
    let evtobj = window.event ? event : e
    if (evtobj.keyCode == 27) setIsGlobalSearchVisible(false);
  }

  async function getAreaByPincode(pincode,field) {
    let areaList = pincodeDirectory.lookup(pincode);
    await setAreaList(areaList);
  }

  /*
    Modification:- if modal is close then reset value from state
    Name:- Masum Raja
    Date:- 18-09-2023
  */

  async function toggleCreateCaseModal(e) {
    if (isCreateCaseModalOpen) {
      setTypeOfCase('');
      setIsCaseErrorMsg(false);
      setVinNumber('');
    }
    setCreateCaseModalOpen(!isCreateCaseModalOpen);
  }

  {/*
    Modification:-  When type of case is selected and create case is clicked, open the global modal with a loading state till the case is being created / show the case creation gif same as kdms, once created, open the modal
    Name:- Rajkumar Pradhan
    Date:- 26-08-2023
  */}

  /*
    Modification:- Added vin search option and if vin number is present then showing error message
    Name:- Masum Raja
    Date:- 18-09-2023
  */
  async function createCase(caseType) {
    try {

      if (!vinNumberValidation(vinNumber)) {
        toastr.error("Please enter the valid VIN number");
        return;
      }

      let Response = await axios.get(`${CONSTANTS.API_URL}/api/v1/findCase?q=${vinNumber}&interactionType=${typeOfCase}`);

      if (Response.data.length) {
        setIsCaseErrorMsg(true);
        setVinNumber("");
        return
      } else {
        setCreateInsuranceCaseLoader(true)
        setVinNumber("");
        setIsCaseErrorMsg(false);
        let task = await axios.post(`${CONSTANTS.API_URL}/api/v1/processInitiation`, {caseType: typeOfCase, userBranchID: userInfo.userBranchID, userDealershipID: userInfo.userDealershipID});
        let taskDetails = await axios.get(`${CONSTANTS.API_URL}/api/v1/taskDetails?taskID=${task.data.uuid}`);
        if (taskDetails.data) {
        currentRow = taskDetails.data;
        await setCurrentRow(currentRow);
        // await setItem("currentRowID", row);
        // if (nextRow) {
        //   await setItem("nextRowID", nextRow);
        // }
        // await setIsFormUpdated(!isFormUpdated);
        let designation = 'Insurance Agent';
        let exchangEvaluators = await axios.get(`${CONSTANTS.API_URL}/api/v1/userOrganization/caseManagement?dealershipID=${userInfo.userDealershipID}&activationStatus=active`);
        await setExchangeEvaluators(exchangEvaluators.data);
        let insuranceCompaniesList = await axios.get(` ${CONSTANTS.API_URL}/api/v1/thirdParty?type=insuranceCompany&dmsName=${(userInfo && userInfo.userDealershipID === "f8ff4333-1d4d-4ebb-8669-f6ce67620b85") ? 'Kia' : 'Honda'}`);
        if (insuranceCompaniesList.data && insuranceCompaniesList.data.length) {
          setInsuranceCompanies(insuranceCompaniesList.data);
        }
        await toggleFormPopover();
        setCreateCaseModalOpen(!isCreateCaseModalOpen);
        setCreateInsuranceCaseLoader(false)
      }
      }
    } catch (HTTPException) {
      if (HTTPException && HTTPException.config && HTTPException.config.url) {
        let payload = {
          apiURL:HTTPException.config.url,
          errorObject:HTTPException,
          statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
          errorMessage:HTTPException.message,
          functionName:"createCase",
          fileName:"rightPanel",
          pageName: "rightPanel",
          userID:userInfo.uuid,
          userName:userInfo.displayName
        };
        await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      }
      return HTTPException;
    }
  }

  const handleVinNumber = async (e) => {
    setVinNumber(e.target.value);
    setIsCaseErrorMsg(false);
  };

  function scrollToTheBottom(elementName) {
      let element = document.getElementById(elementName);
      // element.scrollTop = element.scrollHeight;
      element.scrollTo({
        top: element.scrollHeight,
        behavior: 'smooth',
      })
  }

  /*
    Modification: T319 (Change Taken From caseSemiDetailViewV3) - openTaskCompletionError, toggleTaskCompletionError, checkIfTaskCanComplete added
    By: Ashutosh G
    Date: 23/05/2024
  */
  const openTaskCompletionError = () => {
    setIsShowTaskCompletionError(true);
  }
  const toggleTaskCompletionError = () => {
    setIsShowTaskCompletionError(e => !e);
  }
  const checkIfTaskCanComplete = () => {
    let canBeCompleted = true;

    if (isBatchExists.current || isDataSaving) {
      canBeCompleted = false;
      setTaskCompletionErrorHeader('Saving Changes!');
      setTaskCompletionError('Your last changes are being saved. Please wait.');
    } else if (currentRow && !currentRow.isVisited) {
      canBeCompleted = false;
      setTaskCompletionErrorHeader('Task Unchanged!');
      setTaskCompletionError('There are no changes in the task. Please update the task.');
    } else if (currentRow && currentRow.errorFields && currentRow.errorFields.length) {
      canBeCompleted = false;
      setTaskCompletionErrorHeader('Errors Found!');
      setTaskCompletionError('There are error fields in the form. Please fill all the required information.');
    }

    if (!canBeCompleted) {
      openTaskCompletionError();
    }

    return canBeCompleted;
  }

  async function submitFormNew(e) {
    e.preventDefault();

    /*
      modification : T319 (Change Taken From caseSemiDetailViewV3) isTaskCanComplete not exists then returning from function and setIsSaveDisabled(true) added
      modified : Ashutosh G
      modified : 23/05/2024
    */
    const isTaskCanComplete = checkIfTaskCanComplete();
    if (!isTaskCanComplete) return;

    try {
    await setIsSaveDisabled(true);
    // if(document.getElementById(fields.name).style.borderBottom="1px solid red")
    //   document.getElementById(fields.name).style.border= "1px solid #d1d1d1";
    // }
        // if (currentRow && currentRow.displayName === 'Create Case') {
        //   let payload = currentRow.interaction;
        //
        //   let Response = await axios.put(`${CONSTANTS.API_URL}/api/v1/contactForInteraction`, payload)
        // }
        delete currentRow.interaction;
        let payload = currentRow;
        currentRow['status'] = "Completed";
        let Response = await axios.put(`${CONSTANTS.API_URL}/api/v2/task/${currentRow.uuid}/markComplete`, payload)
        let notificationObj = {
          typeOfNotification: "push",
          message: "Task " + currentRow.displayName + " completed",
          taskID: currentRow.uuid,
          group: "task",
          isSent: true,
          isRead: false,
          sendTo: userInfo.uuid
        };

        // if (Response.data.triggeredTaskList.length) {
        //   await setTriggeredUnassignedTaskList(Response.data.triggeredTaskList);
        //   await toggleOpenAssignUsersTriggeredTask();
        // }
        // if (Response.data.triggeredTaskListForApproval.length) {
        //   await setTriggeredUnassignedApprovalTaskList(Response.data.triggeredTaskListForApproval);
        //   await toggleOpenAssignApprovalUsersTriggeredTask();
        // }
        let taskCompletionMessage = await axios.post(`${CONSTANTS.API_URL}/api/v1/notification`, notificationObj);
        if (taskCompletionMessage.status === 200) {
          triggerNotifications(true)
        }
        // await axios.post(`${CONSTANTS.API_URL}/api/v1/whatsappText`, {
        //   number: "9904695543",
        //   message: "Task " + currentRow.displayName + " completed",
        //   dealershipID: currentRow.dealershipID ? currentRow.dealershipID : userInfo.userDealershipID
        // });
        toastr.success('Task ' + currentRow.displayName + " completed");
        if (Response.data.messageResponses && Response.data.messageResponses.length) {
          await Promise.all(Response.data.messageResponses.map(async (messageObj) => {
            await axios.post(`${CONSTANTS.API_URL}/api/v1/whatsappText`, {
              // number: "9904695543",
              number: messageObj.whatsappNumber ? messageObj.whatsappNumber : messageObj.mobile,
              message: messageObj.message,
              dealershipID: currentRow.dealershipID ? currentRow.dealershipID : userInfo.userDealershipID
            });
            if (messageObj.sendTo) {
              let messageNotification = await axios.post(`${CONSTANTS.API_URL}/api/v1/notification`, messageObj);
              if (messageNotification.status === 200) {
                triggerNotifications(true)
              }
              toastr.info(messageObj.message);
            } else {
              toastr.info(messageObj.message);
            }
          }))
        }
        await toggleFormPopover();
        /*
          modified : Ashutosh G
          modified : 24/05/2024
          modification : isSaveDisabled set to false after api done its job
        */
        await setIsSaveDisabled(false);
    } catch (HTTPException) {
      if (HTTPException && HTTPException.config && HTTPException.config.url) {
        let payload = {
          apiURL:HTTPException.config.url,
          errorObject:HTTPException,
          statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
          errorMessage:HTTPException.message,
          functionName:"submitFormNew",
          fileName:"rightPanel",
          pageName: "rightPanel",
          userID:userInfo.uuid,
          userName:userInfo.displayName
        };
        await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      }
      return HTTPException;
    }
  }

  async function setFormValueInput(e, fieldName, actionIndex) {
    e.preventDefault();
    if (fieldName === "amount") {
      let amountInWords = await inWords(e.target.value);
      let amountNode = document.getElementById(`amount-`+fieldName);
      if (amountNode) {
        amountNode.innerHTML = "INR " + amountInWords;
        amountNode.style.display = "block";
        amountNode.style.color = "#4949ed";
      }
    } else if (fieldName === 'discount') {
      currentRow['dynamicProperties_discountToBeAdjusted'] = e.target.value;
      interactionObj['dynamicProperties_discountToBeAdjusted'] = e.target.value;
    } else if (fieldName === 'latestQuotationPolicyIDV' && currentRow.displayName === 'Generate Renewal Quotation') {
      currentRow['dynamicProperties_latestQuotationPolicyIDV'] = e.target.value;
      interactionObj['dynamicProperties_latestQuotationPolicyIDV'] = e.target.value;
      currentRow['dynamicProperties_latestQuotationPolicyIDV2'] = e.target.value;
      interactionObj['dynamicProperties_latestQuotationPolicyIDV2'] = e.target.value;
    } else if (fieldName === 'exShowroom' && currentRow.displayName === 'Create Case') {
      currentRow['dynamicProperties_policyIDV'] = Number(e.target.value) * 0.95;
      interactionObj['dynamicProperties_policyIDV'] = Number(e.target.value) * 0.95;
    } else if (fieldName === 'premium' && currentRow.displayName === 'Buy New Policy') {
      currentRow['dynamicProperties_chequeAmount'] = e.target.value;
      interactionObj['dynamicProperties_chequeAmount'] = e.target.value;
      currentRow['dynamicProperties_transactionAmount'] = e.target.value;
      interactionObj['dynamicProperties_transactionAmount'] = e.target.value;
    }  /*
       By: Yashvi
       On: 27th May 2024
       T251: BG_FN: Mobile no. field missing
       Added mobile validation
     */ else if (["firstName", "lastName", "name", "email", "address", "dateOfBirth", "nomineeDateOfBirth", "nominee", "engineNumber", "vin", "registrationNo", "mobile"].includes(fieldName)) {
      currentRow['dynamicProperties_old' + fieldName.charAt(0).toUpperCase() + fieldName.slice(1)] = e.target.value;
      interactionObj['dynamicProperties_old' + fieldName.charAt(0).toUpperCase() + fieldName.slice(1)] = e.target.value;
    }
    if (!e.target.value) {
      let successNode = document.getElementById("success-"+actionIndex+"-"+fieldName);
      if (successNode) {
        successNode.style.display = "none";
      }
    }
    // if (fieldName.substring(0, 8) === 'adjusted') {
    //   let adjustedDiscount = Number(interactionObj['dynamicProperties_'+fieldName.charAt(8).toLowerCase()+fieldName.substring(9)]) - Number(e.target.value);
    //   let remainingDiscount = Number(interactionObj['dynamicProperties_discountToBeAdjusted']) - adjustedDiscount;
    //   if (remainingDiscount >= 0) {
    //     interactionObj['dynamicProperties_discountToBeAdjusted'] = remainingDiscount;
    //     currentRow['dynamicProperties_' + fieldName] = e.target.value;
    //     interactionObj['dynamicProperties_' + fieldName] = e.target.value;
    //   }
    // } else {
      currentRow['dynamicProperties_' + fieldName] = e.target.value;
      interactionObj['dynamicProperties_' + fieldName] = e.target.value;
    // }
    if (document.getElementById(fieldName)) {
      if ((fieldName === "addressLine1" || fieldName === "companyAddress") && e.target.value.length < 4) {
        document.getElementById(fieldName).style.borderBottom = "2px solid red";
        let spanNode = document.getElementById("error-"+fieldName);
        if (spanNode) {
          spanNode.style.display ="block";
          spanNode.innerHTML = "Address should be greater than 4 characters"
        }
      } else if ((fieldName === "addressLine1" || fieldName === "companyAddress") && e.target.value.length > 50) {
        document.getElementById(fieldName).style.borderBottom = "2px solid red";
        let spanNode = document.getElementById("error-"+fieldName);
        if (spanNode) {
          spanNode.style.display ="block";
          spanNode.innerHTML = "Address should be less than 50 characters"
        }
      } else {
        document.getElementById(fieldName).style.borderBottom = "1px solid #9e9e9e";
        let node = document.getElementById("error-"+fieldName);
        if (node) {
          node.style.color = "#9e9e9e";
        }
        if (e.target.value) {
          let successNode = document.getElementById("success-"+actionIndex+"-"+fieldName);
          if (successNode) {
            successNode.style.display = "inline";
            let requiredNode = document.getElementById("required-"+actionIndex+"-"+fieldName);
            if (requiredNode) {
              requiredNode.style.display = "none";
            }
          }
        }

      }

    }
    if (fieldName === "newpincode" || fieldName === "pincode" || fieldName === "companyPincode" || fieldName === "registrationAddressPincode" || fieldName === "ownerPincode") {
      getAreaByPincode(e.target.value,fieldName);
    }
    if (fieldName === 'state') {
      getCityList(e.target.value);
    }
    // modified by Vihang
    // modified at 07/06/2022
    // modification : email verification code changes
    if (fieldName === "VerificationCode") {
      if (e.target.value.length === 6) {
        setDisableEmailVerifyButton(false)
      }
      interactionForPdf['dynamicProperties_OtpEnteredByCustomer'] = e.target.value;
    }

    let index = dynamicPropsWithValues.findIndex((d) => d.name === fieldName);
    if (index === -1) {
      dynamicPropsWithValues.push({
        name: fieldName
      });
      setDynamicPropsWithValues(dynamicPropsWithValues);
      progress = dynamicPropsWithValues.length / dynamicProps.length;
      setProgress(progress);
    }
    if (fieldName === 'quotedPrice' && interactionObj['dynamicProperties_expectedPrice']) {
       interactionObj['dynamicProperties_differenceInPrice'] = Math.abs(Number(interactionObj['dynamicProperties_quotedPrice']) - Number(interactionObj['dynamicProperties_expectedPrice']));
    } else if (fieldName === 'expectedPrice' && interactionObj['dynamicProperties_quotedPrice']) {
      interactionObj['dynamicProperties_differenceInPrice'] = Math.abs(Number(interactionObj['dynamicProperties_quotedPrice']) - Number(interactionObj['dynamicProperties_expectedPrice']));
    }
    setIsFormUpdated(!isFormUpdated);
  }

  async function saveFormInput(e, fieldName, formType, index, propIndex) {
    e.preventDefault();
    if (fieldName.substring(0, 8) === 'adjusted') {
      let adjustedDiscount = Number(interactionObj['dynamicProperties_'+fieldName.charAt(8).toLowerCase()+fieldName.substring(9)]) - Number(e.target.value);
      let remainingDiscount = Number(interactionObj['dynamicProperties_discountToBeAdjusted']) - adjustedDiscount;
      if (remainingDiscount >= 0) {
        interactionObj['dynamicProperties_discountToBeAdjusted'] = remainingDiscount;
        currentRow['dynamicProperties_' + fieldName] = e.target.value;
        interactionObj['dynamicProperties_' + fieldName] = e.target.value;
      }
    } else {
      currentRow['dynamicProperties_' + fieldName] = e.target.value;
      interactionObj['dynamicProperties_' + fieldName] = e.target.value;
      if (["relation", "hypothecationBankName", "dateOfBirth", "nomineeDateOfBirth"].includes(fieldName)) {
       currentRow['dynamicProperties_old' + fieldName.charAt(0).toUpperCase() + fieldName.slice(1)] = e.target.value;
       interactionObj['dynamicProperties_old' + fieldName.charAt(0).toUpperCase() + fieldName.slice(1)] = e.target.value;
     }
    }
    let payload = {};
    payload[fieldName] = currentRow['dynamicProperties_' + fieldName];
    // await updateTask(payload);
    let indexField = dynamicPropsWithValues.findIndex((d) => d.name === fieldName);
    if (indexField === -1) {
      dynamicPropsWithValues.push({
        name: fieldName
      });
      setDynamicPropsWithValues(dynamicPropsWithValues);
      if (dynamicProps.length) {
        progress = dynamicPropsWithValues.length / dynamicProps.length;
      } else if (actions[index] && actions[index]['dynamicProps'] && actions[index]['dynamicProps'].length) {
        progress = dynamicPropsWithValues.length / actions[index]['dynamicProps'].length;
      }
      setProgress(progress);
    }
    if (formType && formType === "select") {
      // dynamicProp.dependentEnum
      if (actions.length) {
        await Promise.all(actions[index]['dynamicProps'].map(async (prop) => {
          if (prop.dependentEnum && prop.dependentEnum.length && prop.dependentEnum[0]['nameOfProp'] === fieldName) {
            await Promise.all(prop.dependentEnum.map((dependentEnumObj) => {
              if (dependentEnumObj['valueOfProp'] === e.target.value) {
                dependentEnumObj['isSelected'] = true
              } else {
                dependentEnumObj['isSelected'] = false
              }
            }));
          }
        }));
      } else if (dynamicProps.length) {
        await Promise.all(dynamicProps.map(async (prop) => {
          if (prop.dependentEnum && prop.dependentEnum.length && prop.dependentEnum[0]['nameOfProp'] === fieldName) {
            await Promise.all(prop.dependentEnum.map((dependentEnumObj) => {
              if (dependentEnumObj['valueOfProp'] === e.target.value) {
                dependentEnumObj['isSelected'] = true
              } else {
                dependentEnumObj['isSelected'] = false
              }
            }));
          }
        }));
      }

      // setActions(actions);
    }
    if (fieldName === "interestedModel") {
      currentRow['dynamicProperties_' + fieldName] = e.target.value;
      interactionObj['dynamicProperties_' + fieldName] = e.target.value;
      payload[fieldName] = currentRow['dynamicProperties_' + fieldName];
    }
    if (fieldName === "newpincode" || fieldName === "pincode" || fieldName === "companyPincode") {
      getAreaByPincode(e.target.value,fieldName);
    }
    if (fieldName === 'state') {
      getCityList(e.target.value);
    }
    setIsFormUpdated(!isFormUpdated);
  }

  async function dynamicButtonClicked(e, buttonName) {
     e.preventDefault();

     /*
       modification : T319 (Change Taken From caseSemiDetailViewV3) isTaskCanComplete not exists then returning from function
       modified : Ashutosh G
       modified : 23/05/2024
     */
     const isTaskCanComplete = checkIfTaskCanComplete();
     if (!isTaskCanComplete) return;

     try {
       if (buttonName === "Call") {
         await axios.post(`${CONSTANTS.API_URL}/api/v1/originateCall`, {
           userID: userInfo.uuid,
           mobileNumber: interactionObj['dynamicProperties_mobile'],
           taskID: currentRow.uuid
         });
       } else if ((buttonName === "Register Request") || (buttonName === "Schedule")) {
         let TestDriveResponse = await axios.put(`${CONSTANTS.API_URL}/api/v1/scheduleTestDrive/${currentRow.uuid}`, {
           cartProducts
         });
         if (TestDriveResponse.data.testDriveQueueNumber) {
           setTestDriveQueue(TestDriveResponse.data.testDriveQueueNumber);
         }
       } else if (buttonName === "Approve") {
         /*
         Modification: Added old currentTask to get old status in notification payload
         By: Komal
         Date: 30/03/2023
       */
        let oldTaskObj = deepCopy(currentTask);

         let payload = currentRow;
         currentRow['status'] = "Approved";
         let Response = await axios.put(`${CONSTANTS.API_URL}/api/v2/task/${currentRow.uuid}/markComplete`, payload)
         let notificationObj = {
           typeOfNotification: "push",
           message: "Task " + currentRow.displayName + " approved",
           /*
           Modification: Added newly created keys in payload to be saved and used for notificationDashboard
           By: Devang
           Date: 18/02/2023
         */
           category: "Changed Status",
           currentStatus: "Approved",
           previousStatus: oldTaskObj.status,
           interactionID: currentTask.interactionID,
           createdFrom: 'frontend-rightPanel-dynamicButtonClicked-#1',

           taskID: currentRow.uuid,
           group: "task",
           isSent: true,
           isRead: false,
           sendTo: userInfo.uuid
         };
         // if (Response.data.triggeredTaskList.length) {
         //   await setTriggeredUnassignedTaskList(Response.data.triggeredTaskList);
         //   await toggleOpenAssignUsersTriggeredTask();
         // }
         // if (Response.data.triggeredTaskListForApproval.length) {
         //   await setTriggeredUnassignedApprovalTaskList(Response.data.triggeredTaskListForApproval);
         //   await toggleOpenAssignApprovalUsersTriggeredTask();
         // }
         let taskCompletionMessage = await axios.post(`${CONSTANTS.API_URL}/api/v1/notification`, notificationObj);
         if (taskCompletionMessage.status === 200) {
           triggerNotifications(true)
         }
         toastr.success('Task ' + currentRow.displayName + " approved");
         if (Response.data.messageResponses && Response.data.messageResponses.length) {
           await Promise.all(Response.data.messageResponses.map(async (messageObj) => {
             if (messageObj.sendTo) {
               let messageNotification = await axios.post(`${CONSTANTS.API_URL}/api/v1/notification`, messageObj);
               if (messageNotification.status === 200) {
                 triggerNotifications(true)
               }
               toastr.info(messageObj.message);
             } else {
               toastr.info(messageObj.message);
             }
           }))
         }
         await toggleFormPopover();

         // let ApproveTask = await axios.put(`${CONSTANTS.API_URL}/api/v2/task/${currentRow.uuid}/approve`, {
         //   status: 'Approved'
         // });
         // triggerNotifications(true)
         // toastr.info(`${currentRow.displayName} Approved`);
         // if (Response.data.messageResponses && ApproveTask.data.messageResponses.length) {
         //   await Promise.all(ApproveTask.data.messageResponses.map(async (messageObj) => {
         //     if (messageObj.sendTo) {
         //       let messageNotification = await axios.post(`${CONSTANTS.API_URL}/api/v1/notification`, messageObj);
         //       if (messageNotification.status === 200) {
         //         triggerNotifications(true)
         //       }
         //       toastr.info(messageObj.message);
         //     } else {
         //       toastr.info(messageObj.message);
         //     }
         //   }))
         // }
       } else if (buttonName === "Reject") {
         let payload = currentRow;
         currentRow['status'] = "Rejected";
         let Response = await axios.put(`${CONSTANTS.API_URL}/api/v2/task/${currentRow.uuid}/markComplete`, payload)
         // let notificationObj = {
         //   typeOfNotification: "push",
         //   message: "Task " + currentRow.displayName + " rejected",
         //   taskID: currentRow.uuid,
         //   group: "task",
         //   isSent: true,
         //   isRead: false,
         //   sendTo: userInfo.uuid
         // };
         // let taskCompletionMessage = await axios.post(`${CONSTANTS.API_URL}/api/v1/notification`, notificationObj);
         // if (taskCompletionMessage.status === 200) {
         //   triggerNotifications(true)
         // }
         if (Response.data.messageResponses && Response.data.messageResponses.length) {
           await Promise.all(Response.data.messageResponses.map(async (messageObj) => {
             if (messageObj.sendTo) {
               let messageNotification = await axios.post(`${CONSTANTS.API_URL}/api/v1/notification`, messageObj);
               if (messageNotification.status === 200) {
                 triggerNotifications(true)
               }
               toastr.info(messageObj.message);
             } else {
               toastr.info(messageObj.message);
             }
           }))
         }
         await toggleFormPopover();

         // let ApproveTask = await axios.put(`${CONSTANTS.API_URL}/api/v2/task/${currentRow.uuid}/approve`, {
         //   status: 'Approved'
         // });
         // triggerNotifications(true)
         // toastr.info(`${currentRow.displayName} Approved`);
         // if (Response.data.messageResponses && ApproveTask.data.messageResponses.length) {
         //   await Promise.all(ApproveTask.data.messageResponses.map(async (messageObj) => {
         //     if (messageObj.sendTo) {
         //       let messageNotification = await axios.post(`${CONSTANTS.API_URL}/api/v1/notification`, messageObj);
         //       if (messageNotification.status === 200) {
         //         triggerNotifications(true)
         //       }
         //       toastr.info(messageObj.message);
         //     } else {
         //       toastr.info(messageObj.message);
         //     }
         //   }))
         // }
       } else if (buttonName === "Request Feedback") {
           triggerNotifications(true)
           toastr.info('Feedback link sent to the Customer');
       } else if (buttonName === "Send Link for Digital Signature") {
         triggerNotifications(true)
         toastr.success('Docket Sent to Customer for e-Signature successfully');
         let buttonElement = document.getElementById(e.target.id);
         buttonElement.setAttribute("disabled", true);
       } else if (buttonName === "Push to NDMS") {
         triggerNotifications(true)
         toastr.info('Booking is being pushed to NDMS');
         let buttonElement = document.getElementById(e.target.id);
         buttonElement.setAttribute("disabled", true);
       } else if (buttonName === "Finance Quotation") {
         let payload = {
           taskID: currentRow.uuid,
           interactionID: currentRow.interactionID,
           customerName: interactionForPdf && interactionForPdf["dynamicProperties_customerName"] ? interactionForPdf["dynamicProperties_customerName"] : '',
           customerAddress: interactionForPdf && interactionForPdf && interactionForPdf["dynamicProperties_addressLine1"] && interactionForPdf["dynamicProperties_addressLine2"] && interactionForPdf["dynamicProperties_state"] && interactionForPdf["dynamicProperties_city"] && interactionForPdf["dynamicProperties_pincode"] ?  (interactionForPdf["dynamicProperties_addressLine1"] + " " + interactionForPdf["dynamicProperties_addressLine2"] + ", " +  interactionForPdf["dynamicProperties_state"] + ", " + interactionForPdf["dynamicProperties_city"] + ", " + interactionForPdf["dynamicProperties_pincode"]).charAt(0).toUpperCase() + (interactionForPdf["dynamicProperties_addressLine1"] + " " + interactionForPdf["dynamicProperties_addressLine2"] + ", " +  interactionForPdf["dynamicProperties_state"] + ", " + interactionForPdf["dynamicProperties_city"] + ", " + interactionForPdf["dynamicProperties_pincode"]).slice(1): "",
           customerMobile: interactionForPdf && interactionForPdf["dynamicProperties_mobile"] ? interactionForPdf["dynamicProperties_mobile"] : '',
           // modelID: currentRow.modelID,
           modelName: interactionForPdf && interactionForPdf["dynamicProperties_selectedModelName"] ?  interactionForPdf["dynamicProperties_selectedModelName"] : "",
           color: interactionForPdf && interactionForPdf["dynamicProperties_selectedColor"] ?  interactionForPdf["dynamicProperties_selectedColor"] : "",
           bankID: 'ec840b45-7eba-4366-a0a9-32f043e130a9',
           // onRoadPrice: interactionForPdf && interactionForPdf['dynamicProperties_onRoadPrice'] ? interactionForPdf['dynamicProperties_onRoadPrice'] : 0,
           exShowroomPrice: interactionForPdf && interactionForPdf['dynamicProperties_exShowroom'] ? interactionForPdf['dynamicProperties_exShowroom'] : 0,
           emi: 22000,
           tenure: 60,
           selectedConfigIDForStampDuty: 'ab848fdc-c78f-4305-97db-c264b022fed2',
           selectedConfigIDForProcessingFee: '636e98fb-538d-4752-8aa9-a54e0713ea98',
           selectedModelID: interactionForPdf['dynamicProperties_selectedModel'],
           selectedVariantID: interactionForPdf['dynamicProperties_selectedVariant']
         }
         let onRoadPrice = 0;
         if (interactionForPdf && interactionForPdf['dynamicProperties_exShowroom']) {
           onRoadPrice = onRoadPrice + interactionForPdf['dynamicProperties_exShowroom'];
         }
         if (interactionForPdf && interactionForPdf['dynamicProperties_exShowroom'] > 1000000) {
           onRoadPrice = onRoadPrice + interactionForPdf['dynamicProperties_tcsOnExShowroom'];
         }
         if (interactionForPdf && interactionForPdf['dynamicProperties_rtoIndividual']) {
           onRoadPrice = onRoadPrice + interactionForPdf['dynamicProperties_rtoIndividual'];
         }
         if (interactionForPdf && interactionForPdf['dynamicProperties_insuranceCalculatedl']) {
           onRoadPrice = onRoadPrice + interactionForPdf['dynamicProperties_insuranceCalculated'];
         }
         payload['onRoadPrice'] = onRoadPrice;
         let response = await axios.post(`${CONSTANTS.API_URL}/api/v1/financeQuotation`, payload);
       }
     } catch (HTTPException) {
       if (HTTPException && HTTPException.config && HTTPException.config.url) {
         let payload = {
           apiURL:HTTPException.config.url,
           errorObject:HTTPException,
           statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
           errorMessage:HTTPException.message,
           functionName:"dynamicButtonClicked",
           fileName:"rightPanel",
           pageName: "rightPanel",
           userID:userInfo.uuid,
           userName:userInfo.displayName
         };
         await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
       }
       return HTTPException;
     }
   }

  async function getSelectedTypeUserList(value) {
    try {
     currentRow['dynamicProperties_lostCaseApprovalRole'] = value;
     interactionObj['dynamicProperties_lostCaseApprovalRole'] = value;
     setIsFormUpdated(!isFormUpdated);
     let response = await axios.get(`${CONSTANTS.API_URL}/api/v1/userorganizations?dealershipIDs=${userInfo.dealership}&designation=${value}&branchIDs=${userInfo.userBranchID}`);
     // let userList = response.data.filter((user) => user.roleName === value)
     await setAssignUserList(response.data)
   } catch (HTTPException) {
     if (HTTPException && HTTPException.config && HTTPException.config.url) {
       let payload = {
         apiURL:HTTPException.config.url,
         errorObject:HTTPException,
         statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
         errorMessage:HTTPException.message,
         functionName:"getSelectedTypeUserList",
         fileName:"rightPanel",
         pageName: "rightPanel",
         userID:userInfo.uuid,
         userName:userInfo.displayName
       };
       await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
     }
     return HTTPException;
   }
  }

  async function saveFormInputRadio(e, fieldName, value, index) {
    if (fieldName === "selectedScheme") {
      interactionObj["dynamicProperties_schemeDiscount"] = interactionObj["dynamicProperties_schemeDiscount"] ? interactionObj["dynamicProperties_schemeDiscount"] + schemes[index]['discountAfterGST'] : schemes[index]['discountAfterGST'];
      currentRow["dynamicProperties_schemeDiscount"] = interactionObj["dynamicProperties_schemeDiscount"] ? interactionObj["dynamicProperties_schemeDiscount"] + schemes[index]['discountAfterGST'] : schemes[index]['discountAfterGST'];
    }
    if (value) {
      currentRow['dynamicProperties_' + fieldName] = value;
      interactionObj['dynamicProperties_' + fieldName] = value;
    } else {
      currentRow['dynamicProperties_' + fieldName] = value;
      interactionObj['dynamicProperties_' + fieldName] = value;
    }
    let payload = {};
    payload[fieldName] = currentRow['dynamicProperties_' + fieldName];
    setIsFormUpdated(!isFormUpdated);

    // await updateTask(payload);
  }

  async function getCityList(stateName) {
    let stateObj = stateList.find(state => state.name === stateName);
    if (stateObj) {
      let countryCode = stateObj.countryCode ? stateObj.countryCode : '';
      let stateCode = stateObj.isoCode ? stateObj.isoCode : '';
      let cityListArr = City.getCitiesOfState(countryCode, stateCode);
      await setCityList(cityListArr);
    }
  }

  async function saveFormInputRadioDefault(e, fieldName) {
    currentRow['dynamicProperties_' + fieldName] = e.target.value;
    interactionObj['dynamicProperties_' + fieldName] = e.target.value;
    if (fieldName === 'isNoClaimBonus' && currentRow.displayName === 'Create Case' && e.target.value === 'no') {
      currentRow['dynamicProperties_ncbPercentage'] = "0";
      interactionObj['dynamicProperties_ncbPercentage'] = "0";
    } else if (fieldName === 'doyouHaveaWhatsappNumber' && e.target.value === 'no') {
      currentRow['dynamicProperties_isWhatsappNumberSameAsMobile'] = "";
      interactionObj['dynamicProperties_isWhatsappNumberSameAsMobile'] = "";
    } else if (fieldName === 'contactPersondoyouHaveaWhatsappNumber' && e.target.value === 'no') {
      currentRow['dynamicProperties_contactPersonisWhatsappNumberSameAsMobile'] = "";
      interactionObj['dynamicProperties_contactPersonisWhatsappNumberSameAsMobile'] = "";
    } else if (fieldName === "hypothecation") {
      currentRow['dynamicProperties_oldHypothecation'] = e.target.value;
      interactionObj['dynamicProperties_oldHypothecation'] = e.target.value;
    }
    let payload = {};
    payload[fieldName] = currentRow['dynamicProperties_' + fieldName];
    // await updateTask(payload);
    // if (e.target.value && document.getElementById(fieldName)) {
    //   document.getElementById(fieldName).style.border = "1px solid #d1d1d1";
    // }
    setIsFormUpdated(!isFormUpdated);

  }

  const deleteUploadedFileCase = async (e, fieldName) => {
    // interactionObj['dynamicProperties_'+`${fieldName}`] = '';
    interactionObj[`${fieldName}`] = '';
    await setEndorsementObj({ ...endorsementObj, [fieldName]: '', [`${fieldName}SignedURL`]: '' });
    let obj = JSON.parse(JSON.stringify(formEntityObject));
    // obj['dynamicProperties_'+fieldName] = '';
    obj[fieldName] = '';
    obj[fieldName] = '';formEntityObject = obj;
    setFormEntityObject(formEntityObject);
    setIsEntityFormObjectUpdated(!isEntityFormObjectUpdated);
  }

  function viewAllImages(e, fieldName) {
    let options = {
     zIndex:99999,
     rotatable:false,
     scalable:false
    }
    const gallery = new Viewer(document.getElementById(`image-${fieldName}`),options);
    gallery.show();
  }

  const isAllowedFileType = (f) => {
    let fileName = f.name;
    let extension = fileName.split('.').pop();
    if (filetypes.includes(extension)) {
      return true
    } else {
      return false
    }
  }

/*
 change:  Add isAllowedFileType function for check selected file type
 changeOn: 20 Dec 2022
 changeBy: Manohar Sule
*/

  const uploadFileCase = async (e, fieldName) => {
    try {
      const fileTypeError = await isAllowedFileType(e.target.files[0]);
      if (!fileTypeError) {
        toastr.warning("This file cannot be uploaded either because this is not a supported file type. Please exclude such files and try again.");
        return;
      }
      const file = {
        name: e.target.files[0].name,
        size: e.target.files[0].size,
        type: e.target.files[0].type,
        value: e.target.files[0].name,
      };
      let payload = {
        file,
        interactionID: interactionObj.uuid
      }
        let fileDetails
        await axios.post(`${CONSTANTS.API_URL}/api/v1/file/getSignedUrl`, payload).then(async res => {
          if (res && res.data) {
            fileDetails = res.data;
            try {
              //  Save File on S3
              const opts = {
                headers: {
                  name: 'Content-Type',
                  value: 'multipart/form-data',
                }
              };
              /*
                By: Yashvi
                On: 20th July 2022
                If file type is image only then compress the file.
              */
              let fileData = e.target.files[0];
              let fileExtension = e.target.files[0].name.split('.').pop();
              if (imageTypeExtension.includes(fileExtension)) {
                fileData = await getCompressedImageFile(e.target);
              }
              const fileUpload = await axios.put(fileDetails.signedURL, fileData, opts);

              // interactionObj['dynamicProperties_'+fieldName] = fileDetails.uuid;
              interactionObj[fieldName] = fileDetails.uuid;
              await setEndorsementObj({ ...endorsementObj, [fieldName]: fileDetails.uuid, [`${fieldName}SignedURL`]: URL.createObjectURL(e.target.files[0]) });
              let obj = JSON.parse(JSON.stringify(formEntityObject));
              // obj['dynamicProperties_'+fieldName] = fileDetails.uuid;
              obj[fieldName] = fileDetails.uuid;
              obj[fieldName] = fileDetails.uuid;
              formEntityObject = obj;
              setFormEntityObject(formEntityObject);
              setIsEntityFormObjectUpdated(!isEntityFormObjectUpdated);
            } catch (e) {
              console.error(e);
            }
          }
        });
    } catch (HTTPException) {
      let payload = {
        apiURL:HTTPException.config.url,
        errorObject:HTTPException,
        statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
        errorMessage:HTTPException.message,
        functionName:"uploadFileCase",
        fileName:"rightPanel",
        pageName: "rightPanel",
        userID:userInfo.uuid,
        userName:userInfo.displayName
      };
      await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      return HTTPException;
    }
  }

  async function saveAreaFromForm(e, name) {
    interactionObj['dynamicProperties_' + name] = e.target.value;
    currentRow['dynamicProperties_' + name] = e.target.value;
    setIsFormUpdated(!isFormUpdated);
  }

  async function createEndorsement(interactionID, payload) {
    // e.preventDefault();
    try {
      payload['interactionID'] = interactionID;
      payload['typeOfEndorsement'] = endorsementObj.typeOfEndorsement;
      // selectedManagerID: endorsementObj.selectedManagerID,
      if (endorsementObj.typeOfEndorsement === 'ncbRecoveryRequest') {
        payload.typeOfTask = "ncbRecoveryRequest";
      } else if (endorsementObj.typeOfEndorsement === 'cancellationApproval') {
        payload.typeOfTask = "cancellationApproval";
      }
      // payload.typeOfEndorsement = endorsementObj.typeOfEndorsement;
      // if (endorsementObj.typeOfEndorsement === 'policy') {
      //   payload.endorsementFileID = endorsementObj.endorsementFileID;
      //   payload.endorsementRemark = endorsementObj.endorsementRemark;
      // } else if (endorsementObj.typeOfEndorsement === 'ncbCertificate') {
      //   payload.isNoClaimBonus = endorsementObj.isNoClaimBonus;
      //   payload.ncbCertificateID = endorsementObj.ncbCertificateID;
      //   payload.ncbPercentage = endorsementObj.ncbPercentage;
      // } else if (endorsementObj.typeOfEndorsement === 'ncbRecoveryRequest') {
      //   payload.reasonForNCBRecovery = endorsementObj.reasonForNCBRecovery;
      //   payload.recoveryAmount = endorsementObj.recoveryAmount;
      //   payload.ncbPercentage = endorsementObj.ncbPercentage;
      //   payload.checkingDate = endorsementObj.checkingDate;
      //   payload.mailSentDate = endorsementObj.mailSentDate;
      //   payload.callDate = endorsementObj.callDate;
      //   payload.insuranceCompany = endorsementObj.insuranceCompany;
      //   payload.endorsementRequestDate = endorsementObj.endorsementRequestDate;
      //   payload.approvalDate = endorsementObj.approvalDate;
      //   payload.paymentMode = endorsementObj.paymentMode;
      //   payload.endorsementFileID = endorsementObj.endorsementFileID;
      //   payload.paymentProofID = endorsementObj.paymentProofID;
      // } else if (endorsementObj.typeOfEndorsement === 'cancellationApproval') {
      //   payload.cancellationReason = endorsementObj.cancellationReason;
      //   if (endorsementObj.cancellationReason === 'Other') {
      //     payload.otherCancellationReason = endorsementObj.otherCancellationReason;
      //   }
      // } else if (endorsementObj.typeOfEndorsement === 'ncbRefund') {
      //   payload.endorsementRequestDate = endorsementObj.endorsementRequestDate;
      //   payload.ncbPercentage = endorsementObj.ncbPercentage;
      //   payload.recoveryAmount = endorsementObj.recoveryAmount;
      //   payload.ncbCertificateID = endorsementObj.ncbCertificateID;
      //   payload.rcCopyID = endorsementObj.rcCopyID;
      //   payload.cancelChequeID = endorsementObj.cancelChequeID;
      // } else if (endorsementObj.typeOfEndorsement === 'accessories') {
      //   payload.endorsementRequestDate = endorsementObj.endorsementRequestDate;
      //   payload.chargesPercentage = endorsementObj.chargesPercentage;
      //   payload.accessoriesInvoiceID = endorsementObj.accessoriesInvoiceID;
      // } else if (endorsementObj.typeOfEndorsement === 'ownerTransfer') {
      //   payload.endorsementRequestDate = endorsementObj.endorsementRequestDate;
      //   payload.recoveryAmount = endorsementObj.recoveryAmount;
      //   payload.ownerTransferDate = endorsementObj.ownerTransferDate;
      //   payload.paymentMode = endorsementObj.paymentMode;
      //   payload.inspectionPhotoID = endorsementObj.inspectionPhotoID;
      //   payload.inspectionReportID = endorsementObj.inspectionReportID;
      //   payload.rcCopyID = endorsementObj.rcCopyID;
      //   payload.endorsementCopyID = endorsementObj.endorsementCopyID;
      //   payload.paymentProofID = endorsementObj.paymentProofID;
      //   payload.bextractCopyID = endorsementObj.bextractCopyID;
      //   payload.bextractCopyID = endorsementObj.bextractCopyID;
      //   payload.bextractCopyID = endorsementObj.bextractCopyID;
      //   payload.transactionID = endorsementObj.transactionID;
      //   payload.paymentDate = endorsementObj.paymentDate;
      // } else if (endorsementObj.typeOfEndorsement === 'correction') {
      //   payload.endorsementRequestDate = endorsementObj.endorsementRequestDate;
      //   payload.typeOfCorrection = [];
      //   if (endorsementObj.nameCorrection || endorsementObj.addressCorrection || endorsementObj.nomineeNameCorrection) {
      //     payload.aadharID = endorsementObj.aadharID;
      //   }
      //   if (endorsementObj.addressCorrection && (endorsementObj.phoneBillID || endorsementObj.electricityBillID)) {
      //     payload.phoneBillID = endorsementObj.phoneBillID;
      //     payload.electricityBillID = endorsementObj.electricityBillID;
      //   }
      //   if (endorsementObj.nomineeNameCorrection) {
      //     payload.nomineePanID = endorsementObj.nomineePanID;
      //     payload.typeOfCorrection.push('Nominee Name');
      //   }
      //   if (endorsementObj.hypoticationCorrection) {
      //     payload.sanctionLetterID = endorsementObj.sanctionLetterID;
      //     payload.typeOfCorrection.push('HP (hypotication)');
      //   }
      //   if (endorsementObj.nameCorrection) {
      //     payload.panID = endorsementObj.panID;
      //     payload.typeOfCorrection.push('Name Correction');
      //   }
      //   if (endorsementObj.addressCorrection) {
      //     payload.typeOfCorrection.push('Address');
      //   }
      //   if (endorsementObj.relationCorrection) {
      //     payload.typeOfCorrection.push('Relation');
      //   }
      //   if (endorsementObj.emailCorrection) {
      //     payload.typeOfCorrection.push('Email ID');
      //   }
      //   payload.typeOfCorrection = payload.typeOfCorrection.join(',');
      // } else if (endorsementObj.typeOfEndorsement === 'cng') {
      //   payload.endorsementRequestDate = endorsementObj.endorsementRequestDate;
      //   payload.invoiceDate = endorsementObj.invoiceDate;
      //   payload.recoveryAmount = endorsementObj.recoveryAmount;
      //   payload.paymentMode = endorsementObj.paymentMode;
      //   payload.transactionID = endorsementObj.transactionID;
      //   payload.paymentDate = endorsementObj.paymentDate;
      //   payload.inspectionPhotoID = endorsementObj.inspectionPhotoID;
      //   payload.inspectionReportID = endorsementObj.inspectionReportID;
      //   payload.cngInvoiceID = endorsementObj.cngInvoiceID;
      //   payload.rcCopyID = endorsementObj.rcCopyID;
      //   payload.endorsementCopyID = endorsementObj.endorsementCopyID;
      // } else if (endorsementObj.typeOfEndorsement === 'newVehicleNo') {
      //   payload.endorsementRequestDate = endorsementObj.endorsementRequestDate;
      //   payload.vehicleNumber = endorsementObj.vehicleNumber;
      //   payload.endorsementFileID = endorsementObj.endorsementFileID;
      // }
      let Response = await axios.put(`${CONSTANTS.API_URL}/api/v1/createEndorsement`, payload);
      if (Response.data.messageResponses && Response.data.messageResponses.length) {
        await Promise.all(Response.data.messageResponses.map(async (messageObj) => {
          if (messageObj.sendTo) {
            let messageNotification = await axios.post(`${CONSTANTS.API_URL}/api/v1/notification`, messageObj);
            if (messageNotification.status === 200) {
              triggerNotifications(true)
            }
            toastr.info(messageObj.message);
          } else {
            toastr.info(messageObj.message);
          }
        }));
        await setEndorsementObj({
          searchQuery: '',
          endorsementFileID: '',
          endorsementRemark: '',
          ncbCertificateID: '',
          isNoClaimBonus: '',
          typeOfEndorsement: endorsementObj.typeOfEndorsement,
          selectedManagerID: '',
          endorsementFileIDSignedURL: '',
          ncbCertificateIDSignedURL: '',
          reasonForNCBRecovery: '',
          recoveryAmount: 0,
          cancellationReason: '',
          otherCancellationReason: '',
          ncbPercentage: 0,
          endorsementRequestDate: null,
          chargesPercentage: '',
          ownerTransferDate: null,
          paymentMode: '',
          transactionID: '',
          paymentDate: null,
          invoiceDate: null,
          vehicleNumber: '',
          checkingDate: null,
          mailSentDate: null,
          callDate: null,
          insuranceCompany: '',
          approvalDate: null,
          cancelChequeID: '',
          cancelChequeIDSignedURL: '',
          panID: '',
          panIDSignedURL: '',
          electricityBillID: '',
          electricityBillIDSignedURL: '',
          nomineePanID: '',
          nomineePanIDSignedURL: '',
          sanctionLetterID: '',
          sanctionLetterIDSignedURL: '',
          accessoriesInvoiceID: '',
          accessoriesInvoiceIDSignedURL: '',
          inspectionPhotoID: '',
          inspectionPhotoIDSignedURL: '',
          inspectionReportID: '',
          inspectionReportIDSignedURL: '',
          rcCopyID: '',
          rcCopyIDSignedURL: '',
          paymentProofID: '',
          paymentProofIDSignedURL: '',
          bextractCopyID: '',
          bextractCopyIDSignedURL: '',
          cngInvoiceID: '',
          cngInvoiceIDSignedURL: '',
          phoneBillID: '',
          phoneBillIDSignedURL: '',
          nameCorrection: false,
          addressCorrection: false,
          nomineeNameCorrection: false,
          relationCorrection: false,
          hypoticationCorrection: false,
          emailCorrection: false,
          accessoriesCost: 0
        });
        await setInteraction({});
      }
    } catch (HTTPException) {
      if (HTTPException && HTTPException.config && HTTPException.config.url) {
        let payload = {
          apiURL:HTTPException.config.url,
          errorObject:HTTPException,
          statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
          errorMessage:HTTPException.message,
          functionName:"createEndorsement",
          fileName:"rightPanel",
          pageName: "rightPanel",
          userID:userInfo.uuid,
          userName:userInfo.displayName
        };
        await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      }
      return HTTPException;
    }
  }

  {/*
    Modification:- Add loader for New Endorsement Request
    Name:- Rajkumar Pradhan
    Date:- 24-08-2023
  */}
  async function searchCase(type, interactionID) {
    setEndorsementLoader(true)
    try {
      let Response = {}
      if (interactionID) {
        Response = await axios.get(`${CONSTANTS.API_URL}/api/v1/findCase?q=${interactionID}&isForEndorsement=true`);
      } else {
        Response = await axios.get(`${CONSTANTS.API_URL}/api/v1/findCase?q=${endorsementObj.searchQuery}&interactionType=${endorsementObj.typeOfCase}&isForEndorsement=true`);
      }
      if (type === 'Input') {
        let searchResultsArr = Response.data;
        setSearchResults(searchResultsArr);
      } else {
        if (!Response.data[0] || !Response.data[0].uuid) {
          toastr.error('No Cases found. Make sure you have entered a corrected search query.');
          setEndorsementLoader(false)
          return;
        }
        await setInteraction(Response.data[0]);
        await setEndorsementObj({ ...endorsementObj, isNoClaimBonus: Response.data[0]['dynamicProperties_isNoClaimBonus'] ? Response.data[0]['dynamicProperties_isNoClaimBonus'] : '' });
        await getImageSignedUrlForEndorsement(Response.data[0]);
        let currentEntityName = endorsementObj.typeOfEndorsement ? endorsementObj.typeOfEndorsement : endorsementObj.typeOfCase ? endorsementObj.typeOfCase : '';
        let parentEntityID = Response.data[0].uuid;
        let parentEntityName = 'Interaction';
        if (currentEntityName) {
          await getEntityDynamicProps(currentEntityName, parentEntityID, 'parent', parentEntityName);
          setFormEntityName('Interaction');
          let formOperationName = endorsementObj.typeOfEndorsement ? requestTypes[endorsementObj.typeOfEndorsement] : endorsementObj.typeOfCase ? endorsementObj.typeOfCase : '';
          setFormOperation(formOperationName);
          setFormEntityObject(Response.data[0]);
          // setIsEntityFormObjectUpdated(!isEntityFormObjectUpdated);
          setIsCreateEditFormPopover(true);
        }
      }
      setEndorsementLoader(false)
    } catch (HTTPException) {
      setEndorsementLoader(false)
      if (HTTPException && HTTPException.config && HTTPException.config.url) {
        let payload = {
          apiURL:HTTPException.config.url,
          errorObject:HTTPException,
          statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
          errorMessage:HTTPException.message,
          functionName:"searchCase",
          fileName:"rightPanel",
          pageName: "rightPanel",
          userID:userInfo.uuid,
          userName:userInfo.displayName
        };
        await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      }
      return HTTPException;
    }
  }

  function toggleEndorsementFormPopover(e, type) {
    // e.preventDefault();
    setEndorsementObj({
      searchQuery: '',
      endorsementFileID: '',
      endorsementRemark: '',
      ncbCertificateID: '',
      isNoClaimBonus: '',
      typeOfEndorsement: '',
      typeOfCase: '',
      selectedManagerID: '',
      endorsementFileIDSignedURL: '',
      ncbCertificateIDSignedURL: '',
      reasonForNCBRecovery: '',
      recoveryAmount: 0,
      cancellationReason: '',
      otherCancellationReason: '',
      ncbPercentage: 0,
      endorsementRequestDate: null,
      chargesPercentage: '',
      ownerTransferDate: null,
      paymentMode: '',
      transactionID: '',
      paymentDate: null,
      invoiceDate: null,
      vehicleNumber: '',
      checkingDate: null,
      mailSentDate: null,
      callDate: null,
      insuranceCompany: '',
      approvalDate: null,
      cancelChequeID: '',
      cancelChequeIDSignedURL: '',
      panID: '',
      panIDSignedURL: '',
      electricityBillID: '',
      electricityBillIDSignedURL: '',
      nomineePanID: '',
      nomineePanIDSignedURL: '',
      sanctionLetterID: '',
      sanctionLetterIDSignedURL: '',
      accessoriesInvoiceID: '',
      accessoriesInvoiceIDSignedURL: '',
      inspectionPhotoID: '',
      inspectionPhotoIDSignedURL: '',
      inspectionReportID: '',
      inspectionReportIDSignedURL: '',
      rcCopyID: '',
      rcCopyIDSignedURL: '',
      paymentProofID: '',
      paymentProofIDSignedURL: '',
      bextractCopyID: '',
      bextractCopyIDSignedURL: '',
      cngInvoiceID: '',
      cngInvoiceIDSignedURL: '',
      phoneBillID: '',
      phoneBillIDSignedURL: '',
      nameCorrection: false,
      addressCorrection: false,
      nomineeNameCorrection: false,
      relationCorrection: false,
      hypoticationCorrection: false,
      emailCorrection: false,
      accessoriesCost: 0
    });
    setInteraction({});
    setIsOpenCaseFormPopover(!isOpenCaseFormPopover);
  }

  function toggleEditCaseFormPopover(e, type) {
    e.preventDefault();
    setEndorsementObj({
      searchQuery: '',
      endorsementFileID: '',
      endorsementRemark: '',
      ncbCertificateID: '',
      isNoClaimBonus: '',
      typeOfEndorsement: '',
      typeOfCase: '',
      selectedManagerID: '',
      endorsementFileIDSignedURL: '',
      ncbCertificateIDSignedURL: '',
      reasonForNCBRecovery: '',
      recoveryAmount: 0,
      cancellationReason: '',
      otherCancellationReason: '',
      ncbPercentage: 0,
      endorsementRequestDate: null,
      chargesPercentage: '',
      ownerTransferDate: null,
      paymentMode: '',
      transactionID: '',
      paymentDate: null,
      invoiceDate: null,
      vehicleNumber: '',
      checkingDate: null,
      mailSentDate: null,
      callDate: null,
      insuranceCompany: '',
      approvalDate: null,
      cancelChequeID: '',
      cancelChequeIDSignedURL: '',
      panID: '',
      panIDSignedURL: '',
      electricityBillID: '',
      electricityBillIDSignedURL: '',
      nomineePanID: '',
      nomineePanIDSignedURL: '',
      sanctionLetterID: '',
      sanctionLetterIDSignedURL: '',
      accessoriesInvoiceID: '',
      accessoriesInvoiceIDSignedURL: '',
      inspectionPhotoID: '',
      inspectionPhotoIDSignedURL: '',
      inspectionReportID: '',
      inspectionReportIDSignedURL: '',
      rcCopyID: '',
      rcCopyIDSignedURL: '',
      paymentProofID: '',
      paymentProofIDSignedURL: '',
      bextractCopyID: '',
      bextractCopyIDSignedURL: '',
      cngInvoiceID: '',
      cngInvoiceIDSignedURL: '',
      phoneBillID: '',
      phoneBillIDSignedURL: '',
      nameCorrection: false,
      addressCorrection: false,
      nomineeNameCorrection: false,
      relationCorrection: false,
      hypoticationCorrection: false,
      emailCorrection: false,
      accessoriesCost: 0
    });
    setInteraction({});
    setIsEditCaseFormPopover(!isEditCaseFormPopover);
  }

  async function saveAssignToUserChange(e) {
    try {
      let payload = {
        assignToUserList
      }
      let Response = await axios.put(`${CONSTANTS.API_URL}/api/v1/assignUserToTriggeredTasks`, payload);
      if (Response.data.messageResponses && Response.data.messageResponses.length) {
        await Promise.all(Response.data.messageResponses.map(async (messageObj) => {
          if (messageObj.sendTo) {
            let messageNotification = await axios.post(`${CONSTANTS.API_URL}/api/v1/notification`, messageObj);
            toastr.info(messageObj.message);
          } else {
            toastr.info(messageObj.message);
          }
        }));
        await toggleOpenAssignUsersTriggeredTask();
      }
    } catch (HTTPException) {
      if (HTTPException && HTTPException.config && HTTPException.config.url) {
        let payload = {
          apiURL:HTTPException.config.url,
          errorObject:HTTPException,
          statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
          errorMessage:HTTPException.message,
          functionName:"saveAssignToUserChange",
          fileName:"rightPanel",
          pageName: "rightPanel",
          userID:userInfo.uuid,
          userName:userInfo.displayName
        };
        await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      }
      return HTTPException;
    }
  }

  async function inputClicked(e) {
    let textBox = document.getElementById(e.target.id);
    if (textBox) {
      await textBox.focus();
      await textBox.select();
    }
  }

  async function setFormValueInputCase(e) {
    await setEndorsementObj({ ...endorsementObj, [e.target.name]: e.target.value });
    if (e.target.name === "correction") {
      await setEndorsementObj({ ...endorsementObj, [e.target.id]: e.target.checked });
    }
    // else if (e.target.name === "searchQuery") {
    //   searchCase('Input');
    // }
  }

  async function uploadFile(e, fieldName) {
    try {
      const fileTypeError = await isAllowedFileType(e.target.files[0]);
      if (!fileTypeError) {
        toastr.warning("This file cannot be uploaded either because this is not a supported file type. Please exclude such files and try again.");
        return;
      }
      const file = {
        name: e.target.files[0].name,
        size: e.target.files[0].size,
        type: e.target.files[0].type,
        value: e.target.files[0].name,
      };
      let payload = {
        file,
        interactionID: interactionObj.uuid
      }
        let fileDetails
        await axios.post(`${CONSTANTS.API_URL}/api/v1/file/getSignedUrl`, payload).then(async res => {
          if (res && res.data) {
            fileDetails = res.data;
            try {
              //  Save File on S3
              const opts = {
                headers: {
                  name: 'Content-Type',
                  value: 'multipart/form-data',
                }
              };
              /*
                By: Yashvi
                On: 20th July 2022
                If file type is image only then compress the file.
              */
              let fileData = e.target.files[0];
              let fileExtension = e.target.files[0].name.split('.').pop();
              if (imageTypeExtension.includes(fileExtension)) {
                fileData = await getCompressedImageFile(e.target);
              }
              const fileUpload = await axios.put(fileDetails.signedURL, fileData, opts);

              currentRow['dynamicProperties_'+fieldName] = fileDetails.uuid;
              interactionObj['dynamicProperties_'+fieldName] = fileDetails.uuid;
              // currentRow['dynamicProperties_'+fieldName] = URL.createObjectURL(e.target.files[0]);
              // interactionObj['dynamicProperties_'+fieldName] = URL.createObjectURL(e.target.files[0]);
              await setIsFormUpdated(!isFormUpdated);

            } catch (e) {
              console.error(e);
            }
          }
        });
    } catch (HTTPException) {
      if (HTTPException && HTTPException.config && HTTPException.config.url) {
        let payload = {
          apiURL:HTTPException.config.url,
          errorObject:HTTPException,
          statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
          errorMessage:HTTPException.message,
          functionName:"uploadFile",
          fileName:"rightPanel",
          pageName: "rightPanel",
          userID:userInfo.uuid,
          userName:userInfo.displayName
        };
        await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      }
      return HTTPException;
    }
  }

  async function deleteUploadedFile(e, fieldName) {
    let payload = {
      interactionID: interactionObj.uuid,
      fieldName
    };
    // let DeletedInteractionObj = await axios.put(`${CONSTANTS.API_URL}/api/v1/interaction/removeField/${interactionObj['uuid']}`, payload);
    interactionObj['dynamicProperties_'+fieldName] = '';
    currentRow['dynamicProperties_'+fieldName] = '';
    // let Response = await axios.put(`${CONSTANTS.API_URL}/api/v1/file/remove/${interactionObj['dynamicProperties_'+fieldName]}`, payload);
    setIsFormUpdated(!isFormUpdated);
  }

  {/*
    Bug:- COMP_BG_589
    Modification:- truevalue is send as a props and interaction equal "undefined" from string is changed to undefined
    By:- Rajkumar Pradhan
    On:- 25-09-2023
  */}
  async function saveFormInputCheckbox(e, fieldName, value, trueValue) {
    if (fieldName === "selectedCouponID") {
      if (e.target.checked && currentRow['dynamicProperties_' + fieldName] && (!currentRow['dynamicProperties_' + fieldName] || !currentRow['dynamicProperties_' + fieldName].split(",").includes(value))) {
        currentRow['dynamicProperties_' + fieldName] = currentRow['dynamicProperties_' + fieldName] + ',' + value;
        interactionObj['dynamicProperties_' + fieldName] = interactionObj['dynamicProperties_' + fieldName] + ',' + value;
      } else if (e.target.checked && !currentRow['dynamicProperties_' + fieldName]) {
        currentRow['dynamicProperties_' + fieldName] = value;
        interactionObj['dynamicProperties_' + fieldName] = value;
      } else if (!e.target.checked && currentRow['dynamicProperties_' + fieldName].split(",").includes(value)) {
        let idx = currentRow['dynamicProperties_' + fieldName].split(",").findIndex(d => d === value);
        let tempArr = currentRow['dynamicProperties_' + fieldName].split(",");
        let deleted = tempArr.splice(idx,1);
        currentRow['dynamicProperties_' + fieldName] = tempArr.join(",");
        interactionObj['dynamicProperties_' + fieldName] = currentRow['dynamicProperties_' + fieldName];
      }
    } else {
      if (e.target.checked && (!currentRow['dynamicProperties_' + fieldName] || !currentRow['dynamicProperties_' + fieldName].split(",").includes(value))) {
        currentRow['dynamicProperties_' + fieldName] = currentRow['dynamicProperties_' + fieldName] + ',' + value;
        interactionObj['dynamicProperties_' + fieldName] = interactionObj['dynamicProperties_' + fieldName] + ',' + value;
      } else if (!e.target.checked && currentRow['dynamicProperties_' + fieldName].split(",").includes(value)) {
        let idx = currentRow['dynamicProperties_' + fieldName].split(",").findIndex(d => d === value);
        let tempArr = currentRow['dynamicProperties_' + fieldName].split(",");
        let deleted = tempArr.splice(idx,1);
        currentRow['dynamicProperties_' + fieldName] = tempArr.join(",");
        interactionObj['dynamicProperties_' + fieldName] = currentRow['dynamicProperties_' + fieldName];
        if(interactionObj['dynamicProperties_' + fieldName] == "undefined"){
          interactionObj['dynamicProperties_' + fieldName] = undefined;
        }
      }
    }
    // currentRow['dynamicProperties_' + fieldName] = e.target.value;
    let payload = {};
    payload[fieldName] = currentRow['dynamicProperties_' + fieldName];
    // await updateTask(payload);
    setIsFormUpdated(!isFormUpdated);
  }

  function toggleOpenAssignUsersTriggeredTask() {
    setIsOpenAssignUsersTriggeredTask(!isOpenAssignUsersTriggeredTask)
  }

  function handleAssignToUserChange(e, triggeredTask) {
    let assigneToIndex = assignToUserList.findIndex(task => task.taskID === triggeredTask.taskID);
    if (assigneToIndex > -1) {
      assignToUserList[assigneToIndex].userID = e.target.value;
      setAssignToUserList(assignToUserList);
    } else {
      assignToUserList.push({
        userID: e.target.value,
        taskID: triggeredTask.taskID
      });
      setAssignToUserList(assignToUserList);
    }
  }

  async function toggleFormPopover() {
    try {
     if (isOpenFormPopover) {
       await setIsOpenFormPopover(!isOpenFormPopover);
       if (currentRow && (!currentRow['dynamicProperties_proposerType'] || (currentRow['dynamicProperties_proposerType'] && !currentRow['dynamicProperties_proposerType'].trim()))) {
         await axios.put(`${CONSTANTS.API_URL}/api/v1/deleteInteraction/${currentRow.uuid}`);
       }
       // await setAssignToType('');
       // await setSelectedDepartment('');
       return;
     }
     await setSelectedModel('');
     await setVariantList([]);
     await setCurrentAction('');
     await setDynamicProps([]);
     await setIsDataSaving(false);
     if (currentRow.interactionID) {
       let Interaction = await axios.get(`${CONSTANTS.API_URL}/api/v1/interaction?uuids=${currentRow.interactionID}`);
       if (Interaction.data.length) {
         setInteraction(Interaction.data[0]);
         if (Interaction.data[0]['dynamicProperties_pincode']) {
           getAreaByPincode(Interaction.data[0]['dynamicProperties_pincode'], "pincode");
         } else if (Interaction.data[0]['dynamicProperties_companyPincode']) {
           getAreaByPincode(Interaction.data[0]['dynamicProperties_companyPincode'], "companyPincode");
         }
         if (Interaction.data[0]['dynamicProperties_state']) {
           getCityList(Interaction.data[0]['dynamicProperties_state']);
         }
         if (Interaction.data[0]['dynamicProperties_selectedModel'] && Interaction.data[0]['dynamicProperties_selectedModel'] !== 'NA') {
           setSelectedModel(Interaction.data[0]['dynamicProperties_selectedModel']);
           getVariantForSelectedModel(Interaction.data[0]['dynamicProperties_selectedModel']);
         }
       }
       // if (currentRow.displayName !== "Repair Request" && currentRow.displayName !== "Handover Courier" && currentRow.containerType === "Form" || currentRow.containerType === "Form and Approval" || currentRow.containerType === "Approval") {
       if (currentRow.containerType === "Form" || currentRow.containerType === "Form and Approval" || currentRow.containerType === "Approval") {
         let actions;
         if(currentRow.masterTaskID) {
           actions = await axios.get(`${CONSTANTS.API_URL}/api/v1/action?taskID=${currentRow.masterTaskID}&currentTaskID=${currentRow.uuid}`);
         }
         if (actions && actions.data && actions.data.length) {
           let totalProps = 0;
           await Promise.all(actions.data.map(async (action, index) => {
             let dynamicPropsAction = await axios.get(`${CONSTANTS.API_URL}/api/v1/properties?propertyType=dynamicProperty&actionID=${action.uuid}`);
             /*
               Modification: Handled if data exists
               By: Devang
               Date: 10/06/2022
             */
             if (dynamicPropsAction.data.Properties && dynamicPropsAction.data.Properties.length) {
               action['dynamicProps'] = dynamicPropsAction.data.Properties;
               action['dynamicPropsData'] = dynamicPropsAction.data.PropertiesByGroup;
               totalProps = totalProps + dynamicPropsAction.data.length;
               let formValidation = false;
               await Promise.all(action.dynamicProps.map(async (prop, index) => {
                 if(prop.isRequired && (Interaction.data[0]["dynamicProperties_"+ prop.name] === "" || !Interaction.data[0]["dynamicProperties_"+ prop.name])) {
                   if(!formValidation) {
                       formValidation = true;
                       action['isCompleted'] = false;
                   } else {
                     action['isCompleted'] = true;
                   }
                 } else if (index === action.dynamicProps.length -1 ) {
                   action['isCompleted'] = true;
                 }
                 if (Interaction.data[0]["dynamicProperties_" + prop.name] && Interaction.data[0]["dynamicProperties_" + prop.name] !== "NA") {
                   currentRow["dynamicProperties_" + prop.name] = Interaction.data[0]["dynamicProperties_" + prop.name];
                 }
                 if (prop.formType && prop.formType === "select") {
                   // dynamicProp.dependentEnum
                   if (prop.dependentEnum && prop.dependentEnum.length && prop.dependentEnum[0]['nameOfProp'] === 'mainSource') {
                     await Promise.all(prop.dependentEnum.map((dependentEnumObj) => {
                       if (dependentEnumObj['valueOfProp'] === interactionObj['dynamicProperties_mainSource']) {
                         dependentEnumObj['isSelected'] = true
                       } else {
                         dependentEnumObj['isSelected'] = false
                       }
                     }));
                   }
                   // setActions(actions);
                 } else if (prop.formType && prop.formType === 'file' && (Interaction.data[0]['dynamicProperties_'+prop.name] && Interaction.data[0]['dynamicProperties_'+prop.name] !== "NA")) {
                   prop.signedUrl = await getImageSignedUrl(prop.name, Interaction.data[0],props.currentRow);
                   await Promise.all(Object.keys(action.dynamicPropsData).map(async (group) => {
                     let indexOfProp = await action.dynamicPropsData[group].findIndex((d) => d.name === prop.name);
                     if (indexOfProp > -1) {
                       action.dynamicPropsData[group][indexOfProp]['signedUrl'] = prop.signedUrl;
                     }
                   }))
                 }
               }));

               setCurrentRow(currentRow);
               if (formValidation) {
                 setIsFormEditable(true)
                 // setCurrentAction(incompleteAction)
                 // setIncompleteFieldName(incompleteFieldName)
                 // setSelectedActionIndex(incompleteActionIndex-1)
               }
             }
           // }));
           // await setActions(actions.data);
           // await setSelectedArrayForDynamicProps(actions.data);
           // await Promise.all(actions.data.map(async (action, index) => {
             /*
               Modification: Handled if source is set to something and if its sub-source exists then field will be selected
               By: Devang
               Date: 10/06/2022
             */
             if (action.dynamicProps && action.dynamicProps.length) {
               await action.dynamicProps.map(async prop => {
                 if (prop.dependentEnum && prop.dependentEnum.length) {
                   await Promise.all(prop.dependentEnum.map((dependentEnumObj) => {
                     if (dependentEnumObj['valueOfProp'] === Interaction.data[0]['dynamicProperties_' + prop.dependentEnum[0]['nameOfProp']]) {
                       dependentEnumObj['isSelected'] = true;
                     } else {
                       dependentEnumObj['isSelected'] = false;
                     }
                   }));
                 }
               });
             // }
             // if (action.dynamicProps && action.dynamicProps.length) {
               await Promise.all(action.dynamicProps.map(async (prop) => {
                 let fieldName = prop.name;
                 let indexField = dynamicPropsWithValues.findIndex((d) => d.name === fieldName);
                 if (indexField === -1 && Interaction.data[0]["dynamicProperties_"+fieldName] && (Interaction.data[0]["dynamicProperties_"+fieldName] !== "NA" && Interaction.data[0]["dynamicProperties_"+fieldName] !== "")) {
                   dynamicPropsWithValues.push({
                     name: fieldName
                   });
                   setDynamicPropsWithValues(dynamicPropsWithValues);
                   progress = dynamicPropsWithValues.length / (totalProps);
                   setProgress(progress);
                 }
               }))
             }
           }));
           await setActions(actions.data);
           await setSelectedArrayForDynamicProps(actions.data);
           await setCurrentAction(actions.data[0]);
           /*
             Bug: Cars of the particular selected model was not fetched from database when modal is loaded for first time
             Modification: Commented out previous code and replaced currentAction with actions.data[0] because at this moment, value is not set yet
             By: Devang
             Date: 10//06/2022
           */
           if (actions.data[0].displayName === "Product Selection" || actions.data[0].displayName === "Test Drive Detail" || actions.data[0].displayName  === "Price Details" || actions.data[0].displayName  === "Need Assessment") {
           // if (currentAction.displayName === "Product Selection" || currentAction.displayName === "Test Drive Detail" || currentAction.displayName  === "Price Details") {
             let catalogoueItemResponse = await axios.get(`${CONSTANTS.API_URL}/api/v1/catalogue`);
             let array = catalogoueItemResponse.data
             const result = [];
             const map = new Map();
             for (const item of array) {
               if (!map.has(item.displayName)) {
                 map.set(item.displayName, true);    // set any value to Map
                 result.push({
                   ...item
                 });
               }
             }
             await setCatalogoueItemList(result)
             /*
               Bug: Cars of the particular selected model was not fetched from database when modal is loaded for first time
               Modification: Commented out previous code and replaced selectedProducts with Interaction.data[0] because at this moment, value is not set yet
               By: Devang
               Date: 10//06/2022
             */
             if (Interaction.data && Interaction.data[0]) {
               let testDriveCarsList = await axios.get(`${CONSTANTS.API_URL}/api/v1/stockItem?typeOfStockItem=testDrive&model=${modelMapping[Interaction.data[0]['dynamicProperties_selectedModelName']]}`);
             // if (selectedProducts && selectedProducts[0]) {
             //   let testDriveCarsList = await axios.get(`${CONSTANTS.API_URL}/api/v1/stockItem?typeOfStockItem=testDrive&model=${selectedProducts[0]['model']}`);
               if (testDriveCarsList.data.length) {
                 setTestDriveCars(testDriveCarsList.data);
               } else {
                 setTestDriveCars([]);
               }
             }
           }
           await setSelectedActionIndex(0);
         } else {
           await axios.get(`${CONSTANTS.API_URL}/api/v1/properties?propertyType=dynamicProperty&taskID=${currentRow.masterTaskID}`).then(async (response) => {
             let formValidation = false;
             await Promise.all(response.data.Properties.map(async (prop) => {
               if (Interaction.data[0]["dynamicProperties_" + prop.name] && Interaction.data[0]["dynamicProperties_" + prop.name] !== "NA") {
                 currentRow["dynamicProperties_" + prop.name] = Interaction.data[0]["dynamicProperties_" + prop.name];
                 await Promise.all(response.data.Properties.map(async (prop) => {
                   if(prop.isRequired && (Interaction.data[0]["dynamicProperties_"+ prop.name] === "" || !Interaction.data[0]["dynamicProperties_"+ prop.name])) {
                     if(!formValidation) {
                         formValidation = true;
                     }
                   }
                   if (prop.formType && prop.formType === 'file' && (Interaction.data[0]['dynamicProperties_'+prop.name] && Interaction.data[0]['dynamicProperties_'+prop.name] !== "NA")) {
                     prop.signedUrl = await getImageSignedUrl(prop.name, Interaction.data[0],props.currentRow);
                   }
                   let fieldName = prop.name;
                   let indexField = dynamicPropsWithValues.findIndex((d) => d.name === fieldName);
                   if (indexField === -1 && Interaction.data[0]["dynamicProperties_"+fieldName] && (Interaction.data[0]["dynamicProperties_"+fieldName] !== "NA" && Interaction.data[0]["dynamicProperties_"+fieldName] !== "")) {
                     dynamicPropsWithValues.push({
                       name: fieldName
                     });
                     setDynamicPropsWithValues(dynamicPropsWithValues);
                     progress = dynamicPropsWithValues.length / (response.data.length);
                     setProgress(progress);
                   }
                 }))
               }
             }));
             await setDynamicProps(response.data.Properties);
             await setDynamicPropsData(response.data.PropertiesByGroup);
             await setSelectedArrayForDynamicProps(response.data.Properties);
             await setActions([]);
             setCurrentRow(currentRow);
             if (formValidation) {
               setIsFormEditable(true)
             }
           });
         }
       }
       await setIsOpenFormPopover(!isOpenFormPopover);
     }
     if (!currentRow.interactionID) {
       await setCurrentRow(currentRow);
       await setIsOpenFormPopover(!isOpenFormPopover);
     }
     // let formModalContainerElement =  await document.getElementById("formModalContainer")
     // if(formModalContainerElement && formModalContainerElement.scrollHeight > formModalContainerElement.clientHeight) {
     //   formModalContainerElement.scrollTo({
     //    top: 0,
     //    behavior: 'smooth',
     //  })
     //   setTimeout(async () => {
     //      await setIsNextModalDisable(true);
     //      await setIsSaveModalDisable(true);
     //      await setIsBackModalDisable(true);
     //   }, 0)
     // } else {
     //      await setIsScrolledToTheBottom(true)
     //      await setIsNextModalDisable(false);
     //      await setIsSaveModalDisable(false);
     //      await setIsBackModalDisable(false);
     // }
    } catch (HTTPException) {
     if (HTTPException && HTTPException.config && HTTPException.config.url) {
       let payload = {
         apiURL:HTTPException.config.url,
         errorObject:HTTPException,
         statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
         errorMessage:HTTPException.message,
         functionName:"toggleFormPopover",
         fileName:"rightPanel",
         pageName: "rightPanel",
         userID:userInfo.uuid,
         userName:userInfo.displayName
       };
       await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
     }
     return HTTPException;
   }
  }

  async function getImageSignedUrlForEndorsement(interactionObject) {
    try {
      let fileIDArray = [
        "endorsementFileID",
        "ncbCertificateID",
        "cancelChequeID",
        "electricityBillID",
        "nomineePanID",
        "sanctionLetterID",
        "accessoriesInvoiceID",
        "inspectionPhotoID",
        "inspectionReportID",
        "rcCopyID",
        "paymentProofID",
        "bextractCopyID",
        "cngInvoiceID",
        "phoneBillID"
      ];
      await Promise.all(fileIDArray.map(async fieldName => {
        if ((typeof interactionObject['dynamicProperties_'+fieldName] === "object" && interactionObject['dynamicProperties_'+fieldName].length) || (typeof interactionObject['dynamicProperties_'+fieldName] === "string" && interactionObject['dynamicProperties_'+fieldName])) {
          let file = await axios.get(`${CONSTANTS.API_URL}/api/v1/file/getFileFromS3?courierID=${interactionObject.uuid}&fileID=${interactionObject['dynamicProperties_'+fieldName]}&s3KeyFor=case`)
          if (file.data[0]) {
            //return file.data[0].signedUrl;
            await setEndorsementObj({ ...endorsementObj, [`${fieldName}SignedURL`]: file.data[0].signedUrl });
          } else {
            await setEndorsementObj({ ...endorsementObj, [`${fieldName}SignedURL`]: '' });
          }
        }
      }));
    } catch (HTTPException) {
     if (HTTPException && HTTPException.config && HTTPException.config.url) {
       let payload = {
         apiURL:HTTPException.config.url,
         errorObject:HTTPException,
         statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
         errorMessage:HTTPException.message,
         functionName:"getImageSignedUrlForEndorsement",
         fileName:"rightPanel",
         pageName: "rightPanel",
         userID:userInfo.uuid,
         userName:userInfo.displayName
       };
       await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
     }
     return HTTPException;
   }
  }

  async function getImageSignedUrl(name,interactionObj, currentRow) {
    try {
      if ((typeof currentRow['dynamicProperties_'+name] === "object" && currentRow['dynamicProperties_'+name].length) || (typeof currentRow['dynamicProperties_'+name] === "string" && currentRow['dynamicProperties_'+name])) {
        let file = await axios.get(`${CONSTANTS.API_URL}/api/v1/file/getFileFromS3?courierID=${interactionObj.uuid}&fileID=${currentRow['dynamicProperties_'+name]}&s3KeyFor=case`)
        if (file.data[0]) {
          return file.data[0].signedUrl;
        } else {
          return null;
        }
      }
    } catch (HTTPException) {
       if (HTTPException && HTTPException.config && HTTPException.config.url) {
         let payload = {
           apiURL:HTTPException.config.url,
           errorObject:HTTPException,
           statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
           errorMessage:HTTPException.message,
           functionName:"getImageSignedUrl",
           fileName:"rightPanel",
           pageName: "rightPanel",
           userID:userInfo.uuid,
           userName:userInfo.displayName
         };
         await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
       }
       return HTTPException;
   }
  }

  async function setFormEditable() {
    setIsFormEditable(!isFormEditable);
  }

  async function setBorderOnFocus(e, name) {
    let borderNode = document.getElementById(`${name}-input-box`);
    if (borderNode) {
      borderNode.style.border = "1px solid #003468";
    }
  }

  const toggleComponentFormPopover = (e) => {
    setIsCreateEditFormPopover(!isCreateEditFormPopover);
    setIsEditCaseFormPopover(false);
    setIsOpenCaseFormPopover(false);
  };

  const resetFormValues = () => {
    setFormOperation();
    setFormEntityName();
    setFormEntityObject();
    setFormDynamicProps();
  };

  const getEntityDynamicProps = async (currentEntityName, currentEntityID, entityIDOf, parentEntityName) => {
    try {
      let url = `${CONSTANTS.API_URL}/api/v1/dynamicProperty?formName=${currentEntityName}&userDealershipID=${userInfo.userDealershipID}&userDealershipName=${userInfo.userDealershipName}&userBranchID=${userInfo.userBranchID}&activationStatus=active`;
      if (currentEntityID) {
        url += `&entityID=${currentEntityID}`;
      }
      if (entityIDOf) {
        url += `&entityIDOf=${entityIDOf}`;
      }
      if (parentEntityName) {
        url += `&parentEntityName=${parentEntityName}`;
      }
      let dynamicPropsForEntity = await axios.get(`${url}&sortBy=serialNumber`);
      if (dynamicPropsForEntity.data) {
        setFormDynamicProps(dynamicPropsForEntity.data);
      }
    } catch (HTTPException) {
       if (HTTPException && HTTPException.config && HTTPException.config.url) {
         let payload = {
           apiURL:HTTPException.config.url,
           errorObject:HTTPException,
           statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
           errorMessage:HTTPException.message,
           functionName:"getEntityDynamicProps",
           fileName:"rightPanel",
           pageName: "rightPanel",
           userID:userInfo.uuid,
           userName:userInfo.displayName
         };
         await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
       }
       return HTTPException;
     }
  };

  const updateSelectedInteraction = async (uuid, payload) => {
    try {
      await axios.put(`${CONSTANTS.API_URL}/api/v1/updateInteraction/${uuid}`, payload);
    } catch (HTTPException) {
      let payload = {
       apiURL:HTTPException.config.url,
       errorObject:HTTPException,
       statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
       errorMessage:HTTPException.message,
       functionName:"updateSelectedInteraction",
       fileName:"rightPanel",
       pageName: "rightPanel",
       userID:userInfo.uuid,
       userName:userInfo.displayName
     };
     await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
     return HTTPException;
   }
  };

  const setComponentFormValueInput = async (e, fieldName) => {
    e.preventDefault();
    let obj = JSON.parse(JSON.stringify(formEntityObject));

    if (!e.target.value) {
      obj[fieldName] = e.target.value;
      let successNode = document.getElementById("success-"+fieldName);
      if (successNode) {
        successNode.style.display = "none";
      }
    } else {
      obj[fieldName] = e.target.value;
    }
    /*if (document.getElementById(fieldName)) {
      if ((fieldName === "addressLine1" || fieldName === "companyAddress") && e.target.value.length < 4) {
        document.getElementById(fieldName).style.borderBottom = "2px solid red";
        let spanNode = document.getElementById("error-"+fieldName);
        if (spanNode) {
          spanNode.style.display ="block";
          spanNode.innerHTML = "Address should be greater than 4 characters"
        }
      } else if ((fieldName === "addressLine1" || fieldName === "companyAddress") && e.target.value.length > 50) {
        document.getElementById(fieldName).style.borderBottom = "2px solid red";
        let spanNode = document.getElementById("error-"+fieldName);
        if (spanNode) {
          spanNode.style.display ="block";
          spanNode.innerHTML = "Address should be less than 50 characters"
        }
      } else {
        document.getElementById(fieldName).style.borderBottom = "1px solid #9e9e9e";
        let node = document.getElementById("error-"+fieldName);
        if (node) {
          node.style.color = "#9e9e9e";
        }
        if (e.target.value) {
          let successNode = document.getElementById("success-"+fieldName);
          if (successNode) {
            successNode.style.display = "inline";
            let requiredNode = document.getElementById("required-"+fieldName);
            if (requiredNode) {
              requiredNode.style.display = "none";
            }
          }
        }

      }
    }*/
    // setIsFormUpdated(!isFormUpdated);
    setFormEntityObject(obj);
    setIsEntityFormObjectUpdated(!isEntityFormObjectUpdated);
  };

  const saveComponentFormInput = async (e, fieldName, formType, index) => {
    e.preventDefault();

    let obj = JSON.parse(JSON.stringify(formEntityObject));
    if (formType && formType === "select") {
      /*
      Changed By Rutuja
      Changed on 24/2/23
      Set hypothecation to yes / no dependening on type of hypothecation
      */
      if (fieldName === "dynamicProperties_typeOfHypothecation" && e.target.value === "Add") {
          obj['dynamicProperties_hypothecation'] = "yes";
      } else if (fieldName === "dynamicProperties_typeOfHypothecation" && e.target.value === "Remove") {
        obj['dynamicProperties_hypothecation'] = "no";
      }
      obj[fieldName] = e.target.value;
      /*if (actions.length) {
        await Promise.all(actions[index]['dynamicProps'].map(async (prop) => {
          if (prop.dependentEnum && prop.dependentEnum.length && prop.dependentEnum[0]['nameOfProp'] === fieldName) {
            await Promise.all(prop.dependentEnum.map((dependentEnumObj) => {
              if (dependentEnumObj['valueOfProp'] === e.target.value) {
                dependentEnumObj['isSelected'] = true
              } else {
                dependentEnumObj['isSelected'] = false
              }
            }));
          }
        }));
      }
      else */
      /*if (formDynamicProps.length) {

        let formDynamicPropsIndex = formDynamicProps.findIndex(o => o.name === fieldName);
        let prop = formDynamicProps[formDynamicPropsIndex]

        if (prop.dependentEnum && prop.dependentEnum.length && prop.dependentEnum[0]['nameOfProp'] === fieldName) {
          await Promise.all(prop.dependentEnum.map((dependentEnumObj) => {
            if (dependentEnumObj['valueOfProp'] === e.target.value) {
              dependentEnumObj['isSelected'] = true
            } else {
              dependentEnumObj['isSelected'] = false
            }
          }));
        }
      }*/
    } else {
      obj[fieldName] = e.target.value;
    }
    setFormEntityObject(obj);
    setCurrentUpdatedPropName(fieldName);
    setIsEntityFormObjectUpdated(!isEntityFormObjectUpdated);
    setIsComponentFormUpdated(true);
  };

  const saveComponentFormInputRadioDefault = (e, fieldName, typeOfDBField) => {
    let obj = JSON.parse(JSON.stringify(formEntityObject));
    if (typeOfDBField === "Boolean") {
      obj[fieldName] = e.target.value.toLowerCase() === 'yes' ? true : false;
    } else {
      obj[fieldName] = e.target.value;
    }
    setFormEntityObject(obj);
    setIsEntityFormObjectUpdated(!isEntityFormObjectUpdated);
  };

  const saveComponentFormInputRadio = (e, fieldName, value, index) => {
    let obj = JSON.parse(JSON.stringify(formEntityObject));
    obj[fieldName] = e.target.value;
    setFormEntityObject(obj);
    setIsEntityFormObjectUpdated(!isEntityFormObjectUpdated);
  };

  const saveComponentFormInputCheckbox = (e, fieldName, value) => {
    let obj = JSON.parse(JSON.stringify(formEntityObject));

    if (e.target.checked && (!obj[fieldName] || !obj[fieldName].split(",").includes(value))) {
      obj[fieldName] = obj[fieldName] + ',' + value;
    } else if (!e.target.checked && obj[fieldName].split(",").includes(value)) {
      let idx = obj[fieldName].split(",").findIndex(d => d === value);
      let tempArr = obj[fieldName].split(",");
      let deleted = tempArr.splice(idx,1);
      obj[fieldName] = tempArr.join(",");
    }
    setFormEntityObject(obj);
    setIsEntityFormObjectUpdated(!isEntityFormObjectUpdated);
  };

  const saveAreaFromComponentForm = (e, name) => {
    let obj = JSON.parse(JSON.stringify(formEntityObject));
    obj[name] = e.target.value;
    setFormEntityObject(obj);
    setIsEntityFormObjectUpdated(!isEntityFormObjectUpdated);
  };

  const setFormStageValueInput = (e, key, index) => {
    let obj = JSON.parse(JSON.stringify(formDynamicProps));
    obj[index][key] = e.target.value;
    setFormDynamicProps(obj);
  };

  const setFormArrayValueInput = (e, name, value, key, index) => {
    e.preventDefault();
    let formObj = JSON.parse(JSON.stringify(formEntityObject));
    if (value.trim()) {
      if (formObj[name]) {
        formObj[name].push(value.trim())
      } else {
        formObj[name] = [];
        formObj[name].push(value.trim())
      }
    };
    setFormEntityObject(formObj);

    let dynamicPropObj = JSON.parse(JSON.stringify(formDynamicProps));
    dynamicPropObj[index][key] = "";
    setFormDynamicProps(dynamicPropObj);
  };

  const removeFormArrayValueInput = (e, key, arrRemoveIndex) => {
    let obj = JSON.parse(JSON.stringify(formEntityObject));
    obj[key].splice(arrRemoveIndex, 1);
    setFormEntityObject(obj);
  };

  const submitComponentForm = async (e) => {
    e.preventDefault();
    if (isCreateEditFormPopover) {
      try {

        let payload = {};
        let uuid = formEntityObject.uuid;

        // setting payload
        formDynamicProps.length && formDynamicProps.map((dynamicProp) => {
          payload[`dynamicProperties_${dynamicProp.name}`] = formEntityObject[dynamicProp.name];
        });
        if (endorsementObj.typeOfCase === "Payment Details") {
          payload['isPaymentDetailsUpdation'] = true;
        }
        await createEndorsement(uuid, payload);

        toggleComponentFormPopover();
        resetFormValues();
      } catch (error) {
        console.error(error);
      }
    }
  };
  function triggerNotifications(e) {
    // e.preventDefault();
    setNewNotification(!newNotification);
  }
  /**********************************************************************/
  async function routeToCaseList() {
    route(`/workspaceCaseDetails`);
  }
  /**********************************************************************/
  let [isErrorMessage, setIsErrorMessage] = useState('');
  let [csvParsedListErros, setCsvParsedListErrors] = useState([]);
  let [isCSVDownloadButtonDisable, setIsCSVDownloadButtonDisable] = useState(false);
  let [uploadedCSVView, setUploadedCSVView] = useState(false);
  let [bulkUploadList, setBulkUploadList] = useState([]);
  let [errorFreeRecordCsv, setErrorFreeCsv] = useState([]);
  let [areAllRemoved, setAreAllRemoved] = useState(false);
  let [isSuccessfull, setIsSuccessfull] = useState(false);
  let [bulkUploadResult, setBulkUploadResult] = useState({});
  let [objParsedList, setObjParsedList] = useState([]);
  let [headers, setHeaders] = useState([]);
  let [uploadFilter, setUploadFilter] = useState('');
  let [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  let [selectedBulkUploadAction, setSelectedBulkUploadAction] = useState('');

  function toggleUploadFileModal(e, type) {
    e.preventDefault();
    setIsFileUploadModalOpen(!isFileUploadModalOpen);
    setIsSuccessfull(false);
    setBulkUploadList([]);
    if (type) {
      setSelectedBulkUploadAction(type);
    } else {
      setSelectedBulkUploadAction('');
    }
  }
  async function uploadBrowsedCSV(e) {
    e.preventDefault();
    let dt = e.target;
    let files = e.target.files[0];
    if (!files) return;
    let fileName = files.name;
    let extension = fileName.split('.').pop();
    if (e.target.files.length < 1) {
      setIsErrorMessage('No file is Selected');
      return;
    }
    if (e.target.files[0].size <= 8) {
      setIsErrorMessage('File is empty');
      return;
    }
    if (fileTypeExtension.includes(extension.toLowerCase())) {
      makeCsvJsonAndCheckUniqueness(dt);
      storedFileCsv = e.target.files[0];
    }
  }
  function uploadCSVByDragNDrop(ev) {
    ev.preventDefault();
    let dt = ev.dataTransfer;
    let files = dt.files[0];
    if (!files) return;
    let fileName = files.name;
    let extension = fileName.split('.').pop();
    if (fileTypeExtension.includes(extension)) {
      makeCsvJsonAndCheckUniqueness(dt);
      storedFileCsv = dt.files[0];
    }
  }
  async function makeCsvJsonAndCheckUniqueness(csvFile) {
    try {
      let file = csvFile.files[0];
      let reader = new FileReader();
      reader.onloadend = async (evt) => {
        if (evt.target.readyState === FileReader.DONE) {
          let result = await makeAndReturnJSONForCsv(evt.target.result);
          if (result.repeatedItems && result.repeatedItems.length) {
            let errorInCsv = result.bulkUpload.filter((errors) => {
              return errors.error;
            });
            setCsvParsedListErrors(result.bulkUpload);
            setBulkUploadList(result.bulkUpload);
            if (errorInCsv.length > 0) {
              errorCountFile = errorInCsv.length;
              toggleUploadCSVView();
            } else {

            }
            return;
          } else if ((result.repeatedItems && result.repeatedItems.length === 0) || !result.repeatedItems) {
            let errorInCsv = result.bulkUpload.filter((errors) => {
              return errors.error;
            });
            errorCountFile = errorInCsv.length;
            failRecordCount = errorInCsv.length;
            failRecordDetails = errorInCsv;
            let errorFreeCsv = result.bulkUpload.filter((errors) => {
              return !errors.error;
            });
            setErrorFreeCsv(errorFreeCsv);
            setCsvParsedListErrors(result.bulkUpload);
            if (errorInCsv.length > 0) {
              toggleUploadCSVView();
              setAreAllRemoved(false);
            } else {
              const fileObj = {
                name: storedFileCsv.name,
                size: storedFileCsv.size,
                type: storedFileCsv.type,
                value: storedFileCsv.name,
                fileType: uploadFilter
              };
              if (selectedBulkUploadAction === '64VB') {
                let response = await axios.put(`${CONSTANTS.API_URL}/api/v1/bulkUpload64VB`, result.arrayJson);
                await axios.put(`${CONSTANTS.API_URL}/api/v1/user/pageVisitUserInformation`, { action: '64VB Bulk Upload', email: userInfo.email });
                setBulkUploadResult(response.data);
                setIsSuccessfull(true);
              } else {
                let response = await axios.put(`${CONSTANTS.API_URL}/api/v1/bulkUploadACH`, result.arrayJson);
                await axios.put(`${CONSTANTS.API_URL}/api/v1/user/pageVisitUserInformation`, { action: 'ACH Bulk Upload', email: userInfo.email });
                setBulkUploadResult(response.data);
                setIsSuccessfull(true);
              }
            }
            return;
          }
          let arrayJson = result.arrayJson;
          if (!arrayJson) {
            return;
          }
          setObjParsedList(result.arrayJson);
          setBulkUploadList(bulkUploadList);
        }
      };
      await reader.readAsBinaryString(file);
    } catch (HTTPException) {
      let payload = {
       apiURL:HTTPException.config.url,
       errorObject:HTTPException,
       statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
       errorMessage:HTTPException.message,
       functionName:"makeCsvJsonAndCheckUniqueness",
       fileName:"rightPanel",
       pageName: "rightPanel",
       userID:userInfo.uuid,
       userName:userInfo.displayName
     };
     await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
     return HTTPException;
   }
  }
  function makeAndReturnJSONForCsv(result) {
    result = result.trim();
    let splitOnNewline = result.split(/\r?\n/);
    let headersArray = splitOnNewline[0].split(',');
    headersArray = headersArray.map(value => value.trim().replace(/[^a-zA-Z0-9 ]/g, ""));
    let response = {};
    let catalogueJson = [];
    splitOnNewline.shift();
    splitOnNewline.forEach(row => {
      let valueArr = row.split(',');
      valueArr = valueArr.map(value => value.trim().replace(/"/g, ""));
      let itemObject = {};
      headersArray.map((key, index) => itemObject[key] = valueArr[index]);

      catalogueJson.push(itemObject);
    });
    setHeaders(headersArray);
    response['arrayJson'] = catalogueJson;
    let catalogueItems = catalogueJson.map((item) => {
      return item;
    });

    let repeatedItems = [];
    let lookup;
    let bulkUpload = [];
    catalogueItems.map(item => {
      //------------
      lookup = catalogueItems.reduce((a, e) => {
        a[e.name] = ++a[e.name] || 0;
        return a;
      }, {});
      let object = Object.assign(item);
      // object['uuid'] = item.uuid ? item.uuid : '';
      if (lookup[item.uuid]) {
        // object['error'] = 'duplicate entry';
      }
      // let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]{2,}\.)+[a-zA-Z]{2,}))$/;
      // if (!user.email.match(regex)) {
      //   object['error'] = 'invalid email';
      // }
      object['displayName'] = item.model ? item.model : '';
      bulkUpload.push(object);
    });

    // debugger;
    setBulkUploadList(bulkUpload); // got all records in 'bulkUpload' but unable to set
    if (!lookup) {
      setIsErrorMessage('File is empty');
    }
    let all = Object.keys(lookup);
    all.map((key) => {
      if (lookup[key] > 0) {
        // repeatedItems.push(key);
      }
    });
    // response['repeatedItems'] = repeatedItems;
    response['bulkUpload'] = bulkUpload;
    return response;
  }
  function toggleUploadCSVView() {
    setUploadedCSVView(true);
  }
  async function initiateCSVReportDownload() {
    try {
      setIsCSVDownloadButtonDisable(true);
      let res = await axios.get(`${CONSTANTS.API_URL}/api/v1/policyReport?dealershipID=${userInfo.userDealershipID}`);
      const results = res.data.csvData.filter(element => {
        return element !== null;
      });
      if (results && results.length) {
        let response = await downloadCSV(results, res.data.fileName);
        toastr.success("Policy Report Downloaded");
      } else {
        toastr.error("No data");
      }
      setIsCSVDownloadButtonDisable(false);
    } catch (HTTPException) {
      let payload = {
       apiURL:HTTPException.config.url,
       errorObject:HTTPException,
       statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
       errorMessage:HTTPException.message,
       functionName:"initiateCSVReportDownload",
       fileName:"rightPanel",
       pageName: "rightPanel",
       userID:userInfo.uuid,
       userName:userInfo.displayName
     };
     await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
     return HTTPException;
   }
  }
  /**********************************************************************/
  async function generateQuotation(e) {
    e.preventDefault();
    try {
      let Response = await axios.post(`${CONSTANTS.API_URL}/api/v1/generateRenewalQuotations`, {});
      if (Response.data.messageResponses && Response.data.messageResponses.length) {
        await Promise.all(Response.data.messageResponses.map(async (messageObj) => {
          // if (messageObj.sendTo) {
          //   let messageNotification = await axios.post(`${CONSTANTS.API_URL}/api/v1/notification`, messageObj);
          //   if (messageNotification.status === 200) {
          //     triggerNotifications(true)
          //   }
          //   toastr.info(messageObj.message);
          // } else {
            toastr.info(messageObj.message);
          // }
        }))
      } else {
        toastr.info('No Pending Tasks');
      }
    } catch (HTTPException) {
      if (HTTPException && HTTPException.config && HTTPException.config.url) {
        let payload = {
          apiURL:HTTPException.config.url,
          errorObject:HTTPException,
          statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
          errorMessage:HTTPException.message,
          functionName:"generateQuotation",
          fileName:"rightPanel",
          pageName: "rightPanel",
          userID:userInfo.uuid,
          userName:userInfo.displayName
        };
      await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      }
      return HTTPException;
    }
  }

  async function toggleOnGoingCallWindow(e) {
    if(!isOngoingCallWindowOpen) {
      setOngoingCallWindowOpen(!isOngoingCallWindowOpen);
      // setOngoingCallWindowClose(!isOngoingCallWindowClose);
    } else if(isOngoingCallWindowClose) {
      setOngoingCallWindowClose(!isOngoingCallWindowClose);
    }
  }
  async function closeOnGoingCallWindow(e) {
    if(isOngoingCallWindowOpen) {
      setOngoingCallWindowClose(!isOngoingCallWindowClose);
    }
  }
  async function toggleCallModal(e) {
    if (isCallModalOpen) {
      setCallResponse('')
      setCallResponseText('')
      setCallResponseObj({});
      setCallResponseData('');
      setIsCallOriginatedByUser(false);
      setMobileNumberEntered('');
    }
    setCallModalOpen(!isCallModalOpen);
  }

  /*
    Bug: T277 | BG_FN - Calculator Not Resetting Displayed Value on Reopen
    Modification: Commented calculator
    By: Devang
    Date: 24/05/2024
  */
  /*async function toggleCalculatorModal(e) {
    setCalculatorModalOpen(!isCalculatorModalOpen);
  }*/

  async function toggleOnGoingCallWindow(e) {
    if(!isOngoingCallWindowOpen) {
      setOngoingCallWindowOpen(!isOngoingCallWindowOpen);
      // setOngoingCallWindowClose(!isOngoingCallWindowClose);
    } else if(isOngoingCallWindowClose) {
      setOngoingCallWindowClose(!isOngoingCallWindowClose);
    }
  }

  async function closeOnGoingCallWindow(e) {
    if(isOngoingCallWindowOpen) {
      setOngoingCallWindowClose(!isOngoingCallWindowClose);
    }
  }
  async function originateCall(e) {
    await axios.post(`${CONSTANTS.API_URL}/api/v1/originateCall`, {
      userID: userInfo.uuid,
      mobileNumber: selectedCaseObj['dynamicProperties_mobile']
    });
    setIsCallOriginated(!isCallOriginated);
  }

  async function originateCallNew(e) {
    let res;
    // if (userDesignation === "SALES CONSULTANT") {
    //   res = await axios.post(`${CONSTANTS.API_URL}/api/v1/originateCall/mobile`, {
    //     userID: userInfo.uuid,
    //     mobileNumber: mobileNumberEntered,
    //     followUpFor,
    //     reasonForCalling
    //   });
    // } else {
    res = await axios.post(`${CONSTANTS.API_URL}/api/v1/originateCall`, {
      userID: userInfo.uuid,
      mobileNumber: mobileNumberEntered,
      followUpFor,
      reasonForCalling
    });
    // }
    // let res = await axios.post(`${CONSTANTS.API_URL}/api/v1/originateCall/mobile`, {
    //   userID: userInfo.uuid,
    //   mobileNumber: mobileNumberEntered
    // });
    if (res.data) {
      setCallResponse(res.data.response);
      setCallResponseText(res.data.responseText);
      setCallResponseObj(res.data);
      setCallResponseData(res.data.responseData);
    }
    setIsCallOriginatedByUser(!isCallOriginatedByUser);

  }

  async function toggleRecentlyViewedModal(e) {
    setRecentlyViewedModalVisibility(!isRecentlyViewedModalVisible);
  }
  async function toggleFollowedCasesModal(e) {
    setFollowedCasesModalVisibility(!isFollowedCasesModalVisible);
  }

  const logout = async (e) => {
    try {
      e.preventDefault();
      let res = await axios.post(`${CONSTANTS.API_URL}/api/v1/logout`);
      let email = getItem('userinfo').email;
      await axios.put(`${CONSTANTS.API_URL}/api/v1/user/pageVisitUserInformation`, { action: 'logout', typeOfVisit: 'success', email });
      if (res) {
        if ((userInfo.userDealershipID && userInfo.userDealershipID === "f8ff4333-1d4d-4ebb-8669-f6ce67620b85")){
          await removeAll();
          await route('/kia');
        } else {
          await removeAll();
          await route(`/`);
        }
      }
    } catch (HTTPException) {
      if (HTTPException && HTTPException.config && HTTPException.config.url) {
        let payload = {
          apiURL: HTTPException.config.url,
          errorObject:HTTPException,
          statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
          errorMessage:HTTPException.message,
          functionName:"logout",
          fileName:"rightPanel",
          pageName: "rightPanel",
          userID:userInfo.uuid,
          userName:userInfo.displayName
        };
        await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      }
      return HTTPException;
    }
  };

  /*
    modified : Ashutosh G
    modified : 05/10/2023
    modification : taken all function from thirdParty route file and modified it as per page requirements
  */
  /*
    modified : Ashutosh G
    modified : 06/10/2023
    modification : Replaced axios with callAPI transceiver
  */
  async function togglePOModal(e) {
    setIsCatalogueModalOpen(prevValue => !prevValue);
    // if (isCreateCaseModalOpen === false) {
    //   setCatalogueItems([]);
    //   setVendorDetails({});
    //   setPoType("");
    //   setPoText("");
    // }
  }

  const getItemPropertyValue = (uuid, propertyName, array) => {
    const item = array.find(item => item.catalogueItemID === uuid);
    if (item) {
      return item[propertyName];
    }
    return null;
  };

  const calculateTotal = (rate, gst) => {
    const rateValue = parseFloat(rate);
    const gstValue = parseFloat(gst);

    if (isNaN(rateValue) || isNaN(gstValue)) {
      return "";
    }

    const totalAmount = rateValue + (rateValue * gstValue) / 100;
    return totalAmount.toFixed(2);
  };

  const calculateTotalRate = (rate, gst, qty) => {
    const rateValue = parseFloat(rate);
    const gstValue = parseFloat(gst);
    const qtyValue = parseFloat(qty);

    if (isNaN(rateValue) || isNaN(gstValue) || isNaN(qtyValue)) {
      return "";
    }

    const totalAmount = (rateValue + (rateValue * gstValue) / 100) * qtyValue;
    return totalAmount.toFixed(2);
  };


  /*
    modified : Ashutosh G
    modified : 18/10/2023
    modification : toggleFormPopoverRequest, addRequest, getFormNameRequest, submitForm, saveFormInputForCoreEntity, setFormValueInputForCoreEntity, saveFormInputRadioDefaultForCoreEntity, saveFormInputCheckboxForCoreEntity for coreEntity Modal
  */
  const toggleFormPopoverRequest = (e) => { // form functions
    setIsCoreEntityFormPopover(prevValue => !prevValue);
    setErrorField([]);
  };

  const addRequest = async () => { // adding new request
    try {
      await getFormNameRequest();
      let object = {};
      let apiBody = { coreEntity: "adminRequest", coreEntityProcessName: "Admin Flow" };
      let response = await callAPI(`put`, `/v1/instantiateCoreEntityProcess`, apiBody);
      setFormEntityObject(response.data);
      setFormOperation("Create Request");
      setIsCoreEntityFormPopover(true);
    } catch(HTTPException) {
      let payload = {
        apiURL:(HTTPException && HTTPException.config && HTTPException.config.url) ? HTTPException.config.url : "",
        errorObject:HTTPException,
        statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
        errorMessage:HTTPException.message,
        functionName:"addRequest",
        fileName:"rightPanel",
        pageName: "rightPanel",
        userID:userInfo.uuid,
        userName:userInfo.displayName
      };
      await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      return HTTPException;
    }
  };

  /*
    modified : Ashutosh G
    modified : 20/10/2023
    modification : getting dynamicProps for Raise Request master task
  */
  const getFormNameRequest = async () => { //getting dynamic properties
    try {
      let dynamicPropsPayload = {
        coreEntity: "adminRequest",
        masterTaskID: "be7cd8b4-6a6b-4b13-8042-25d9b7fe65d0",
      }
      let response = await axios.put(`${CONSTANTS.API_URL}/api/v1/dynamicPropsOrActionDynamicPropsByTask`, dynamicPropsPayload);
      if (response.data.Properties) {
        setRequestDynamicProps(response.data.Properties);
      }
    } catch (HTTPException) {
      let payload = {
        apiURL:(HTTPException && HTTPException.config && HTTPException.config.url) ? HTTPException.config.url : "",
        errorObject:HTTPException,
        statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
        errorMessage:HTTPException.message,
        functionName:"getFormNameRequest",
        fileName:"rightPanel",
        pageName: "rightPanel",
        userID:userInfo.uuid,
        userName:userInfo.displayName
      };
      await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      return HTTPException;
    }
  };

  const submitForm = async (e) => { //On submit event base on edit or create
    e.preventDefault();
    if (isCoreEntityFormPopover) {
      /*if (formOperation === "Edit") {*/
        try {

          let payload = {
            coreEntityID: formEntityObject.allCreatedTasks[0].coreEntityID,
            CoreEntity: formEntityObject.allCreatedTasks[0].CoreEntity
          };
          let uuid = formEntityObject.uuid;

          // setting payload
          requestDynamicProps.length && requestDynamicProps.map((dynamicProp) => {
            payload[dynamicProp.name] = formEntityObject[dynamicProp.name];
          });

          // await updateRequest(uuid, payload);
          await createRequest(uuid, payload);
          toggleFormPopoverRequest();
          resetFormValues();

        } catch (error) {
          console.error(error);
        }
      /*} else {

        try {
          let payload = {};
          let errorFieldsObj = [];

          //setting error field
          await Promise.all (requestDynamicProps.length && requestDynamicProps.map ((dynamicProp) => {
            payload[dynamicProp.name] = formEntityObject[dynamicProp.name];
            if (dynamicProp.isRequired && dynamicProp.name &&  (payload[dynamicProp.name] === undefined || payload[dynamicProp.name] === "")) {
              if (!dynamicProp.dependentProp && (!dynamicProp.dependentPropValue || !dynamicProp.dependentPropValue.length)) {
                errorFieldsObj.push(dynamicProp);
                setErrorField(errorFieldsObj);
              } else if(dynamicProp.dependentPropValue === payload[dynamicProp.dependentProp]) {
                if (dynamicProp.isRequired && dynamicProp.name &&  (payload[dynamicProp.name] === undefined || payload[dynamicProp.name] === "")) {
                    errorFieldsObj.push(dynamicProp);
                    setErrorField(errorFieldsObj);
                }
              }
            }
          }));

          if (!errorFieldsObj.length) {
            await createRequest(payload);
            toggleFormPopover();
            resetFormValues();
          }else {
            toastr.warning("Please fill out all required fields.");
          }

        } catch (error) {
          console.error(error);
        }
      }*/
    }
  };

  const saveFormInputForCoreEntity = async (e, fieldName, formType, index) => {
    e.preventDefault();

    let obj = JSON.parse(JSON.stringify(formEntityObject));
    if (formType && formType === "select") {
      obj[fieldName] = e.target.value;
    } else {
      obj[fieldName] = e.target.value;
    }
    setFormEntityObject(obj);
    setCurrentUpdatedPropName(fieldName);
    setIsFormUpdated(true);
  };

  const setFormValueInputForCoreEntity = async (e, fieldName) => { // input values
    e.preventDefault();
    let obj = JSON.parse(JSON.stringify(formEntityObject));

    if (!e.target.value) {
      obj[fieldName] = e.target.value;
      let successNode = document.getElementById("success-"+fieldName);
      if (successNode) {
        successNode.style.display = "none";
      }
    } else {
      obj[fieldName] = e.target.value;
    }

    setFormEntityObject(obj);
  };

  const saveFormInputRadioDefaultForCoreEntity = (e, fieldName, typeOfDBField) => {
    let obj = JSON.parse(JSON.stringify(formEntityObject));
    if (typeOfDBField === "Boolean") {
      obj[fieldName] = e.target.value.toLowerCase() === 'yes' ? true : false;
    } else {
      obj[fieldName] = e.target.value;
    }
    setFormEntityObject(obj);
  };

  const saveFormInputRadioForCorEntity = (e, fieldName, value, index) => {
    let obj = JSON.parse(JSON.stringify(formEntityObject));
    obj[fieldName] = e.target.value;
    setFormEntityObject(obj);
  };

  const saveFormInputCheckboxForCoreEntity = (e, fieldName, value) => {
    let obj = JSON.parse(JSON.stringify(formEntityObject));
    if (e.target.checked && (!obj[fieldName] || !obj[fieldName].split(",").includes(value))) {
      obj[fieldName] = obj[fieldName] + ',' + value;
    } else if (!e.target.checked && obj[fieldName].split(",").includes(value)) {
      let idx = obj[fieldName].split(",").findIndex(d => d === value);
      let tempArr = obj[fieldName].split(",");
      let deleted = tempArr.splice(idx,1);
      obj[fieldName] = tempArr.join(",");
    }
    setFormEntityObject(obj);
  };

  /*
    By: Yashvi
    On: 21st Jan 2023
    Loaders for searching and creating the legacy active case.
  */

  let [schedulerSearchLoader,  setSchedulerSearchLoader] = useState(false);
  let [schedulerCreationLoader,  setSchedulerCreationLoader] = useState(false);
  let [schedulerCaseCreatedLoader,  setSchedulerCaseCreatedLoader] = useState(false);
  let [isSchedulerLegacyFormPopover, setIsSchedulerLegacyFormPopover] = useState(false);
  let [searchQuery, setSearchQuery] = useState('');
  let [schedulerLegacyCases, setSchedulerLegacyCases] = useState([]);

  const routeToResultRowPage = (row, entityName) => {
    window.open(`/processView/${row.uuid}`, '_blank');
  };

  function toggleEditSchedulerLegacyFormPopover(e, type) {
    e.preventDefault();
    setIsSchedulerLegacyFormPopover(!isSchedulerLegacyFormPopover);
    setSchedulerSearchLoader(false);
    setSchedulerCreationLoader(false);
    setSchedulerCaseCreatedLoader(false);
    setSearchQuery('');
    setSchedulerLegacyCases([]);
  }
  async function searchCaseForScheduler(type, interactionID) {
    await setSchedulerSearchLoader(true);
    try {
      let Response = await axios.get(`${CONSTANTS.API_URL}/api/v1/findCase?q=${searchQuery}`);
      await getLegacyCaseSearchResults(Response.data);
      await setSchedulerSearchLoader(false);
    } catch (HTTPException) {
      if (HTTPException && HTTPException.config && HTTPException.config.url) {
        let payload = {
          apiURL:HTTPException.config.url,
          errorObject:HTTPException,
          statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
          errorMessage:HTTPException.message,
          functionName:"searchCaseForScheduler",
          fileName:"rightPanel",
          pageName: "rightPanel",
          userID:userInfo.uuid,
          userName:userInfo.displayName
        };
        await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
        await setSchedulerSearchLoader(false);
      }
      return HTTPException;
    }
  }
  const getLegacyCaseSearchResults = async (results) => {
    const rowList = results.map((row) => {
      let uuid = row.uuid;
      let caseNo = row.uniqueID;
      let customerName = row.dynamicProperties_customerName;
      let currentStage = row.currentStage;
      let owner = row.assignedToUserName ? row.assignedToUserName : row.dynamicProperties_salesConsultant;
      let openSinceDate = row.createdAt && new Date(row.createdAt);
      let openSince = row.createdAt && `${getDifferenceInDays(row.createdAt)} Days`;
      let status = row.dynamicProperties_caseStatus;
      let mobile = row.dynamicProperties_mobile ? row.dynamicProperties_mobile : '';
      let vin = row.dynamicProperties_vin ? row.dynamicProperties_vin : '';
      let registrationNo = row.dynamicProperties_registrationNo ? row.dynamicProperties_registrationNo : '';

      let obj = { uuid, caseNo, customerName, currentStage, owner, openSinceDate, openSince, status, mobile, vin, registrationNo };
      return obj;
    });

    const listObj = {
      columns: [
        {label: 'Customer Name', field: 'customerName', classes: 'w-20p'},
        {label: 'Mobile', field: 'mobile', classes: 'w-16p'},
        {label: 'Owner', field: 'owner', classes: 'w-16p'},
        {label: 'Chassis no.', field: 'vin', classes: 'w-16p'},
        {label: 'Vehicle no.', field: 'registrationNo', classes: 'w-16p'},
        {label: 'Current Stage', field: 'currentStage', classes: 'w-16p'}
      ], rows: rowList
    };
    await setSchedulerLegacyCases(listObj);

  };
  async function runSchedulerForLegacyCase(e) {
    e.preventDefault();
    try {
      await setSchedulerCreationLoader(true);
      let Response = await axios.put(`${CONSTANTS.API_URL}/api/v1/createLegacyActiveCase/${schedulerLegacyCases.rows[0].uuid}`);
      if (Response.data && Response.data.length) {
        await setSchedulerCreationLoader(false);
        await setSchedulerCaseCreatedLoader(true);
        getLegacyCaseSearchResults(Response.data);
        toastr.info('Case Created!');
      } else {
        toastr.error('An error occured. Try again later.');
      }
    } catch (HTTPException) {
      await setSchedulerCreationLoader(false);
      if (HTTPException && HTTPException.config && HTTPException.config.url) {
        let payload = {
          apiURL:HTTPException.config.url,
          errorObject:HTTPException,
          statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
          errorMessage:HTTPException.message,
          functionName:"generateQuotation",
          fileName:"rightPanel",
          pageName: "rightPanel",
          userID:userInfo.uuid,
          userName:userInfo.displayName
        };
      await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      }
      return HTTPException;
    }
  }

  {/*
   Modification:- Added functionBundle for GeneratePurchaseOrder
   By:- Rajkumar Pradhan
   On:- 22-03-2024
  */}
  const functionBundle = {
    togglePOModal : togglePOModal,
  };

  return (
    <div style='border-left: 1px solid #e1e1e1;' class={`${props.isMobileView ? '' : 'rightPanel-tab  '}`}>
    {props.isMobileView && (<div class={`${props.showMobileFooter ? 'mobile-bottom-nav ' : ' mobile-bottom-nav-hide-transition '}  is-hide-Desktop  `}>
    <div title='Home' style='padding: 0 5px' class={pathName === "/workspace" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
      setPathName(`/workspace`);
      route(`/workspace`);
      setIsCoreEntityFormPopover(false);
      setIsCatalogueModalOpen(false);
    }}
    >
      <div class="gap-16px p-l-10 p-t-5 p-r-10">
      <Home height="22px" width="22px" viewBox="0 0 552 552" fill={pathName === "/workspace" ? '#000' : '#808080'} />
        <div style={`color: ${pathName === "/workspace" ? '#000' : '#808080'};`} class='menu-name flex-c-m fs-12'>{userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "administrator" ? 'Home' : 'Workspace'}</div>
      </div>
    </div>
    {/*-----------------------Admin Dashboard Bottom Nav Mobile View Start---------------------------*/}
    {/*
        modified by Arun Singh
        modified at 28/12/2023
        modification : Add bottom nav for mobile view;
    */}
    {userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "administrator" && (
      <>
        <div title='Admin Route' style='padding: 0 5px' class={pathName === "/adminRoute" && !isCoreEntityFormPopover && !isCatalogueModalOpen ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
          setPathName(`/adminRoute`);
          route(`/adminRoute`);
          setIsCoreEntityFormPopover(false);
          setIsCatalogueModalOpen(false);
        }}>
          <div class="gap-16px p-l-10 p-t-5 p-r-10">
          <InteractionSearchSuggestion height="22px" width="22px" stroke={pathName === "/adminRoute" && !isCoreEntityFormPopover && !isCatalogueModalOpen ? '#000' : '#808080'} />
            <div style={`color: ${pathName === "/adminRoute" && !isCoreEntityFormPopover && !isCatalogueModalOpen ? '#000' : '#808080'};`} class='menu-name flex-c-m fs-12'>Admin</div>
          </div>
        </div>
        <div title='Generate Purchase Order' style='padding: 0 5px' class={isCatalogueModalOpen && !isCoreEntityFormPopover ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={(e)=> {togglePOModal();setIsCoreEntityFormPopover(false)}}>
          <div class="gap-16px p-l-10 p-t-5 p-r-10">
          <Money height="22px" width="22px" viewBox="0 0 552 552" fill={isCatalogueModalOpen && !isCoreEntityFormPopover ? '#000' : '#808080'} />
            <div style={`color: ${isCatalogueModalOpen && !isCoreEntityFormPopover ? '#000' : '#808080'};`} class='menu-name flex-c-m fs-12'>Order</div>
          </div>
        </div>
        <div title='Create Request/Add Request' style='padding: 0 5px' class={isCoreEntityFormPopover && !isCatalogueModalOpen ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={(e)=> {addRequest();setIsCatalogueModalOpen(false)}}>
          <div class="gap-16px p-l-10 p-t-5 p-r-10">
          <Request height="22px" width="22px" fill={isCoreEntityFormPopover && !isCatalogueModalOpen ? '#000' : '#808080'} />
            <div style={`color: ${isCoreEntityFormPopover && !isCatalogueModalOpen ? '#000' : '#808080'};`} class='menu-name flex-c-m fs-12'>Request</div>
          </div>
        </div>
      </>
    )}
    {/*-----------------------Admin Dashboard Bottom Nav Mobile View End---------------------------*/}
    {
      /*
        Modified by: Arun Singh
        Modified on: 27/11/2023
        COMP_BG_704: The "Cases" tab is not required
      */
    }
    {/*{(userInfo.userDesignation && userInfo.userDesignation.toLowerCase() !== "administrator") && (
      <div
        title='Case'
        style='padding: 0 5px'
        class={pathName === "/workspaceCaseDetails" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
        onClick={() => {
          setPathName('/workspaceCaseDetails');
          route(`/workspaceCaseDetails`);
        }}
      >
      <div class="gap-16px p-l-10 p-t-5 p-r-10">
        <Cases height="22px" width="22px" viewBox="0 0 282 352" fill={pathName === "/workspaceCaseDetails" ? '#000' : '#808080'} />
        <div style={`color: ${pathName === "/workspaceCaseDetails" ? '#000' : '#808080'};`} class='menu-name flex-c-m fs-12'>Cases</div>
      </div>
      </div>
    )}*/}
    {(userDesignation.toLowerCase() === "insurance agent" || userDesignation.toLowerCase() === "back-office executive" || userDesignation.toLowerCase() === "insurance team lead" || userDesignation.toLowerCase() === "accounts manager" || userDesignation.toLowerCase() === "accounts executive" || userDesignation.toLowerCase() === "sales accounts executive" || userDesignation.toLowerCase() === "insurance head") && (
      <div
        title='Add New Case'
        style='padding: 0 5px'
        class={isCreateCaseModalOpen ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
        onClick={() => {
          toggleCreateCaseModal()
        }}
      >
      <div class="gap-16px p-l-10 p-t-5 p-r-10">
        <AddCase height="22px" width="22px" viewBox="0 0 442 545" fill={isCreateCaseModalOpen ? '#000' : '#808080'} />
        <div style={`color: ${isCreateCaseModalOpen ? '#000' : '#808080'};`} class='menu-name flex-c-m fs-12'>Add Case</div>
      </div>
      </div>
    )}

    <div
      title='Logout'
      style='padding: 0 5px'
      onClick={(e) => {
        logout(e)
      }}
    >
    <div class="gap-16px p-l-10 p-t-2 p-r-10">
      <em class="cursor-pointer icon-sign-out w-22px h-22px text-white fs-22" style='color:#808080 !important'/>
      <div style={`color: ${isCreateCaseModalOpen ? '#000' : '#808080'}; margin-top: -3px !important;`} class='menu-name flex-c-m fs-12'>Logout</div>
    </div>
    </div>
    </div>)
    }
    {
      !props.isMobileView &&
        <div class='rightPanel-tab'>
          {/*
             Modification: Added Global Search Fragment
             By: Devang
             Date: 22/02/2022
          */}
          {/*
            Modification: Updated z-index of background and search icon
            By: Devang
            Date: 22/03/2023
          */}
          <Fragment>
           {isGlobalSearchVisible &&
             <div class="pos-fixed top-0 left-0 bottom-0 right-0 z-index-9997 bg-black-75" onclick={()=> setIsGlobalSearchVisible(e => !e)} />
           }
           <div class={`flex-c flip-card-inner m-r-10 h-fit-content pos-relative ${isGlobalSearchVisible ? 'z-index-9998' : 'z-index-2'}`}>
             {
               isGlobalSearchVisible &&
               <SearchInputSuggestion
                 setIsGlobalSearchVisible={setIsGlobalSearchVisible}
               />
             }
             <div class={`leftInfoPanel-cards p-8-imp h-fit-content cursor-pointer flex-c-m`} onclick={()=> setIsGlobalSearchVisible(e => !e)}>
               {
                 !isGlobalSearchVisible ?
                 <span>
                   <Search height="24px" width="24px" fill="#fff" title='Click Here to Search' />
                 </span>
                 :
                 <span style="width: 24px; height: 30.5px;" />
               }
             </div>
           </div>
          </Fragment>
          {userInfo.userDesignation && userInfo.userDesignation.toLowerCase() !== "administrator" && (
            <>
              <div class="flex-c flip-card-inner m-r-10 h-fit-content">
                <div class={`leftInfoPanel-cards p-8-imp h-fit-content cursor-pointer flex-c-m`}>
                  <span>
                    <ViewedRecently height="26px" width="26px" fill="#fff" action={(e)=> toggleRecentlyViewedModal(e)} title='Recently Viewed'/>
                  </span>
                </div>
              </div>
              <div class="flex-c flip-card-inner m-r-10 h-fit-content">
                <div class={`leftInfoPanel-cards p-8-imp h-fit-content cursor-pointer flex-c-m`}>
                  <span>
                    <RoundStar height="26px" width="26px" fill="#fff" action={(e)=> toggleFollowedCasesModal(e)} title='Important Cases'/>
                  </span>
                </div>
              </div>
              <div class="flex-c flip-card-inner m-r-10 h-fit-content">
                <div class={`leftInfoPanel-cards p-8-imp h-fit-content cursor-pointer flex-c-m ${ isCallModalOpen ? 'rightPanel-menu-active' : ''}`}>
                  <span>
                  <AddCall fill="#fff" height="22px" width="22px"  action={(e)=> toggleCallModal(e)} title='Add Call' />
                  </span>
                </div>
              </div>
            </>
          )}
          {(userDesignation.toLowerCase() === "insurance agent" || userDesignation.toLowerCase() === "back-office executive" || userDesignation.toLowerCase() === "insurance team lead" || userDesignation.toLowerCase() === "accounts manager" || userDesignation.toLowerCase() === "accounts executive" || userDesignation.toLowerCase() === "sales accounts executive" || userDesignation.toLowerCase() === "insurance head") && (
            <div class="flex-c flip-card-inner m-r-10 h-fit-content">
              <div class={`leftInfoPanel-cards p-8-imp h-fit-content cursor-pointer flex-c-m ${ isCreateCaseModalOpen ? 'rightPanel-menu-active' : ''}`}>
                <span>
                  <AddCase height="24px" width="24px" fill="#fff"action={(e)=> toggleCreateCaseModal()} title='Add New Case'/>
                </span>
              </div>
            </div>
          )}
          {(userDesignation.toLowerCase() === "insurance agent" || userDesignation.toLowerCase() === "back-office executive" || userDesignation.toLowerCase() === "insurance team lead" || userDesignation.toLowerCase() === "insurance head") && (
            <div class="flex-c flip-card-inner m-r-10 h-fit-content">
              <div class={`leftInfoPanel-cards p-8-imp h-fit-content cursor-pointer flex-c-m ${ isOpenCaseFormPopover ? 'rightPanel-menu-active' : ''}`}>
                <span>
                  <Clipboard height="24px" width="24px" fill="#fff"action={(e)=> toggleEndorsementFormPopover()} title='Endorsement'/>
                </span>
              </div>
            </div>
          )}
          {/*(userDesignation.toLowerCase() === "back-office executive" || userDesignation.toLowerCase() === "accounts executive" || userDesignation.toLowerCase() === "insurance head") &&
            <div class="flex-c flip-card-inner m-r-10 h-fit-content">
              <div class="leftInfoPanel-cards p-8-imp h-fit-content cursor-pointer flex-c-m">
                <span>
                  <Download height="24px" width="24px" fill="#fff" action={(e)=> initiateCSVReportDownload()} title='Download Report'/>
                </span>
              </div>
            </div>
          */}
          {(userDesignation.toLowerCase() === "insurance head" || userDesignation.toLowerCase() === "back-office executive") &&
            <div class="flex-c flip-card-inner m-r-10 h-fit-content">
              <div class="leftInfoPanel-cards p-8-imp h-fit-content cursor-pointer flex-c-m">
                <span>
                  <Upload height="24px" width="24px" fill="#fff" action={(e)=> toggleUploadFileModal(e, 'ACH Transactions')} title='Bulk Upload for ACH Transactions'/>
                </span>
              </div>
            </div>
          }
          {(userDesignation.toLowerCase() === "insurance head" || userDesignation.toLowerCase() === "back-office executive") &&
            <div class="flex-c flip-card-inner m-r-10 h-fit-content">
              <div class="leftInfoPanel-cards p-8-imp h-fit-content cursor-pointer flex-c-m">
                <span>
                  <VB height="24px" width="24px" fill="#fff" action={(e)=> toggleUploadFileModal(e, '64VB')} title='Bulk Upload 64VB'/>
                </span>
              </div>
            </div>
          }
          {(userDesignation.toLowerCase() === "insurance head") && (
            <div class="flex-c flip-card-inner m-r-10 h-fit-content">
              <div class={`leftInfoPanel-cards p-8-imp h-fit-content cursor-pointer flex-c-m`}>
                <span>
                  <PriceList height="26px" width="26px" fill="#fff"action={(e)=> toggleEditSchedulerLegacyFormPopover(e)} title='Generate Legacy Active Case'/>
                </span>
              </div>
            </div>
          )}
          {/*(userDesignation.toLowerCase() === "insurance head") && (
            <div class="flex-c flip-card-inner m-r-10 h-fit-content">
              <div class={`leftInfoPanel-cards p-8-imp h-fit-content cursor-pointer flex-c-m`}>
                <span>
                  <PriceList height="26px" width="26px" fill="#fff"action={(e)=> generateQuotation(e)} title='Generate Quotation'/>
                </span>
              </div>
            </div>
          )}
          {(userDesignation.toLowerCase() === "back-office executive" || userDesignation.toLowerCase() === "accounts manager" || userDesignation.toLowerCase() === "accounts executive" || userDesignation.toLowerCase() === "sales accounts executive" || userDesignation.toLowerCase() === "insurance head") && (
            <div class="flex-c flip-card-inner m-r-10 h-fit-content">
              <div class={`leftInfoPanel-cards p-8-imp h-fit-content cursor-pointer flex-c-m ${ isCreateCaseModalOpen ? 'rightPanel-menu-active' : ''}`}>
                <div title='Notifications' class={pathName === "/notificationDashboard" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
                  setPathName('/notificationDashboard');
                  route(`/notificationDashboard`);}}
                >
                  <div style="margin-bottom: -5px;">
                    <Notification stroke="white" height="24px" width="24px" stroke={'white'} />
                  </div>
                </div>
              </div>
            </div>
          )*/}
          {/*
            Bug: T277 | BG_FN - Calculator Not Resetting Displayed Value on Reopen
            Modification: Commented calculator
            By: Devang
            Date: 24/05/2024
          */}
          {/*<div class="flex-c flip-card-inner m-r-10 h-fit-content">
            <div class={`leftInfoPanel-cards p-8-imp h-fit-content cursor-pointer flex-c-m ${ isCalculatorModalOpen ? 'rightPanel-menu-active' : ''}`}>
              <span>
              <Calculator fill="#fff" height="22px" width="22px"  action={(e)=> toggleCalculatorModal(e)} title='Calculator' />
              </span>
            </div>
          </div>*/}
          {userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "administrator" && (
            <>
            <div class="flex-c flip-card-inner m-r-10 h-fit-content">
              <div class={`leftInfoPanel-cards p-8-imp h-fit-content cursor-pointer flex-c-m ${ isCatalogueModalOpen ? 'rightPanel-menu-active' : ''}`}>
                <span>
                <Money fill="#fff" height="22px" width="22px" action={(e)=> togglePOModal()} title='Generate Purchase Order' />
                </span>
              </div>
            </div>
            <div class="flex-c flip-card-inner m-r-10 h-fit-content">
              <div class={`leftInfoPanel-cards p-8-imp h-fit-content cursor-pointer flex-c-m ${ isCoreEntityFormPopover ? 'rightPanel-menu-active' : ''}`}>
                <span>
                <Request fill="#fff" height="22px" width="22px" action={(e)=> addRequest()} title='Add Request' />
                </span>
              </div>
            </div>
            </>
          )}

        </div>
    }
      <ShowNotification slideNotificationRef={slideNotificationRef} showSlideNotification={showSlideNotification} message={message} />
      {/*(userDesignation.toLowerCase() === "insurance head") && (
        <div class="flex-c flip-card-inner m-r-10 h-fit-content">
          <div class={`leftInfoPanel-cards p-8-imp h-fit-content cursor-pointer flex-c-m`}>
            <span>
              <Folder height="26px" width="26px" fill="#fff"action={(e)=> route('/organization')} title='Organization'/>
            </span>
          </div>
        </div>
      )}
      {(userDesignation.toLowerCase() === "insurance head") || (userDesignation.toLowerCase() === "service accounts executive") && (
        <div class="flex-c flip-card-inner m-r-10 h-fit-content">
          <div class={`leftInfoPanel-cards p-8-imp h-fit-content cursor-pointer flex-c-m`}>
            <span>
              <Insurance height="24px" width="24px" fill="#fff"action={(e)=> route('/siloAdministration')} title='Silo Administration'/>
            </span>
          </div>
        </div>
      )}
      {(userDesignation.toLowerCase() === "insurance head" || userDesignation.toLowerCase() === "insurance team lead") && (
        <div class="flex-c flip-card-inner m-l-5 m-r-10 h-fit-content">
          <div class={`leftInfoPanel-cards p-8-imp h-fit-content cursor-pointer flex-c-m`}>
            <span>
              <Cases height="26px" width="26px" fill="#fff"action={(e)=> route('/caseManagement')} title='Case Allocation'/>
            </span>
          </div>
        </div>
      )*/}
      {/*<div class="flex-c flip-card-inner m-r-10 h-fit-content">
        <div class="leftInfoPanel-cards p-8-imp h-fit-content cursor-pointer flex-c-m">
          <span>
            <Cases height="26px" width="26px" fill="#fff" viewBox="0 0 302 382" action={(e)=> routeToCaseList()} title='Cases'/>
          </span>
        </div>
      </div>*/}
      {
        isCallModalOpen && (
          <div class='orgChartModal'>
            <div id="modelCaseModal" style="display:block;" class="org-chart-modal overflow-unset">
              <div class="org-chart-modal-content org-chart-width" style='padding: 0px !important'>
                <div>
                  <div class={`modal-header`}>
                    <span class='fs-12'>
                      <div class="display-flex">
                        <ul class='breadrcrumb_arrows_inverted m-r-8'>
                          <li>Add Call</li>
                        </ul>
                      </div>
                    </span>
                    <span class="modal-close" onClick={(e) => toggleCallModal(e)}>&times;</span>
                  </div>
                  <div class="org-chart-modal-body background-transparent" style="height:80vh; overflow:auto; padding: 30px 0px !important">
                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-12 m-t-16">
                        <span title="Follow up for" class="p-0 accordion-category-heading fs-13 flex-l-m">
                        Follow up for
                        </span>
                        <select class="p-l-0 fs-15" type="text" value={followUpFor ? followUpFor: ''} id="followUpFor" onChange={(e) => {
                          setFollowUpFor(e.target.value);
                        }}
                        >
                          <option value="" >Select Follow up for</option>
                          <option value={'New Insurance'}>{'New Insurance'}</option>
                          <option value={'Insurance Renewal'}>{'Insurance Renewal'}</option>
                        </select>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-12 m-t-8 follow-up">
                        <span title="Follow up for" class="p-0 accordion-category-heading fs-13 flex-l-m">
                        Reason
                        </span>
                        <select class="p-l-0 fs-15" type="text" value={reasonForCalling ? reasonForCalling: ''} id="reasonForCalling" onChange={(e) => {
                          setReasonForCalling(e.target.value);
                        }}
                        >
                          <option value="" >Select Reason</option>
                          <option value={'Courtesy Call '}>{'Courtesy Call '}</option>
                          <option value={'Quotation Confirmation'}>{'Quotation Confirmation'}</option>
                          <option value={'Regular Follow Up'}>{'Regular Follow Up'}</option>
                          <option value={'Negotiation'}>{'Negotiation'}</option>
                          <option value={'Appointment Confirmation'}>{'Appointment Confirmation'}</option>
                          <option value={'Payment Confirmation'}>{'Payment Confirmation'}</option>
                        </select>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-12 m-t-8 display-grid">
                        <span title="Remark" class="p-0 m-t-0 accordion-category-heading fs-13">
                        Remark
                        </span>
                        <input class="textarea-height m-t-8" type="textarea" id="remark" rows="4" />
                      </div>
                      {
                        reasonForCalling === "Other" && (
                          <div class="col-xs-12 col-sm-12 col-md-12 m-t-8 display-grid">
                            <span title="Reason" class="p-0 m-t-0 accordion-category-heading fs-13">
                            Enter Reason
                            </span>
                            <input required class="textarea-height m-t-8" type="textarea" id="remark" rows="4" />
                          </div>
                        )
                      }
                      <div class="col-xs-12 col-sm-12 col-md-12 m-t-8 display-grid">
                        <span title="Mobile Number" class="p-0 m-t-0 accordion-category-heading fs-13">
                        Enter Mobile Number
                        </span>
                        <input autocomplete="off" value={mobileNumberEntered} onInput={(e) => setMobileNumberEntered(e.target.value)} />
                      </div>
                      <div class="col-xs-12">
                        <div class="p-b-10">
                          <button disabled={isCallOriginatedByUser} class="primary-button m-t-20 m-b-20" onClick={e => originateCallNew(e)}>Call</button>
                        </div>
                        {
                          isCallOriginatedByUser && !callResponseData && (
                            <div class="p-b-10">
                              <p class="form-label">Call Request Status</p>
                              <span>{callResponse} {callResponseText}</span>
                            </div>
                          )
                        }
                        {
                          isCallOriginatedByUser && callResponseData && (
                            <div class="p-b-10">
                              <p class="form-label">Call Request: Error</p>
                              <span class="text-color-red">{callResponseData}</span>
                            </div>
                          )
                        }
                        {
                          isCallOriginatedByUser && !callResponseData && (
                            <div class="p-b-10">
                              <p class="form-label">Call Initiated From</p>
                              <span>{callResponseObj.extension}</span>
                            </div>
                          )
                        }
                        {
                          isCallOriginatedByUser && !callResponseData && (
                            <div class="p-b-10">
                              <p class="form-label">Call Initiated To</p>
                              <span>{callResponseObj.mobileNumber}</span>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      {
        isOngoingCallWindowOpen && !isOngoingCallWindowClose && (
          <CallingCard
            cardBgColor='#000000bf'
            // cardHeight={!props.isTaskView ? '50%' : '100%'}
            cardHeight={'50%'}
            iconText={props.currentInteraction['dynamicProperties_customerName'] ? props.currentInteraction['dynamicProperties_customerName']: props.currentInteraction['customerName']}
            // iconContainerClasses={!props.isTaskView ? "display-none": ""}
            header={'Calling...'}
            cardHeader={props.currentInteraction['dynamicProperties_customerName'] ? props.currentInteraction['dynamicProperties_customerName']: props.currentInteraction['customerName']}
            mainHeaderClasses={"row justify-between"}
            truecallerName={props.trueCallerData.name}
            subHeader={'Mobile ' + props.trueCallerData.phones[0]['e164Format']}
            state={props.trueCallerData.addresses[0]['city']}
            country={props.trueCallerData.addresses[0]['countryCode']}
            isTaskView={false}
            action={(e)=> closeOnGoingCallWindow(e)}
        />
        )
      }
      {
        isCreateCaseModalOpen && (
          <div class='orgChartModal'>
            <div id="modelCaseModal" style="display:block;" class="org-chart-modal">
              <div class="card org-chart-modal-content org-chart-width p-b-16 p-t-0 p-r-0 p-l-0">
                <div class="pos-relative">
                  {/*<div class="right-drawer-label" onClick={(e) => toggleCreateCaseModal(e)}>
                    <div class="right-drawer-close-btn" title="Close">
                      <div class="right-drawer-close-btn-inner" />
                    </div>
                  </div>*/}
                  <div class={`modal-header`}>
                    <span class='fs-12'>
                      <div class="display-flex">
                        <ul class='breadrcrumb_arrows_inverted m-r-8'>
                          <li>Add New Case</li>
                        </ul>
                      </div>
                    </span>
                    <span class="modal-close" onClick={(e) => {toggleCreateCaseModal(e);}}>&times;</span>
                  </div>
                  <div class="org-chart-modal-body background-transparent" style="height:40vh; overflow:auto">
                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 m-t-5 display-flex justify-center flex-column">
                        {/*
                          Modification:- Added tick loader in between form transition in insurance case
                          Name:- Rajkumar Pradhan
                          Date:- 24-08-2023
                        */}
                        {createInsuranceCaseLoader ?
                          <div class="text-center m-t-20">
                            <img src="/assets/images/gifs/done.gif" width="160" height="160"/>
                            {caseDetail=="New" ? <p>Creating a New Case</p>:<p>Creating a New Renewal Case</p>}
                          </div>
                          :
                          <div class="m-b-10">
                          <>
                            <div class="p-b-10" >
                              <p> Select Type of Case <span class="star-mandatory-entry p-l-2">*</span></p>
                              <select class="p-l-r-0-2rem text-4b4d51 fs-1rem h-2rem text-4b4d51 background-white" type="text"  name="case" value={typeOfCase} onChange={(e) => {
                                setTypeOfCase(e.target.value);setCaseDetail(e.target.value);
                              }} style="text-shadow:none">
                                <option value=''>Select  Type of Case</option>
                                <option value="New">New</option>
                                {(userDesignation.toLowerCase() !== "accounts manager" && userDesignation.toLowerCase() !== "accounts executive" && userDesignation.toLowerCase() !== "sales accounts executive") && <option value="Renewal">Renewal</option>}
                              </select>
                            </div>
                            {/*
                              Modification:- Added vin search option
                              Name:- Masum Raja
                              Date:- 18-09-2023
                            */}
                            <div class="p-b-10">
                              <p>Search VIN
                                <span class="star-mandatory-entry p-l-2">*</span>
                              </p>
                              <input autocomplete="off" class="first-letter-capital"
                                name="searchVin"
                                id="searchVin"
                                type="text"
                                value={vinNumber}
                                required="true"
                                placeholder='Search VIN Number'
                                onInput={handleVinNumber}
                              />
                            </div>
                            {/*
                              Modified by: Arun Singh
                              Modified on: 24/11/2023
                              COMP_BG_708: The alignment of the alert message "There is a Renewal case which already exists with the entered VIN in our system. If you want to create a new case for this existing VIN, please search the VIN via Global search and reopen the case from the Process View." is incorrect.
                            */}
                            <div class={`${!props.isMobileView && 'flex-sb'} p-b-10`}>
                              <div class={`${props.isMobileView ? 'w-full m-b-10' : 'w-75p'}`}>
                              {
                                isCaseErrorMsg &&
                                <>
                                  <p className='fs-15 red-text'>{`There is a ${typeOfCase} case which already exists with the entered VIN in our system.`}</p>
                                  <p className='fs-15 red-text'>{'If you want to create a new case for this existing VIN, please search the VIN via Global search and reopen the case from the Process View.'}</p>
                                </>
                              }
                              </div>
                              <button disabled={!typeOfCase || !vinNumber} class={`${props.isMobileView && 'w-full'} primary-button`} onClick={(e) => createCase(e)}>Create Case</button>
                            </div>
                          </>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      {isOpenCaseFormPopover &&
        <NewPopupModal isMobileView={props.isMobileView} classes="formModal" modalWidth={"65%"} modalDisplay={('show-formPopover')} onClose={(e) => toggleEndorsementFormPopover(e)}>
          <div class="enquiryForm background-transparent h-full">
            <div class="row p-t-10 p-l-30 p-r-30 p-b-10" style="background:#f7f7f7;border-bottom:1px solid lightgrey">
              <div class="col-xs-8">
                <span class="formHeader display-flex">
                {
                  endorsementObj.typeOfEndorsement && (
                    <h4 class="m-t-5 m-b-0 text-up">New Request: <span class="fw-600 input-like-header"> {requestTypes[endorsementObj.typeOfEndorsement]} </span></h4>
                  )
                }
                {
                  !endorsementObj.typeOfEndorsement && (
                    <h4 class="m-t-5 m-b-0 text-up">New Request</h4>
                  )
                }
                  {/*<h4 class="m-t-5 m-b-0">{endorsementObj.typeOfEndorsement === 'policy' ? 'New Policy Endorsement' : endorsementObj.typeOfEndorsement === 'ncbCertificate' ? 'New No Claim Bonus' : endorsementObj.typeOfEndorsement === 'cancellationApproval' ? 'Policy Cancellation' : 'New NCB Recovery Request'}</h4>*/}
                </span>
              </div>
              {/*<div class="col-xs-4 display-flex justify-flex-end align-center p-r-2">
                <button onClick={(e) => createEndorsement(e)} class="primary-button m-l-10"
                  disabled={(endorsementObj.typeOfEndorsement === 'policy' && (!endorsementObj.endorsementFileID || !endorsementObj.endorsementRemark || !endorsementObj.selectedManagerID) ||
                    (endorsementObj.typeOfEndorsement === 'ncbCertificate' && (!endorsementObj.ncbPercentage || !endorsementObj.ncbCertificateID || endorsementObj.isNoClaimBonus === "no" || !endorsementObj.selectedManagerID)) ||
                      (endorsementObj.typeOfEndorsement === 'ncbRecoveryRequest' && (!endorsementObj.ncbPercentage || !endorsementObj.reasonForNCBRecovery || !endorsementObj.recoveryAmount || !endorsementObj.selectedManagerID)) ||
                        (endorsementObj.typeOfEndorsement === 'cancellationApproval' && (!endorsementObj.cancellationReason || (endorsementObj.cancellationReason && endorsementObj.cancellationReason === 'Other' && !endorsementObj.otherCancellationReason) || !endorsementObj.selectedManagerID)))}>
                  {(endorsementObj.typeOfEndorsement === 'ncbRecoveryRequest') ? 'Create Recovery Request' : (endorsementObj.typeOfEndorsement === 'cancellationApproval') ? 'Initiate Policy Cancellation' : 'Create Endorsement'}
                </button>
              </div>*/}
            </div>
            {/*
               Modification:- Added tick loader in between form transition in endorsement
               Name:- Rajkumar Pradhan
               Date:- 24-08-2023
             */}
             { endorsementLoader ?
               <div class="text-center sizefull flex-col-c-m">
                  <img src="/assets/images/gifs/done.gif" width="300" height="300"/>
                  <p>Creating a new request</p>
               </div>
               :
              <div id="formModalContainer" class="formModalContainer">
                {(!interactionObj || !interactionObj.dynamicProperties_customerName) &&
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 m-t-15">
                    <p class="formLabel fs-15">Select Type of Case
                      <span class="star-mandatory-entry p-l-2">*</span>
                    </p>
                    <select class='select'
                      style="text-transform:capitalize"
                      type="text"
                      id="typeOfCase"
                      name="typeOfCase"
                      value={endorsementObj.typeOfCase}
                      onChange={e => setFormValueInputCase(e)}
                    >
                      <option class="first-letter-capital" value="" disabled selected>Select Type of Case</option>
                      <option class="first-letter-capital" selected="New Case" value="New Case">New Case</option>
                      <option class="first-letter-capital" selected="Renewal" value="Renewal">Renewal</option>
                    </select>
                  </div>
                }
                {/*
                   New NCB, Cancellation, NCB Refund, Correction, Accessories, New Vehicle No, CNG
                */}
                {(!interactionObj || !interactionObj.dynamicProperties_customerName) && (endorsementObj.typeOfCase === 'New Case') &&
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 m-t-15">
                    <p class="formLabel fs-15">Select Endorsement
                      <span class="star-mandatory-entry p-l-2">*</span>
                    </p>
                    <select class='select'
                      style="text-transform:capitalize"
                      type="text"
                      id="typeOfEndorsement"
                      name="typeOfEndorsement"
                      value={endorsementObj.typeOfEndorsement}
                      onChange={e => setFormValueInputCase(e)}
                    >
                      <option class="first-letter-capital" value="" disabled selected>Select Endorsement</option>
                      {/*<option class="first-letter-capital" selected="policy" value="policy">Policy Endorsement</option>
                      <option class="first-letter-capital" selected="ncbCertificate" value="ncbCertificate">New NCB</option>*/}
                      <option class="first-letter-capital" selected="cancellationApproval" value="cancellationApproval">Cancellation</option>
                      <option class="first-letter-capital" selected="ncbCertificate" value="ncbCertificate">NCB Refund</option>
                      <option class="first-letter-capital" selected="correction" value="correction">Correction</option>
                      <option class="first-letter-capital" selected="accessories" value="accessories">Accessories</option>
                      <option class="first-letter-capital" selected="cng" value="cng">CNG</option>
                      <option class="first-letter-capital" selected="newVehicleNo" value="newVehicleNo">New vehicle no.</option>
                      <option class="first-letter-capital" selected="ownerTransfer" value="ownerTransfer">Owner Transfer</option>
                    </select>
                  </div>
                }
                {/*
                  NCB Recovery, New NCB, NCB Refund, Cancellation, Correction, Owner Transfer, Accessories, CNG
                */}
                {(!interactionObj || !interactionObj.dynamicProperties_customerName) && (endorsementObj.typeOfCase === 'Renewal') &&
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 m-t-15">
                    <p class="formLabel fs-15">Select Endorsement
                      <span class="star-mandatory-entry p-l-2">*</span>
                    </p>
                    <select class='select'
                      style="text-transform:capitalize"
                      type="text"
                      id="typeOfEndorsement"
                      name="typeOfEndorsement"
                      value={endorsementObj.typeOfEndorsement}
                      onChange={e => setFormValueInputCase(e)}
                    >
                      <option class="first-letter-capital" value="" disabled selected>Select Endorsement</option>
                      {/*<option class="first-letter-capital" selected="ncbCertificate" value="ncbCertificate">New NCB</option>*/}
                      <option class="first-letter-capital" selected="ncbRecoveryRequest" value="ncbRecoveryRequest">NCB Recovery</option>
                      <option class="first-letter-capital" selected="cancellationApproval" value="cancellationApproval">Cancellation</option>
                      <option class="first-letter-capital" selected="ncbCertificate" value="ncbCertificate">NCB Refund</option>
                      <option class="first-letter-capital" selected="correction" value="correction">Correction</option>
                      <option class="first-letter-capital" selected="ownerTransfer" value="ownerTransfer">Owner Transfer</option>
                      <option class="first-letter-capital" selected="accessories" value="accessories">Accessories</option>
                      <option class="first-letter-capital" selected="cng" value="cng">CNG</option>
                    </select>
                  </div>
                }
                {(!endorsementObj.typeOfEndorsement || (!interactionObj || !interactionObj.dynamicProperties_customerName)) &&
                  <div class="w-full display-flex">
                    <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8 m-t-15">
                      <p class="formLabel fs-15 p-b-3">Search VIN / Mobile No. / Policy No.
                        <span class="star-mandatory-entry p-l-2">*</span>
                      </p>
                      <input autocomplete="off" class="first-letter-capital"
                        onClick={(e) => inputClicked(e)}
                        name="searchQuery"
                        id="searchQuery"
                        type="text"
                        value={endorsementObj.searchQuery}
                        required="true"
                        onInput={(e) => setFormValueInputCase(e)}
                      />
                      <div class="dropdown-filter-container">
                        {
                          searchResults.length > 0 && searchResults.map((result) => (
                            result.dynamicProperties_customerName &&
                              <div class="dropdown-menu grey-on-hover display-flex flex-direction-column cursor-pointer" onClick={() => searchCase('Select', result.uuid)}>
                                <span>
                                  <span class="fs-14" >{result.dynamicProperties_uniqueID}</span>
                                  <span class="fs-12">| {result.dynamicProperties_customerName}</span>
                                </span>
                              </div>
                          ))
                        }
                      </div>
                      {/*<span id={`error-`+dynamicProp.name} class="text-color-red fs-10" style="display:none;">Please enter {dynamicProp.displayName}</span>*/}
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 m-t-15" style="align-self: center">
                      <button onClick={() => searchCase()} class="primary-button m-l-10" disabled={!endorsementObj.typeOfEndorsement || !endorsementObj.searchQuery}>Search Insurance Case</button>
                    </div>
                  </div>
                }
              </div>
              }
          </div>
        </NewPopupModal>
      }
      {isOpenFormPopover &&
        <FormWizard
        modelMapping={modelMapping}
        isScrolledToTheBottom={isScrolledToTheBottom}
        cartNumber={cartNumber}
        progress={progress}
        actions={actions}
        dynamicProps={dynamicProps}
        dynamicPropsData={dynamicPropsData}
        areaList={areaList}
        cityList={cityList}
        stateList={stateList}
        exchangEvaluators={exchangEvaluators}
        schemes={schemes}
        offers={offers}
        interactionObj={interactionObj}
        listofManufacturers={listofManufacturers}
        catalogoueItemList={catalogoueItemList}
        variantList={variantList}
        testDriveCars={testDriveCars}
        cartProducts={cartProducts}
        assignUserList={assignUserList}
        selectedModel={selectedModel}
        currentAction={currentAction}
        currentRow={currentRow}
        courierData={courierData}
        testDriveQueue={testDriveQueue}
        selectedProducts={selectedProducts}
        selectedColor={selectedColor}
        availableStock={availableStock}
        allUserList={allUserList}
        isHandoverDocket={isHandoverDocket}
        isEditDocket={isEditDocket}
        setCourierImgDelete={setCourierImgDelete}
        setSelectedActionIndex={setSelectedActionIndex}
        selectedActionIndex={selectedActionIndex}
        setCurrentAction={setCurrentAction}
        isBackModalDisable={isBackModalDisable}
        isNextModalDisable={isNextModalDisable}
        isSaveModalDisable={isSaveModalDisable}
        financeExecutives={financeExecutives}
        toggleFormPopover={toggleFormPopover}
        scrollToTheBottom={scrollToTheBottom}
        setIsScrolledToTheBottom={setIsScrolledToTheBottom}
        setIsSaveModalDisable={setIsSaveModalDisable}
        setIsNextModalDisable={setIsNextModalDisable}
        setIsBackModalDisable={setIsBackModalDisable}
        submitFormNew={submitFormNew}
        setFormValueInput={setFormValueInput}
        saveFormInput={saveFormInput}
        inputClicked={inputClicked}
        deleteUploadedFile={deleteUploadedFile}
        saveFormInputCheckbox={saveFormInputCheckbox}
        saveAreaFromForm={saveAreaFromForm}
        uploadFile={uploadFile}
        viewAllImages={viewAllImages}
        dynamicButtonClicked={dynamicButtonClicked}
        getSelectedTypeUserList={getSelectedTypeUserList}
        /*submitFormOnStep={submitFormOnStep}*/
        saveFormInputRadio={saveFormInputRadio}
        saveFormInputRadioDefault={saveFormInputRadioDefault}
        isFormEditable={isFormEditable}
        setFormEditable={setFormEditable}
        setBorderOnFocus={setBorderOnFocus}
        completedActions={completedActions}
        backgroundColor={"#f7f7f7"}
        selectedArrayForDynamicProps={selectedArrayForDynamicProps}
        insuranceCompanies={insuranceCompanies}
        setIsFormUpdated={setIsFormUpdated}
        isFormUpdated={isFormUpdated}
        isDataSaving={isDataSaving}
        setIsDataSaving={setIsDataSaving}
        handleSaveTaskOnStep={handleSaveTaskOnStep}
        isMobileView={props.isMobileView}
        /*
          Modification: T319 (Change Taken From caseSemiDetailViewV3) - Added isBlur prop to blue the component on task completion error and isSaveDisabled and updateFormAPICancelToken added
          By: Ashutosh G
          Date: 22/05/2024
        */
        isBlur={isShowTaskCompletionError}
        isSaveDisabled={isSaveDisabled}
        updateFormAPICancelToken={cancelTokenSource}
      />
      }
      {isEditCaseFormPopover &&
        <NewPopupModal isMobileView={props.isMobileView} classes="formModal" modalWidth={"65%"} modalDisplay={('show-formPopover')} onClose={(e) => toggleEditCaseFormPopover(e)}>
          <div class="enquiryForm background-transparent h-full">
            <div class="row p-t-10 p-l-30 p-r-30 p-b-10" style="background:#f7f7f7;border-bottom:1px solid lightgrey">
              <div class="col-xs-8">
                <span class="formHeader display-flex">
                {
                  endorsementObj.typeOfEndorsement && (
                    <h4 class="m-t-5 m-b-0 text-up">New Request: <span class="fw-600 input-like-header"> {requestTypes[endorsementObj.typeOfEndorsement]} </span></h4>
                  )
                }
                {
                  !endorsementObj.typeOfEndorsement && (
                    <h4 class="m-t-5 m-b-0 text-up">New Request</h4>
                  )
                }
                  {/*<h4 class="m-t-5 m-b-0">{endorsementObj.typeOfEndorsement === 'policy' ? 'New Policy Endorsement' : endorsementObj.typeOfEndorsement === 'ncbCertificate' ? 'New No Claim Bonus' : endorsementObj.typeOfEndorsement === 'cancellationApproval' ? 'Policy Cancellation' : 'New NCB Recovery Request'}</h4>*/}
                </span>
              </div>
            </div>
            {/*
               Modification:- Added tick loader in between form transition in endorsement
               Name:- Rajkumar Pradhan
               Date:- 24-08-2023
             */}
            { endorsementLoader ?
              <div class="text-center sizefull flex-col-c-m">
                <img src="/assets/images/gifs/done.gif" width="300" height="300"/>
                <p>Creating a new request</p>
              </div>:
            <div id="formModalContainer" class="formModalContainer">
              {(!interactionObj || !interactionObj.dynamicProperties_customerName) &&
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 m-t-15">
                  <p class="formLabel fs-15">Select Fields to Update
                    <span class="star-mandatory-entry p-l-2">*</span>
                  </p>
                  <select class='select'
                    style="text-transform:capitalize"
                    type="text"
                    id="typeOfCase"
                    name="typeOfCase"
                    value={endorsementObj.typeOfCase}
                    onChange={e => setFormValueInputCase(e)}
                  >
                    <option class="first-letter-capital" value="" disabled selected>Select Fields to Update</option>
                    <option class="first-letter-capital" selected="Pay-in Slip" value="Pay-in Slip">Pay-in Slip</option>
                    <option class="first-letter-capital" selected="Payment Details" value="Payment Details">Payment Details</option>
                    <option class="first-letter-capital" selected="Inspection Details" value="Inspection Details">Inspection Details</option>
                  </select>
                </div>
              }
              {(!endorsementObj.typeOfEndorsement || (!interactionObj || !interactionObj.dynamicProperties_customerName)) &&
                <div class="w-full display-flex">
                  <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8 m-t-15">
                    <p class="formLabel fs-15 p-b-3">Search VIN / Mobile No. / Policy No.
                      <span class="star-mandatory-entry p-l-2">*</span>
                    </p>
                    <input autocomplete="off" class="first-letter-capital"
                      onClick={(e) => inputClicked(e)}
                      name="searchQuery"
                      id="searchQuery"
                      type="text"
                      value={endorsementObj.searchQuery}
                      required="true"
                      onInput={(e) => setFormValueInputCase(e)}
                    />
                    <div class="dropdown-filter-container">
                      {
                        searchResults.length > 0 && searchResults.map((result) => (
                          result.dynamicProperties_customerName &&
                            <div class="dropdown-menu grey-on-hover display-flex flex-direction-column cursor-pointer" onClick={() => searchCase('Select', result.uuid)}>
                              <span>
                                <span class="fs-14" >{result.dynamicProperties_uniqueID}</span>
                                <span class="fs-12">| {result.dynamicProperties_customerName}</span>
                              </span>
                            </div>
                        ))
                      }
                    </div>
                    {/*<span id={`error-`+dynamicProp.name} class="text-color-red fs-10" style="display:none;">Please enter {dynamicProp.displayName}</span>*/}
                  </div>
                  <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 m-t-15" style="align-self: center">
                    <button onClick={() => searchCase()} class="primary-button m-l-10" disabled={!endorsementObj.typeOfCase || !endorsementObj.searchQuery}>Search Insurance Case</button>
                  </div>
                </div>
              }
            </div>}
          </div>
        </NewPopupModal>
      }
      {isCreateEditFormPopover &&
        <CreateEditForm
          operation={formOperation}
          entityName={formEntityName}
          entityObject={formEntityObject}
          setFormEntityObject={setFormEntityObject}
          dynamicProps={formDynamicProps}
          toggleFormPopover={toggleComponentFormPopover}
          backgroundColor={"#f7f7f7"}
          inputClicked={inputClicked}
          submitForm={submitComponentForm}

          setFormValueInput={setComponentFormValueInput}
          saveFormInputRadioDefault={saveComponentFormInputRadioDefault}
          saveFormInputRadio={saveComponentFormInputRadio}
          saveFormInputCheckbox={saveComponentFormInputCheckbox}
          saveAreaFromForm={saveAreaFromComponentForm}
          saveFormInput={saveComponentFormInput}
          uploadFile={uploadFileCase}
          deleteUploadedFile={deleteUploadedFileCase}

          setFormStageValueInput={setFormStageValueInput}
          setFormArrayValueInput={setFormArrayValueInput}
          removeFormArrayValueInput={removeFormArrayValueInput}
          isMobileView={props.isMobileView}
        />
      }
      <NewPopupModal isMobileView={props.isMobileView} modalWidth={"50%"} modalDisplay={(isOpenAssignUsersTriggeredTask ? 'show-formPopover' : 'hide-formPopover')} modalScroll={true} onClose={(e) => toggleOpenAssignUsersTriggeredTask(e)}>
        <div class="p-l-15 p-r-15 p-t-15 p-b-15 pos-relative">
          <div class="display-flex flex-direction-column align-center">
            <h4 class="f-w-600">Task Assignment</h4>
            <div class="row">
              {
                triggeredUnassignedTaskList && triggeredUnassignedTaskList.length > 0 && triggeredUnassignedTaskList.map(triggeredTask => (
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <p class="first-letter-capital">{triggeredTask.taskName}</p>
                    <select onChange={(e) => handleAssignToUserChange(e, triggeredTask)}>
                      <option value=''>
                        Select User
                      </option>
                      {triggeredTask.assignToUsersList && triggeredTask.assignToUsersList.map(user => (
                        <option value={user.displayName ?user.uuid: user.userID} name={user.displayName ? user.displayName: user.userName} selected={assignToUserList.findIndex(task => ((task.taskID === triggeredTask.taskID) && (task.userID === user.uuid))) > -1}>
                          {user.displayName ? user.displayName: user.userName}
                        </option>
                      ))}
                    </select>
                  </div>
                ))
              }
            </div>
            <button onClick={(e) => saveAssignToUserChange(e)} class="primary-button m-l-10">Save</button>
          </div>
        </div>
      </NewPopupModal>
      {isFileUploadModalOpen &&
        <div class='orgChartModal'>
          <div id="modelCaseModal" style="display:block;" class="org-chart-modal">
            <div class="card org-chart-modal-content org-chart-width p-b-16 p-t-0 p-r-0 p-l-0">
              <div class="pos-relative">
                <div class={`modal-header`}>
                  <span class='fs-12'>
                    <div class="display-flex">
                      <ul class='breadrcrumb_arrows_inverted m-r-8'>
                        <li>Upload {selectedBulkUploadAction === '64VB' ? '64VB' : 'ACH Transactions'}</li>
                      </ul>
                    </div>
                  </span>
                  <span class="modal-close" onClick={(e) => {toggleUploadFileModal(e);}}>&times;</span>
                </div>
                <div class="org-chart-modal-body background-transparent" style="height:40vh; overflow:auto">
                  <div class="w-full">
                    {
                      !uploadedCSVView && !isSuccessfull && (
                        <div class="w-full">
                          <button>
                            <a href={`${selectedBulkUploadAction === '64VB' ? "/assets/static/BulkUpload64VB.csv" : "/assets/static/BulkUpload.csv"}`} style="color:#000" class="noClick" download={`${selectedBulkUploadAction === '64VB' ? "BulkUpload64VB.csv" : "BulkUpload.csv"}`} target="_blank"><p style="font-size: 15px;" class='header-text noClick'>Download Sample .CSV file</p></a>
                          </button>
                          <p style="font-size:12px; color: red; margin-top:10px">{isErrorMessage}</p>
                          <div class="flex-c-m p-0" onDrop={(e) => uploadCSVByDragNDrop(e)}  onDragOver={ev => ev.preventDefault()} >
                            <input type="file" id="file-upload" required style="display: none;"  onChange={(e) => uploadBrowsedCSV(e)} accept='.csv'  />
                            <label class="file-upload text-align-center cursor-pointer" for="file-upload" style="padding: 15px; width:100%" >
                              <p><img src="/assets/images/09_File_Upload.svg" /> Choose a CSV file(s) to upload</p>
                              <p>or drag & drop a CSV File here</p>
                            </label>
                          </div>
                        </div>
                      )
                    }
                    {
                      isSuccessfull && (
                        <div class="answers-list spot-and-tag image-container" style="padding: 0px;" >
                          <label class="file-upload" for="file-upload" style="padding: 15px; width:100%" >
                            <p>File Uploaded Successfully!</p>
                            <div>
                            <table>
                              <thead>
                                <tr>
                                  <td>Updated Cases</td>
                                  <td>Already Closed Cases</td>
                                  <td>Not Found Cases</td>
                                  {selectedBulkUploadAction === 'ACH Transactions' && <td>Non ACH Cases</td>}
                                  <td>Total</td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{bulkUploadResult.updatedInteractionsCount}</td>
                                  <td>{bulkUploadResult.alreadyClosedInteractionsCount}</td>
                                  <td>{bulkUploadResult.notFoundInteractionsCount}</td>
                                  {selectedBulkUploadAction === 'ACH Transactions' && <td>{bulkUploadResult.nonACHInteractionsCount}</td>}
                                  <td>{bulkUploadResult.total}</td>
                                </tr>
                              </tbody>
                            </table>
                            </div>
                          </label>
                        </div>
                      )
                    }
                    {
                      isSuccessfull && bulkUploadResult && bulkUploadResult.notFoundInteractionsCount > 0 && (
                        <div class="answers-list spot-and-tag image-container" style="padding: 0px;" >
                          <label class="file-upload" for="file-upload" style="padding: 15px; width:100%" >
                            <p>Not Found Cases</p>
                            <div>
                              <table>
                                <thead>
                                  <tr>
                                    <td>Chassis No</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {bulkUploadResult.notFoundInteractions && bulkUploadResult.notFoundInteractions.map(item => (
                                    <tr>
                                      <td>{item.dynamicProperties_vin}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </label>
                        </div>
                      )
                    }
                    {
                      isSuccessfull && bulkUploadResult && bulkUploadResult.nonACHInteractionsCount > 0 && (
                        <div class="answers-list spot-and-tag image-container" style="padding: 0px;" >
                          <label class="file-upload" for="file-upload" style="padding: 15px; width:100%" >
                            <p>Non ACH Cases</p>
                            <div>
                              <table>
                                <thead>
                                  <tr>
                                    <td>Customer Name</td>
                                    <td>Chassis No</td>
                                    <td>Policy Type</td>
                                    <td>Enquiry Source</td>
                                    <td>Payment Mode</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {bulkUploadResult.nonACHInteractions && bulkUploadResult.nonACHInteractions.map(item => (
                                    <tr>
                                      <td>{item.dynamicProperties_customerName}</td>
                                      <td>{item.dynamicProperties_vin}</td>
                                      <td>{item.dynamicProperties_typeOfPolicy}</td>
                                      <td>{item.dynamicProperties_source}</td>
                                      <td>{item.dynamicProperties_paymentModeSMC}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </label>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {/*
        Bug: T277 | BG_FN - Calculator Not Resetting Displayed Value on Reopen
        Modification: Commented calculator
        By: Devang
        Date: 24/05/2024
      */}
      {/*
        isCalculatorModalOpen && (
        <DraggableCalculator id="floating-new-assignee" right="55" ripple={false} name="New Assignee" classes="drag-drop-floating-calculator" clickEvent={toggleCalculatorModal} />
        )
      */}
      <div class="visitmodalslide">
        <Modal title="Recently Viewed List" modalSize="is-small-filter" isProfileModal
          modalDisplay={(isRecentlyViewedModalVisible ? 'show-modal' : 'hide-modal')} onClose={(e) => toggleRecentlyViewedModal(e)} >
          <RecentlyViewed isVisible={isRecentlyViewedModalVisible} userID={userInfo.uuid} listType='recentlyViewed' title='Recently Viewed List'/>
        </Modal>
      </div>
      <div class="visitmodalslide">
        <Modal title="Important Cases List" modalSize="is-small-filter" isProfileModal
          modalDisplay={(isFollowedCasesModalVisible ? 'show-modal' : 'hide-modal')} onClose={(e) => toggleFollowedCasesModal(e)} >
          <RecentlyViewed isVisible={isFollowedCasesModalVisible} userID={userInfo.uuid} listType='followedCases' title="Important Cases List"/>
        </Modal>
      </div>
      {/*
       Modification:- Added GeneratePurchaseOrder component instead of entire code
       By:- Rajkumar Pradhan
       On:- 22-03-2024
      */}
      {
        isCatalogueModalOpen ? (
          <GeneratePurchaseOrder
            isMobileView={props.isMobileView}
            isCatalogueModalOpen={isCatalogueModalOpen}
            setIsCatalogueModalOpen={setIsCatalogueModalOpen}
            poStatus={"new-purchase-order"}
            functionBundle={functionBundle}
          />
        ): null
      }
      {/*
        modified : Ashutosh G
        modified : 18/10/2023
        modification : CoreEntityDynamicForm modal added
      */}
      {
        isCoreEntityFormPopover &&
        <CoreEntityDynamicForm
          operation={formOperation}
          toggleFormPopover={toggleFormPopoverRequest}
          inputClicked={inputClicked}
          backgroundColor={"#f7f7f7"}
          dynamicProps={requestDynamicProps}
          entityObject={formEntityObject}
          saveFormInput={saveFormInputForCoreEntity}
          setFormValueInput={setFormValueInputForCoreEntity}
          saveFormInputRadioDefault={saveFormInputRadioDefaultForCoreEntity}
          saveFormInputRadio={saveFormInputRadioForCorEntity}
          saveFormInputCheckbox={saveFormInputCheckboxForCoreEntity}
          errorField={errorField}
          submitForm={submitForm}
          resetFormValues={resetFormValues}
          isMobileView={props.isMobileView}
        />
      }
      {/*
        By: Yashvi
        On: 21st Jan 2023 Modal for searching and creating the legacy active case.
      */}
      {isSchedulerLegacyFormPopover &&
        <NewPopupModal classes="formModal" modalWidth={"65%"} modalDisplay={('show-formPopover')} onClose={(e) => toggleEditSchedulerLegacyFormPopover(e)}>
          <div class="enquiryForm background-transparent h-full">
            <div class="row p-t-10 p-l-30 p-r-30 p-b-10" style="background:#f7f7f7;border-bottom:1px solid lightgrey">
              <div class="col-xs-8">
                <span class="formHeader display-flex">
                  <h4 class="m-t-5 m-b-0 text-up">Create Legacy Active Legacy</h4>
                </span>
              </div>
            </div>
            {
              (!schedulerCreationLoader && schedulerLegacyCases && schedulerLegacyCases.rows && schedulerLegacyCases.rows.length > 0) && (
                <div>
                  <NewListV2 entityTitle={`Legacy Case`} entityName={`Interaction`} dataset={schedulerLegacyCases} headClasses={`bor-rad-t-r-5 bor-rad-t-l-5 shadow1`} lastColumnClasses={`bor-rad-b-r-5 bor-rad-b-l-5`} rowAction={routeToResultRowPage} isFirstColumnEntityNameIcon isShowHighlightText highlightedText={searchQuery} />
                  {!schedulerCaseCreatedLoader && (schedulerLegacyCases && schedulerLegacyCases.rows && schedulerLegacyCases.rows.length === 1) && <button onClick={(e) => runSchedulerForLegacyCase(e)} class="primary-button m-r-16 float-right" disabled={!searchQuery}>Create an active case</button>}
                  {!schedulerCaseCreatedLoader && (schedulerLegacyCases && schedulerLegacyCases.rows && schedulerLegacyCases.rows.length > 1) && <p class="text-color-red fs-10">There are more than one case found for your query. Please make sure you are creating a case whose active case does not exist.</p>}
                </div>
              )
            }
            { (schedulerSearchLoader && (!schedulerLegacyCases || !schedulerLegacyCases.rows || schedulerLegacyCases.rows.length === 0)) &&
              <div class="text-center sizefull flex-col-c-m">
                <img src="/assets/images/gifs/done.gif" width="300" height="300"/>
                <p>Searching Your Case</p>
              </div>
            }
            { (schedulerCreationLoader) &&
              <div class="text-center sizefull flex-col-c-m">
                <img src="/assets/images/gifs/done.gif" width="300" height="300"/>
                <p>Creating Your Case</p>
              </div>
            }
            { (!schedulerSearchLoader && (!schedulerLegacyCases || !schedulerLegacyCases.rows || schedulerLegacyCases.rows.length === 0)) &&
            <div id="formModalContainer" class="formModalContainer">
              <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8 m-t-15">
                <p class="formLabel fs-15 p-b-3">Search Case ID
                  <span class="star-mandatory-entry p-l-2">*</span>
                </p>
                <input autocomplete="off" class="first-letter-capital"
                  onClick={(e) => inputClicked(e)}
                  name="searchQuery"
                  id="searchQuery"
                  type="text"
                  value={searchQuery}
                  required="true"
                  onInput={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <button onClick={() => searchCaseForScheduler()} class="primary-button m-r-16 float-right" disabled={!searchQuery}>Search Case</button>
            </div>}
          </div>
        </NewPopupModal>
      }
      {/*
        Modification: T319 (Change Taken From caseSemiDetailViewV3) - Added task completion error modal
        By: Ashutosh G
        Date: 22/05/2024
      */}
      {
          isShowTaskCompletionError &&
          <TaskErrorModal
            isShowTaskCompletionError={isShowTaskCompletionError}
            taskCompletionErrorHeader={taskCompletionErrorHeader}
            taskCompletionError={taskCompletionError}
            currentTask={currentRow}
            toggleTaskCompletionError={toggleTaskCompletionError}
          />
      }
    </div>
  );
};

export default RightPanel;
