/*
  Modification: add Retail svg
  By: Komal Wable
  Date: 19/04/2023
*/
import { h } from 'preact';

const Retail = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" class={`${props.classes || ""}`} width={props.width || "10"} height={props.width || "10"} fill={props.fill || "none"} viewBox="0 0 24 20">
        <path d="M9.5 4.5H14.5" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.5 6.44446H14.5" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.5 8.38892L13.0417 11.5" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.5 8.38892H10.75" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.75 8.38889C13.5279 8.38889 13.5279 4.5 10.75 4.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20.8 1H3.2C1.98497 1 1 2.04467 1 3.33333V12.6667C1 13.9553 1.98497 15 3.2 15H20.8C22.015 15 23 13.9553 23 12.6667V3.33333C23 2.04467 22.015 1 20.8 1Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};

export default Retail;
