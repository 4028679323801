import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Hyundai = (props) => {
  return (
    <span title={props.title || ''} class={props.masterClasses || ''}>
      <svg class={props.classes || ""} xmlns="http://www.w3.org/2000/svg" id="logosandtypes_com" onclick={(e) => props.action && props.action(e)} width={props.width || "150pt"} height={props.height || "150pt"} viewBox={props.viewBox || "0 0 150 150"}>
        <path d="M0 0h150v150H0V0z" fill="none"/>
        <path d="M47.8 45.7c1.5-.3 2.6-.3 2.9.1.3.3.3.8 0 1.2l-.6.9C37 63.3 27 86 24.2 92.6c-.2.5-.4.9-.6 1.2-.2.5-.7.8-1.2.9-.6 0-1.1-.2-1.6-.5C12.1 88.8 7 82.2 7 74.9c0-13.1 16.8-24.3 40.8-29.2m13.4 9.8c1.2-2.9 3.4-9 8.2-11.4 1.5-.7 3.2-1.1 4.9-1.1h.7c13.7 0 26.3 1.9 37 5.1.1 0 .3.1.4.2 1 .3 1.5.8 1.5 1.3s-.2.9-.6 1.2c-.2.2-.4.3-.7.6-2.5 2.1-9.9 7.4-23.8 12.7-4.9 1.9-11 4.2-17.4 5.6-3.6.8-17.6 2.9-10.2-14.2m65 .5c.2-.5.7-.8 1.2-.9.6 0 1.1.2 1.5.5 8.7 5.4 13.8 12 13.8 19.3 0 13.1-16.8 24.3-40.8 29.2-1.5.3-2.6.3-2.9-.1-.3-.3-.3-.8 0-1.2l.6-.9c13.1-15.4 23.1-38.2 25.9-44.7.3-.4.5-.9.7-1.2M88.8 94.2c-1.2 2.9-3.4 9-8.2 11.4-1.5.7-3.2 1.1-4.9 1.1H75c-13.7 0-26.3-1.9-37-5.1l-.4-.2c-1-.3-1.5-.8-1.5-1.3s.2-.9.6-1.2c.2-.2.4-.4.7-.6 2.5-2.1 9.8-7.4 23.8-12.7 4.9-1.9 11-4.2 17.4-5.6 3.6-.8 17.6-2.9 10.2 14.2m-13.9 18.6c40.8 0 73.9-17 73.9-37.9S115.7 37 74.9 37 .9 54 .9 74.9s33.1 37.9 74 37.9" fill={props.fill || "#002c5e"}/>
      </svg>
    </span>
  );
};

export default Hyundai;
